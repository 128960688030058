import { consoleRequest } from './requests';

class ExternalRequest {
  constructor() {
    this.externalCommunication = 'api/external-communication/v1';
    this.core = 'api/core/v1';
  }

  getTicketDetail(ticketId) {
    return consoleRequest.get(`${this.externalCommunication}/tickets/${ticketId}`);
  }

  createTicket(data = {}) {
    return consoleRequest.post(`${this.externalCommunication}/tickets/`, data);
  }

  getContext() {
    return consoleRequest.get(`${this.core}/context/`);
  }
}

export default new ExternalRequest();
