import React from 'react';
import { SaveOutlined } from '@ant-design/icons';

import {
  Avatar, Button, Divider, Drawer, Input, Tag,
} from 'antd';

import { HeaderWrapperContext } from '../Context/HeaderContext';
import { photoBgApiUrl } from '../../../constants/general.constants';
import AccountRequests from '../../../helpers/requests/accountRequests';
import { UPDATE_ACCOUNT } from '../../../views/accountsView/AccountsViewReducer';
import { AccountContext } from '../../../views/accountsView/account/AccountContext';
import { AccountsViewContext } from '../../../views/accountsView/AccountsViewContext';

import Styles from './DrawerWrapper.module.scss';

const controller = new AbortController();

const DrawerWrapper = () => {
  const [viewContext] = AccountsViewContext();
  const [{ currentAccountId: accountId }] = AccountContext();
  const [
    {
      role,
      canEdit,
      isUpdating,
      canEditLogo,
      isDrawerOpen,
      inputsValues,
      userNameLetter,
      canEditOrgName,
      setInputsValues,
      handleInputChange,
      handleToggleDrawer,
      canEditInitiativeName,
    },
  ] = HeaderWrapperContext();

  const {
    user: { username, email = 'unknow' },
  } = viewContext;

  const {
    name: initiativeName,
    profile_url: webSiteUrl,
    profile_logo_url: clientLogoUrl,
  } = viewContext?.accounts[accountId] || { name: '' };

  const [, accountsViewDispatch] = AccountsViewContext();

  const showRoleTag = role !== '';

  const updateData = () => {
    AccountRequests.patch(
      (response) => {
        const state = { [accountId]: response.data };
        accountsViewDispatch({ type: UPDATE_ACCOUNT, state });
        handleToggleDrawer();
      },
      (error) => {
        setInputsValues({
          ...inputsValues,
          errorsFromRequest: error.response.data,
        });
      },
      accountId,
      inputsValues,
      controller,
    );
  };

  const resetDrawer = () => {
    handleToggleDrawer();
    controller.abort();
  };

  /**
   * It's weird, but it's the only way to make the drawer styles work.
   *
   * @link https://ant.design/components/drawer#api
   */
  const drawerBodyStyles = {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  };

  const drawerFooterStyles = {
    textAlign: 'center',
  };

  return (
    <Drawer
      title="Account details"
      placement="right"
      open={isDrawerOpen}
      onClose={resetDrawer}
      bodyStyle={drawerBodyStyles}
      footerStyle={drawerFooterStyles}
    >
      <div>
        <img src={photoBgApiUrl} className={Styles.drawerBgImage} alt="" />
        <div className={Styles.drawerUserInfoWrapper}>
          <Avatar
            className={Styles.drawerAvatar}
            size={{
              xs: 50,
              sm: 50,
              md: 50,
              lg: 50,
              xl: 65,
              xxl: 65,
            }}
          >
            {userNameLetter}
          </Avatar>
          <p className={Styles.userName}>{username}</p>
          <p className={Styles.userRole}>{email}</p>
          {showRoleTag && (
            <Tag color="purple" className={Styles.drawerTagRole}>
              {role}
            </Tag>
          )}
        </div>
      </div>

      <Divider />

      <div className={Styles.drawerInfoWrapper}>
        <div className={Styles.drawerInputWrapper}>
          <p className={Styles.inputName}>Account name</p>
          <Input
            placeholder={initiativeName}
            value={inputsValues.name}
            disabled={!canEditInitiativeName}
            onChange={handleInputChange}
            name="name"
            status={inputsValues.errorsFromRequest?.name && 'error'}
          />
        </div>
        <div className={Styles.drawerInputWrapper}>
          <p className={Styles.inputName}>Logo url</p>
          <Input
            placeholder={clientLogoUrl}
            value={inputsValues.profile_logo_url}
            disabled={!canEditLogo}
            onChange={handleInputChange}
            name="profile_logo_url"
          />
        </div>
        <div className={Styles.drawerInputWrapper}>
          <p className={Styles.inputName}>Website URL</p>
          <Input
            placeholder={webSiteUrl}
            value={inputsValues.profile_url}
            disabled={!canEditOrgName}
            onChange={handleInputChange}
            name="profile_url"
          />
        </div>
        {canEdit && (
          <Button
            type="primary"
            icon={<SaveOutlined />}
            size="large"
            className={Styles.drawerSaveButton}
            onClick={updateData}
            ghost
            disabled={!isUpdating}
          >
            Save
          </Button>
        )}
      </div>
    </Drawer>
  );
};

export default DrawerWrapper;
