/**
 *  DOCUMENTATION LINKS CONSTANTS CONTROL CENTER
 *  In this file the documentation links constants of the project are configured.
 */

const documentationBaseUrl = `${process.env.DOCS_URL}/en/latest/`;
const mySiteBaseDocumentationUrl = `${documentationBaseUrl}external/managers/site_integrations/`;

export default {
  index: `${documentationBaseUrl}index.html`,
  sla: 'https://www.edunext.co/sla/',
  termsOfUse: 'https://www.edunext.co/terms-of-use/',
  resources: 'https://www.edunext.co/resources/',
  courseAuthoring: `${documentationBaseUrl}external/course_creators/authoring_courses/`,
  courseCreators: `${documentationBaseUrl}external/course_creators/`,
  google: `${mySiteBaseDocumentationUrl}Google_oauth_app_configuration_customer.html`,
  linkedin: `${mySiteBaseDocumentationUrl}Linkedin_oauth_app_configuration_customer.html`,
  facebook: `${mySiteBaseDocumentationUrl}Facebook_oauth_app_configuration_customer.html`,
  microsoft: `${mySiteBaseDocumentationUrl}Office365_oauth_app_configuration_customer.html`,
  saml: `${mySiteBaseDocumentationUrl}SAML_customer_guide.html`,
  oauth: `${mySiteBaseDocumentationUrl}OIDC_oauth_app_configuration_customer.html`,
};
