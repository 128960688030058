import React from 'react';
import PropTypes from 'prop-types';

import UpgradeSubscriptionButton from '../../upgradeSubscriptionButton/UpgradeSubscriptionButton';
import SupportInfoStyles from '../SupportInfo.module.scss';

/**
 * Component that renders support lite type render.
 *
 * @param {string} productId - Product external key from tab position (index).
 */
const SupportLite = ({ productId }) => (
  <div className={SupportInfoStyles.wrapper} id="list">
    <p className={SupportInfoStyles.textInfo}>
      Your
      <span className={SupportInfoStyles.txtSubscription}>
        {' '}
        lite subscription
        {' '}
      </span>
      is limited in functionality and support.
    </p>
    <p className={SupportInfoStyles.textInfo}>
      You can easily upgrade to enjoy the additional benefits of the paid
      subscriptions and get access to additional funtionality and our
      customer support services.
    </p>
    <p className={SupportInfoStyles.textInfo}>
      <UpgradeSubscriptionButton as="link" productExternalKey={productId} />
      {' '}
      to enjoy the additional benefits of the paid subscriptions and get
      access to our customer support services.
    </p>
    <br />
    <div className="d-flex justify-content-center">
      <UpgradeSubscriptionButton productExternalKey={productId} />
    </div>
  </div>
);

SupportLite.propTypes = {
  productId: PropTypes.string.isRequired,
};

export default SupportLite;
