import React from 'react';
import PropTypes from 'prop-types';

import styles from './LoadingPage.module.scss';

/**
 * It returns a loading screen with the custom props.
 *
 * @param {string} message - Indicate to message loading.
 * @param {string} height -Indicate height style.
 *
 * @returns A div with a class of container and a height of the height prop.
 */
const LoadingPage = ({ message, height }) => (
  <div
    className={styles.container}
    style={{ height, maxHeight: '100%' }}
    data-testid="loading-root-element"
  >
    <div>
      <div className={styles.loader}>{message}</div>
      <div>{message}</div>
    </div>
  </div>
);

LoadingPage.propTypes = {
  message: PropTypes.node,
  height: PropTypes.string,
};

LoadingPage.defaultProps = {
  message: 'Loading...',
  height: '100vh',
};

export default LoadingPage;
