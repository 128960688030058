import { createContext, useContext } from 'react';

import apisDefaultImg from '../../../assets/apisDefault.jpg';
import xblockDefaultImg from '../../../assets/xblocDefault.png';
import defaultAttrImg from '../../../assets/attributeDefault.png';
import platformDefaultImg from '../../../assets/platformDefault.png';
import instanceDefaultImg from '../../../assets/instanceDefault.jpg';
import triggersDefaultImg from '../../../assets/triggersDefault.jpg';
import attributesDefaultImg from '../../../assets/triggersDefault.png';
import customizationDefaultImg from '../../../assets/customizationDefault.png';

export const attributeDefaultImages = {
  default: defaultAttrImg,
  apis: apisDefaultImg,
  xblock: xblockDefaultImg,
  attributes: attributesDefaultImg,
  triggers: triggersDefaultImg,
  platform: platformDefaultImg,
  instance: instanceDefaultImg,
  customization: customizationDefaultImg,
};

export const modalStatus = {
  hidden: 'hidden',
  creating: 'creating',
  updating: 'updating',
  deleting: 'deleting',
  displaying: 'displaying',
};

export const cardStyle = {
  card: 'card',
  list: 'list',
};

export const modalStatusContent = () => ({
  creating: {
    buttonStyle: 'addNewItem',
    text: {
      button: 'Save',
      title: 'Complete the following fields:',
    },
  },
  deleting: {
    buttonStyle: 'normalButton',
    text: {
      button: 'Confirm',
      title: 'Are you sure to delete this attribute?',
    },
  },
  displaying: {
    buttonStyle: 'normalButton',
    text: {
      button: 'Close',
      title: 'Attribute details:',
    },
  },
  updating: {
    style: 'confirmAction',
    text: {
      button: 'Update',
      title: 'Update attribute',
    },
  },
});

export const initialState = {
  loading: true,
  modalStatus: modalStatus.hidden,
  cardStyle: cardStyle.card,
  attributeItems: [],
  request: {
    data: {},
    inProgress: false,
  },
};

export const missConfiguredSubscriptions = [
  'inactive_stratus_subscription',
  'inactive_subscription',
  'else',
];

export const availableSubscriptions = [
  'nimbus_free',
  'nimbus_basic',
  'nimbus_enhanced',
  'nimbus_advanced',
  'on_premise',
  'cirrus',
  'cirrus_sn',
  'cirrus_se',
  'stratus',
];

export const Context = createContext();
Context.displayName = 'AttributeContext';

export const AttributeContext = () => useContext(Context);
