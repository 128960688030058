export const dateFormat = 'YYYY-MM-DD';

export const logicOperatorOptions = [
  {
    value: 'and',
    label: 'AND',
  },
  {
    value: 'or',
    label: 'OR',
  },
];

export const sortFilterLookupsChoices = [
  {
    value: 'product__name',
    label: 'Name subscription',
  },
  {
    value: 'product__billing_and_payment__next_invoice_date',
    label: 'Next invoice date',
  },
  {
    value: 'account__name',
    label: 'Company name',
  },
  {
    value: 'product__main_language',
    label: 'Language',
  },
  {
    value: 'account__main_country',
    label: 'Country',
  },
  {
    value: 'product__size',
    label: 'Size users',
  },
  {
    value: 'product__mau_quota',
    label: 'MAU Quotas',
  },
];

export default (disableds) => [
  {
    value: 'account',
    label: 'Account',
    children: [
      {
        value: '__',
        label: 'General Info',
        children: [
          {
            value: 'vertical',
            label: 'Vertical',
            disabled: disableds?.includes('account__vertical'),
          },
          {
            value: 'region',
            label: 'Continent',
            disabled: disableds?.includes('account__region'),
          },
          {
            value: 'main_country',
            label: 'Main Country',
            disabled: disableds?.includes('account__main_country'),
          },
          {
            value: 'referral',
            label: 'Referral',
            disabled: disableds?.includes('account__referral'),
          },
          {
            value: 'referral_date',
            label: 'Referral Date',
            disabled: disableds?.includes('account__referral_date'),
          },
          {
            value: 'referral_expiration',
            label: 'Referral Expiration',
            disabled: disableds?.includes('account__referral_expiration'),
          },
          {
            value: 'date_created',
            label: 'Date Created',
            disabled: disableds?.includes('account__date_created'),
          },
        ],
      },
      {
        value: '__contact__',
        label: 'Contact',
        children: [
          {
            value: 'name',
            label: 'Contact Name',
            disabled: disableds?.includes('account__contact__name'),
          },
          {
            value: 'category',
            label: 'Category',
            disabled: disableds?.includes('account__contact__category'),
          },
        ],
      },
    ],
  },
  {
    value: 'product',
    label: 'Subscription',
    children: [
      {
        value: '__',
        label: 'General Info',
        children: [
          {
            value: 'is_active',
            label: 'Service Is Active',
            disabled: disableds?.includes('product__is_active'),
          },
          {
            value: 'type',
            label: 'Type Subscription',
            disabled: disableds?.includes('product__type'),
          },
          {
            value: 'status',
            label: 'Status Subscription',
            disabled: disableds?.includes('product__status'),
          },

          {
            value: 'date_upgraded',
            label: 'Date Upgraded',
            disabled: disableds?.includes('product__date_upgraded'),
          },
          {
            value: 'date_downgraded',
            label: 'Date Downgraded',
            disabled: disableds?.includes('product__date_downgraded'),
          },
          {
            value: 'tier',
            label: 'Tier',
            disabled: disableds?.includes('product__tier'),
          },
          {
            value: 'mau_quota',
            label: 'MAU Quota',
            disabled: disableds?.includes('product__mau_quota'),
          },

          {
            value: 'size',
            label: 'Size Users',
            disabled: disableds?.includes('product__size'),
          },
          {
            value: 'main_language',
            label: 'Language',
            disabled: disableds?.includes('product__main_language'),
          },
        ],
      },
      {
        value: '__billing_and_payment__',
        label: 'Billing and Payment',
        children: [
          {
            value: 'billing_cycle',
            label: 'Billing Cycle',
            disabled: disableds?.includes('product__billing_and_payment__billing_cycle'),
          },
          {
            value: 'next_invoice_date',
            label: 'Next Invoice Date',
            disabled: disableds?.includes('product__billing_and_payment__next_invoice_date'),
          },

          {
            value: 'recurring_payment',
            label: 'Recurring Payment',
            disabled: disableds?.includes('product__billing_and_payment__recurring_payment'),
          },
          {
            value: 'payment_type',
            label: 'Payment Type',
            disabled: disableds?.includes('product__billing_and_payment__payment_type'),
          },
          {
            value: 'account__billing_alegra_last_sync',
            label: 'Last Sync With Alegra',
            disabled: disableds?.includes('product__billing_and_payment__account__billing_alegra_last_sync'),
          },
          {
            value: 'account__billing_info_last_changed',
            label: 'Last Billing Info Changed',
            disabled: disableds?.includes('product__billing_and_payment__account__billing_info_last_changed'),
          },
        ],
      },
      {
        value: '__rating__',
        label: 'Rating',
        children: [
          {
            value: 'maturity_rating',
            label: 'Maturity',
            disabled: disableds?.includes('product__rating__maturity_rating'),
          },
          {
            value: 'brand_rating',
            label: 'Brand',
            disabled: disableds?.includes('product__rating__brand_rating'),
          },
          {
            value: 'aesthetic_rating',
            label: 'Aesthetic',
            disabled: disableds?.includes('product__rating__aesthetic_rating'),
          },
          {
            value: 'features_rating',
            label: 'Features',
            disabled: disableds?.includes('product__rating__features_rating'),
          },
          {
            value: 'courses_rating',
            label: 'Courses',
            disabled: disableds?.includes('product__rating__courses_rating'),
          },
        ],
      },
      {
        value: '__team_member__',
        label: 'Team Member',
        children: [
          {
            value: 'name',
            label: 'Name',
            disabled: disableds?.includes('product__team_member__name'),
          },
          {
            value: 'role__account',
            label: 'Role By Account',
            disabled: disableds?.includes('product__team_member__role__account'),
          },
          {
            value: 'role__product',
            label: 'Role By Subscription',
            disabled: disableds?.includes('product__team_member__role__product'),
          },
          {
            value: 'role__site',
            label: 'Role By Site',
            disabled: disableds?.includes('product__team_member__role__site'),
          },
        ],
      },
    ],
  },
  {
    value: 'platform',
    label: 'Platform',
    children: [
      {
        value: '__applications__',
        label: 'Attributes',
        children: [
          {
            value: 'name',
            label: 'Name',
            disabled: disableds?.includes('platform__applications__name'),
          },
          {
            value: 'type',
            label: 'Type',
            disabled: disableds?.includes('platform__applications__type'),
          },
          {
            value: 'version',
            label: 'Version',
            disabled: disableds?.includes('platform__applications__version'),
          },
          {
            value: 'status',
            label: 'Status',
            disabled: disableds?.includes('platform__applications__status'),
          },
          {
            value: 'date_added',
            label: 'Date Added',
            disabled: disableds?.includes('platform__applications__date_added'),
          },
        ],
      },
      {
        value: '__infrastructures__',
        label: 'Infrastructures Attributes',
        children: [
          {
            value: 'name',
            label: 'Name',
            disabled: disableds?.includes('platform__infrastructures__name'),
          },
          {
            value: 'type',
            label: 'Type',
            disabled: disableds?.includes('platform__infrastructures__type'),
          },
          {
            value: 'version',
            label: 'Version',
            disabled: disableds?.includes('platform__infrastructures__version'),
          },
          {
            value: 'status',
            label: 'Status',
            disabled: disableds?.includes('platform__infrastructures__status'),
          },
          {
            value: 'date_added',
            label: 'Date Added',
            disabled: disableds?.includes('platform__infrastructures__date_added'),
          },
        ],
      },
      {
        value: '__customizations__',
        label: 'Customizations',
        children: [
          {
            value: 'name',
            label: 'Name',
            disabled: disableds?.includes('platform__customizations__name'),
          },
          {
            value: 'version',
            label: 'Version',
            disabled: disableds?.includes('platform__customizations__version'),
          },
          {
            value: 'status',
            label: 'Status',
            disabled: disableds?.includes('platform__customizations__status'),
          },
          {
            value: 'date_added',
            label: 'Date Added',
            disabled: disableds?.includes('platform__customizations__date_added'),
          },
        ],
      },
    ],
  },
  {
    value: 'site',
    label: 'Site',
    children: [
      {
        value: '__',
        label: 'General Info',
        children: [
          {
            value: 'type',
            label: 'Type',
            disabled: disableds?.includes('site__type'),
          },
          {
            value: 'language',
            label: 'Language',
            disabled: disableds?.includes('site__language'),
          },
        ],
      },
      {
        value: '__advanced_configs__',
        label: 'Advanced Configs',
        children: [
          {
            value: 'name',
            label: 'Name',
            disabled: disableds?.includes('site__advanced_configs__name'),
          },
          {
            value: 'type',
            label: 'Type',
            disabled: disableds?.includes('site__advanced_configs__type'),
          },
          {
            value: 'version',
            label: 'Version',
            disabled: disableds?.includes('site__advanced_configs__version'),
          },
          {
            value: 'status',
            label: 'Status',
            disabled: disableds?.includes('site__advanced_configs__status'),
          },
          {
            value: 'date_added',
            label: 'Date Added',
            disabled: disableds?.includes('site__advanced_configs__date_added'),
          },
          {
            value: 'support_through',
            label: 'Support Through',
            disabled: disableds?.includes('site__advanced_configs__support_through'),
          },
        ],
      },
      {
        value: '__mobile_apps__',
        label: 'Mobile Apps',
        children: [
          {
            value: 'name',
            label: 'Name',
            disabled: disableds?.includes('site__mobile_apps__name'),
          },
          {
            value: 'version',
            label: 'Version',
            disabled: disableds?.includes('site__mobile_apps__version'),
          },
          {
            value: 'status',
            label: 'Status',
            disabled: disableds?.includes('site__mobile_apps__status'),
          },
          {
            value: 'date_added',
            label: 'Date Added',
            disabled: disableds?.includes('site__mobile_apps__date_added'),
          },
          {
            value: 'support_through',
            label: 'Support Through',
            disabled: disableds?.includes('site__mobile_apps__support_through'),
          },
        ],
      },
      {
        value: '__apis__',
        label: 'Apis',
        children: [
          {
            value: 'name',
            label: 'Name',
            disabled: disableds?.includes('site__apis__name'),
          },
          {
            value: 'version',
            label: 'Version',
            disabled: disableds?.includes('site__apis__version'),
          },
          {
            value: 'status',
            label: 'Status',
            disabled: disableds?.includes('site__apis__status'),
          },
          {
            value: 'date_added',
            label: 'Date Added',
            disabled: disableds?.includes('site__apis__date_added'),
          },
          {
            value: 'support_through',
            label: 'Support Through',
            disabled: disableds?.includes('site__apis__support_through'),
          },
        ],
      },
      {
        value: '__triggers__',
        label: 'Triggers',
        children: [
          {
            value: 'name',
            label: 'Name',
            disabled: disableds?.includes('site__triggers__name'),
          },
          {
            value: 'version',
            label: 'Version',
            disabled: disableds?.includes('site__triggers__version'),
          },
          {
            value: 'status',
            label: 'Status',
            disabled: disableds?.includes('site__triggers__status'),
          },
          {
            value: 'date_added',
            label: 'Date Added',
            disabled: disableds?.includes('site__triggers__date_added'),
          },
          {
            value: 'support_through',
            label: 'Support Through',
            disabled: disableds?.includes('site__triggers__support_through'),
          },
        ],
      },
      {
        value: '__customizations__',
        label: 'Customizations',
        children: [
          {
            value: 'name',
            label: 'Name',
            disabled: disableds?.includes('site__customizations__name'),
          },
          {
            value: 'version',
            label: 'Version',
            disabled: disableds?.includes('site__customizations__version'),
          },
          {
            value: 'status',
            label: 'Status',
            disabled: disableds?.includes('site__customizations__status'),
          },
          {
            value: 'date_added',
            label: 'Date Added',
            disabled: disableds?.includes('site__customizations__date_added'),
          },
          {
            value: 'support_through',
            label: 'Support Through',
            disabled: disableds?.includes('site__customizations__support_through'),
          },
        ],
      },
    ],
  },
];
