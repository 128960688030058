import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Card } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';

import { AccountsViewContext } from '../../../views/accountsView/AccountsViewContext';
import CustomButton from '../../customButton/CustomButton';

import { getLabelByValue } from '../../../helpers/utils';

import Styles from './SubscriptionDetailCard.module.scss';

/**
 * Component that renders a subscription detail element like the ones in '/accounts'
 * @param {object} data - Subscription information object.
 * @param {func} onClose - Function that is triggered when the element is selected.
 */
const SubscriptionDetailCard = ({ data, onClose }) => {
  if (!data) {
    return null;
  }
  const [viewContext] = AccountsViewContext();

  return (
    <Card
      className={Styles.subscriptionDetail}
      headStyle={{ padding: 0, minHeight: 'auto' }}
      bodyStyle={{ padding: 0 }}
      title={(
        <div className={Styles.closeButtonContent}>
          <button
            className={Styles.closeButton}
            type="button"
            aria-label="Button close content"
            onClick={onClose}
          >
            <CloseOutlined />
          </button>
        </div>
      )}
    >
      <div className={Styles.body}>
        <div className={Styles.content}>
          <div className={Styles.contentElement} style={{ margin: 0 }}>
            <div>Account:</div>
            <div>External Key:</div>
          </div>
          <div className={Styles.contentElement} style={{ margin: 0 }}>
            <div className={Styles.titleLinklessSecondary}>{data?.account?.name}</div>
            <div className={Styles.subtitleSecondary}>{data?.account?.external_key}</div>
          </div>
          <br />
          <div className={Styles.contentElement}>
            <div>
              <span>Created date: </span>
              <span className={Styles.subtitle}>
                { data?.account?.created_date ? moment(data.account.created_date).utc().format('DD/MM/YYYY') : '-' }
              </span>
            </div>
            <div>
              <span>CRM ID: </span>
              <span className={Styles.subtitle}>{data?.account?.CRM_id || '-'}</span>
            </div>
          </div>
          <div className={Styles.contentElement}>
            <div>
              <span>Vertical: </span>
              <span className={Styles.subtitle}>
                {getLabelByValue(viewContext.options, 'account_vertical_choices', data?.account?.vertical) || '-'}
              </span>
            </div>
            <div>
              <span>ERP ID: </span>
              <span className={Styles.subtitle}>{data?.account?.erp_id || '-'}</span>
            </div>
          </div>
          <div className={Styles.contentElement}>
            <div>
              <div>Other names:</div>
              <div className={Styles.subtitle}>{data?.account?.profile_other_names || '-'}</div>
            </div>
          </div>
          <div className={Styles.contentElement}>
            <div>
              <div>Company name for billing: </div>
              <div className={Styles.subtitle}>{data?.account?.billing_name || '-'}</div>
            </div>
          </div>
          <div className={Styles.contentElement}>
            <div>
              <div>Contact: </div>
              <div className={Styles.subtitle}>{data?.account?.billing_name || '-'}</div>
            </div>
          </div>
        </div>

        <hr />

        <div className={Styles.content}>
          <div className={Styles.contentElement} style={{ margin: 0 }}>
            <div>Subscription:</div>
            <div>External Key:</div>
          </div>
          <div className={Styles.contentElement} style={{ margin: 0 }}>
            <div className={Styles.titleLinklessSecondary}>
              <Link
                className={Styles.link}
                to={`/accounts/${data?.account?.external_key}/products/${data?.external_key}`}
              >
                <span className={Styles.title}>{data.name}</span>
              </Link>
            </div>
            <div className={Styles.subtitleSecondary}>{data?.external_key}</div>
          </div>
          <br />
          <div className={Styles.contentElement}>
            <div>
              <span>Size users: </span>
              <span className={Styles.subtitle}>{data?.size || '-'}</span>
            </div>
          </div>
          <div className={Styles.contentElement}>
            <div>
              <span>Billing info last change: </span>
              <span className={Styles.subtitle}>
                {
                  data?.account?.billing_info_last_changed
                    ? moment(data.account.billing_info_last_changed).utc().format('DD/MM/YYYY') : '-'
                }
              </span>
            </div>
          </div>
          <div className={Styles.contentElement}>
            <div>
              <span>Profile PayPal: </span>
              {
                data?.billing_paypal_profile_id ? (
                  <a
                    className={Styles.link}
                    href={`${process.env.PAYPAL_URL}${data.billing_paypal_profile_id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Go to PayPal Profile
                  </a>
                ) : (
                  <span className={Styles.subtitle}>-</span>
                )
              }
            </div>
          </div>
          <div className={Styles.contentElement}>
            <div>
              <span>Mau quota: </span>
              <span className={Styles.subtitle}>{data?.mau_quota || '-'}</span>
            </div>
          </div>
        </div>

        <div className={Styles.footer}>
          <Link
            to={`/accounts/${data?.account?.external_key}/products/${data?.external_key}`}
          >
            <CustomButton variant="normalButton">
              View More Information
            </CustomButton>
          </Link>
        </div>
      </div>
    </Card>
  );
};

SubscriptionDetailCard.propTypes = {
  data: PropTypes.shape({
    account: PropTypes.shape({
      name: PropTypes.string,
      vertical: PropTypes.string,
      crm_id: PropTypes.string,
      billing_alegra_id: PropTypes.string,
      profile_logo_url: PropTypes.string,
      external_key: PropTypes.string,
      created_date: PropTypes.string,
      CRM_id: PropTypes.string,
      erp_id: PropTypes.string,
      profile_other_names: PropTypes.string,
      billing_name: PropTypes.string,
      billing_info_last_changed: PropTypes.string,
    }).isRequired,
    is_active: PropTypes.bool,
    name: PropTypes.string,
    external_key: PropTypes.string,
    mau_quota: PropTypes.string,
    billing_paypal_profile_id: PropTypes.string,
    size: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SubscriptionDetailCard;
