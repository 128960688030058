import React from 'react';
import PropTypes from 'prop-types';
import cardStyles from './Card.module.scss';

/**
 * Card component that allows to show a main component
 * and a set of sub-component in columns.
 *
 * @component
 * @example
 * const columnsNumber = 2
 * const children = in this example <h2> as main component and <h3> as complementary components,
 * the parameter unique must be set true if the component is unique per row.
 * return (
 *   <Card columnsNumber={2}>
 *     <h2>Main component</h2>
 *     <h3 unique="true">Unique component</h3>
 *     <h3>Complementary component</h3>
 *     <h3>Complementary component</h3>
 *     <h3>Complementary component</h3>
 *   </Card>
 * )
 */
function Card({
  children,
  columnsNumber,
  className,
  hasMainElement,
  classCard,
  classMainElement,
  classElement,
}) {
  let aux = [];
  const elements = [];
  const keyRow = 'card-element';
  const keyElement = 'card-element';
  const childrenArray = React.Children.toArray(children);
  const main = hasMainElement ? childrenArray.shift() : null;

  childrenArray.map((child) => {
    if (child.props.unique === 'true') {
      elements.push(
        <div key={keyRow + elements.length} className={cardStyles.row}>
          <div key={keyElement + aux.length} className={`${cardStyles.element} ${className != null ? className : ''}`}>
            {React.cloneElement(child, null)}
          </div>
        </div>,
      );
      return null;
    }

    aux.push(
      <div key={keyElement + aux.length} className={`${cardStyles.element} ${classElement != null ? classElement : ''}`}>
        {React.cloneElement(child, null)}
      </div>,
    );

    if (aux.length === columnsNumber) {
      elements.push(<div key={keyRow + elements.length} className={cardStyles.row}>{aux}</div>);
      aux = [];
    }

    return null;
  });

  if (aux.length > 0) {
    elements.push(<div key={keyRow + elements.length} className={cardStyles.row}>{aux}</div>);
  }

  return (
    <div className={`${cardStyles.container} ${className != null ? className : ''}`}>
      {
        hasMainElement
        && (
          <div className={`${cardStyles.mainElement} ${classMainElement != null ? classMainElement : ''}`}>{React.cloneElement(main, null)}</div>
        )
      }
      <div className={`${cardStyles.rows} ${classCard != null ? classCard : ''}`}>{elements}</div>
    </div>
  );
}

Card.propTypes = {
  /**
   * Container elements
   */
  children: PropTypes.node.isRequired,
  /**
   * Number of columns for the info section.
   */
  columnsNumber: PropTypes.number,
  /**
   * Class name aditionals
   */
  className: PropTypes.string,
  /**
   * Class name aditionals
   */
  classMainElement: PropTypes.string,
  /**
   * Class name aditionals
   */
  classElement: PropTypes.string,
  /**
   * Class name additional
   */
  classCard: PropTypes.string,
  /**
   * Indicate if the first element children
   * in Card is main
   */
  hasMainElement: PropTypes.bool,
};

Card.defaultProps = {
  columnsNumber: 2,
  className: null,
  classMainElement: null,
  classCard: null,
  classElement: null,
  hasMainElement: true,
};

export default Card;
