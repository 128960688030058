import React from 'react';
import { useParams } from 'react-router-dom';

import ZohoForm from '../ZohoForm/ZohoForm';
import ModalWrapperWithOpenButton from '../ModalWrapperWithOpenButton/ModalWrapperWithOpenButton';
import CustomButton from '../customButton/CustomButton';
import { AccountContext } from '../../views/accountsView/account/AccountContext';

const DeveloperApiForm = () => {
  const { siteId } = useParams();
  const [accountContext] = AccountContext();
  const {
    name,
    lms_base: lmsBase,
  } = accountContext.tenants.find((tenant) => tenant.external_key === siteId);

  return (
    <div className="d-flex align-items-center flex-wrap">
      <a
        href={lmsBase ? `${lmsBase}/eox-core/api-docs/` : '#'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <CustomButton variant="normalOutLine">
          Click to see the API docs
        </CustomButton>
      </a>
      <ModalWrapperWithOpenButton
        title="Open a new support ticket"
        openButtonText="Request the EOX_CORE API credentials"
      >
        <ZohoForm
          showEmail
          showOptions
          disableType
          showSubject
          showUserName
          disableSubject
          showDescription={false}
          showAttachments={false}
          subject="EOX APIs add-on activation request"
          extraFields={[
            {
              id: 'url-lms-site',
              name: 'url-lms-site',
              label: 'URL for your LMS site',
              isRequired: true,
              value: name,
              isDisabled: true,
            },
          ]}
        />
      </ModalWrapperWithOpenButton>
    </div>
  );
};

export default DeveloperApiForm;
