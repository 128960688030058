/**
 *  PLATFORM CONSTANTS CONTROL CENTER
 *  In this file the platform constants of the project are configured.
 *  In this file we will find ONLY the keys used in the Platform view.
 *
 *  WARNINGS:
 *            - When you need to include more than one HTML tag, they must be contained in
 *              a parent HTML tag, for example:
 *              Examples of incorrect code:
 *              (
 *                <h1>Hello World!</h1>
 *                <p>Lorem ipsum ... </p>
 *              )
 *
 *              Examples of correct code:
 *              (
 *                <>
 *                    <h1>Hello World!</h1>
 *                    <p>Lorem ipsum ... </p>
 *                </>
 *              )
 *
 *            - Make sure to preserve both the structure and the indentation, our format is:
 *              {
 *                section_name: {
 *                    static: (... Text|HTML),
 *                    variable: {
 *                        variable_1: (... Text|HTML),
 *                        ...
 *                    },
 *                },
 *                ...
 *              }
 */
import React from 'react';
import { Col, Row } from 'antd';

import ZohoForm from '../components/ZohoForm/ZohoForm';

import intermediate from '../assets/intermediate.png';

import { NOT_AVAILABLE } from './messages.constants';
import {
  INACTIVE_SUBSCRIPTION,
  INACTIVE_STRATUS_SUBSCRIPTION,
  MISCONFIGURED_SUBSCRIPTION,
} from './subscription.constants';

const REQUEST_NEW_ORG_TAB_1_CONTENT = (
  <Row justify="center" align="middle">
    <Col xl={{ span: 12 }} lg={{ span: 24 }}>
      <div style={{ textAlign: 'center' }}>
        <img src={intermediate} alt="intermediate" style={{ maxWidth: '400px', width: '100%' }} />
      </div>
    </Col>
    <Col xl={{ span: 12 }} lg={{ span: 24 }}>
      <div style={{ maxWidth: '600px', margin: '0 auto' }}>
        <ZohoForm
          subject="Request new organization"
          showUserName={false}
          showEmail={false}
          showSubject={false}
          showOptions={false}
          showDescription={false}
          showAttachments={false}
          extraFields={[
            {
              id: 'short-name-org',
              name: 'short-name-org',
              label: 'Short Name for the Org that shall be created',
              isRequired: true,
            },
            {
              id: 'what-site-org',
              name: 'what-site-org',
              label: 'What site should now include courses from your new ORG?',
              isRequired: true,
            },
            {
              id: 'user-emails-studio',
              name: 'user-emails-studio',
              label: 'List any user emails that should be granted access to the creating courses for this new organization in STUDIO.',
              isRequired: true,
            },
          ]}
        />
      </div>
    </Col>
  </Row>
);

const VIEW_ALL_ORG_TAB_1_CONTENT = (
  <Row justify="center" align="middle">
    <Col xl={{ span: 12 }} lg={{ span: 24 }}>
      <div style={{ textAlign: 'center' }}>
        <img src={intermediate} alt="intermediate" style={{ maxWidth: '400px', width: '100%' }} />
      </div>
    </Col>
    <Col xl={{ span: 12 }} lg={{ span: 24 }}>
      <div style={{ maxWidth: '600px', margin: '0 auto' }}>
        <p style={{ marginBottom: 10 }}>
          The list of organizations cannot be viewed in STUDIO or the LMS,
          however you can request it from the support team.
        </p>
        <ZohoForm
          subject="Request my list of ORGs"
          submitButtonText="Request my list of ORGs"
          showUserName={false}
          showEmail={false}
          showSubject={false}
          showOptions={false}
          showDescription={false}
          showAttachments={false}
        />
      </div>
    </Col>
  </Row>
);

const EDIT_ORG_TAB_1_CONTENT = (
  <Row justify="center" align="middle">
    <Col xl={{ span: 12 }} lg={{ span: 24 }}>
      <div style={{ textAlign: 'center' }}>
        <img src={intermediate} alt="intermediate" style={{ maxWidth: '400px', width: '100%' }} />
      </div>
    </Col>
    <Col xl={{ span: 12 }} lg={{ span: 24 }}>
      <div style={{ maxWidth: '600px', margin: '0 auto' }}>
        <ZohoForm
          subject="Edit organization"
          showUserName={false}
          showEmail={false}
          showSubject={false}
          showOptions={false}
          showDescription={false}
          showAttachments={false}
          extraFields={[
            {
              id: 'short-name-org',
              name: 'short-name-org',
              label: 'Short Name for the Org that shall be edited',
              isRequired: true,
            },
            {
              id: 'change-want-make',
              name: 'change-want-make',
              label: 'What changes do you want to make?',
              type: 'textarea',
              helpType: 'code',
              textHelp: 'In this field, you can add extra questions before performing this action.'
              + ' '
              + 'Note: This record will be linked with courses, and all their corresponding data.',
              isRequired: true,
            },
            {
              id: 'describe-change-want-make',
              name: 'describe-change-want-make',
              label: 'Describe in detail the change you want to make in your organization',
              type: 'textarea',
              isRequired: true,
            },
          ]}
        />
      </div>
    </Col>
  </Row>
);

const DELETE_ORG_TAB_1_CONTENT = (
  <Row justify="center" align="middle">
    <Col xl={{ span: 12 }} lg={{ span: 24 }}>
      <div style={{ textAlign: 'center' }}>
        <img src={intermediate} alt="intermediate" style={{ maxWidth: '400px', width: '100%' }} />
      </div>
    </Col>
    <Col xl={{ span: 12 }} lg={{ span: 24 }}>
      <div style={{ maxWidth: '600px', margin: '0 auto' }}>
        <ZohoForm
          subject="Delete organization"
          showUserName={false}
          showEmail={false}
          showSubject={false}
          showOptions={false}
          showDescription={false}
          showAttachments={false}
          extraFields={[
            {
              id: 'short-name-org',
              name: 'short-name-org',
              label: 'Short Name for the Org that shall be deleted',
              isRequired: true,
            },
            {
              id: 'sure-delete',
              name: 'sure-delete',
              label: 'Are you sure that this is the organization you want to delete?',
              isRequired: true,
            },
          ]}
        />
      </div>
    </Col>
  </Row>
);

const getRequestCustomerSupportTab = (children) => ({
  label: 'Request Customer support',
  key: 0,
  children,
});

export default {
  request_new_org: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: [
        getRequestCustomerSupportTab(NOT_AVAILABLE.nimbus_free),
      ],
      nimbus_basic: [
        getRequestCustomerSupportTab(NOT_AVAILABLE.nimbus_basic),
      ],
      nimbus_enhanced: [
        getRequestCustomerSupportTab(REQUEST_NEW_ORG_TAB_1_CONTENT),
      ],
      nimbus_advanced: [
        getRequestCustomerSupportTab(REQUEST_NEW_ORG_TAB_1_CONTENT),
      ],
      on_premise: [
        getRequestCustomerSupportTab(REQUEST_NEW_ORG_TAB_1_CONTENT),
      ],
      cirrus: [
        getRequestCustomerSupportTab(REQUEST_NEW_ORG_TAB_1_CONTENT),
      ],
      cirrus_sn: [
        getRequestCustomerSupportTab(REQUEST_NEW_ORG_TAB_1_CONTENT),
      ],
      cirrus_se: [
        getRequestCustomerSupportTab(REQUEST_NEW_ORG_TAB_1_CONTENT),
      ],
      stratus: [
        getRequestCustomerSupportTab(REQUEST_NEW_ORG_TAB_1_CONTENT),
      ],
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  view_all_org: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: [
        getRequestCustomerSupportTab(NOT_AVAILABLE.nimbus_free),
      ],
      nimbus_basic: [
        getRequestCustomerSupportTab(VIEW_ALL_ORG_TAB_1_CONTENT),
      ],
      nimbus_enhanced: [
        getRequestCustomerSupportTab(VIEW_ALL_ORG_TAB_1_CONTENT),
      ],
      nimbus_advanced: [
        getRequestCustomerSupportTab(VIEW_ALL_ORG_TAB_1_CONTENT),
      ],
      on_premise: [
        getRequestCustomerSupportTab(VIEW_ALL_ORG_TAB_1_CONTENT),
      ],
      cirrus: [
        getRequestCustomerSupportTab(VIEW_ALL_ORG_TAB_1_CONTENT),
      ],
      cirrus_sn: [
        getRequestCustomerSupportTab(VIEW_ALL_ORG_TAB_1_CONTENT),
      ],
      cirrus_se: [
        getRequestCustomerSupportTab(VIEW_ALL_ORG_TAB_1_CONTENT),
      ],
      stratus: [
        getRequestCustomerSupportTab(VIEW_ALL_ORG_TAB_1_CONTENT),
      ],
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  edit_org: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: [
        getRequestCustomerSupportTab(NOT_AVAILABLE.nimbus_free),
      ],
      nimbus_basic: [
        getRequestCustomerSupportTab(EDIT_ORG_TAB_1_CONTENT),
      ],
      nimbus_enhanced: [
        getRequestCustomerSupportTab(EDIT_ORG_TAB_1_CONTENT),
      ],
      nimbus_advanced: [
        getRequestCustomerSupportTab(EDIT_ORG_TAB_1_CONTENT),
      ],
      on_premise: [
        getRequestCustomerSupportTab(EDIT_ORG_TAB_1_CONTENT),
      ],
      cirrus: [
        getRequestCustomerSupportTab(EDIT_ORG_TAB_1_CONTENT),
      ],
      cirrus_sn: [
        getRequestCustomerSupportTab(EDIT_ORG_TAB_1_CONTENT),
      ],
      cirrus_se: [
        getRequestCustomerSupportTab(EDIT_ORG_TAB_1_CONTENT),
      ],
      stratus: [
        getRequestCustomerSupportTab(EDIT_ORG_TAB_1_CONTENT),
      ],
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  delete_org: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: [
        getRequestCustomerSupportTab(NOT_AVAILABLE.nimbus_free),
      ],
      nimbus_basic: [
        getRequestCustomerSupportTab(DELETE_ORG_TAB_1_CONTENT),
      ],
      nimbus_enhanced: [
        getRequestCustomerSupportTab(DELETE_ORG_TAB_1_CONTENT),
      ],
      nimbus_advanced: [
        getRequestCustomerSupportTab(DELETE_ORG_TAB_1_CONTENT),
      ],
      on_premise: [
        getRequestCustomerSupportTab(DELETE_ORG_TAB_1_CONTENT),
      ],
      cirrus: [
        getRequestCustomerSupportTab(DELETE_ORG_TAB_1_CONTENT),
      ],
      cirrus_sn: [
        getRequestCustomerSupportTab(DELETE_ORG_TAB_1_CONTENT),
      ],
      cirrus_se: [
        getRequestCustomerSupportTab(DELETE_ORG_TAB_1_CONTENT),
      ],
      stratus: [
        getRequestCustomerSupportTab(DELETE_ORG_TAB_1_CONTENT),
      ],
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
};
