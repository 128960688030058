/**
 *  MODALS CONSTANTS CONTROL CENTER
 *  In this file the modals constants of the project are configured.
 */
import React from 'react';
import ZohoForm from '../components/ZohoForm/ZohoForm';
import ModalWrapperWithOpenButton from '../components/ModalWrapperWithOpenButton/ModalWrapperWithOpenButton';

// https://docs.google.com/forms/d/e/1FAIpQLSfOGiaTbDqHycaXNSUoBnOFuoHhNNM-snNJoAsE8yaQvmZqCg/viewform
export const MULTI_SITES_FORM = (
  <ModalWrapperWithOpenButton
    title="Multi sites add on - New site request"
    openButtonText="Request your new LMS site"
  >
    <ZohoForm
      subject="Multi sites add on - New site request"
      showUserName={false}
      showEmail={false}
      showSubject={false}
      showOptions={false}
      showDescription={false}
      extraFields={[
        {
          id: 'url-new-site',
          name: 'url-new-site',
          label: 'URL for your new site',
          textHelp:
            'Provide here the URL where your site will be located.'
            + 'Make sure you have already pointed your DNS records towards edunext,'
            + 'following the instructions provided here:'
            + '(https://www.edunext.co/provision/open-edx-in-your-own-domain/)'
            + 'You can also request a site to be created in an URL such as https://SOMETHING.edunext.io',
          isRequired: true,
          validation: { type: 'url', warningOnly: false },
        },
        {
          id: 'scratch-or-cloned',
          name: 'scratch-or-cloned',
          label:
            'Should the new site be created from scratch (with default configurations)'
            + 'or do you prefer the configurations to be cloned from one of your existing sites?',
          textHelp:
            'If you want one of your existing sites to be cloned, please provide the full URL of the site.',
          isRequired: true,
        },
        {
          id: 'short-name',
          name: 'short-name',
          label: 'Short Name for the Org that will be used for your new site',
          textHelp:
            'The short name for the org should be shorter than 10 characters,'
            + 'and must not have any accents, spaces, or special characters. If the name'
            + 'requested is not available, the edunext team will select a close alternative.',
          isRequired: true,
        },
        {
          id: 'list-users-emails',
          name: 'list-users-emails',
          label:
            'List users emails that should be granted permission to create courses with this new'
            + 'organization in STUDIO.',
          textHelp:
            'These users should have an active account in Studio, otherwise, permission cannot be granted.',
          isRequired: true,
        },
        {
          id: 'list-edunext-management',
          name: 'list-edunext-management',
          label:
            'List any edunext Control Center users that need to be granted'
            + 'reporting permissions for the new site.',
          textHelp:
            'The users should have an active account in the edunext'
            + 'Control Center, otherwise, permission cannot be granted.',
          isRequired: true,
        },
      ]}
    />
  </ModalWrapperWithOpenButton>
);

// https://docs.google.com/forms/d/e/1FAIpQLSeqpbnjOuf_yleKqsP_5K6fKVBBwLDrWnaXXaTxYB1Km6kOJQ/viewform
export const EMC_USER_FORM = (
  <ModalWrapperWithOpenButton
    title="New EMC user request"
    openButtonText="Request the addition of an admin user"
  >
    <ZohoForm
      subject="New EMC user request"
      showUserName={false}
      showEmail={false}
      showSubject={false}
      showOptions={false}
      showDescription={false}
      extraFields={[
        {
          id: 'email-user-created',
          name: 'email-user-created',
          label: 'Email for the user that will be created',
          textHelp:
            'Make sure that this same email has already been created in the LMS side,'
            + 'as the user for the edunext Control Center will be linked to the existing user in the LMS.',
          isRequired: true,
          validation: {
            type: 'email',
            warningOnly: false,
          },
        },
        {
          id: 'permission-user-created',
          name: 'permission-user-created',
          label: 'Permission for the user that will be created',
          textHelp:
            'Please indicate the permissions you require for this user.',
          isRequired: true,
        },
        {
          id: 'is-user-already-registered',
          name: 'is-user-already-registered',
          label:
            'Did you make sure that the user is already registered in your LMS site?',
          type: 'checkbox',
          options: ['Yes, the user is registered in your LMS site.'],
          isRequired: true,
        },
      ]}
    />
  </ModalWrapperWithOpenButton>
);

// https://docs.google.com/forms/d/e/1FAIpQLScUAFUf9Bml6xB0eJ70TKJNvG6vXjiyacdm9JNkLBlHxmdF0Q/viewform
export const MULTI_ORG_FORM = (
  <ModalWrapperWithOpenButton
    title="Multi org add on - New ORG request"
    openButtonText="Request your new organization."
  >
    <ZohoForm
      subject="Multi org add on - New ORG request"
      showUserName={false}
      showEmail={false}
      showSubject={false}
      showOptions={false}
      showDescription={false}
      extraFields={[
        {
          id: 'short-name-org',
          name: 'short-name-org',
          label: 'Short Name for the Org that shall be created',
          textHelp:
            'The short name for the org should be shorter than 10 characters, and must'
            + 'not have any accents, spaces or special characters.  If the name requested'
            + 'is not available, the edunext team will select a close alternative.',
          isRequired: true,
        },
        {
          id: 'include-courses',
          name: 'include-courses',
          label: 'What site should now include courses from your new ORG?',
          textHelp:
            'Provide here the url of the existing site that will now'
            + 'hold the courses of the newly created ORG',
          isRequired: true,
        },
        {
          id: 'list-users-email',
          name: 'list-users-email',
          label:
            'List any user emails that should be granted access to the creating courses for this new organization in STUDIO.',
          textHelp:
            'The users should already exist and be activated in studio, otherwise the permission cannot be granted.',
          isRequired: true,
        },
      ]}
    />
  </ModalWrapperWithOpenButton>
);

// https://docs.google.com/forms/d/e/1FAIpQLScB-rjgYR44GxFrKj1y5Ekg63reonFGAlEXzv8USkm1Q1C9SQ/viewform
export const CUSTOM_REGISTRATION_FIELD_FORM = (
  <ModalWrapperWithOpenButton
    title="Customized registration activation request"
    openButtonText="Request new fields"
  >
    <ZohoForm
      subject="Customized registration activation request"
      showUserName={false}
      showEmail={false}
      showSubject={false}
      showOptions={false}
      showDescription={false}
      extraFields={[
        {
          id: 'url-lms-site',
          name: 'url-lms-site',
          label: 'URL for your LMS site',
          textHelp:
            'Provide in the following field the URL where your site is located.',
          isRequired: true,
          validation: { type: 'url', warningOnly: false },
        },
        {
          id: 'name-field',
          name: 'name-field',
          label: 'Name of the field',
          textHelp:
            'This name should be short and will not be displayed in the page.',
          isRequired: true,
        },
        {
          id: 'label-field',
          name: 'label-field',
          label: 'Label',
          textHelp:
            'Provide the explanations that users will find next to the name of this'
            + 'field in the form.  For certain fields it may include basic HTML'
            + 'information such as links to external pages that open in a new window.'
            + 'In that case, provide the html here as well.',
          isRequired: true,
        },
        {
          id: 'instructions-field',
          name: 'instructions-field',
          label: 'Instructions',
          textHelp:
            'A short text indicating the user how to fill it out.'
            + 'It will be displayed once the user focus on the specific field.',
          isRequired: true,
        },
        {
          id: 'mandatory-field',
          name: 'mandatory-field',
          label: 'Define whether the new field is mandatory or not',
          type: 'radio',
          options: ['Required', 'Optional', 'Otro'],
          isRequired: true,
        },
        {
          id: 'mandatory-field-other',
          name: 'mandatory-field-other',
          label:
            'If you have chosen "Otro" in the previous option (mandatory), specify:',
          isRequired: false,
        },
        {
          id: 'error-field',
          name: 'error-field',
          label: 'Error message',
          textHelp:
            'For mandatory fields, provide the text that will be displayed when the user has not set a value for the field.',
          isRequired: true,
        },
        {
          id: 'position-field',
          name: 'position-field',
          label: 'Position of the field',
          textHelp:
            'Indicate the field after which this new field should be placed in the learner registration form.',
          isRequired: true,
        },
        {
          id: 'type-field',
          name: 'type-field',
          label: 'Type of field',
          textHelp:
            'Depending on the type of field you select, you can send the information for the creation of the new fields.'
            + 'For example: text, email, textarea, checkbox...',
          type: 'radio',
          options: ['Text', 'Email', 'Textarea', 'Checkbox', 'Dropdown-select'],
          isRequired: true,
        },
      ]}
    />
  </ModalWrapperWithOpenButton>
);

// https://docs.google.com/forms/d/1oAST18ipw3EtpVvmy-QN6xrc2Envf5Pw4McP9HYRWP4/edit?ts=61a7d063
export const CANCEL_SUBSCRIPTION_FORM = (
  <ModalWrapperWithOpenButton
    title="Subscription Cancellation Request"
    openButtonText="Cancel my subscription"
    openButtonVariant="cancelAction"
  >
    <ZohoForm
      subject="Subscription Cancellation Request"
      showUserName={false}
      showEmail={false}
      showSubject={false}
      showOptions={false}
      showDescription={false}
      extraFields={[
        {
          id: 'why-leaving-us',
          name: 'why-leaving-us',
          label: 'Why are you leaving us?',
          textHelp:
            'We would like to know the reasons behind your decision.'
            + 'We have listed a few options that we think you might have to cancel your subscription.',
          type: 'radio',
          options: [
            'The platform is quite complex and difficult to use',
            'I could not adapt my course content to the Open edX technology',
            'The support provided by edunext was not enough',
            'Platform costs were not aligned with our budget',
            'The e-learning initiative never turned out as expected',
            'We were just exploring the platform and defining if this technology was suitable for our project',
            'The UX(User experience) is limited',
            'Service not stable. Performance difficulties',
            'We found another provider',
            'Other...',
          ],
          isRequired: true,
        },
        {
          id: 'why-leaving-us-other',
          name: 'why-leaving-us-other',
          label:
            'If you have chosen "Other" in the previous option (Why are you leaving us?), specify:',
          isRequired: false,
        },
        {
          id: 'can-improve',
          name: 'can-improve',
          type: 'select',
          options: [
            {
              value: 'schedule-call',
              label: 'Schedule a call with the head of customer support',
            },
            {
              value: 'drop-line',
              label: 'Drop a line',
            },
            {
              value: 'cancel',
              label: 'Just cancel please',
            },
          ],
          label: 'Wait! Let us know how we can improve',
          textHelp: 'How would you like to give your feedback?',
          isRequired: true,
        },
        {
          id: 'feedback',
          name: 'feedback',
          type: 'textarea',
          label: 'We trully appreciate your feedback.',
          textHelp:
            'Please leave your comments below. Only if you have chosen "Drop a line" in the previous option.',
          isRequired: false,
        },
      ]}
    />
  </ModalWrapperWithOpenButton>
);

// https://docs.google.com/forms/d/e/1FAIpQLSeKkiGI_riJKbTrqcTFrzdKO058jhMS4M6mUZnxb-6wFtVubg/viewform
export const SPECIAL_STUDIO_PERMISSIONS_FORM = (
  <ModalWrapperWithOpenButton
    title="Special STUDIO permissions request"
    openButtonText="Request special permissions for the STUDIO platform"
  >
    <ZohoForm
      subject="Special STUDIO permissions request"
      showUserName={false}
      showEmail={false}
      showSubject={false}
      showOptions={false}
      showDescription={false}
      extraFields={[
        {
          id: 'email-user-permission-request',
          name: 'email-user-permission-request',
          label: 'Email for the user for which the permissions are requested',
          textHelp:
            'Make sure that this same email has already been created in the LMS side,'
            + 'as the user for the edunext management console will be linked to the existing user in the LMS.',
          isRequired: true,
          validation: {
            type: 'email',
            warningOnly: false,
          },
        },
        {
          id: 'need-permission',
          name: 'need-permission',
          label: 'Do you need permissions added or removed?',
          type: 'radio',
          options: ['Added', 'Removed'],
          isRequired: true,
        },
        {
          id: 'select-permission',
          name: 'select-permission',
          label: 'Select the permission that needs to be added / removed',
          type: 'checkbox',
          options: [
            'Course creation permissions for a particular ORG',
            'Admin permissions for all courses in a particular ORG',
            'Library creation permissions',
          ],
          isRequired: false,
        },
        {
          id: 'shortcode-org-need-permissions',
          name: 'shortcode-org-need-permissions',
          label:
            'Type the shortcode for the ORG where the permissions need to be applied',
          textHelp:
            'If your request is for more than one ORG, add the different orgs separated by commas.',
          isRequired: true,
        },
      ]}
    />
  </ModalWrapperWithOpenButton>
);

// https://docs.google.com/forms/d/e/1FAIpQLSd6SmfQKoZivd3NYz0JgfubWvAaxUrvw2OjV-xNQVYQOT-vyA/viewform
export const BULK_LMS_FORM_REQUEST_FORM = (
  <ModalWrapperWithOpenButton
    title="Bulk registration request"
    openButtonText="Request Bulk Registration"
  >
    <ZohoForm
      subject="Bulk registration request"
      showUserName={false}
      showEmail={false}
      showSubject={false}
      showOptions={false}
      showDescription={false}
      extraFields={[
        {
          id: 'url-lms-site',
          name: 'url-lms-site',
          label: 'URL for your LMS site',
          textHelp:
            'Provide in the following field the URL where your site is located. Make sure you have'
            + 'already pointed your DNS records towards edunext, following the instructions provided here:'
            + '(https://www.edunext.co/provision/open-edx-in-your-own-domain/).',
          isRequired: true,
          validation: { type: 'url', warningOnly: false },
        },
        {
          id: 'accounts-receive-email',
          name: 'accounts-receive-email',
          label:
            'Do you want the accounts to be activated upon creation or instead to receive an email for activating the account?',
          type: 'radio',
          options: [
            'Yes, activate the accounts as part of the bulk creation',
            'No. The learners should receive an email to activate their accounts manually',
            'Other',
          ],
          isRequired: true,
        },
        {
          id: 'accounts-receive-email-other',
          name: 'accounts-receive-email-other',
          label: 'If you have chosen "Other" in the previous option, specify:',
          isRequired: false,
        },
      ]}
    />
  </ModalWrapperWithOpenButton>
);

export const COURSE_DELETE_FORM_REQUEST_FORM = (
  <ModalWrapperWithOpenButton
    title="Course delete request"
    openButtonText="Request course deletion"
  >
    <ZohoForm
      subject="Course delete request"
      showUserName={false}
      showEmail={false}
      showSubject={false}
      showOptions={false}
      showDescription={false}
      extraFields={[
        {
          id: 'course-id',
          name: 'course-id',
          label: 'Course ID',
          textHelp:
            'Please send your request for deletion with the exact course_id.',
          isRequired: true,
        },
      ]}
    />
  </ModalWrapperWithOpenButton>
);

export const COURSE_MODIFY_FORM_REQUEST = (
  <ModalWrapperWithOpenButton
    title="Course modify request"
    openButtonText="Request course modification"
  >
    <ZohoForm
      subject="Course modify request"
      showUserName={false}
      showEmail={false}
      showSubject={false}
      showOptions={false}
      showDescription={false}
      extraFields={[
        {
          id: 'course-id',
          name: 'course-id',
          label: 'Course ID',
          textHelp:
            'Please send your request for deletion with the exact course_id.',
          isRequired: true,
        },
      ]}
    />
  </ModalWrapperWithOpenButton>
);

export const GOOGLE_SSO_INTEGRATION_FORM_REQUEST = (
  <ModalWrapperWithOpenButton
    title="Google SSO Integration Request"
    openButtonText="Request Google SSO Integration"
  >
    <ZohoForm
      subject="Google SSO Integration Request"
      showUserName={false}
      showEmail={false}
      showSubject={false}
      showOptions={false}
      showDescription={false}
      extraFields={[
        {
          id: 'url-lms-site',
          name: 'url-lms-site',
          label: 'URL for your LMS site',
          isRequired: true,
          validation: { type: 'url', warningOnly: false },
        },
        {
          id: 'type-sso-integration',
          name: 'type-sso-integration',
          label: 'Type of SSO integration required for this site',
          value: 'Google',
          isDisabled: true,
          isRequired: true,
        },
        {
          id: 'json-credentials',
          name: 'json-credentials',
          type: 'textarea',
          label:
            'Provide the JSON formated value for the credentials you have created in your google API project.',
          helpType: 'code',
          helpTitle: 'Example:',
          textHelp:
            '{"web":{"client_id":"xxxxxxxxxxx-xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx.apps.googleusercontent.com","project_id":"your_project_id","auth_uri":" https://accounts.google.com/o/oauth2/auth","token_uri":" https://accounts.google.com/o/oauth2/token","auth_provider_x509_cert_url":" https://www.googleapis.com/oauth2/v1/certs","client_secret":"xxXXXxxxXXXXxxxXXXXxxxxX","redirect_uris":[" https://YOURLMSDOMAIN/auth/complete/google-oauth2/"]}}',
          isRequired: true,
        },
        {
          id: 'sso-authentication-provider-default',
          name: 'sso-authentication-provider-default',
          label:
            'By default your SSO authentication provider will be called "Google". If you prefer a custom name type it below.',
          isRequired: false,
        },
        {
          id: 'authentication-optional-or-enforced',
          name: 'authentication-optional-or-enforced',
          label:
            'Should this method of authentication be optional, or should it be enforced?',
          type: 'radio',
          options: [
            'Optional. Learners can register or log in with Google, but also with the Open edX credentials.',
            'Enforced. Learners can only register or log in with Google.',
          ],
          isRequired: true,
        },
      ]}
    />
  </ModalWrapperWithOpenButton>
);

export const FACEBOOK_SSO_INTEGRATION_FORM_REQUEST = (
  <ModalWrapperWithOpenButton
    title="Facebook SSO Integration Request"
    openButtonText="Request Facebook SSO Integration"
  >
    <ZohoForm
      subject="Facebook SSO Integration Request"
      showUserName={false}
      showEmail={false}
      showSubject={false}
      showOptions={false}
      showDescription={false}
      extraFields={[
        {
          id: 'url-lms-site',
          name: 'url-lms-site',
          label: 'URL for your LMS site',
          isRequired: true,
          validation: { type: 'url', warningOnly: false },
        },
        {
          id: 'type-sso-integration',
          name: 'type-sso-integration',
          label: 'Type of SSO integration required for this site',
          value: 'Facebook',
          isDisabled: true,
          isRequired: true,
        },
        {
          id: 'app-id',
          name: 'app-id',
          label: 'App ID',
          isRequired: true,
        },
        {
          id: 'app-secret',
          name: 'app-secret',
          label: 'App Secret',
          isRequired: true,
        },
        {
          id: 'sso-authentication-provider-default',
          name: 'sso-authentication-provider-default',
          label:
            'By default your SSO authentication provider will be called "Facebook". If you prefer a custom name type it below.',
          isRequired: false,
        },
        {
          id: 'authentication-optional-or-enforced',
          name: 'authentication-optional-or-enforced',
          label:
            'Should this method of authentication be optional, or should it be enforced?',
          type: 'radio',
          options: [
            'Optional. Learners can register or log in with Facebook, but also with the Open edX credentials.',
            'Enforced. Learners can only register or log in with Facebook.',
          ],
          isRequired: true,
        },
      ]}
    />
  </ModalWrapperWithOpenButton>
);

export const LINKEDIN_SSO_INTEGRATION_FORM_REQUEST = (
  <ModalWrapperWithOpenButton
    title="LinkedIn SSO Integration Request"
    openButtonText="Request LinkedIn SSO Integration"
  >
    <ZohoForm
      subject="LinkedIn SSO Integration Request"
      showUserName={false}
      showEmail={false}
      showSubject={false}
      showOptions={false}
      showDescription={false}
      extraFields={[
        {
          id: 'url-lms-site',
          name: 'url-lms-site',
          label: 'URL for your LMS site',
          isRequired: true,
          validation: { type: 'url', warningOnly: false },
        },
        {
          id: 'type-sso-integration',
          name: 'type-sso-integration',
          label: 'Type of SSO integration required for this site',
          value: 'LinkedIn',
          isDisabled: true,
          isRequired: true,
        },
        {
          id: 'app-id',
          name: 'app-id',
          label: 'App ID',
          isRequired: true,
        },
        {
          id: 'app-secret',
          name: 'app-secret',
          label: 'App Secret',
          isRequired: true,
        },
        {
          id: 'sso-authentication-provider-default',
          name: 'sso-authentication-provider-default',
          label:
            'By default your SSO authentication provider will be called "LinkedIn". If you prefer a custom name type it below.',
          isRequired: false,
        },
        {
          id: 'authentication-optional-or-enforced',
          name: 'authentication-optional-or-enforced',
          label:
            'Should this method of authentication be optional, or should it be enforced?',
          type: 'radio',
          options: [
            'Optional. Learners can register or log in with LinkedIn, but also with the Open edX credentials.',
            'Enforced. Learners can only register or log in with LinkedIn.',
          ],
          isRequired: true,
        },
      ]}
    />
  </ModalWrapperWithOpenButton>
);

export const MICROSOFT_SSO_INTEGRATION_FORM_REQUEST = (
  <ModalWrapperWithOpenButton
    title="Microsoft SSO Integration Request"
    openButtonText="Request Microsoft SSO Integration"
  >
    <ZohoForm
      subject="Microsoft SSO Integration Request"
      showUserName={false}
      showEmail={false}
      showSubject={false}
      showOptions={false}
      showDescription={false}
      extraFields={[
        {
          id: 'url-lms-site',
          name: 'url-lms-site',
          label: 'URL for your LMS site',
          isRequired: true,
          validation: { type: 'url', warningOnly: false },
        },
        {
          id: 'type-sso-integration',
          name: 'type-sso-integration',
          label: 'Type of SSO integration required for this site',
          value: 'Microsoft',
          isDisabled: true,
          isRequired: true,
        },
        {
          id: 'app-id',
          name: 'app-id',
          label: 'Application ID - ms',
          isRequired: true,
        },
        {
          id: 'app-secret',
          name: 'app-secret',
          label: 'Application key',
          isRequired: true,
        },
        {
          id: 'valid-test-username',
          name: 'valid-test-username',
          label: 'Valid Test User - username ms',
          isRequired: true,
        },
        {
          id: 'valid-test-password',
          name: 'valid-test-password',
          label: 'Valid Test User - Password ms',
          textHelp:
            'About the test user:'
            + 'We also kindly ask you not to delete this user. We will use it from time to time to solve support tickets that'
            + 'you might send us in regard to authentication. Also, we use it to test that your SSO is working correctly'
            + 'when we do updates to the underlying tech of our service.',
          isRequired: true,
        },
        {
          id: 'sso-authentication-provider-default',
          name: 'sso-authentication-provider-default',
          label:
            'By default your SSO authentication provider will be called "Microsoft". If you prefer a custom name type it below.',
          isRequired: false,
        },
        {
          id: 'authentication-optional-or-enforced',
          name: 'authentication-optional-or-enforced',
          label:
            'Should this method of authentication be optional, or should it be enforced?',
          type: 'radio',
          options: [
            'Optional. Learners can register or log in with Microsoft, but also with the Open edX credentials.',
            'Enforced. Learners can only register or log in with Microsoft.',
          ],
          isRequired: true,
        },
      ]}
    />
  </ModalWrapperWithOpenButton>
);

export const SAML_SSO_INTEGRATION_FORM_REQUEST = (
  <ModalWrapperWithOpenButton
    title="SAML SSO Integration Request"
    openButtonText="Request SAML SSO Integration"
  >
    <ZohoForm
      subject="SAML SSO Integration Request"
      showUserName={false}
      showEmail={false}
      showSubject={false}
      showOptions={false}
      showDescription={false}
      extraFields={[
        {
          id: 'url-lms-site',
          name: 'url-lms-site',
          label: 'URL for your LMS site',
          isRequired: true,
          validation: { type: 'url', warningOnly: false },
        },
        {
          id: 'type-sso-integration',
          name: 'type-sso-integration',
          label: 'Type of SSO integration required for this site',
          value: 'SAML',
          isDisabled: true,
          isRequired: true,
        },
      ]}
    />
  </ModalWrapperWithOpenButton>
);

export const OAUTH_SSO_INTEGRATION_FORM_REQUEST = (
  <ModalWrapperWithOpenButton
    title="OAuth SSO Integration Request"
    openButtonText="Request OAuth SSO Integration"
  >
    <ZohoForm
      subject="OAuth SSO Integration Request"
      showUserName={false}
      showEmail={false}
      showSubject={false}
      showOptions={false}
      showDescription={false}
      extraFields={[
        {
          id: 'url-lms-site',
          name: 'url-lms-site',
          label: 'URL for your LMS site',
          isRequired: true,
          validation: { type: 'url', warningOnly: false },
        },
        {
          id: 'type-sso-integration',
          name: 'type-sso-integration',
          label: 'Type of SSO integration required for this site',
          value: 'OAuth',
          isDisabled: true,
          isRequired: true,
        },
        {
          id: 'client-id',
          name: 'client-id',
          label: 'Client ID',
          isRequired: true,
        },
        {
          id: 'client-secret',
          name: 'client-secret',
          label: 'Client Secret',
          isRequired: true,
        },
        {
          id: 'url-discovery-endpoint',
          name: 'url-discovery-endpoint',
          label: 'URL for the Discovery Endpoint',
          textHelp:
            'The discovery endpoint is a URL where there is a JSON document containing all the information required byOIDC to connect.  It is present on your Identity service on a URL that ends with “.well-known/openid-configuration/”.',
          isRequired: true,
        },
        {
          id: 'valid-test-username',
          name: 'valid-test-username',
          label: 'Valid Test User - username',
          isRequired: true,
        },
        {
          id: 'valid-test-password',
          name: 'valid-test-password',
          label: 'Valid Test User - Password',
          textHelp:
            'About the test user:'
            + 'We also kindly ask you not to delete this user. We will use it from time to time to solve support tickets that'
            + 'you might send us in regard to authentication. Also, we use it to test that your SSO is working correctly'
            + 'when we do updates to the underlying tech of our service.',
          isRequired: true,
        },
        {
          id: 'authentication-provider-call',
          name: 'authentication-provider-call',
          label: 'How should be your authentication provider called?',
          isRequired: true,
        },
        {
          id: 'authentication-optional-or-enforced',
          name: 'authentication-optional-or-enforced',
          label:
            'Should this method of authentication be optional, or should it be enforced?',
          type: 'radio',
          options: [
            'Optional. Learners can register or log in with my custom provider, but also with the Open edX credentials.',
            'Enforced. Learners can only register or log in with my custom provider.',
          ],
          isRequired: true,
        },
      ]}
    />
  </ModalWrapperWithOpenButton>
);

export const LMS_DOMAIN_ACTIVATION_FORM_REQUEST = (
  openButtonText = 'Request LMS Domain activation',
) => (
  <ModalWrapperWithOpenButton
    title="LMS Domain activation"
    openButtonText={openButtonText}
  >
    <ZohoForm
      subject="LMS Domain activation"
      showUserName={false}
      showEmail={false}
      showSubject={false}
      showOptions={false}
      showDescription={false}
      extraFields={[
        {
          id: 'url-lms-site',
          name: 'url-lms-site',
          label: 'URL for your LMS site',
          isRequired: true,
          validation: { type: 'url', warningOnly: false },
        },
      ]}
    />
  </ModalWrapperWithOpenButton>
);
