import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Form,
  Spin,
  Input,
  Result,
} from 'antd';

import {
  LoadingOutlined,
} from '@ant-design/icons';

import CustomButton from '../customButton/CustomButton';
import learnerRequests from '../../helpers/requests/learnerRequests';
import useNotificationFromStateChange from '../../helpers/hooks/useNotificationFromStateChange/useNotificationFromStateChange';
import { SERVICE_ERROR_MESSAGES } from '../../constants/general.constants';
import ServiceError from '../serviceError/ServiceError';

const learnerActionInfo = {
  'force-activation': {
    primaryContent: 'Sometimes you may need to force the activation of a user, for example in case they didn \'t receive the activation email.',
    formLearnerButtonTitle: 'Activate',
    loadingTitle: 'Activating user...',
    successResultTitle: 'Your user has been successfully activated!',
    successButtonTitle: 'Activate another user',
  },
  'force-password': {
    primaryContent: 'This will override the user password and the user will get a notification in their email about the operation.',
    formLearnerButtonTitle: 'Change Password',
    loadingTitle: 'Changing user password...',
    successResultTitle: "Your user's password has been successfully modified!",
    successButtonTitle: "Change another user's password",
  },
};

/**
 * @param {String} learnerAction - One of [force-activation, force-password]
 */
const LearnerManagement = ({ learnerAction }) => {
  const [form] = Form.useForm();
  const { siteId } = useParams();

  const controller = new AbortController();

  const [requestInfo, setRequestInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const resetStates = () => {
    setRequestInfo(null);
    form.resetFields();
  };

  const request = async (formData) => {
    try {
      setLoading(true);

      let sendRequest;

      if (learnerAction === 'force-activation') {
        sendRequest = await learnerRequests.forceActivation(
          siteId,
          { ...formData },
          controller,
        );
      } else {
        sendRequest = await learnerRequests.forcePassword(
          siteId,
          { ...formData },
          controller,
        );
      }

      if (sendRequest?.status === 200) {
        setRequestInfo({
          status: 'success',
          error: true,
          message: {
            title: 'Changes updated successfully!',
            description: learnerActionInfo[learnerAction].successResultTitle,
          },
        });
        resetStates();
      }
    } catch (error) {
      const {
        title,
        message: description,
        tryAgain,
        noShowResponseDetail,
      } = SERVICE_ERROR_MESSAGES[error?.response?.status] || SERVICE_ERROR_MESSAGES[500];
      return setRequestInfo({
        status: 'error',
        error: true,
        noUseNotification: true,
        noShowResponseDetail,
        tryAgain,
        message: {
          title,
          description,
          detail: noShowResponseDetail ? undefined : error?.response?.data?.detail,
        },
      });
    } finally {
      setLoading(false);
    }

    return null;
  };

  useEffect(() => () => {
    controller.abort();
    resetStates();
  }, []);

  useNotificationFromStateChange(
    requestInfo?.noUseNotification ? null : requestInfo,
    requestInfo?.message?.title,
    requestInfo?.message?.description,
  );

  const Loading = () => (
    <Result
      icon={<Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />}
      title={learnerActionInfo[learnerAction].loadingTitle}
    />
  );

  const FormLearner = () => (
    <Form
      form={form}
      name="basic"
      scrollToFirstError
      onFinish={request}
      layout="vertical"
      size="large"
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[{ type: 'email', required: true, message: 'This field is required' }]}
      >
        <Input placeholder="example@email.com" />
      </Form.Item>

      {
        learnerAction === 'force-password' && (
          <Form.Item
            label="New password"
            name="password"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input.Password />
          </Form.Item>
        )
      }

      <CustomButton type="submit" variant="buttonForm">
        {learnerActionInfo[learnerAction]?.formLearnerButtonTitle}
      </CustomButton>
    </Form>
  );

  return (
    <div>
      <p>
        {learnerActionInfo[learnerAction]?.primaryContent}
      </p>
      <br />
      {!requestInfo && !loading && <FormLearner />}
      {!requestInfo && loading && <Loading />}
      {
        requestInfo?.status === 'error' && (
          <ServiceError
            title={requestInfo.message.title}
            message={requestInfo.message.description}
            detail={requestInfo.message.detail}
            onTryAgain={
              requestInfo.tryAgain ? resetStates : null
            }
          />
        )
      }
    </div>
  );
};

LearnerManagement.propTypes = {
  learnerAction: PropTypes.oneOf([
    'force-activation',
    'force-password',
  ]),
};

LearnerManagement.defaultProps = {
  learnerAction: 'force-activation',
};

export default LearnerManagement;
