import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';

import CustomButton from '../customButton/CustomButton';
import styles from './ForbidenPage.module.scss';

/**
 * Component for showing an forbiden message.
 *
 * @param {node} title - Forbiden page title message.
 * @param {node} subtitle - Forbiden page subtitle message.
 * @param {node} body - Forbiden page body message.
 * @component
 * @examples
 * This will show the default error message.
 * return (
 *   <ForbidenPage />
 * )
 * or
 * return (
 *   <ForbidenPage title={<>Foo</>} body={<span>Bar</span>}/>
 * )
 */
function ForbidenPage({
  title,
  subtitle,
  body,
}) {
  const { accountId } = useParams();

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className="text-center">
          <h1>{title}</h1>
          <h5>{subtitle}</h5>
          <br />
          <p>{body}</p>
          <br />
          <div className="d-flex justify-content-center align-items-center">
            <Link
              className={styles.link}
              to={accountId ? `/accounts/${accountId}/dashboard/` : '/accounts/'}
            >
              <CustomButton variant="normalButton">
                {accountId ? 'Go to Dashboard' : 'Go to Accounts'}
              </CustomButton>
            </Link>
            <a className={styles.link} href={`${process.env.MANAGE_ROOT_URL}logout/`} rel="noreferrer">
              <CustomButton variant="cancelAction">Logout and use another user</CustomButton>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

ForbidenPage.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  body: PropTypes.node,
};

ForbidenPage.defaultProps = {
  title: <>Oops! you don&apos;t have access.</>,
  subtitle: <>Contact your account manager or edunext support for more information.</>,
  body: (
    <>
      Your user may not have the necessary permissions within the account
      members to request this resource.
      <br />
      Try to contact the manager of this account first, if this does not work
      you can contact the edunext support team.
    </>
  ),
};

export default ForbidenPage;
