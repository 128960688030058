/**
 * Information of tab routing
 *
 * @param {string} baseUrl - Base URL obtained from useBaseURL hook.
 * @param {array} subPaths - List of objects containing the title and pathname of the subpaths.
 * @param {string} tabName - Name or title of the tab.
 * @param {array} sections - List of subsection names preceding the subpath. i.e: [look-and-feel].
 */
const tabRouterInfo = (baseUrl, subPaths, tabName, sections = []) => {
  const subSection = subPaths?.find(
    (subsection) => subsection.title?.toLowerCase() === tabName?.toLowerCase(),
  );
  let tabRouterFullPath = `/${baseUrl}/${subSection?.pathName}`;

  if (sections?.length > 0) {
    tabRouterFullPath = `/${baseUrl}/${sections.join('/')}/${subSection?.pathName}`;
  }

  return {
    subSection,
    tabRouterFullPath,
  };
};

export default tabRouterInfo;
