/**
 * Concatenate firstName and lastName to obtain the fullName from certain data.
 *
 * @param {object} data - Object that contains the information of the firstNameKey and lastNameKey.
 * @param {string} firstNameKey - Indicate firstName key of data object.
 * @param {string} lastNameKey - Indicate lastName key of data object.
 * @returns {string} - The firstName concat with lastName.
 */
const getFullName = (data, firstNameKey = 'firstName', lastNameKey = 'lastName') => {
  if (!data) {
    return '';
  }

  const firstName = data[firstNameKey] || '';
  const lastName = data[lastNameKey] || '';

  return `${firstName} ${lastName}`.trim();
};

export default getFullName;
