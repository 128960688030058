import React, {
  useState, useRef, useEffect, memo, useMemo,
} from 'react';
import { Tooltip } from 'antd';

import { HeaderWrapperContext } from '../Context/HeaderContext';

import Styles from './AccountLogo.module.scss';

const AccountLogo = () => {
  const [isLogoBroken, setIsLogoBroken] = useState(false);
  const [{ clientLogoUrl, handleToggleDrawer, webSiteUrl }] = HeaderWrapperContext();
  const imageRef = useRef();

  const existInitiativeLogo = clientLogoUrl !== undefined
    && clientLogoUrl !== null
    && clientLogoUrl !== '';

  useEffect(() => {
    setIsLogoBroken(false);
  }, [clientLogoUrl]);

  const Logo = useMemo(() => {
    if (existInitiativeLogo && !isLogoBroken) {
      return (
        <img
          src={clientLogoUrl}
          ref={imageRef}
          className={Styles.clientLogo}
          onError={() => setIsLogoBroken(true)}
          onLoad={() => setIsLogoBroken(false)}
          alt=""
        />
      );
    }

    if (isLogoBroken) {
      return (
        <div className={Styles.clientLogoDiv}>
          <Tooltip
            title="Error: Logo not found. Try to upload a new one or check if the URL provided is correct."
            placement="bottom"
            color="#ff4d4f87"
          >
            <span
              className={Styles.clientLogoSpanError}
              onClick={handleToggleDrawer}
              aria-hidden="true"
            >
              ⚠️
            </span>
          </Tooltip>
        </div>
      );
    }

    return (
      <div className={Styles.clientLogoDiv}>
        <span
          className={Styles.clientLogoSpan}
          onClick={handleToggleDrawer}
          aria-hidden="true"
        >
          Logo
        </span>
      </div>
    );
  }, [clientLogoUrl, handleToggleDrawer, webSiteUrl, isLogoBroken]);

  return (
    <div>
      {webSiteUrl && existInitiativeLogo ? (
        <a href={webSiteUrl} target="_blank" rel="noopener noreferrer">
          {Logo}
        </a>
      ) : Logo}
    </div>
  );
};

export default memo(AccountLogo);
