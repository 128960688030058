import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  memo,
} from 'react';

import {
  Link,
  NavLink,
  useLocation,
} from 'react-router-dom';

import {
  Affix,
  Grid,
  Layout,
  Menu,
  Skeleton,
  Divider,
} from 'antd';

import PropTypes from 'prop-types';

import {
  ApartmentOutlined,
  DollarOutlined,
  FileTextFilled,
  HddFilled,
  RocketFilled,
  SmileFilled,
} from '@ant-design/icons';

import { AccountsViewContext } from '../../../views/accountsView/AccountsViewContext';
import { AccountContext } from '../../../views/accountsView/account/AccountContext';

import controlCenterLogo from '../../../assets/controlCenter.svg';

import './_override.scss';

const { Sider } = Layout;
const { useBreakpoint } = Grid;

/**
 * Component for showing side bar navigation.
 *
 * @component
 * @param {bool} collapse - Indicate if sidebar nav is collapse.
 * @param {func} setCollapse - Method to switch collapse.
 */
const SiderWrapper = ({ collapse, setCollapse, ...wrapperProps }) => {
  const screens = useBreakpoint();
  const [viewContext] = AccountsViewContext();
  const [accountContext] = AccountContext();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    const productPath = `/products/${accountContext.currentProductId}`;
    const platformPath = `/platform/${accountContext.currentPlatformId}`;
    const sitePath = `/sites/${accountContext.currentSiteId}`;
    const possiblePaths = [sitePath, platformPath, productPath];

    /**
     * To select the root path in the sidebar in case of being in a subsection
     * For example:
     *  /accounts/a:Xn8gA0Y7jOl/products/p:XZq4pKwB7y4/billing
     *  Should is selected subscription p:XZq4pKwB7y4 in sidebar menu
     */
    const rootPath = possiblePaths.map((subPath) => {
      const splitRootPath = path.split(subPath);

      if (splitRootPath.length === 2) {
        return `${splitRootPath[0]}${subPath}`;
      }

      return false;
    }).filter((subPath) => subPath)[0];

    setSelectedKeys([rootPath || path]);

    return () => setSelectedKeys([]);
  }, [
    location,
    accountContext.currentAccountId,
    accountContext.currentProductId,
    accountContext.currentPlatformId,
    accountContext.currentSiteId,
  ]);

  const renderLabel = ({ title, linkTo }) => {
    if (linkTo) {
      return <NavLink to={linkTo}>{title}</NavLink>;
    }

    return title;
  };

  const getProductMenus = useCallback(() => {
    const productEntries = Object.entries(accountContext.products);
    const rootLabel = `Subscription${productEntries.length > 1 ? 's' : ''}`;
    const children = productEntries.map(([key, product]) => ({
      label: renderLabel({
        title: product.name,
        linkTo: `/accounts/${product?.account}/products/${key}`,
      }),
      key: `/accounts/${product?.account}/products/${key}`,
    }));

    if (children.length > 1) {
      return { label: rootLabel, children, key: 'subscriptions' };
    }

    return {
      label: renderLabel({ title: rootLabel, linkTo: children[0]?.key }),
      key: children[0]?.key,
      disabled: !children[0]?.key,
    };
  });

  const getPlatformMenu = useCallback(() => {
    let children = [];
    let tenantsFromCurrentProduct = accountContext.tenants;
    const rootLabel = 'Platform';

    if (accountContext.currentProductId) {
      tenantsFromCurrentProduct = tenantsFromCurrentProduct.filter(
        (tenant) => accountContext.currentProductId === tenant.product,
      );
    }

    if (tenantsFromCurrentProduct && accountContext.instances) {
      tenantsFromCurrentProduct.map((tenant) => {
        const instanceOfTenant = accountContext.instances.find(
          (instance) => instance.id === tenant.instance,
        );
        if (instanceOfTenant) {
          const instanceLinkTo = `/accounts/${accountContext.currentAccountId}/products/${tenant.product}/platform/${instanceOfTenant.id}`;
          const itemsHasInstance = children.find(
            (item) => item.key === instanceLinkTo,
          );
          if (!itemsHasInstance) {
            children = [
              ...children,
              {
                label: renderLabel({
                  title: instanceOfTenant.name,
                  linkTo: instanceLinkTo,
                }),
                key: instanceLinkTo,
              },
            ];
          }
        }
        return null;
      });
    }

    if (children.length > 1) {
      return { label: rootLabel, children, key: 'platforms' };
    }

    return {
      label: renderLabel({ title: rootLabel, linkTo: children[0]?.key }),
      key: children[0]?.key,
      disabled: !children[0]?.key,
    };
  });

  const getSiteMenus = useCallback(() => {
    let children = [];
    let tenantsFromCurrentProduct = accountContext.tenants;
    tenantsFromCurrentProduct = tenantsFromCurrentProduct.filter((tenant) => tenant.is_active);
    const rootLabel = `My Site${tenantsFromCurrentProduct.length > 1 ? 's' : ''}`;

    if (accountContext.currentProductId) {
      tenantsFromCurrentProduct = tenantsFromCurrentProduct.filter(
        (tenant) => accountContext.currentProductId === tenant.product,
      );
    }

    if (accountContext.currentPlatformId) {
      tenantsFromCurrentProduct = tenantsFromCurrentProduct.filter(
        (tenant) => parseInt(accountContext.currentPlatformId, 10)
          === parseInt(tenant.instance, 10),
      );
    }

    tenantsFromCurrentProduct.map((site) => {
      const siteLinkTo = `/accounts/${accountContext.currentAccountId}/products/${site.product}/platform/${site.instance}/sites/${site.external_key}`;
      children = [
        ...children,
        {
          label: renderLabel({
            title: site.name,
            linkTo: siteLinkTo,
          }),
          key: siteLinkTo,
        },
      ];

      return null;
    });

    if (children.length > 1) {
      return { label: rootLabel, children, key: 'sites' };
    }

    return {
      label: renderLabel({ title: rootLabel, linkTo: children[0]?.key }),
      key: children[0]?.key,
      disabled: !children[0]?.key,
    };
  });

  const getMenuSkeleton = (length = 10) => Array(length)
    .fill(' ')
    .map((_, index) => ({
      label: <Skeleton.Input className="control-center-menu-skeleton" active block />,
      key: `menu-sider-skeleton-${index}`,
    }));

  const getMenus = useCallback(() => {
    if (!viewContext.loaded) {
      return getMenuSkeleton();
    }

    return [
      {
        ...getProductMenus(),
        icon: <DollarOutlined />,
      },
      {
        ...getPlatformMenu(),
        icon: <HddFilled />,
      },
      {
        ...getSiteMenus(),
        icon: <ApartmentOutlined />,
      },
      {
        label: renderLabel({
          title: 'Documentation',
          linkTo: `/accounts/${accountContext.currentAccountId}/documentation/`,
        }),
        key: `/accounts/${accountContext.currentAccountId}/documentation/`,
        icon: <FileTextFilled />,
      },
      {
        label: renderLabel({
          title: 'Customer Support',
          linkTo: `/accounts/${accountContext.currentAccountId}/support/`,
        }),
        key: `/accounts/${accountContext.currentAccountId}/support/`,
        icon: <SmileFilled />,
      },
      {
        label: renderLabel({
          title: 'Get started',
          linkTo: `${
            location.search
              ? `${location.pathname}${location.search}&`
              : `${location.pathname}?`
          }show_welcome_modal=true`,
        }),
        icon: <RocketFilled />,
      },
    ];
  });

  const menuItemsMemo = useMemo(() => getMenus(), [
    viewContext.loaded,
    accountContext.products,
    accountContext.instances,
    accountContext.tenants,
    accountContext.currentAccountId,
    accountContext.currentProductId,
    accountContext.currentPlatformId,
    location.pathname,
  ]);

  useEffect(() => {
    setCollapse(
      !Object.entries(screens).some((screen) => screen[0] === 'xl' && screen[1]),
    );

    return () => setCollapse(false);
  }, [screens]);

  return (
    <Sider
      theme="light"
      className="control-center-sider"
      collapsedWidth="0"
      zeroWidthTriggerStyle={{ display: 'none' }}
      collapsed={collapse}
      width={290}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...wrapperProps}
    >
      <Affix offsetTop={0}>
        <div>
          <div className="control-center-logo-wrapper">
            <Link to="/accounts">
              <img src={controlCenterLogo} alt="" />
            </Link>
          </div>
          <Divider style={{ margin: '12px 0px' }} />
          <Menu
            className="control-center-sider-menu"
            mode="inline"
            defaultOpenKeys={['subscriptions', 'platforms', 'sites']}
            selectedKeys={selectedKeys}
            items={menuItemsMemo}
          />
        </div>
      </Affix>
    </Sider>
  );
};

SiderWrapper.propTypes = {
  collapse: PropTypes.bool.isRequired,
  setCollapse: PropTypes.func.isRequired,
};

export default memo(SiderWrapper);
