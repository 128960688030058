import { countMemoActive } from '../../../helpers/utils';

export const RESET = 'reset';
export const UPDATE_INVOICES = 'updateInvoices';
export const UPDATE_CONTACTS = 'updateContacts';
export const UPDATE_PRODUCTS = 'updateProducts';
export const UPDATE_ASSOCIATIONS = 'updateAssociations';
export const UPDATE_PRODUCT = 'updateProduct';
export const UPDATE_MEMOS = 'updateMemos';
export const ADD_TENANTS = 'addTenants';
export const UPDATE_TENANTS = 'updateTenants';
export const UPDATE_INSTANCES = 'updateInstances';
export const UPDATE_TICKETS = 'updateTickets';
export const UPDATE_TICKETDETAILS = 'updateTicketDetails';
export const UPDATE_THUMBNAILS = 'updateThumbnails';
export const UPDATE_OPTIONS_CONTEXT = 'updateOptionsContext';
export const UPDATE_CURRENT_ACCOUNTID = 'updateCurrentAccountId';
export const UPDATE_CURRENT_PRODUCTID = 'updateCurrentProductId';
export const UPDATE_CURRENT_PLATFORMID = 'updateCurrentPlatformId';
export const UPDATE_CURRENT_SITEID = 'updateCurrentSiteId';
export const UPDATE_TENANT_FLAGS = 'updateTenantFlags';

export function AccountReducer(state, action) {
  switch (action.type) {
    case RESET:
      return {
        ...state,
        ...{
          products: {},
          contacts: [],
          invoices: [],
          associations: {
            accountAssociations: [],
            productAssociations: [],
            tenantAssociations: [],
          },
          memos: [],
          countMemosActive: 0,
          tenants: [],
          instances: [],
          tickets: {},
          ticketDetails: {},
          tenantThumbnails: {},
          accoutOptions: {},
          currentAccountId: undefined,
          currentProductId: undefined,
          currentPlatformId: undefined,
          currentSiteId: undefined,
          tenantFlags: {},
        },
      };
    case UPDATE_INVOICES:
      return { ...state, invoices: action.data };
    case UPDATE_CONTACTS:
      return { ...state, contacts: action.data };
    case UPDATE_PRODUCTS:
      return { ...state, products: action.data };
    case UPDATE_ASSOCIATIONS:
      return { ...state, associations: action.data };
    case UPDATE_PRODUCT:
      return { ...state, products: { ...state.products, ...action.data } };
    case UPDATE_MEMOS:
      return {
        ...state,
        memos: action.data,
        countMemosActive: countMemoActive(action.data),
      };
    case ADD_TENANTS:
      return { ...state, tenants: [...state.tenants, ...action.data] };
    case UPDATE_TENANTS:
      return { ...state, tenants: action.data };
    case UPDATE_INSTANCES:
      return { ...state, instances: action.data };
    case UPDATE_TICKETS:
      return {
        ...state,
        tickets: { ...state.tickets, ...action.data },
      };
    case UPDATE_THUMBNAILS:
      return {
        ...state,
        tenantThumbnails: { ...state.tenantThumbnails, ...action.data },
      };
    case UPDATE_TICKETDETAILS:
      return {
        ...state,
        ticketDetails: { ...state.ticketDetails, ...action.data },
      };
    case UPDATE_OPTIONS_CONTEXT:
      return {
        ...state,
        accoutOptions: { ...state.accoutOptions, ...action.data },
      };
    case UPDATE_CURRENT_ACCOUNTID:
      return { ...state, currentAccountId: action.data };
    case UPDATE_CURRENT_PRODUCTID:
      return { ...state, currentProductId: action.data };
    case UPDATE_CURRENT_PLATFORMID:
      return { ...state, currentPlatformId: action.data };
    case UPDATE_CURRENT_SITEID:
      return { ...state, currentSiteId: action.data };
    case UPDATE_TENANT_FLAGS:
      return { ...state, tenantFlags: { ...state.tenantFlags, ...action.data } };
    default:
      throw new Error();
  }
}
