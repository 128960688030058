import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import ForbidenPage from '../../components/forbidenPage/ForbidenPage';
import LoadingPage from '../../components/loading/LoadingPage';

function getInitialState() {
  const ednxJwtJookie = Cookies.get(process.env.JWT_HEADER_PAYLOAD_COOKIE_NAME);

  return {
    isAuthenticated: ednxJwtJookie !== undefined,
  };
}

/**
 * Private route that shows the content if the authentication jwt cookie is available.
 *
 * @component
 * @example
 * return (
 *    <PrivateRoute path="/application/accounts">
 *      <AccountsViewRouter />
 *    </PrivateRoute>
 * )
 */
function PrivateRoute({
  children,
  permission,
  forbiden,
  ...extra
}) {
  const [state] = useState(() => getInitialState());

  if (state.isAuthenticated) {
    let render = (
      <ForbidenPage
        title={forbiden?.title}
        subtitle={forbiden?.subtitle}
        body={forbiden?.body}
      />
    );
    if (permission) {
      render = <Route {...extra}>{children}</Route>;
    } else if (permission === undefined) {
      render = <LoadingPage />;
    }

    return render;
  }

  window.location = `${process.env.MANAGE_ROOT_URL}login/?next=${window.location.href}`;

  return <LoadingPage />;
}

PrivateRoute.propTypes = {
  /**
   * Route elements.
   */
  children: PropTypes.node,
  /**
   * Route permission.
   */
  permission: PropTypes.bool,
  /**
   * Route permission.
   */
  forbiden: PropTypes.shape({
    title: PropTypes.node,
    subtitle: PropTypes.node,
    body: PropTypes.node,
  }),
};
PrivateRoute.defaultProps = {
  children: [],
  permission: undefined,
  forbiden: undefined,
};

export default PrivateRoute;
