import React from 'react';
import PropTypes from 'prop-types';

import { AccountsViewContext } from '../../../views/accountsView/AccountsViewContext';
import ZohoForm from '../../ZohoForm/ZohoForm';
import ModalWrapperWithOpenButton from '../../ModalWrapperWithOpenButton/ModalWrapperWithOpenButton';
import clipSupport from '../../../assets/clipSupport.png';
import SupportInfoStyles from '../SupportInfo.module.scss';

/**
 * Component that renders support openticket type render.
 *
 * @param {string} productId - Product external key from tab position (index).
 * @param {func} onFinishSubmit - Method to run when finish submit ticket.
 */
const SupportNewTicket = ({ productId, onFinishSubmit }) => {
  const [viewContext] = AccountsViewContext();

  return (
    <div className={SupportInfoStyles.wrapper}>
      <h3 className={SupportInfoStyles.titleCard}>
        Open a new support ticket
      </h3>

      <p className={SupportInfoStyles.textInfo}>
        Use the button below to open a Support case for sending requests,
        raising inquiries, or reporting any incidents.
      </p>
      <div className={SupportInfoStyles.container}>
        <img
          src={clipSupport}
          alt="support"
          className={SupportInfoStyles.zohoIcon}
        />

        <div className={SupportInfoStyles.ticketContainer}>
          <p className={SupportInfoStyles.textInfoModal}>
            Cases will be reviewed, prioritized and resolved appropriately.
            <br />
            You&apos;ll receive a response over email referencing the ticket
            number.
          </p>
          <ModalWrapperWithOpenButton
            title="Open a new support ticket"
            openButtonText="Open a new support ticket"
          >
            <ZohoForm
              user={{
                name: viewContext.user?.name,
                email: viewContext.user?.email,
              }}
              customProductId={productId}
              onFinishSubmit={onFinishSubmit}
              defaultType="Incident"
            />
          </ModalWrapperWithOpenButton>
        </div>
      </div>
      <div className={SupportInfoStyles.helpCard}>
        <h3 className={SupportInfoStyles.titleCard}>
          Help us provide the best level of support:
          {' '}
        </h3>
        <div className={SupportInfoStyles.textInfo}>
          <ul>
            <li>
              Include in your request all the necessary information, for
              example, the exact URL and a screenshot when necessary.
            </li>
            <li>
              Send any response or inquiry about an open case by replying to
              the corresponding email instead of opening a new support
              ticket.
            </li>
            <li>
              Open a new separated Case for each new request or inquiry you
              may have.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

SupportNewTicket.propTypes = {
  productId: PropTypes.string.isRequired,
  onFinishSubmit: PropTypes.func,
};

SupportNewTicket.defaultProps = {
  onFinishSubmit: null,
};

export default SupportNewTicket;
