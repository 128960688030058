import React, { useEffect, Suspense, lazy } from 'react';
import { Switch, useParams, useRouteMatch } from 'react-router-dom';

import { AccountContext } from './AccountContext';
import {
  UPDATE_CONTACTS,
  UPDATE_PRODUCTS,
  UPDATE_INVOICES,
  ADD_TENANTS,
  UPDATE_INSTANCES,
  UPDATE_OPTIONS_CONTEXT,
} from './AccountReducer';
import {
  UPDATE_ACCOUNT,
  UPDATE_USER_METADATA,
  UPDATE_ALLOWED_ACTION,
  UPDATE_USER_DETAILS,
} from '../AccountsViewReducer';
import { AccountsViewContext } from '../AccountsViewContext';
import Dashboard from './dashboard/Dashboard';
import Documentation from './documentation/Documentation';
import Support from './support/Support';
import Notifications from './notifications/Notifications';
import LoadingPage from '../../../components/loading/LoadingPage';
import NotFound from '../../../components/notFound/NotFound';
import PrivateRoute from '../../../helpers/routes/privateRoute';
import ContactRequest from '../../../helpers/requests/contactRequests';
import ProductRequest from '../../../helpers/requests/productRequests';
import AccountRequest from '../../../helpers/requests/accountRequests';
import TenantRequest from '../../../helpers/requests/tenantRequests';
import InstanceRequest from '../../../helpers/requests/instanceRequests';
import UserDetailsRequest from '../../../helpers/requests/userDetailsRequest';

const MySiteRouter = lazy(() => import('./MySite/MySiteRouter/MySiteRouter'));
const ProductRouter = lazy(() => import('./product/ProductRouter'));
const PlatformRouter = lazy(() => import('./platforms/PlatformRouter'));

const AccountRouter = () => {
  const { path } = useRouteMatch();
  const { accountId } = useParams();
  const [, accountDispatch] = AccountContext();
  const [accountsViewContext, accountsViewDispatch] = AccountsViewContext();
  const params = {
    account__external_key: accountId,
  };
  const account = accountsViewContext.accounts[accountId];

  const responseContactHandler = (response) => {
    accountsViewDispatch({
      type: UPDATE_ALLOWED_ACTION,
      view: 'contact',
      action: 'read',
      value: true,
    });
    accountDispatch({ type: UPDATE_CONTACTS, data: response.data });
  };

  const errorContactHandler = (error) => {
    let value = true;
    if (error.response) {
      value = error.response.status !== 403;
    }
    accountsViewDispatch({
      type: UPDATE_ALLOWED_ACTION,
      view: 'contact',
      action: 'read',
      value,
    });
  };

  const responseTenantHandler = (response) => {
    accountDispatch({ type: ADD_TENANTS, data: response.data });
  };

  const responseProductHandler = (response) => {
    const data = [];

    response.data.map((product) => {
      data[product.external_key] = product;

      TenantRequest.get(responseTenantHandler, {
        product__external_key: product.external_key,
      });

      return null;
    });

    accountDispatch({ type: UPDATE_PRODUCTS, data });
  };

  const responseInvoiceHandler = (response) => {
    accountDispatch({ type: UPDATE_INVOICES, data: response });
  };

  const responseInstanceHandler = (response) => {
    accountDispatch({ type: UPDATE_INSTANCES, data: response.data });
    accountsViewDispatch({
      type: UPDATE_ALLOWED_ACTION,
      view: 'instance',
      action: 'read',
      value: true,
    });
  };

  const errorInstanceHandler = (error) => {
    let value = true;
    if (error.response) {
      value = error.response.status !== 403;
    }
    accountsViewDispatch({
      type: UPDATE_ALLOWED_ACTION,
      view: 'instance',
      action: 'read',
      value,
    });
  };

  const responseHandler = (response) => {
    const accountResponse = response.data;
    const state = { [accountId]: accountResponse };
    const user = {};
    user.roles = accountResponse.metadata.roles;
    user.allowed_actions = accountResponse.metadata.allowed_actions;
    user.allowed_actions.read = true;
    accountsViewDispatch({ type: UPDATE_ACCOUNT, state });
    accountsViewDispatch({ type: UPDATE_USER_METADATA, user });
  };

  const errorHandler = () => {
    accountsViewDispatch({ type: UPDATE_ACCOUNT, state: {} });
  };

  const responseAccountContextOptionsHandler = (response) => {
    const {
      account_geography_choices: geographyChoices,
      account_referral_choices: referralChoices,
      account_vertical_choices: verticalChoices,
      attribute_status: attributeStatus,
      contact_categories: contactCategories,
      country_choices: countryChoices,
      instance_attribute_types: instanceAttributeTypes,
      language_choices: languageChoices,
      permission_choices: permissionChoices,
      product_levels: productLevels,
      product_billing_terms_choices: productBillingTermsChoices,
      product_payment_type_choices: productPaymentTypeChoices,
      product_rating_choices: productRatingChoices,
      product_size_choices: productSizeChoices,
      product_status_choices: productStateusChoices,
      product_tier_choices: productTierChoices,
      product_types: productTypes,
      tenant_attribute_types: attributeTypes,
      tenant_types: tenantTypes,
      memo_categories: memoCategories,
      memo_priorities: memoPriorities,
      memo_statuses: memoStatuses,
      association_choices: associationChoices,
    } = response;

    const accountContextData = {
      geographyChoices,
      referralChoices,
      verticalChoices,
      attributeStatus,
      contactCategories,
      countryChoices,
      instanceAttributeTypes,
      languageChoices,
      permissionChoices,
      productLevels,
      productBillingTermsChoices,
      productPaymentTypeChoices,
      productRatingChoices,
      productSizeChoices,
      productStateusChoices,
      productTierChoices,
      productTypes,
      attributeTypes,
      tenantTypes,
      memoCategories,
      memoPriorities,
      memoStatuses,
      associationChoices,
    };

    accountDispatch({
      type: UPDATE_OPTIONS_CONTEXT,
      data: accountContextData,
    });
  };

  const responseUserDetailHandler = (res) => {
    const user = {
      email: res.data.email,
      name: `${res.data.first_name} ${res.data.last_name}`,
      username: res.data.username,
    };

    accountsViewDispatch({ type: UPDATE_USER_DETAILS, user });
  };

  useEffect(() => {
    AccountRequest.getContextOptions(responseAccountContextOptionsHandler);
    ProductRequest.get(responseProductHandler, params);
    ContactRequest.get(responseContactHandler, errorContactHandler, accountId);
    AccountRequest.getInvoices(responseInvoiceHandler, accountId);
    InstanceRequest.get(responseInstanceHandler, errorInstanceHandler);
    if (!accountsViewContext.userLoaded) {
      UserDetailsRequest.get().then(responseUserDetailHandler);
    }

    if (account === undefined) {
      AccountRequest.get(responseHandler, errorHandler, accountId);
    }
  }, [accountId]);

  return (
    <Switch>
      <PrivateRoute
        exact={false}
        path={`${path}/products/:productId/platform/:platformId/sites/:siteId`}
        permission
      >
        <Suspense fallback={<LoadingPage />}>
          <MySiteRouter />
        </Suspense>
      </PrivateRoute>

      <PrivateRoute
        exact={false}
        path={`${path}/products/:productId/platform/:platformId`}
        permission={accountsViewContext.user.allowed_actions.instance.read}
      >
        <Suspense fallback={<LoadingPage />}>
          <PlatformRouter />
        </Suspense>
      </PrivateRoute>

      <PrivateRoute
        exact={false}
        path={`${path}/products/:productId`}
        permission={accountsViewContext.user.allowed_actions.read}
      >
        <Suspense fallback={<LoadingPage />}>
          <ProductRouter />
        </Suspense>
      </PrivateRoute>

      <PrivateRoute exact path={`${path}/support/`} permission>
        <Suspense fallback={<LoadingPage />}>
          <Support />
        </Suspense>
      </PrivateRoute>

      <PrivateRoute exact path={`${path}/notifications/`} permission>
        <Suspense fallback={<LoadingPage />}>
          <Notifications />
        </Suspense>
      </PrivateRoute>

      <PrivateRoute exact path={`${path}/documentation/`} permission>
        <Suspense fallback={<LoadingPage />}>
          <Documentation />
        </Suspense>
      </PrivateRoute>

      <PrivateRoute exact path={`${path}/dashboard/`} permission>
        <Suspense fallback={<LoadingPage />}>
          <Dashboard />
        </Suspense>
      </PrivateRoute>

      <NotFound />
    </Switch>
  );
};

export default AccountRouter;
