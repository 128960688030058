import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import DOCS_URLS from '../../../constants/documentation.constants';
import SupportInfoStyles from '../SupportInfo.module.scss';

/**
 * Component that renders support explore knowledge type render.
 *
 * @param {string} accountId - Account external key to account URL.
 */
const SupportDocs = ({ accountId }) => (
  <div className={SupportInfoStyles.wrapper}>
    <p className={SupportInfoStyles.textInfo}>
      Explore our
      {' '}
      <b>Documentation resources</b>
      {' '}
      to gain valuable information on
      how to manage your subscription, configure and manage your LMS site,
      create courses, launch and manage your
      courses, use analytics tools,
      and archive your Open edX courses.
    </p>
    <br />
    <div className="d-flex justify-content-center">
      <Link to={`/accounts/${accountId}/documentation/`} className={SupportInfoStyles.linkNormalButton}>
        edunext&apos;s Documentation
      </Link>
      <a
        href={DOCS_URLS.resources}
        target="_blank"
        className={SupportInfoStyles.linkNormalButton}
        rel="noopener noreferrer"
      >
        Resources
      </a>
    </div>
  </div>
);

SupportDocs.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default SupportDocs;
