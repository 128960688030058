import React from 'react';
import { Timeline } from 'antd';
import 'react-quill/dist/quill.snow.css';

import CustomButton from '../../../../components/customButton/CustomButton';
import Notification from '../../../../components/Notifications/Notification/Notification';
import { NotificationContext } from '../../../../components/Notifications/Provider/NotificationProvider';

import Styles from './Notifications.module.scss';
import NotificationModal from '../../../../components/Notifications/Modal/NotificationModal';
import { useDocTitle } from '../../../../helpers/customHooks';

const NewNotificationButton = () => {
  useDocTitle('Notifications');
  const {
    notification,
    setNotification,
    hasNotificationPermissions,
  } = NotificationContext();

  return (
    <div className="d-flex align-items-center">
      <h4 className="me-3 mb-0">Updates and notifications</h4>
      {
        hasNotificationPermissions && (
          <CustomButton
            variant="normalButton"
            onClick={() => setNotification({
              ...notification,
              isOpen: true,
            })}
          >
            New notification
          </CustomButton>
        )
      }
    </div>
  );
};

const Notifications = () => {
  const {
    allNotifications,
    saveNotificationStatus,
  } = NotificationContext();

  if (allNotifications.length === 0) {
    return (
      <div className="w-100 h-100 d-flex flex-column px-5">
        <NewNotificationButton />
        <NotificationModal />
        <h3 className="mt-5 text-center">There are not notifications...</h3>
      </div>
    );
  }

  return (
    <div className="p-4 d-flex flex-column">
      <NewNotificationButton />
      <NotificationModal />
      <div className="w-100 d-flex flex-column align-items-center">
        <Timeline className="mt-4 d-flex flex-column align-items-center" style={{ width: 'fit-content' }}>
          {
            allNotifications.length > 3 && (
              <div className="d-flex justify-content-end w-100 mb-2">
                <button
                  type="button"
                  className={Styles.buttonMarkAll}
                  onClick={() => allNotifications
                    .forEach((item) => saveNotificationStatus(item.id))}
                >
                  Mark all read
                </button>
              </div>
            )
          }
          {
            allNotifications.map((notice) => (
              <Timeline.Item style={{ padding: 0 }} key={`notice-${notice.id}`}>
                <Notification
                  id={notice.id}
                  title={notice.title}
                  message={notice.message}
                  textFormat={notice.textFormat}
                  createdDate={notice.createdDate}
                  validFrom={notice.validFrom}
                  validTo={notice.validTo}
                  fullScreen={notice.fullScreen}
                />
              </Timeline.Item>
            ))
          }
        </Timeline>
      </div>
    </div>
  );
};

export default Notifications;
