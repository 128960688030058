import isObject from './is-object.utils';

import SUBSCRIPTION_TIER_MAP from '../mapping/subscription-tier.mapping';

/**
 * Gets the key to access the value of the variable section of the constants.jsx file.
 * This function executes a logic from dictionaries. That is, as long as there are blocks
 * with key-value inside the object X, it will apply recursion until obtaining a string,
 * undefined, null or even an unexpected element such as an array.
 *
 * @param {object} subscription - The subscription object in account context.
 * @return {string} Key retrieved from according to the subscription matches with
 *                  SUBSCRIPTION_TIER_MAP object.
 */
export const getTypeTierMapBySubscription = (subscription) => {
  const status = (subscription?.is_active ? 'active' : 'inactive');
  const subscriptionStatusMap = SUBSCRIPTION_TIER_MAP[status];

  if (subscriptionStatusMap && isObject(subscriptionStatusMap)) {
    const subscriptionTypeMap = subscriptionStatusMap[subscription?.product_type];

    if (subscriptionTypeMap && isObject(subscriptionTypeMap)) {
      return subscriptionTypeMap[subscription?.tier] || 'else';
    }

    return subscriptionTypeMap;
  }

  return subscriptionStatusMap;
};

/**
 * Get the variable content by subscription tier from constants.
 *
 * @param {object} subscription - The subscription object in account context.
 * @param {string} section - Key value of section in constants.
 * @param {string} constants - All constants elements from src/constants/index.jsx.
 * @return {any} Element in constants by variable content of subscription tier.
 */
const getVariableContentBySubscription = (subscription, section, constants) => {
  const subscriptionLevel = getTypeTierMapBySubscription(subscription);
  if (subscriptionLevel) {
    const content = constants[section];

    if (content && 'variable' in content) {
      return content.variable[subscriptionLevel] === undefined
        ? content.variable.else : content.variable[subscriptionLevel];
    }
  }

  return null;
};

export default getVariableContentBySubscription;
