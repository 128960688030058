import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Layout, Dropdown } from 'antd';

import {
  BellOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';

import { Link } from 'react-router-dom';

import UserAvatar from './UserAvatar/UserAvatar';
import AccountLogo from './AccountLogo/AccountLogo';
import DrawerWrapper from './DrawerWrapper/DrawerWrapper';
import { Context as HeaderWrapperContext } from './Context/HeaderContext';
import { AccountsViewContext } from '../../views/accountsView/AccountsViewContext';
import { AccountContext } from '../../views/accountsView/account/AccountContext';

import Styles from './HeaderWrapper.module.scss';

import { isFieldEditable, textSelectFormatter } from '../../helpers/utils';

const { Header } = Layout;
const { useBreakpoint } = Grid;

/**
 * Renders a header with the drawer to edit the account details.
 *
 * @param {boolean} isSidebarCollapse - Determine if the sidebar is open.
 * @param {func} setSidebarCollapse - Toggles the state of the toggle.
 */
const HeaderWrapper = ({ isSidebarCollapse, setSidebarCollapse }) => {
  const screens = useBreakpoint();

  const [viewContext] = AccountsViewContext();
  const [{ currentAccountId: accountId }] = AccountContext();

  const {
    user: { username, roles = [] },
  } = viewContext;

  const {
    metadata,
    name: initiativeName,
    profile_url: webSiteUrl,
    profile_logo_url: clientLogoUrl,
  } = viewContext?.accounts[accountId] || { name: '' };

  const initialInputState = {
    name: initiativeName,
    profile_url: webSiteUrl,
    profile_logo_url: clientLogoUrl,
    errorsFromRequest: {},
  };

  const { label: role } = roles.length > 0 ? textSelectFormatter(roles[0]) : { label: '' };

  const canEditOrgName = isFieldEditable(metadata, 'name');
  const canEditInitiativeName = isFieldEditable(metadata, 'name');
  const canEditLogo = isFieldEditable(metadata, 'profile_logo_url');
  // Shows the edit button inside the drawer.
  const canEdit = canEditInitiativeName || canEditLogo || canEditOrgName;

  const existUserName = username !== undefined && username !== '';
  const userNameLetter = existUserName ? username[0].toUpperCase() : '?';

  const [isUpdating, setIsUpdating] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isScreenMobile, setIsScreenMobile] = useState(false);
  const [inputsValues, setInputsValues] = useState(initialInputState);

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    setInputsValues({ ...inputsValues, [name]: value });
    setIsUpdating(true);
  };

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    setInputsValues(initialInputState);
    setIsUpdating(false);
  };

  const notificationsItems = [
    {
      key: '1',
      label: (
        <Link
          to={`/accounts/${accountId}/notifications`}
          className={Styles.link}
        >
          See all notifications
        </Link>
      ),
    },
  ];

  const HamburgerButton = () => (
    <button
      type="button"
      className={Styles.buttonSidebarCollapse}
      onClick={() => setSidebarCollapse(!isSidebarCollapse)}
    >
      {isScreenMobile && <MenuOutlined />}

      <span className={Styles.sideBar}>
        {isSidebarCollapse ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </span>
    </button>
  );

  const BellLogo = (
    <>
      {accountId && (
        <Dropdown
          placement="bottom"
          arrow={{ pointAtCenter: true }}
          menu={{ items: notificationsItems }}
        >
          <BellOutlined className={Styles.bell} />
        </Dropdown>
      )}
    </>
  );

  const CompanyName = (
    <div>
      <p className={Styles.initiativeName} title={initiativeName}>
        {initiativeName}
      </p>
    </div>
  );

  useEffect(() => {
    setIsScreenMobile(
      !Object.entries(screens).some((screen) => screen[0] === 'sm' && screen[1]),
    );
  }, [screens]);

  return (
    <HeaderWrapperContext.Provider
      value={[
        {
          role,
          canEdit,
          webSiteUrl,
          isUpdating,
          canEditLogo,
          inputsValues,
          isDrawerOpen,
          clientLogoUrl,
          userNameLetter,
          canEditOrgName,
          setInputsValues,
          handleInputChange,
          handleToggleDrawer,
          setSidebarCollapse,
          canEditInitiativeName,
        },
      ]}
    >
      <Header className={Styles.header}>
        <div className={Styles.controlCenterLogoWrapper}>
          {accountId && <HamburgerButton />}
        </div>
        <div className={Styles.wrapper}>
          {accountId && (
            <div className={Styles.wrapper}>
              {BellLogo}
              <AccountLogo />
              {CompanyName}
            </div>
          )}
          <div className={Styles.wrapper}>
            <UserAvatar />
          </div>
        </div>
        <DrawerWrapper />
      </Header>
    </HeaderWrapperContext.Provider>
  );
};

HeaderWrapper.propTypes = {
  isSidebarCollapse: PropTypes.bool.isRequired,
  setSidebarCollapse: PropTypes.func.isRequired,
};

export default memo(HeaderWrapper);
