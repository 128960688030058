import React from 'react';

import clip from '../../../assets/clipBanner.png';
import { ReactComponent as Shape } from '../../../assets/shape.svg';
import SupportBannerStyles from './SupportBanner.module.scss';

/**
 * Component that renders support footer type render.
 */
const SupportBanner = () => (
  <div className={SupportBannerStyles.box}>
    <Shape className={SupportBannerStyles.shape} />
    <div className={SupportBannerStyles.wrapper}>
      <h1 className={SupportBannerStyles.textInfo}>
        Grow strong in the Open edX world
        {' '}
        <br />
        with our qualified team of experts
      </h1>
      <img src={clip} alt="clip team" />
    </div>
  </div>
);

export default SupportBanner;
