import { consoleRequest } from './requests';

class UserDetailsRequest {
  constructor() {
    this.base_url = 'auth/api/v1/user/details/';
  }

  async get() {
    return consoleRequest.get(this.base_url);
  }
}

export default new UserDetailsRequest();
