import React, { useEffect, useState, memo } from 'react';
import {
  Link, Redirect, useParams, useRouteMatch,
} from 'react-router-dom';
import ReactGA from 'react-ga4';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { AccountsViewContext } from '../../AccountsViewContext';
import { UPDATE_THUMBNAILS } from '../AccountReducer';
import { AccountContext } from '../AccountContext';
import ErrorPage from '../../../../components/errorPage/ErrorPage';
import LoadingPage from '../../../../components/loading/LoadingPage';
import CustomButton from '../../../../components/customButton/CustomButton';

import DashboardStyle from './Dashboard.module.scss';
import { useDocTitle, useParamsToState } from '../../../../helpers/customHooks';
import TenantRequest from '../../../../helpers/requests/tenantRequests';
import SubscriptionStatusBanner from '../../../../components/SubscriptionStatusBanner/SubscriptionStatusBanner';

import { imgNotFoundBase64 } from '../../../../constants/general.constants';

const Dashboard = () => {
  useDocTitle('Dashboard');
  useParamsToState();
  const { path } = useRouteMatch();
  const { accountId } = useParams();
  const [viewContext] = AccountsViewContext();
  const [accountContext, accountDispatch] = AccountContext();
  const account = viewContext.accounts[accountId];
  const sites = accountContext.tenants;
  const [thumbnailLoading, setThumbnailLoading] = useState({});

  const getLinkToSite = (siteProduct, siteInstance, siteId) => `/accounts/${accountId}/products/${siteProduct}/platform/${siteInstance}/sites/${siteId}`;

  const getThumbnail = async (siteId) => {
    setThumbnailLoading((prevThumbnailLoading) => ({
      ...prevThumbnailLoading,
      [siteId]: true,
    }));

    try {
      const { data } = await TenantRequest.getThumbnail(siteId);

      accountDispatch({
        type: UPDATE_THUMBNAILS,
        data: { [siteId]: data.thumbnail },
      });
      setThumbnailLoading((prevThumbnailLoading) => ({
        ...prevThumbnailLoading,
        [siteId]: false,
      }));
    } catch (error) {
      accountDispatch({
        type: UPDATE_THUMBNAILS,
        data: { [siteId]: null },
      });
    } finally {
      setThumbnailLoading((prevThumbnailLoading) => ({
        ...prevThumbnailLoading,
        [siteId]: false,
      }));
    }

    return null;
  };

  useEffect(async () => {
    if (sites.length > 0) {
      sites.map(async (site) => {
        if (
          site.is_active
          && !(site.external_key in accountContext.tenantThumbnails)
          && !(site.external_key in thumbnailLoading)
        ) {
          await getThumbnail(site.external_key);
        }

        return null;
      });
    }
  }, [sites]);

  const renderSites = (
    <Row xs={1} md={4} className="p-3">
      {sites.map(
        (site) => site.is_active && (
        <Col key={site.external_key}>
          <Card>
            {thumbnailLoading[site.external_key] ? (
              <LoadingPage height="17.5vw" />
            ) : (
              <Link
                to={getLinkToSite(
                  site.product,
                  site.instance,
                  site.external_key,
                )}
              >
                <Card.Img
                  variant="top"
                  src={`data:image/png;base64,${
                    accountContext.tenantThumbnails[site.external_key]
                    || imgNotFoundBase64
                  }`}
                />
              </Link>
            )}
            <Card.Body>
              <Card.Title className={DashboardStyle.subtitle}>
                {site.name}
              </Card.Title>
            </Card.Body>
            <Card.Footer className="text-center">
              <div className="d-flex justify-content-center">
                <Link
                  className={DashboardStyle.link}
                  to={getLinkToSite(
                    site.product,
                    site.instance,
                    site.external_key,
                  )}
                  style={{ maxHeight: 'none' }}
                >
                  <CustomButton variant="normalButton">Select</CustomButton>
                </Link>
              </div>
            </Card.Footer>
          </Card>
        </Col>
        ),
      )}
    </Row>
  );

  const renderOrRedirectToSites = () => {
    const onlyOneProdSite = sites.filter((site) => site.instance === 3);

    if (sites.length > 1) {
      if (onlyOneProdSite.length === 1) {
        const site = onlyOneProdSite[0];
        return (
          <Redirect
            to={getLinkToSite(site.product, site.instance, site.external_key)}
          />
        );
      }

      return renderSites;
    }

    const site = sites[0];
    return (
      <Redirect
        to={getLinkToSite(site.product, site.instance, site.external_key)}
      />
    );
  };

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: path,
      title: 'Account dashboard',
    });
  }, []);

  if (!viewContext.loaded) {
    return <LoadingPage />;
  }

  if (account === undefined || sites === undefined) {
    return <ErrorPage />;
  }

  return (
    <div>
      <SubscriptionStatusBanner />
      <div className={DashboardStyle.containerShortcuts}>
        <h2 className={DashboardStyle.title}>Select a site</h2>
        <div className={DashboardStyle.wrap}>
          {sites.length > 0 && renderOrRedirectToSites()}
        </div>
      </div>
    </div>
  );
};

export default memo(Dashboard);
