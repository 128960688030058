import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  FilterFilled,
  MinusCircleFilled,
} from '@ant-design/icons';
import { Select, Space } from 'antd';

import { logicOperatorOptions } from '../filter/Filter.options';
import { fieldLookupsOptionsMapping } from '../filter/Filter.mapping';
import { valueInLookup } from '../filter/Filter.utils';
import { isObjectEmpty } from '../../../helpers/utils';

import FilterTagsStyle from './FilterTags.module.scss';

/**
 * Component that renders filters as tags.
 *
 * @param {array} filters - Array list of object filters.
 * @param {string} logicOperator - String indicate logic operator ('and', 'or').
 * @param {number} lenResults - Quantity of results by filters.
 * @param {function} deleteHandler - Method to delete filter of array list filters.
 * @param {function} onChange - Method when change logic operator.
 */
const FilterTags = ({
  filters,
  logicOperator,
  lenResults,
  deleteHandler,
  onChange,
}) => {
  const clearFilterName = (filterName) => {
    if (filterName === undefined) return null;

    return (filterName[2]).includes('_') ? filterName[2].replaceAll(/_/ig, ' ') : filterName[2];
  };

  const getLabelForLookup = (filter) => (
    fieldLookupsOptionsMapping[filter?.valueType]?.find((lookup) => (
      lookup.value === filter?.lookup
    ))?.label
  ) || 'is';

  const getValue = (value) => {
    if (Array.isArray(value)) {
      return value.join(', ');
    }

    return value;
  };

  return (
    <div className={FilterTagsStyle.main}>
      <Space className={FilterTagsStyle.container}>
        <Space className={FilterTagsStyle.logicOperator}>
          <FilterFilled />
          <span>Where</span>
        </Space>
        <Space wrap>
          {
            filters.map((filter, index) => !isObjectEmpty(filter) && (
              <Space key={`filter-tag-${filter?.name}`}>
                <Space className={FilterTagsStyle.tag}>
                  {clearFilterName(filter?.name)}
                  <span aria-label="filter-lookup" className={FilterTagsStyle.tagLookup}>
                    {getLabelForLookup(filter)}
                  </span>
                  {
                    !valueInLookup(filter) && (
                      <span className={FilterTagsStyle.tagValue}>{getValue(filter.value)}</span>
                    )
                  }
                  {
                    filters.length > 1 && (
                      <button
                        type="button"
                        aria-label="Delete filter"
                        className={FilterTagsStyle.buttonWithoutBoder}
                        onClick={() => deleteHandler(index)}
                      >
                        <MinusCircleFilled />
                      </button>
                    )
                  }
                </Space>
                {
                  (index === 0 && filters.length > 1 && !isObjectEmpty(filters[index + 1])) && (
                    <Select
                      aria-label="filterTagLogicOperator"
                      options={logicOperatorOptions}
                      onChange={onChange}
                      value={logicOperator}
                      disabled={filters.length > 2}
                    />
                  )
                  }
                {
                (
                  index > 0
                  && index !== filters.length - 1
                  && !isObjectEmpty(filters[index + 1])
                ) && (
                  <span>
                    {logicOperatorOptions.find((logicOperatorIter) => (
                      logicOperatorIter.value === logicOperator
                    )).label}
                  </span>
                )
                }
              </Space>
            ))
          }
        </Space>
      </Space>
      {
        lenResults > 0 && (
          <p className={FilterTagsStyle.results}>
            {lenResults}
            {' '}
            results for this filter
          </p>
        )
      }
    </div>
  );
};

FilterTags.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.arrayOf(PropTypes.string),
      lookup: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.instanceOf(moment),
      ]),
      valueType: PropTypes.string,
    }),
  ).isRequired,
  logicOperator: PropTypes.string.isRequired,
  lenResults: PropTypes.number.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FilterTags;
