/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import CustomButton from '../customButton/CustomButton';

/**
 *
 * @param showModal - Boolean
 * @param toggleModalVisibility - Function for hide or show the modal.
 * @param modalTitle - React component for custom jsx messages.
 * @param modalBody - React component for custom jsx messages.
 * @param confirmationAction - Function that will be executed when click the "accept" button.
 * @param cancelAction - - Function that will be executed when click the "cancel" button.
 * @param cancelButtonCustomText - Custom text for the cancel button.
 * @param confirmButtonCustomText - Custom text for the confirm button.
 * @param confirmButtonStyle - Custom text for the confirm button.
 * @param modalSize - Size of the modal.
 *
 * @returns Generic confirmation modal.
 */

const ModalConfirmation = ({
  showModal,
  modalTitle: ModalTitle,
  modalBody: ModalBody,
  toggleModalVisibility,
  confirmationAction,
  cancelAction,
  cancelButtonCustomText,
  confirmButtonCustomText,
  confirmButtonStyle,
  modalSize,
  hideFooter,
  ...customButtonSettings
}) => (
  <Modal
    show={showModal}
    onHide={toggleModalVisibility}
    size={modalSize}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton closeVariant="white">
      <Modal.Title id="contained-modal-title-vcenter">
        <ModalTitle />
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ModalBody />
    </Modal.Body>
    {
      !hideFooter && (
        <Modal.Footer>
          <CustomButton
            variant="cancelAction"
            onClick={() => cancelAction()}
          >
            {cancelButtonCustomText}
          </CustomButton>
          <CustomButton
            variant={confirmButtonStyle}
            onClick={() => confirmationAction()}
            {...customButtonSettings}
          >
            {confirmButtonCustomText}
          </CustomButton>
        </Modal.Footer>
      )
    }
  </Modal>
);

ModalConfirmation.propTypes = {
  modalSize: PropTypes.string,
  hideFooter: PropTypes.bool,
  modalBody: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  modalTitle: PropTypes.func.isRequired,
  confirmButtonStyle: PropTypes.string,
  cancelAction: PropTypes.func.isRequired,
  cancelButtonCustomText: PropTypes.string,
  confirmButtonCustomText: PropTypes.string,
  confirmationAction: PropTypes.func.isRequired,
  toggleModalVisibility: PropTypes.func.isRequired,
};

ModalConfirmation.defaultProps = {
  modalSize: 'md',
  hideFooter: false,
  cancelButtonCustomText: 'Cancel',
  confirmButtonStyle: 'normalButton',
  confirmButtonCustomText: 'Confirm',
};

export default ModalConfirmation;
