import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams } from 'react-router-dom';

/**
 * If the user has an accountId, redirect them to the dashboard page. Otherwise, display a message
 * saying the page doesn't exist.
 *
 * @param {boolean} hasAccontId
 * @returns A redirect to the dashboard page if hasAccountId is false.
 */
const NotFound = ({ hasAccontId }) => {
  if (hasAccontId) {
    const { accountId } = useParams();

    return <Redirect to={`/accounts/${accountId}/dashboard`} />;
  }

  return (
    <div>
      <h2>Not Found</h2>
      <p>The page you&apos;re looking for does not exist!</p>
    </div>
  );
};

NotFound.propTypes = {
  hasAccontId: PropTypes.bool,
};

NotFound.defaultProps = {
  hasAccontId: true,
};

export default NotFound;
