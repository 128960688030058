import axios from 'axios';

axios.defaults.withCredentials = true;

export const consoleRequest = axios.create({
  baseURL: process.env.CONSOLE_ROOT_URL,
});

export const xmanRequest = axios.create({
  baseURL: process.env.XMAN_ROOT_URL,
});

export const signedS3FileUpload = ({ data, file }) => {
  const postData = new FormData();

  Object.keys(data?.fields).map((key) => postData.append(key, data.fields[key]));

  postData.append('file', file);

  return axios.post(data.url, postData, {});
};
