import React, { useState } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

import CustomButton from '../customButton/CustomButton';

/**
 * Component that renders a modal with a open button.
 *
 * @param {string} title - Modal title.
 * @param {string} openButtonText - Text for open modal button.
 * @param {string} openButtonVariant - Variant for open modal button.
 */
const ModalWrapperWithOpenButton = ({
  title,
  children,
  openButtonText,
  openButtonVariant,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <CustomButton variant={openButtonVariant} onClick={showModal}>
        {openButtonText}
      </CustomButton>
      <Modal
        open={isModalOpen}
        title={title}
        handleOk={handleOk}
        onCancel={handleCancel}
        zIndex={1025}
        footer={[]}
      >
        {children}
      </Modal>
    </>
  );
};

ModalWrapperWithOpenButton.propTypes = {
  openButtonText: PropTypes.string,
  title: PropTypes.string.isRequired,
  openButtonVariant: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ModalWrapperWithOpenButton.defaultProps = {
  openButtonVariant: 'normalButton',
  openButtonText: 'Open Modal',
};

export default ModalWrapperWithOpenButton;
