import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Tag, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { textSelectFormatter } from '../../../../helpers/utils';
import { AttributeContext } from '../../Context/AttributeContext';

import Styles from './ListItem.module.scss';

const ListItem = ({ attribute }) => {
  const {
    name,
    description,
    status = 'removed',
    image_url: imageUrl,
    date_added: dateAdded,
    support_through: supportThrough,
  } = attribute;

  const [
    {
      attributeType,
      attributeState,
      isSupportMember,
      handleModalEditing,
      handleListItemDelete,
      handleModalDisplaying,
      attributeDefaultImages,
      attributeStatusTagColors,
    },
  ] = AttributeContext();

  const { label: tagText } = textSelectFormatter(status);

  return (
    <article
      className={Styles.listWrapper}
      onClick={(event) => handleModalDisplaying(attribute, event)}
      role="presentation"
    >
      <img
        src={imageUrl || attributeDefaultImages[attributeType]}
        className={Styles.listImage}
        alt=""
      />
      <div className={Styles.listContent}>
        <div className={Styles.listTitleWrapper}>
          <div className={Styles.listTitle}>
            <h4 style={{ textTransform: 'capitalize' }}>{name}</h4>
            <Tag color={attributeStatusTagColors[status]}>{tagText}</Tag>
          </div>
          {isSupportMember && (
            <div className={Styles.listButtons}>
              <Popconfirm
                title="Are you sure to delete this attribute?"
                description="This can not be undone."
                icon="⚠️"
                onConfirm={(event) => handleListItemDelete(attribute, event)}
                okButtonProps={{ loading: attributeState.request.isProgress }}
                okText="Yes, continue."
                showCancel={false}
                trigger="hover"
              >
                <button type="button">
                  <DeleteOutlined />
                  <span className={Styles.listDivider}>|</span>
                </button>
              </Popconfirm>
              <Tooltip title="Edit attribute">
                <button
                  type="button"
                  onClick={(event) => handleModalEditing(attribute, event)}
                  aria-label="Edit"
                >
                  <EditOutlined />
                </button>
              </Tooltip>
            </div>
          )}
        </div>
        <div className={Styles.listDatesWrapper}>
          {dateAdded && (
          <p>
            Added on:
            {' '}
            {moment(dateAdded).utc().format('MM/DD/YY')}
          </p>
          )}

          {supportThrough && (
            <p>
              Last modification:
              {' '}
              {moment(supportThrough).utc().format('MM/DD/YY')}
            </p>
          )}
          {!supportThrough && !dateAdded && description === null && (
            <p>No data provided</p>
          )}
        </div>
        <p className={Styles.listDescription}>{description}</p>
      </div>
    </article>
  );
};

ListItem.propTypes = {
  attribute: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    status: PropTypes.string.isRequired,
    image_url: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    date_added: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    support_through: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
};

export default ListItem;
