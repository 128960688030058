import { useEffect } from 'react';
import { notification } from 'antd';

/**
 * CustomHook that open a notification when `stateError` changes and contains information.
 *
 * @param {any} stateError - Any element that contains state error information.
 * @param {string} titleMessage - Title of notification.
 * @param {string} description - Description of notification.
 */
const useNotificationFromStateChange = (stateError, titleMessage, description) => {
  useEffect(() => {
    if (stateError !== null) {
      notification.open({
        message: titleMessage,
        description,
        placement: 'bottomRight',
      });
    }
  }, [stateError]);
};

export default useNotificationFromStateChange;
