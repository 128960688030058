import { consoleRequest } from './requests';

class TenantRequest {
  constructor() {
    this.base_url = 'api/tenant/v1/tenants/';
  }

  get(responseHandler, params) {
    consoleRequest.get(this.base_url, { params }).then((res) => {
      responseHandler(res);
    });
  }

  getThumbnail(tenantId) {
    return consoleRequest.get(`${this.base_url}${tenantId}/thumbnail/`);
  }

  patch(responseHandler, errorHandler, tenantId, data = {}) {
    const url = `${this.base_url}${tenantId}/`;
    consoleRequest
      .patch(url, data)
      .then((res) => {
        responseHandler(res);
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  post(responseHandler, errorHandler, data = {}) {
    consoleRequest
      .post(this.base_url, data)
      .then((res) => {
        responseHandler(res);
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  delete(responseHandler, errorHandler, tenantId) {
    const url = `${this.base_url}${tenantId}/`;
    consoleRequest
      .delete(url)
      .then((res) => {
        responseHandler(res);
      })
      .catch((error) => {
        errorHandler(error);
      });
  }
}

export default new TenantRequest();
