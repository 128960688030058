import React from 'react';
import {
  Avatar, Button, Dropdown, Tag,
} from 'antd';

import { ExportOutlined, SettingOutlined } from '@ant-design/icons';

import { HeaderWrapperContext } from '../Context/HeaderContext';
import { AccountsViewContext } from '../../../views/accountsView/AccountsViewContext';
import { AccountContext } from '../../../views/accountsView/account/AccountContext';

import Styles from './UserAvatar.module.scss';

const UserAvatar = () => {
  const [viewContext] = AccountsViewContext();
  const [{ currentAccountId: accountId }] = AccountContext();
  const [{
    canEdit, userNameLetter, handleToggleDrawer, role,
  }] = HeaderWrapperContext();

  const {
    user: { username, roles = [] },
  } = viewContext;

  const isStaffMember = roles.length > 0;
  const userItems = [
    {
      key: '1',
      label: (
        <div className={Styles.dropDownWrapper}>
          <div className={Styles.dropDownUserDetails}>
            <Avatar className={Styles.avatarDropDown}>{userNameLetter}</Avatar>
            <div className={Styles.userDetailsWrapper}>
              <p className={Styles.userName}>{username}</p>
              <p className={Styles.userRole}>{role}</p>
              {isStaffMember && (
                <Tag color="purple" className={Styles.drawerTagRole}>
                  edunext staff member.
                </Tag>
              )}
            </div>
          </div>
          {accountId && (
            <Button
              ghost
              type="primary"
              className={Styles.buttonOpenDrawer}
              onClick={handleToggleDrawer}
              icon={<SettingOutlined />}
            >
              {canEdit ? 'Edit account details' : 'View account details'}
            </Button>
          )}
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      danger: true,
      label: (
        <a
          className={Styles.link}
          href={`${process.env.MANAGE_ROOT_URL}logout/`}
          rel="noopener noreferrer"
        >
          <ExportOutlined className={Styles.logOutIcon} />
          Sign Out
        </a>
      ),
    },
    {
      type: 'divider',
    },
  ];
  return (
    <Dropdown
      placement="bottomRight"
      menu={{ items: userItems }}
      arrow={{ pointAtCenter: true }}
    >
      <Avatar className={Styles.avatar}>{userNameLetter}</Avatar>
    </Dropdown>
  );
};

export default UserAvatar;
