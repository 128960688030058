import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  FilterFilled,
  RightOutlined,
  SearchOutlined,
  SortAscendingOutlined,
} from '@ant-design/icons';
import {
  Input,
  Space,
  Dropdown,
} from 'antd';

import CustomButton from '../customButton/CustomButton';
import FilterDropdownMenu from './filterDropdownMenu/FilterDropdownMenu';
import FilterSortDropdownMenu from './filterSortDropdownMenu/FilterSortDropdownMenu';
import FilterTags from './filterTags/FilterTags';
import { logicOperatorOptions } from './filter/Filter.options';
import { getFiltersToParams, getFilterSortToParams } from './filter/Filter.utils';
import { useUrlSearchParamsReactive } from '../../helpers/customHooks';
import { isObjectEmpty } from '../../helpers/utils';

import FiltersBarStyle from './FiltersBar.module.scss';

/**
 * Component that renders a filter bar with an cascader, logic connector and value.
 *
 * @param {boolean} loading - Indicate if search is running.
 * @param {number} totalResults - Indicate total number results of search.
 * @param {func} onSearch - Method that is fired when executing a search.
 */
const FiltersBar = ({ loading, totalResults, onSearch }) => {
  const [filters, setFilters] = useUrlSearchParamsReactive('filters', [{}], true);
  const searchKey = 'search';
  const [search, setSearch] = useUrlSearchParamsReactive(searchKey, '');
  const logicOperatorKey = 'inclusive';
  const [logicOperator, setLogicOperator] = useUrlSearchParamsReactive(
    logicOperatorKey,
    logicOperatorOptions[0].value,
  );
  const [visible, setVisible] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [isSortVisible, setIsSortVisible] = useState(false);
  const sortKey = 'ordering';
  const [filterSort, setFilterSort] = useUrlSearchParamsReactive(sortKey, [{}], true);
  const [refresh, setRefresh] = useState(false);

  const deleteFilter = (indexFilter) => filters.filter((_, index) => index !== indexFilter);

  const addFilterHandler = () => {
    setFilters([...filters, {}]);
  };

  const deleteFilterHandler = (indexFilter) => {
    const oldFilters = deleteFilter(indexFilter);
    setFilters([...oldFilters]);
  };

  const deleteFilterWithRefreshHandler = (indexFilter) => {
    deleteFilterHandler(indexFilter);
    setRefresh(true);
  };

  const onChange = (filter, logicOperatorFilter, index) => {
    const oldFilters = deleteFilter(index);
    setFilters([
      ...oldFilters.slice(0, index),
      filter,
      ...oldFilters.slice(index, oldFilters.length),
    ]);
    setLogicOperator(logicOperatorFilter);
  };

  const searchHandler = (extraParams = {}) => {
    setRefresh(false);
    setVisible(false);
    setIsSortVisible(false);
    const filterJoined = getFiltersToParams(filters);

    if (logicOperator === 'or') {
      filterJoined[logicOperatorKey] = true;
    }

    if (search) {
      filterJoined[searchKey] = search;
    }

    if (filterSort && Object.keys(filterSort[0]).length > 0) {
      filterJoined[sortKey] = getFilterSortToParams(filterSort);
    }

    onSearch(filterJoined, extraParams);
  };

  const submitFilterHandler = () => {
    setShowTags(true);
    searchHandler();
  };

  const resetFilterHandler = () => {
    setVisible(false);
    setShowTags(false);
    setFilters([{}]);
    setRefresh(true);
  };

  const resetFilterSortHandler = () => {
    setIsSortVisible(false);
    setFilterSort([{}]);
    setRefresh(true);
  };

  const openFilterDropdown = () => {
    setVisible(!visible);
    if (isSortVisible) {
      setIsSortVisible(false);
    }
  };

  const openFilterSortDropdown = () => {
    setIsSortVisible(!isSortVisible);
    if (visible) {
      setVisible(false);
    }
  };

  const handleLogicOperatorChange = (value) => {
    setLogicOperator(value);
    setRefresh(true);
  };

  useEffect(() => {
    if (!isObjectEmpty(filters[0])) setShowTags(true);
    searchHandler();
  }, []);

  useEffect(() => {
    if (refresh) searchHandler();
  }, [refresh]);

  return (
    <div>
      <Space className={FiltersBarStyle.filterHeader} wrap>
        <Dropdown
          open={visible}
          onClick={openFilterDropdown}
          overlayClassName={FiltersBarStyle.dropdownOverlay}
          dropdownRender={() => (
            <FilterDropdownMenu
              filters={filters}
              logicOperator={logicOperator}
              addHandler={addFilterHandler}
              deleteHandler={deleteFilterHandler}
              onChange={onChange}
              onSubmit={submitFilterHandler}
              onReset={resetFilterHandler}
            />
          )}
        >
          <div>
            <CustomButton variant="buttonFilter">
              <Space align="center" className={FiltersBarStyle.filterButton}>
                <FilterFilled className={FiltersBarStyle.filterButtonIcon} />
                <span>Filters</span>
                <RightOutlined
                  className={FiltersBarStyle.filterButtonIcon}
                  style={{ transform: visible && 'rotate(90deg)' }}
                />
              </Space>
            </CustomButton>
          </div>
        </Dropdown>
        <Dropdown
          open={isSortVisible}
          onClick={openFilterSortDropdown}
          overlayClassName={FiltersBarStyle.dropdownOverlay}
          dropdownRender={() => (
            <FilterSortDropdownMenu
              filterSort={filterSort}
              onChange={(name) => setFilterSort([{ name, value: '' }])}
              onChangeLookup={(value) => setFilterSort([{ ...filterSort[0], value }])}
              onSubmit={() => searchHandler()}
              onReset={resetFilterSortHandler}
            />
          )}
        >
          <div>
            <CustomButton variant="buttonFilter">
              <Space align="center" className={FiltersBarStyle.filterButton}>
                <SortAscendingOutlined className={FiltersBarStyle.filterButtonIcon} />
                <span>Sort</span>
                <RightOutlined
                  className={FiltersBarStyle.filterButtonIcon}
                  style={{ transform: isSortVisible && 'rotate(90deg)' }}
                />
              </Space>
            </CustomButton>
          </div>
        </Dropdown>
        <Input.Search
          aria-label="searchBar"
          className="antSearchBar"
          placeholder="Search"
          enterButton="Search"
          bordered={false}
          prefix={<SearchOutlined />}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          onSearch={searchHandler}
          loading={loading}
        />
      </Space>
      {
        (showTags && !isObjectEmpty(filters[0])) && (
          <FilterTags
            filters={filters}
            logicOperator={logicOperator}
            lenResults={totalResults}
            deleteHandler={deleteFilterWithRefreshHandler}
            onChange={handleLogicOperatorChange}
          />
        )
      }
    </div>
  );
};

FiltersBar.propTypes = {
  loading: PropTypes.bool.isRequired,
  totalResults: PropTypes.number.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default FiltersBar;
