import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from 'react-tabs';
import { Link } from 'react-router-dom';

import { useDocTitle } from '../../helpers/customHooks';
import tabRouterInfo from '../../helpers/utils/tabRouterInfo/tabRouterInfo';
import useBaseUrl from '../../helpers/hooks/useBaseUrl/useBaseUrl';

import './_override.scss';
import TabsContainerStyle from './TabsContainer.module.scss';

/**
 *  Component for showing multiple components by tab.
 *  @component
 *  @example
 *  const defaultIndex = 1
 *  const children = in this example <h1> components, the prop name must be especified.
 *  return (
 *    <TabsContainer defaultIndex={1}>
 *     <h1 name="first-component">Fist Component</h1>
 *     <h1 name="second-component">Second Component</h1>
 *     <h1 name="third-component">Third Component</h1>
 *     <h1 name="fourth-component">Fourth Component</h1>
 *    </TabsContainer>
 *  )
 *
 *  @param {node} children - Tabs and content each.
 *  @param {number} defaultIndex - Indicate tab index selected.
 *  @param {number} onSelect - Indicate tab index selected.
 *  @param {number} useCustomBlueColor - Indicate tab index selected.
 *  @param {array} sections - List of subsection names preceding the subpath. i.e: [look-and-feel]
 *  @param {array} subPaths - List of objects containing the title and pathname of the subpaths.
 *  @param {boolean} useLinkTo - Indicate if tabs use link to redirect.
 */
const TabsContainer = ({
  children,
  defaultIndex,
  onSelect,
  useCustomBlueColor,
  sections,
  subPaths,
  useLinkTo,
}) => {
  const { baseUrl, titlePage } = useBaseUrl();
  const [, setDocTitle] = useDocTitle();
  const tabs = [];
  const tabContents = [];
  const [tabIndex, setTabIndex] = useState(defaultIndex);

  const getTabElement = ({ props: { name, notification } }) => (
    <Tab
      key={name}
      className={useCustomBlueColor ? TabsContainerStyle['react-tabs'] : 'react-tabs__tab'}
      selectedClassName={useCustomBlueColor ? TabsContainerStyle['react-tabs--selected'] : 'react-tabs__tab--selected'}
    >
      <div className={TabsContainerStyle.header}>
        <div className={TabsContainerStyle.title}>{name}</div>
        {
          notification > 0 && (
            <div className={TabsContainerStyle.notification}>
              { notification <= 9 ? notification : '+9' }
            </div>
          )
        }
      </div>
    </Tab>
  );

  useEffect(() => {
    setTabIndex(defaultIndex);
  }, [defaultIndex]);

  useEffect(() => {
    if (onSelect) {
      const [tabName] = React.Children.map(children, (child, index) => {
        if (child && index === tabIndex) {
          return child.props.name;
        }
        return null;
      });
      onSelect(tabIndex, tabName);
    }
  }, [tabIndex]);

  useEffect(() => {
    if (useLinkTo) {
      const tabName = subPaths[tabIndex]?.title;
      if (tabName && titlePage) {
        setDocTitle(`${tabName} | ${titlePage}`);
      }
    }
  }, [tabIndex, subPaths]);

  React.Children.map(children, (child) => {
    if (child) {
      tabs.push(
        useLinkTo ? (
          <Link
            to={tabRouterInfo(baseUrl, subPaths, child.props.name, sections).tabRouterFullPath}
            key={child.props.name}
          >
            {getTabElement(child)}
          </Link>
        ) : getTabElement(child),
      );

      tabContents.push(
        <React.Fragment key={child.props.name}>
          <TabPanel>
            {React.cloneElement(child, null)}
          </TabPanel>
        </React.Fragment>,
      );
    }

    return null;
  });

  return (
    <Tabs selectedIndex={tabIndex} onSelect={useLinkTo ? null : (index) => setTabIndex(index)}>
      <TabList>
        {tabs}
      </TabList>
      {tabContents}
    </Tabs>
  );
};

TabsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  defaultIndex: PropTypes.number,
  onSelect: PropTypes.func,
  useCustomBlueColor: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.string),
  subPaths: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    pathName: PropTypes.string,
  })),
  useLinkTo: PropTypes.bool,
};

TabsContainer.defaultProps = {
  defaultIndex: 0,
  onSelect: null,
  useCustomBlueColor: false,
  sections: [],
  subPaths: [],
  useLinkTo: false,
};

export default TabsContainer;
