import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga4';
import { useRouteMatch } from 'react-router-dom';

import {
  UPDATE_EXTRA_MENUS,
  UPDATE_MENUS,
  UPDATE_OPTIONS,
  UPDATE_SUBSCRIPTIONS,
  UPDATE_USER_METADATA,
  UPDATE_USER_DETAILS,
} from './AccountsViewReducer';
import { RESET } from './account/AccountReducer';
import { AccountContext } from './account/AccountContext';
import { AccountsViewContext } from './AccountsViewContext';

import ChatWidget from '../../components/ChatWidget/ChatWidget';
import ErrorPage from '../../components/errorPage/ErrorPage';
import RefreshPage from '../../components/refresh/RefreshPage';
import FiltersBar from '../../components/filtersBar/FiltersBar';
import SubscriptionListCard from '../../components/subscriptionListCard/SubscriptionListCard';

import { useUrlSearchParamsReactive } from '../../helpers/customHooks';
import ExternalRequest from '../../helpers/requests/externalRequests';
import ProductRequest from '../../helpers/requests/productRequests';
import UserDetailsRequest from '../../helpers/requests/userDetailsRequest';

import AccountsViewStyles from './AccountsView.module.scss';

/**
 * This is the main component of the accounts view.
 * It is responsible for displaying the list of
 * accounts, as well as the search bar.
 */
const AccountsView = () => {
  const { path } = useRouteMatch();

  const [, accountDispatch] = AccountContext();
  const [viewContext, accountsViewDispatch] = AccountsViewContext();

  const [filterParams, setFilterParams] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useUrlSearchParamsReactive('page', '1');
  const [changePage, setChangePage] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [renderRetry, setRenderRetry] = useState(0);
  const mainRef = useRef();

  const changePageHandler = (page) => {
    setChangePage(true);
    setCurrentPage(String(page));
  };

  const loadContext = async () => {
    try {
      const res = await ExternalRequest.getContext();
      accountsViewDispatch({ type: UPDATE_OPTIONS, state: res.data });

      if (!viewContext.userLoaded) {
        const user = {};
        const userDetails = await UserDetailsRequest.get();

        user.roles = userDetails.data.roles;
        user.allowed_actions = {};
        user.allowed_actions.read = true;
        user.username = userDetails.data.username;
        user.email = userDetails.data.email;

        accountsViewDispatch({ type: UPDATE_USER_METADATA, user });
        accountsViewDispatch({ type: UPDATE_USER_DETAILS, user });
      }
      setRenderRetry(0);
    } catch {
      setRenderRetry(renderRetry + 1);
    }
  };

  const searchHandler = async (response, extraParams = {}) => {
    const params = { ...response, ...{ page: currentPage }, ...extraParams };
    setFilterParams(params);

    try {
      setLoading(true);
      const results = await ProductRequest.search(params);
      accountsViewDispatch({
        type: UPDATE_SUBSCRIPTIONS,
        state: results?.data?.results,
      });
      setLoading(false);
      setTotalPage(results?.data?.count || 0);
    } catch (e) {
      // TO-DO: change this to a code error coordinated with backend
      // https://github.com/eduNEXT/medusa/issues/117
      if (e.response?.data?.detail === 'Invalid page.') {
        changePageHandler(1);
      }
    }
  };

  useEffect(() => {
    if (currentPage && changePage) {
      setChangePage(false);
      searchHandler(filterParams);
      mainRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentPage]);

  useEffect(async () => {
    // Reset the account information as products, tenants, etc. Why?
    // This prevents junk information from recently accessed accounts from being left behind.
    // For example: if this is not done, when accessing the dashboard from Account-A
    // having previously accessed Account-B, it will try to use the information from the sites
    // of Account-B (due to a slight delay in the endpoints).
    accountDispatch({ type: RESET });
    // reset the menus available in this view to not have menus from the account previously viewed
    accountsViewDispatch({ type: UPDATE_MENUS, menus: [] });
    accountsViewDispatch({ type: UPDATE_EXTRA_MENUS, menus: [] });
    accountsViewDispatch({ type: UPDATE_SUBSCRIPTIONS, state: [] });

    ReactGA.send({
      hitType: 'pageview',
      page: path + window.location.search,
      title: 'Accounts',
    });

    await loadContext();
  }, []);

  useEffect(async () => {
    if (viewContext.userLoaded && viewContext.user.roles.length <= 0) {
      await searchHandler();
    }
  }, [viewContext.userLoaded]);

  if (renderRetry > 0 && renderRetry <= 3) {
    return <RefreshPage onClick={loadContext} />;
  }

  if (renderRetry > 3) {
    return <ErrorPage />;
  }

  return (
    <div className={AccountsViewStyles.accounts} ref={mainRef}>
      <ChatWidget />
      {viewContext.user.roles?.length > 0 && (
        <FiltersBar
          loading={loading}
          totalResults={totalPage}
          onSearch={searchHandler}
        />
      )}
      <section className={AccountsViewStyles.content}>
        <SubscriptionListCard
          loading={loading}
          totalPage={totalPage}
          currentPage={parseInt(currentPage, 10)}
          onChangePage={changePageHandler}
        />
      </section>
    </div>
  );
};

export default AccountsView;
