/**
 *  TEST CONSTANTS CONTROL CENTER
 *  In this file the testing constants of the project are configured.
 *
 *  WARNINGS:
 *            - When you need to include more than one HTML tag, they must be contained in
 *              a parent HTML tag, for example:
 *              Examples of incorrect code:
 *              (
 *                <h1>Hello World!</h1>
 *                <p>Lorem ipsum ... </p>
 *              )
 *
 *              Examples of correct code:
 *              (
 *                <>
 *                    <h1>Hello World!</h1>
 *                    <p>Lorem ipsum ... </p>
 *                </>
 *              )
 *
 *            - Make sure to preserve both the structure and the indentation, our format is:
 *              {
 *                section_name: {
 *                    static: (... Text|HTML),
 *                    variable: {
 *                        variable_1: (... Text|HTML),
 *                        ...
 *                    },
 *                },
 *                ...
 *              }
 */
import React from 'react';
import { INACTIVE_STRATUS_SUBSCRIPTION } from './subscription.constants';

// Component for testing purposes
// eslint-disable-next-line react/prop-types
const TestComponent = ({ message }) => <p>{`test ${message}`}</p>;

const TEST_COMPONENT = {
  component: TestComponent,
};

const TEST_BASE_CONSTANTS = {
  static: <p>static message</p>,
  variable: {
    inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
    inactive_subscription: <p>inactive section</p>,
    nimbus_free: <p>nimbus FREE section</p>,
    nimbus_basic: <p>nimbus BASIC section</p>,
    nimbus_enhanced: <p>nimbus ENHANCED section</p>,
    nimbus_advanced: <p>nimbus ADVANCED section</p>,
    on_premise: <p>on premise section</p>,
    cirrus: <p>cirrus section</p>,
    cirrus_sn: <p>cirrus SN</p>,
    cirrus_se: <p>cirrus SE</p>,
    stratus: <p>stratus section</p>,
    else: <p>else section</p>,
  },
};

export default {
  test_section_used_only_for_unit_tests_purposes_please_not_delete:
    TEST_BASE_CONSTANTS,
  test_section_2_only_for_unit_test: {
    static: <p>static message</p>,
    variable: {
      nimbus_free: TEST_COMPONENT,
      nimbus_basic: <p>nimbus BASIC section</p>,
      nimbus_enhanced: <p>nimbus ENHANCED section</p>,
      nimbus_advanced: <p>nimbus ADVANCED section</p>,
      on_premise: <p>on premise section</p>,
      cirrus: <p>cirrus section</p>,
      cirrus_sn: <p>cirrus SN</p>,
      cirrus_se: <p>cirrus SE</p>,
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: <p>inactive section</p>,
      stratus: <p>stratus section</p>,
      else: <p>else section</p>,
    },
    test_sub_section: <p>test sub section</p>,
    test_sub_section_2: TEST_COMPONENT,
  },
  test_onboarding: {
    ...TEST_BASE_CONSTANTS,
    onboardingTitle: <div>onboardingTitle</div>,
    onboardingSubtitle: <div>onboardingSubtitle</div>,
    onboardingBox: <div>onboardingBox</div>,
    onboardingImage: 'src-onboardingImage',
    onboardingVideoId: 'id-onboardingVideoId',
  },
};
