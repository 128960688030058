import React from 'react';

import {
  Tag,
  Row,
  Form,
  Alert,
  Image,
  Input,
  Modal,
  Select,
  Button,
} from 'antd';

import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import generatePicker from 'antd/es/date-picker/generatePicker';

import { AttributeContext } from '../../Context/AttributeContext';
import { SET_ATTRIBUTE_MODAL_STATUS } from '../../Reducer/attributeReducer';

import Styles from './AttributeModal.module.scss';
import { imgNotFoundBase64 } from '../../../../constants/general.constants';
import { getErrorByFieldName } from '../../../../helpers/utils';

const { TextArea } = Input;

const DatePicker = generatePicker(momentGenerateConfig);

/**
 * This component is responsible for create, edit and show an attribute.
 */
const AttributeModal = () => {
  const [
    {
      form,
      actions,
      showType,
      modalStatus,
      attributeState,
      isSupportMember,
      handleCloseModal,
      showSupportDate,
      selectTypeOptions,
      attributeDispatch,
      modalStatusContent,
      selectStatusOptions,
    },
  ] = AttributeContext();

  const isModalOpen = attributeState.modalStatus === modalStatus.creating
    || attributeState.modalStatus === modalStatus.updating
    || attributeState.modalStatus === modalStatus.displaying;

  const isCreating = attributeState.modalStatus === modalStatus.creating;
  const isDisplaying = attributeState.modalStatus === modalStatus.displaying;
  const isEditing = attributeState.modalStatus === modalStatus.updating;

  const attributeImage = attributeState.currentAttribute?.image_url;
  const renderAttributeImage = attributeImage !== null ? attributeImage : 'error';

  const handleOk = () => {
    form.validateFields().then((values) => {
      actions[attributeState.modalStatus]({
        ...values,
        id: attributeState.currentAttribute?.id,
      });
    });

    if (isDisplaying) {
      attributeDispatch({
        type: SET_ATTRIBUTE_MODAL_STATUS,
        payload: modalStatus.hidden,
      });
    }
  };

  const modalTitle = () => modalStatusContent()[attributeState.modalStatus]?.text.title;
  const getModalTitle = modalTitle();

  const modalButtonText = () => (
    modalStatusContent()[attributeState.modalStatus]?.text.button
  );
  const getModalButtonText = modalButtonText();

  const getRequestError = (fieldName) => {
    const error = getErrorByFieldName(attributeState.request.data, fieldName);

    return {
      isError: error !== null && 'error',
      errorMessage: error,
    };
  };

  return (
    <Modal
      open={isModalOpen}
      title={getModalTitle}
      okText="Create"
      cancelText="Cancel"
      onCancel={handleCloseModal}
      onOk={handleOk}
      footer={[
        <React.Fragment key="back-modal-button">
          {(isCreating || isEditing) && (
            <Button onClick={handleCloseModal}>
              Cancel
            </Button>
          )}
        </React.Fragment>,

        <Button
          key="submit-modal-button"
          type="primary"
          loading={attributeState.request.inProgress}
          onClick={handleOk}
        >
          {getModalButtonText}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        disabled={isDisplaying}
      >
        <Row justify="space-around" align="middle">
          <Form.Item
            name="name"
            label="Name"
            className={Styles.inputFull}
            rules={[{ required: true, message: 'Please input the attribute name.' }, { type: 'string', min: 4 }]}
          >
            <Input />
          </Form.Item>
        </Row>

        <Row style={{ marginBottom: 20 }}>
          {!isCreating && attributeImage && (
            <Image
              width={100}
              height={100}
              src={renderAttributeImage}
              fallback={`data:image/png;base64,${imgNotFoundBase64}`}
            />
          )}
          <Form.Item
            name="image_url"
            label="Image URL"
            style={{
              marginLeft: !isCreating && attributeImage ? 30 : 0,
              width: !isCreating && attributeImage ? '70%' : '100%',
            }}
            rules={[{ type: 'string', min: 6, warningOnly: true }]}
            help={(
              <>
                {getRequestError('image_url').isError && (
                  <Alert
                    message={getRequestError('image_url').errorMessage}
                    type="error"
                    closeIcon
                    style={{ marginTop: 10 }}
                  />
                )}
              </>
            )}
          >
            <Input
              placeholder={
                (!attributeImage && !isCreating) ? 'No image provided' : null
              }
            />
          </Form.Item>
        </Row>

        {showType && (
          <Row
            justify="space-between"
            align="middle"
            className={Styles.inputWrapper}
          >
            <Form.Item
              name="type"
              label="Type"
              rules={[{ required: true, message: 'Please select the type.' }]}
              className={Styles.inputFull}
              help={(
                <>
                  {getRequestError('type').isError && (
                    <Alert
                      message={getRequestError('type').errorMessage}
                      type="error"
                      closeIcon
                      style={{ marginTop: 10 }}
                    />
                  )}
                </>
              )}
            >
              <Select options={selectTypeOptions} />
            </Form.Item>
          </Row>
        )}

        <Row justify="start" align="middle">
          <Form.Item
            name="docs_url"
            className={Styles.inputFull}
            label="Documentation URL"
            help={(
              <>
                {getRequestError('docs_url').isError && (
                <Alert
                  message={getRequestError('docs_url').errorMessage}
                  type="error"
                  closeIcon
                  style={{ marginTop: 10 }}
                />
                )}
                {attributeState.currentAttribute?.docs_url && !isEditing && (
                  <Alert
                    type="info"
                    style={{ margin: '20px 0' }}
                    message={(
                      <p style={{ marginBottom: 0 }}>
                        You can view your documentation
                        {' '}
                        <a
                          href={attributeState.currentAttribute?.docs_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here
                        </a>
                        .
                      </p>
                    )}
                  />
                )}
              </>
            )}
            rules={[
              { type: 'url', warningOnly: true, message: 'The URL must begin with http://' },
              { type: 'string', min: 6 },
            ]}
          >
            <Input />
          </Form.Item>
        </Row>

        <Row justify="start" align="middle">
          <Form.Item
            name="base_url"
            className={Styles.inputFull}
            label="Base URL"
            help={(
              <>
                {getRequestError('base_url').isError && (
                <Alert
                  message={getRequestError('base_url').errorMessage}
                  type="error"
                  closeIcon
                  style={{ marginTop: 10 }}
                />
                )}
                {attributeState.currentAttribute?.base_url && !isEditing && (
                  <Alert
                    type="info"
                    style={{ margin: '20px 0' }}
                    message={(
                      <p style={{ marginBottom: 0 }}>
                        You can view the base URL
                        {' '}
                        <a
                          href={attributeState.currentAttribute?.base_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here
                        </a>
                        .
                      </p>
                    )}
                  />
                )}
              </>
            )}
            rules={[
              { type: 'url', warningOnly: true },
              { type: 'string', min: 6 },
            ]}
          >
            <Input />
          </Form.Item>
        </Row>

        <Row
          justify="space-between"
          align="middle"
          className={Styles.inputWrapper}
        >
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: 'Please input the status.' }]}
            className={`${Styles.inputHalf} ${Styles.marginRightSmall}`}
          >
            <Select options={selectStatusOptions} />
          </Form.Item>
          <Form.Item
            name="version"
            label="Version"
            help={(
              <>
                {getRequestError('version').isError && (
                  <Alert
                    message={getRequestError('version').errorMessage}
                    type="error"
                    closeIcon
                  />
                )}
              </>
            )}
            className={`${Styles.inputHalf} ${Styles.marginRightSmall}`}
            rules={[{ type: 'string', min: 1 }]}
          >
            <Input />
          </Form.Item>
        </Row>

        <Row
          justify="space-around"
          align="middle"
          className={Styles.inputWrapper}
        >
          <Form.Item
            name="date_added"
            label="Date added"
            className={Styles.inputFull}
            help={(
              <>
                {getRequestError('date_added').isError && (
                  <Alert
                    message={getRequestError('date_added').errorMessage}
                    type="error"
                    closeIcon
                    style={{ marginTop: 10 }}
                  />
                )}
              </>
            )}
          >
            <DatePicker />
          </Form.Item>

          {showSupportDate && (
            <Form.Item
              name="support_through"
              label="Support Through"
              help={(
                <>
                  {getRequestError('support_through').isError && (
                    <Alert
                      message={getRequestError('support_through').errorMessage}
                      type="error"
                      closeIcon
                      style={{ marginTop: 10 }}
                    />
                  )}
                </>
              )}
              className={Styles.inputFull}
            >
              <DatePicker />
            </Form.Item>
          )}
        </Row>

        <Row justify="space-around" align="middle">
          <Form.Item
            name="description"
            label="Description"
            className={Styles.inputFull}
            help={(
              <>
                {getRequestError('description').isError && (
                  <Alert
                    message={getRequestError('description').errorMessage}
                    type="error"
                    closeIcon
                  />
                )}
              </>
            )}
            rules={[{ type: 'string', min: 4, warningOnly: true }]}
          >
            <TextArea rows={5} />
          </Form.Item>
        </Row>

        {isSupportMember && (
          <Row justify="space-around" align="middle">
            <Form.Item
              name="internal_notes"
              help={(
                <>
                  {getRequestError('internal_notes').isError && (
                    <Alert
                      message={getRequestError('internal_notes').errorMessage}
                      type="error"
                      closeIcon
                    />
                  )}
                </>
              )}
              label={(
                <>
                  <span>Internal notes</span>
                  <Tag color="purple" className={Styles.marginLeftSmall}>
                    exclusive for edunext
                  </Tag>
                </>
              )}
              className={Styles.inputFull}
              rules={[{ type: 'string', min: 4, warningOnly: true }]}
            >
              <TextArea rows={5} />
            </Form.Item>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default AttributeModal;
