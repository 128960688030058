import { consoleRequest } from './requests';

class NotificationRequest {
  constructor() {
    this.baseUrl = '/api/notification/v1/notifications/';
  }

  async getNotifications(isActive = false) {
    if (isActive) {
      return consoleRequest.get(`${this.baseUrl}active/`);
    }

    return consoleRequest.get(this.baseUrl);
  }

  async newNotification(payload = {}) {
    return consoleRequest.post(this.baseUrl, payload);
  }

  async updateNotification(notificationId, notificationData) {
    return consoleRequest.patch(`${this.baseUrl}${notificationId}/`, notificationData);
  }

  async deleteNotification(notificationId) {
    return consoleRequest.delete(`${this.baseUrl}${notificationId}/`);
  }
}

export default new NotificationRequest();
