import { consoleRequest } from './requests';

class InstanceRequest {
  constructor() {
    this.base_url = 'api/instance/v1/instances/';
  }

  get(responseHandler, errorHandler, instanceId = '') {
    const url = `${this.base_url}${instanceId}`;
    consoleRequest.get(url)
      .then((res) => {
        responseHandler(res);
      }).catch((error) => {
        errorHandler(error);
      });
  }

  patch(responseHandler, errorHandler, instanceId, data = {}) {
    const url = `${this.base_url}${instanceId}/`;
    consoleRequest.patch(url, data)
      .then((res) => {
        responseHandler(res);
      })
      .catch((error) => {
        errorHandler(error);
      });
  }
}

export default new InstanceRequest();
