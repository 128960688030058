import React, { useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';

import { AccountContext } from '../../../views/accountsView/account/AccountContext';
import { UPDATE_TICKETDETAILS } from '../../../views/accountsView/account/AccountReducer';
import Table from '../../table/Table';
import TableStyles from '../../table/Table.module.scss';
import CustomButton from '../../customButton/CustomButton';
import ServiceError from '../../serviceError/ServiceError';
import { SERVICE_ERROR_MESSAGES } from '../../../constants/general.constants';
import DOCS_URLS from '../../../constants/documentation.constants';
import getFullName from '../../../helpers/utils/getFullName/getFullName.utils';
import ExternalRequests from '../../../helpers/requests/externalRequests';
import SupportInfoStyles from '../SupportInfo.module.scss';
import SupportTicketDetail from '../supportTicketDetail/SupportTicketDetail';

/**
 * Component that renders support explained type render.
 *
 * @param {string} productId - Product external key from tab position (index).
 * @param {func} getTickets - Method run when click in 'Reload Tickets' and 'Try Again'.
 * @param {boolean} loadingTickets - Indicate if tickets is loading.
 */
const SupportExplained = ({ productId, getTickets, loadingTickets }) => {
  const [accountContext, accountDispatch] = AccountContext();
  const [ticketDetail, setTicketDetail] = useState({});
  const [showModalTicketDetail, setModalTicketDetail] = useState(false);
  const [loadingTicketDetail, setLoadingTicketDetail] = useState(false);
  const [errorTicketDetail, setErrorTicketDetail] = useState(false);

  const tickets = accountContext.tickets[productId]?.data || [];

  const renderTicketDetail = async (ticketId) => {
    setModalTicketDetail(true);
    const currentTicket = tickets?.find((ticket) => ticket.id === ticketId);
    setTicketDetail({ id: ticketId, ticketNumber: currentTicket?.ticketNumber });
    setErrorTicketDetail(false);
    if (!accountContext.ticketDetails[ticketId]) {
      setLoadingTicketDetail(true);
      try {
        const { data } = await ExternalRequests.getTicketDetail(ticketId);
        accountDispatch({ type: UPDATE_TICKETDETAILS, data: { [data.id]: data } });
        setTicketDetail(data);
      } catch {
        setErrorTicketDetail(true);
        setTicketDetail({ id: ticketId, ticketNumber: currentTicket?.ticketNumber });
      } finally {
        setLoadingTicketDetail(false);
      }
    } else {
      setTicketDetail(accountContext.ticketDetails[ticketId]);
    }
  };

  const columns = [
    {
      Header: 'Ticket Number',
      accessor: 'ticketNumber',
    },
    {
      Header: 'Date',
      id: 'createdTime',
      accessor: 'createdTime',
      Cell(rows) {
        const { row } = rows;
        return (
          <span className={TableStyles.tdTextSpace}>
            {
             row.values.createdTime !== null && moment(row.values.createdTime).format('DD/MM/YYYY')
            }
          </span>
        );
      },
    },
    {
      Header: 'Subject',
      accessor: 'subject',
      Cell(rows) {
        const { row } = rows;
        return (<span className={TableStyles.tdTextLeft}>{row.values.subject}</span>);
      },
    },
    {
      Header: 'Contact',
      id: 'contact',
      accessor: 'contact',
      Cell(rows) {
        const { row } = rows;

        return (
          <span
            className={TableStyles.tdTextSpace}
          >
            {getFullName(row.values.contact)}
          </span>
        );
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell(rows) {
        const { row } = rows;
        return (<span className={TableStyles.tdTextSpace}>{row.values.email}</span>);
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell(rows) {
        const { row } = rows;

        return (<span className={TableStyles.tdTextSpace}>{row.values.status}</span>);
      },
    },
    {
      Header: 'Due Date',
      id: 'dueDate',
      accessor: 'dueDate',
      Cell(rows) {
        const { row } = rows;

        return (
          <span className={TableStyles.tdTextSpace}>
            {
              row.values.dueDate !== null
              && moment(row.values.dueDate).format('DD/MM/YYYY')
            }
          </span>
        );
      },
    },
    {
      Header: 'Closed Time',
      id: 'closedTime',
      accessor: 'closedTime',
      Cell(rows) {
        const { row } = rows;

        return (
          <span className={TableStyles.tdTextSpace}>
            {
              row.values.closedTime !== null
              && moment(row.values.closedTime).format('DD/MM/YYYY')
            }
          </span>
        );
      },
    },
    {
      Header: 'Actions',
      id: 'id',
      accessor: 'id',
      Cell(rows) {
        const { row } = rows;
        const ticketId = row.values.id;

        return (
          <button
            type="button"
            className={SupportInfoStyles.link}
            onClick={() => renderTicketDetail(ticketId)}
          >
            Details
          </button>
        );
      },
    },
  ];

  const ticketsRender = () => {
    if (accountContext.tickets[productId]?.error) {
      const ticketsErrors = accountContext.tickets[productId].error;
      const {
        title,
        message: description,
        tryAgain,
      } = SERVICE_ERROR_MESSAGES[ticketsErrors?.status] || SERVICE_ERROR_MESSAGES[500];

      return (
        <ServiceError
          title={title}
          message={description}
          onTryAgain={
            tryAgain ? getTickets : null
          }
        />
      );
    }

    return (
      <>
        <div className={SupportInfoStyles.tableWrapper}>
          <CustomButton disabled={loadingTickets} onClick={getTickets} aria-label="reload-tickets">
            {
              loadingTickets ? (
                <LoadingOutlined />
              ) : 'Reload Tickets'
            }
          </CustomButton>
        </div>
        <Table columns={columns} data={tickets} dataName="tickets" loading={loadingTickets} />
      </>
    );
  };

  return (
    <>
      <SupportTicketDetail
        show={showModalTicketDetail}
        onHide={() => setModalTicketDetail(false)}
        loading={loadingTicketDetail}
        error={errorTicketDetail}
        ticketDetail={ticketDetail}
        renderTicketDetail={renderTicketDetail}
      />
      <div className={SupportInfoStyles.wrapper}>
        <p className={SupportInfoStyles.textInfo}>
          Your subscription is entitled to edunext&apos;s customer support
          services. These include:
        </p>
        <br />
        <ul className={SupportInfoStyles.textInfo}>
          <li>Requests</li>
          <li>Inquiries</li>
          <li>Incidents</li>
          <li>Customer success consulting</li>
        </ul>
        <br />
        <p className={SupportInfoStyles.textInfo}>
          You will find all the details about these services in our
          {' '}
          <a
            href={DOCS_URLS.sla}
            target="_blank"
            rel="noopener noreferrer"
            className={SupportInfoStyles.link}
          >
            customer support agreement.
          </a>
        </p>
      </div>
      <div className={SupportInfoStyles.wrapper} id="list">
        <h3 className={SupportInfoStyles.titleCard}>
          Keep track of your open tickets
        </h3>
        <p className={SupportInfoStyles.textInfo}>
          All the responses from the support team will be sent to
          the email that created the ticket. Additionally, you
          can view the open and recently closed tickets by any
          member of your team in the table below.
        </p>
        <br />
        {ticketsRender()}
      </div>
    </>
  );
};

SupportExplained.propTypes = {
  productId: PropTypes.string.isRequired,
  getTickets: PropTypes.func.isRequired,
  loadingTickets: PropTypes.bool,
};

SupportExplained.defaultProps = {
  loadingTickets: false,
};

export default SupportExplained;
