import React from 'react';
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { useLocation } from 'react-router-dom';
import { Toast, ToastContainer } from 'react-bootstrap';

import { NotificationContext } from '../Provider/NotificationProvider';

import Styles from '../Notifications.module.scss';

const ToastNotification = () => {
  const {
    hideNotification,
    activeNotifications,
    dismissNotification,
    HTML_SANITIZE_CONFIG,
  } = NotificationContext();

  const { pathname } = useLocation();

  if (pathname.includes('/notifications/') || activeNotifications.length === 0) return null;

  return (
    <ToastContainer
      className="p-3"
      position="bottom-end"
      style={{ zIndex: 1000 }}
      ref={(element) => {
        if (element) element.style.setProperty('position', 'fixed', 'important');
      }}
    >
      {
        activeNotifications.map((notification) => (
          <Toast
            key={notification.id}
            show={notification.isActive}
            onClose={() => hideNotification(notification.id)}
            className={Styles.toast}
          >
            <Toast.Header className={Styles.toastHead} closeVariant="white">
              <strong className="me-auto text-white">{notification.title}</strong>
            </Toast.Header>
            <Toast.Body className={Styles.notificationMessageWrapper}>
              {
                notification.text_format === 'plain' ? (
                  <p>{notification.message}</p>
                ) : (
                  <div>{parse(sanitizeHtml(notification.message, HTML_SANITIZE_CONFIG))}</div>
                )
            }
              <button
                className={Styles.buttonTertiary}
                type="button"
                onClick={() => dismissNotification(notification.id)}
              >
                Dismiss
              </button>
            </Toast.Body>
          </Toast>
        ))
    }
    </ToastContainer>
  );
};

export default ToastNotification;
