import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import LoadingPage from '../loading/LoadingPage';

import IframeWrapperStyle from './IframeWrapper.module.scss';

/**
 * It renders an iframe with a loading animation while the iframe is loading.
 *
 * @param {string} height - Custom height
 * @param {string} url - Url.
 *
 * @returns Component that renders an iframe.
 */
const IframeWrapper = ({ url, height }) => {
  const iframeRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const divStyles = !height ? `${IframeWrapperStyle.root} ${IframeWrapperStyle.container}` : IframeWrapperStyle.root;
  const extraPath = window.location.href.split('/documentation/')[1];

  const onLoad = () => {
    if (loading) {
      setLoading(false);
      // Avoid scrolling parent page by fragment/anchor in src
      iframeRef.current.src = (extraPath !== undefined ? `${url}${extraPath}` : url);
    }
  };

  return (
    <div className={divStyles}>
      {
        loading && (
          <div className={IframeWrapperStyle.loadingContainer}>
            <LoadingPage />
          </div>
        )
      }
      <iframe
        ref={iframeRef}
        title="IframeWrapper"
        src={extraPath !== undefined ? `${url}${extraPath}` : url}
        onLoad={onLoad}
        height={height}
        width="100%"
        // Avoid scrolling parent page by fragment/anchor in src
        style={{ display: loading ? 'none' : 'block' }}
      />
    </div>
  );
};

IframeWrapper.propTypes = {
  url: PropTypes.string.isRequired,
  height: PropTypes.string,
};

IframeWrapper.defaultProps = {
  height: null,
};

export default IframeWrapper;
