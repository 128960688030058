import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faPen } from '@fortawesome/free-solid-svg-icons';
import LoadingBar from 'react-top-loading-bar';
import moment from 'moment';

import EditableStyle from './Editable.module.scss';

/**
 * Component for update field.
 *
 * @component
 * @example
 * return (
 *   <Editable content="text" nameField="profile_url" onChangeValue={() => {..}} />
 * )
 */
function Editable({
  emptyMessage,
  children,
  content,
  nameField,
  onChangeValue,
  type,
  options,
  errorMessage,
  helpText,
  editing,
  isNew,
  maxCharacters,
  showEditMessage,
  loadingBarColor,
  showLoadingBar,
  showLabelAsValueInSelect,
  className,
  block,
  classPointer,
}) {
  if (!nameField || !onChangeValue || (type === 'select' && !options)) {
    return null;
  }

  const isInputTypeRenderImmediate = (isNew || type === 'checkbox') && !block;

  const cleanContent = (valueToClean) => {
    let valueCleaned = valueToClean || '';
    if (type === 'datetime') {
      valueCleaned = valueCleaned.replace('Z', '');
    } else if (type === 'date') {
      [valueCleaned] = valueCleaned.split('T');
    } else if (type === 'checkbox') {
      return Boolean(valueCleaned);
    }

    return valueCleaned.toString().trim();
  };

  const cleanValueBeforeRequest = (valueToClean) => {
    let valueCleaned = valueToClean;
    if (type === 'date' && valueCleaned !== '') {
      valueCleaned += 'T00:00';
    } else if ((type === 'date' || type === 'datetime') && valueCleaned === '') {
      valueCleaned = null;
    }

    return valueCleaned;
  };

  const [state, setState] = useState({
    valueContent: cleanContent(content),
    progress: 0,
    isEditing: editing,
    isEdit: false,
    errorResponse: errorMessage,
    sendingRequest: false,
  });

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setState({
      ...state,
      isEditing: editing,
      errorResponse: errorMessage,
    });
  }, [errorMessage, editing]);

  useEffect(() => {
    if (state.isEditing === false && state.sendingRequest === false) {
      setState({
        ...state,
        valueContent: cleanContent(content),
        isEdit: false,
        errorResponse: errorMessage,
      });
    }
  }, [content]);

  const formatContent = () => {
    let valueFormated = state.valueContent;
    if (type === 'datetime') {
      valueFormated = moment(valueFormated).format('DD/MM/YYYY HH:mm');
    } else if (type === 'date') {
      valueFormated = moment(valueFormated).format('DD/MM/YYYY');
    } else if (type === 'select' && showLabelAsValueInSelect) {
      const option = options.find((opt) => opt.value === valueFormated);
      if (option) {
        valueFormated = option.label;
      }
    }

    return valueFormated.toString();
  };

  const handlerChange = (event) => {
    const { value } = event.target;
    if (value.length <= maxCharacters || maxCharacters == null) {
      setState({ ...state, valueContent: value });
    }
  };

  const onEdit = () => {
    if (block) {
      return;
    }

    setState({ ...state, isEditing: true });
  };

  const updateData = (event) => {
    let value = type === 'checkbox' ? event.target.checked : event.target.value.trim();
    const initialContent = cleanContent(content);
    if (value === initialContent) {
      setState({
        ...state,
        valueContent: value,
        isEditing: false,
      });
      return;
    }

    value = cleanValueBeforeRequest(value);

    setState({
      ...state,
      sendingRequest: true,
    });

    onChangeValue(nameField, value)
      .then(() => {
        setState({
          valueContent: cleanContent(value),
          errorResponse: null,
          isEdit: true,
          isEditing: false,
          progress: 100,
          sendingRequest: false,
        });
      })
      .catch((error) => {
        let { errorResponse } = state;
        if (error.response && error.response.data[nameField]) {
          errorResponse = error.response.data[nameField];
        }
        setState({
          valueContent: initialContent,
          errorResponse,
          isEdit: false,
          isEditing: false,
          progress: 100,
          sendingRequest: false,
        });
      });
  };

  const keyDownUpdateData = (event) => {
    if (event.key === 'Enter') {
      updateData(event);
    }
  };

  const renderTypeEdit = () => {
    switch (type) {
      case 'text':
        return (
          <input
            type="text"
            value={state.valueContent}
            onKeyDown={keyDownUpdateData}
            onBlur={updateData}
            autoFocus={!isInputTypeRenderImmediate}
            onChange={handlerChange}
            className={classPointer !== null ? `${classPointer} ${EditableStyle.input}` : EditableStyle.input}
            disabled={state.sendingRequest}
          />
        );
      case 'textarea':
        return (
          <textarea
            value={state.valueContent}
            onBlur={updateData}
            autoFocus={!isInputTypeRenderImmediate}
            onChange={handlerChange}
            className={classPointer !== null ? `${classPointer} ${EditableStyle.input}` : EditableStyle.input}
            rows="6"
            disabled={state.sendingRequest}
          />
        );
      case 'select':
        return (
          <select
            value={state.valueContent}
            onChange={updateData}
            onBlur={updateData}
            autoFocus={!isInputTypeRenderImmediate}
            className={EditableStyle.input}
            disabled={state.sendingRequest}
          >
            <option key="void" value="">Select an option</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      case 'date':
        return (
          <input
            type="date"
            defaultValue={state.valueContent}
            onBlur={updateData}
            onKeyDown={keyDownUpdateData}
            autoFocus={!isInputTypeRenderImmediate}
            className={EditableStyle.input}
            disabled={state.sendingRequest}
          />
        );
      case 'datetime':
        return (
          <input
            type="datetime-local"
            defaultValue={state.valueContent}
            onBlur={updateData}
            onKeyDown={keyDownUpdateData}
            autoFocus={!isInputTypeRenderImmediate}
            className={EditableStyle.input}
            disabled={state.sendingRequest}
          />
        );
      case 'checkbox':
        return (
          <input
            type="checkbox"
            onChange={updateData}
            checked={state.valueContent}
            className={EditableStyle.input}
            disabled={state.sendingRequest}
          />
        );
      case 'password':
        return (
          <div className="position-relative">
            <input
              type={showPassword ? 'text' : 'password'}
              value={state.valueContent}
              onKeyDown={keyDownUpdateData}
              onBlur={updateData}
              autoFocus={!isInputTypeRenderImmediate}
              onChange={handlerChange}
              className={classPointer !== null ? `${classPointer} ${EditableStyle.input}` : EditableStyle.input}
              disabled={state.sendingRequest}
            />
            <button type="button" onClick={() => setShowPassword(!showPassword)} className="position-absolute bg-transparent" style={{ border: 'none', top: 4 }}>
              {
                showPassword
                  ? <FontAwesomeIcon icon={faEye} />
                  : <FontAwesomeIcon icon={faEyeSlash} />
              }
            </button>
          </div>
        );
      default:
        throw new Error();
    }
  };

  const getClassName = (classNameBase) => {
    let classContent = classNameBase;
    if (block) {
      classContent = `${classContent} ${EditableStyle.block}`;
    }

    if (type === 'textarea') {
      classContent = `${classContent} ${EditableStyle.content_textarea}`;
    }

    if (className) {
      classContent = `${classContent} ${className}`;
    }

    return classContent;
  };

  const renderEdit = () => {
    let render;
    if (state.isEditing || isInputTypeRenderImmediate) {
      render = renderTypeEdit();
    } else if (!children) {
      if (state.valueContent !== '') {
        render = (<span className={getClassName(EditableStyle.content)}>{formatContent()}</span>);
      } else {
        render = (<span className={getClassName(EditableStyle.empty)}>{emptyMessage}</span>);
      }
    }
    return render;
  };

  return (
    <div className={`${EditableStyle.container} ${className != null ? className : ''}`}>
      {
        showLoadingBar
        && (
          <LoadingBar
            shadow
            color={loadingBarColor}
            progress={state.progress}
            onLoaderFinished={() => setState({ ...state, progress: 0 })}
          />
        )
      }
      <div className={EditableStyle.wrapper}>
        <span onDoubleClick={onEdit}>
          {children}
          { renderEdit() }
          {
            !state.isEditing && !isInputTypeRenderImmediate && !block
            && (
            <span
              className={
                classPointer != null ? (
                  `${classPointer} ${EditableStyle.pointer}`
                ) : EditableStyle.pointer
              }
              role="button"
              aria-label="Edit"
              tabIndex={0}
              onClick={onEdit}
              onKeyDown={onEdit}
            >
              <FontAwesomeIcon icon={faPen} size="xs" />
            </span>
            )
          }
        </span>
      </div>
      <div className={EditableStyle.message}>
        {
          (state.isEditing || isInputTypeRenderImmediate)
          && (type === 'text' || type === 'textarea')
          && maxCharacters
          && (
            <div>
              <span>
                {state.valueContent.length}
                /
                {maxCharacters}
              </span>
            </div>
          )
        }
        {
          state.isEdit && showEditMessage
          && (
            <div className={EditableStyle.edit}>
              <span>Saved!</span>
            </div>
          )
        }
        {
          state.errorResponse
          && (
            <div className={EditableStyle.error}>
              <span>Error: </span>
              {state.errorResponse}
            </div>
          )
        }
        {
          helpText && !block
          && (
            <div>
              <span>{helpText}</span>
            </div>
          )
        }
      </div>
    </div>
  );
}

Editable.propTypes = {
  emptyMessage: PropTypes.string,
  /**
   * Container elements
   */
  children: PropTypes.node,
  /**
   * Content text
   */
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  /**
   * Name field in api
   */
  nameField: PropTypes.string.isRequired,
  /**
   * Function uploader
   */
  onChangeValue: PropTypes.func.isRequired,
  /**
   * Type of input
   */
  type: PropTypes.oneOf([
    'text',
    'select',
    'textarea',
    'datetime',
    'date',
    'checkbox',
    'password',
  ]),
  /**
   * Options for input type select
   */
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  /**
   * Message error
   */
  errorMessage: PropTypes.string,
  /**
   * Message help
   */
  helpText: PropTypes.string,
  /**
   * Indicate if is editing
   */
  editing: PropTypes.bool,
  /**
   * Indicate if is new
   */
  isNew: PropTypes.bool,
  /**
   * Maximum number of characters in input
   */
  maxCharacters: PropTypes.number,
  /**
   * Show edit message after update field
   */
  showEditMessage: PropTypes.bool,
  /**
   * Color of the loading bar
   */
  loadingBarColor: PropTypes.string,
  /**
   * Show loading bar while updating field
   */
  showLoadingBar: PropTypes.bool,
  /**
   * Show label as value in select fields
   */
  showLabelAsValueInSelect: PropTypes.bool,
  /**
   * Class name additional
   */
  className: PropTypes.string,
  /**
   * Class name additional
   */
  classPointer: PropTypes.string,
  /**
   * Indicate if block edit
   */
  block: PropTypes.bool,
};

Editable.defaultProps = {
  emptyMessage: 'Empty',
  children: null,
  content: '',
  type: 'text',
  options: null,
  errorMessage: null,
  helpText: null,
  editing: false,
  isNew: false,
  maxCharacters: null,
  showEditMessage: true,
  loadingBarColor: '#f11946',
  showLoadingBar: true,
  showLabelAsValueInSelect: true,
  className: null,
  classPointer: null,
  block: false,
};

export default Editable;
