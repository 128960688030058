import moment from 'moment';

import isObject from './utils/is-object.utils';

/**
 * Indicates if the date passed by parameter is greater than today.
 * @param {string} date - The date to compare, in mm/dd/YYYY format.
 * @return {boolean} True if the date passed by parameter is greater than today. False otherwise.
 */
export const dateHigherThanToday = (date) => {
  const today = moment().format('YYYY-MM-DD');

  return moment(today).diff(moment(date)) <= 0;
};

/**
 * Indicates if the date passed by parameter is less than today.
 * @param {string} date - The date to compare, in mm/dd/YYYY format.
 * @return {boolean} True if the date passed by parameter is less than today. False otherwise.
 */
export const dateLessThanToday = (date) => {
  const today = moment().format('YYYY-MM-DD');

  return moment(today).diff(moment(date)) >= 0;
};

/**
 * Indicates whether a memo is considered active or inactive.
 * @param {object} memo - The memo to compare, with status and valid_to attributes.
 * @return {boolean} True if the memo passed by parameter is active. False otherwise.
 */
export const isMemoActive = (memo) => {
  const memoValidFrom = memo.valid_from ? memo.valid_from : '1000-01-01';
  const memoValidTo = memo.valid_to ? memo.valid_to : '3000-01-01';

  return memo.status.toLowerCase() === 'pending' && dateLessThanToday(memoValidFrom) && dateHigherThanToday(memoValidTo);
};

/**
 * Counts the number of fewer assets in an array of memos.
 * @param {array} memos - The memos to compare, with object type elements and each one with
 *                        status and valid_to attributes.
 * @return {integer} integer number of active memos in the array.
 */
export const countMemoActive = (memos) => {
  const memosActive = memos.filter((memo) => isMemoActive(memo));

  return memosActive.length;
};

/**
 * Get error by field name in list of errors.
 * @param {object} errors - The list of errors where the error will be searched by field name.
 * @param {string} fieldName - Error identifier in error array.
 * @return {string} Text with error message by field name.
 */
export const getErrorByFieldName = (errors, fieldName) => {
  if (fieldName in errors) {
    if (Array.isArray(errors[fieldName])) {
      const [error] = errors[fieldName];
      return error;
    }

    return errors[fieldName];
  }

  return null;
};

export const lenguages = {
  en: 'English',
  es: 'Spanish',
};

/**
 * Determines if a field is editable based on the current user's permissions and the field's name.
 * @param {Object} metadata - The metadata object containing the current user's
 * permissions and allowed actions.
 * @param {string} fieldName - The name of the field to check.
 * @returns {boolean} - True if the field is editable, false otherwise.
 */
export const isFieldEditable = (metadata, fieldName) => {
  let readOnly = [];
  let update = false;
  if (metadata && metadata.current_user_permissions
      && metadata.current_user_permissions.read_only) {
    readOnly = metadata.current_user_permissions.read_only;
  }
  if (metadata && metadata.allowed_actions && metadata.allowed_actions.update) {
    update = metadata.allowed_actions.update;
  }

  return update && !readOnly.includes(fieldName);
};

export const isEditable = (metadata) => {
  let update = false;
  if (metadata && metadata.allowed_actions && metadata.allowed_actions.update) {
    update = metadata.allowed_actions.update;
  }
  return update;
};

/**
 * This replace all "_" of the text.
 * @param {string} text
 *
 *  E.g:
 *
 * input: "hello_world".
 * output: "Hello world"
 */
export const textSelectFormatter = (text) => {
  const textLowercaseClean = text.toLowerCase().toString().replace(/_/g, ' ');
  const capitalLetter = textLowercaseClean.slice(0, 1).toUpperCase();
  const textCapitalized = capitalLetter + textLowercaseClean.slice(1, textLowercaseClean.length);

  return {
    label: textCapitalized,
    value: text,
  };
};

/**
 * Determines if value is an object and has property 'component'.
 * @param {node} value - The value to determinate if is an object.
 * @return {boolean} True if is an object and has property 'component', False otherwise.
 */
export const isReactObject = (value) => (
  isObject(value) && Object.prototype.hasOwnProperty.call(value, 'component')
);

/**
 * Get formatted options to select
 * @param {object} context - Object (key, value) with label and value about options to select.
 * @param {string} key - Indicate option to format label and value.
 * @param {bool} asObject - Indicate if should be treated as object.
 * @param {string} subkey - Indicate sub-option to format label and value.
 * @return {array} List options formatted as [{label: ..., value: ...}].
 */
export const getFormattedOptionsByContext = (context, key, asObject = false, subkey = null) => {
  if (key in context) {
    if (asObject) {
      const object = context[key];
      return Object.keys(object).map(
        (objectKey) => ({
          value: objectKey,
          label: object[objectKey],
        }),
      ) || [];
    }

    if (subkey && subkey in context[key]) {
      return context[key][subkey].map(
        (choice) => ({ value: choice[0], label: choice[1] }),
      ) || [];
    }

    return context[key].map(
      (choice) => ({ value: choice[0], label: choice[1] }),
    ) || [];
  }

  return [];
};

export const getLabelByValue = (context, key, value, asObject = false, subkey = null) => {
  const formattedOptions = getFormattedOptionsByContext(context, key, asObject, subkey);

  return formattedOptions.find((option) => option.value === value)?.label;
};

/**
 * It returns an object with the keys 200, 201, 403, 400, 404, and 500.
 * @param {string} parameter - Custom message for 404 errors
 */
export const getRequestError = (parameter = ' ') => ({
  200: 'Operation completed successfully',
  201: 'Operation completed successfully',
  403: 'You do not have permission to perform this action.',
  400: 'Invalid params, check it and try again.',
  404: `${parameter} was not found!`,
  500: 'Internal server error',
});

/**
 * Indicate if a object is empty by keys.
 * @param {object} object - Object to eval.
 */
export const isObjectEmpty = (object) => (
  Object.keys(object || {}).length === 0
);

/**
 * Go to top of the container.
 *
 * @param {ref} ref - React ref of the scroll element.
 */
export const goToTop = (ref) => {
  ref?.current?.scrollTo({ top: 0, behavior: 'smooth' });
};

/**
 * Sets a new title for the document title with a static suffix.
 *
 * @param {String} documentTitle - Page title.
 */
export const setDocumentTitle = (documentTitle) => {
  document.title = `${documentTitle} | Edunext Control Center`;
};
