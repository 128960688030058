import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import AccountsViewRouter from './AccountsViewRouter';
import { AccountsViewProvider } from './AccountsViewContext';
import NotFound from '../../components/notFound/NotFound';

import PrivateRoute from '../../helpers/routes/privateRoute';

import './AccountsView.module.scss';
import '../../_overrides.scss';

const App = () => (
  <Router>
    <Switch>
      <PrivateRoute path="/accounts" permission>
        <AccountsViewProvider>
          <AccountsViewRouter />
        </AccountsViewProvider>
      </PrivateRoute>
      <Route exact path="/">
        <Redirect to="/accounts" />
      </Route>
      <NotFound hasAccontId={false} />
    </Switch>
  </Router>
);

ReactDOM.render(<App />, document.getElementById('root') || document.createElement('div'));
