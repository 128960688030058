import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  CheckCircleFilled,
  CloseCircleFilled,
  MinusCircleFilled,
} from '@ant-design/icons';
import { Space } from 'antd';

import Filter from '../filter/Filter';
import CustomButton from '../../customButton/CustomButton';

import FilterDropdownMenuStyle from './FilterDropdownMenu.module.scss';

/**
 * Component that renders a dropdown menu to Dropdown AntD with filters selected.
 *
 * @param {array} filters - Array list of object filters.
 * @param {string} logicOperator - String indicate logic operator ('and', 'or').
 * @param {function} addHandler - Method to add new filter to array list filters.
 * @param {function} deleteHandler - Method to delete filter of array list filters.
 * @param {function} onChange - Method when change filter of array list filters.
 * @param {function} onSubmit - Method when submit filters.
 * @param {function} onReset - Method when reset filters.
 */
const FilterDropdownMenu = ({
  filters,
  logicOperator,
  addHandler,
  deleteHandler,
  onChange,
  onSubmit,
  onReset,
}) => (
  <div className={FilterDropdownMenuStyle.main}>
    <div>
      {
        filters.map((filter, index) => (
          <div key={`filter-${filter.name?.join('')}`}>
            <Space>
              <Filter
                index={index}
                disableds={filters.map((filterIter) => filterIter.name?.join(''))}
                logicOperator={logicOperator}
                filter={filter}
                onChange={onChange}
                disableCascader={filters.length > 1}
                disableLogicOperator={filters.length > 2}
              />
              {
                filters.length > 1 && (
                  <button
                    type="button"
                    className={FilterDropdownMenuStyle.buttonWithoutBoder}
                    onClick={() => deleteHandler(index)}
                    aria-label="Delete filter"
                  >
                    <MinusCircleFilled />
                  </button>
                )
              }
            </Space>
          </div>
        ))
      }
      {
          filters[filters.length - 1]?.name && (
            <CustomButton
              variant="addNewItem"
              onClick={addHandler}
            >
              <span> Add Filters</span>
            </CustomButton>
          )
        }
    </div>
    <hr />
    <div className={FilterDropdownMenuStyle.applyFiltersSection}>
      <div className={FilterDropdownMenuStyle.describeSection}>
        Approve or cancel your filter selections
      </div>
      <Space>
        <button
          type="button"
          onClick={onSubmit}
          aria-label="Approve filter button"
          className={FilterDropdownMenuStyle.searchButton}
        >
          <CheckCircleFilled />
        </button>
        <button
          type="button"
          onClick={onReset}
          aria-label="Reset filter button"
          className={FilterDropdownMenuStyle.resetButton}
        >
          <CloseCircleFilled />
        </button>
      </Space>
    </div>
  </div>
);

FilterDropdownMenu.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.arrayOf(PropTypes.string),
      lookup: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.instanceOf(moment),
      ]),
      valueType: PropTypes.string,
    }),
  ).isRequired,
  logicOperator: PropTypes.string.isRequired,
  addHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default FilterDropdownMenu;
