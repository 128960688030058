import React, { useEffect, useReducer, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Form, message, Grid } from 'antd';
import { useParams } from 'react-router-dom';

import AttributeItems from './Items/AttributeItems';
import AttributeHeader from './Header/AttributeHeader';
import AttributeModal from './Modals/AttributeModal/AttributeModal';
import AttributeRequest from '../../helpers/requests/attributeRequest';
import AttributeModalWarning from './Modals/Warning/AttributeModalWarning';

import {
  attributeReducer,
  SET_ATTRIBUTES,
  SET_CURRENT_ATTRIBUTE,
  CLEAR_CURRENT_ATTRIBUTE,
  SET_ATTRIBUTE_MODAL_STATUS,
  SET_ATTRIBUTE_REQUEST_STATUS,
} from './Reducer/attributeReducer';

import { attributeStatusTagColors } from '../../constants/general.constants';
import { AccountContext } from '../../views/accountsView/account/AccountContext';
import { AccountsViewContext } from '../../views/accountsView/AccountsViewContext';

import {
  cardStyle,
  modalStatus,
  initialState,
  modalStatusContent,
  availableSubscriptions,
  attributeDefaultImages,
  missConfiguredSubscriptions,
  Context as AttributeContext,
} from './Context/AttributeContext';
import { getTypeTierMapBySubscription } from '../../helpers/utils/get-variable-content-by-subscription.utils';

import Styles from './AttributeWrapper.module.scss';

const { useForm } = Form;
const { useBreakpoint } = Grid;

/**
 * @param {String} attributeName - Name of the section.
 * @param {String} customQuery - Query for make custom request to attribute endpoint.
 * @param {String} category - Required for filter the right results.
 * @param {String} ownerType - [instance_attributes, tenant_attributes].
 * @param {Boolean} showType - [true, false] Shows the type field.
 * @param {String} modelName - [tenant, instance].
 * @param {String} canMakeRequest - [true, false] - Shows the request attribute button.
 * @param {Boolean} showSupportDate - Show the input for support date.
 * @param {String} attributeType - Name of attribute, determines the default image.
 * @param {String} instanceId - Id of the instance.
 * @param {Array} deniedSubscriptionAccess - Of denied subscriptions.
 *
 * @returns Wrapper which call the attributes endpoint and renders a collection of items.
 */
const AttributeWrapper = ({
  attributeName,
  customQuery,
  category,
  ownerType,
  modelName,
  showType,
  instanceId,
  canMakeRequest,
  attributeType,
  showSupportDate,
  deniedSubscriptionAccess,
}) => {
  const [form] = useForm();
  const screens = useBreakpoint();
  const [isMobile, setIsMobile] = useState(false);
  const { siteId, platformId, productId } = useParams();

  const [attributeState, attributeDispatch] = useReducer(
    attributeReducer,
    initialState,
  );

  const [viewContext] = AccountsViewContext();
  const [messageApi, contextHolder] = message.useMessage();

  const attributeItems = attributeState.attributeItems.filter(
    (item) => item.category === category,
  );

  const [accountContext] = AccountContext();

  const { instanceAttributeTypes, attributeTypes, attributeStatus } = accountContext.accoutOptions;

  const selectInputAttributeOptions = {
    instance_attributes: instanceAttributeTypes,
    tenant_attributes: attributeTypes,
    status: attributeStatus,
  };

  const selectStatusOptions = selectInputAttributeOptions.status.map(
    (item) => ({ label: item[1], value: item[0] }),
  );

  const selectTypeOptions = selectInputAttributeOptions[ownerType][
    category
  ].map((item) => ({ label: item[1], value: item[0] }));

  const isSupportMember = viewContext.user.roles.includes('support');

  const subscriptionInformation = accountContext.products[productId];
  const subscriptionTypeName = getTypeTierMapBySubscription(subscriptionInformation) || 'else';
  const forbiddenSubscriptions = [
    ...deniedSubscriptionAccess,
    ...missConfiguredSubscriptions,
  ];

  const showRequestButton = (
    canMakeRequest && !forbiddenSubscriptions.some((subscription) => (
      subscriptionTypeName.includes(subscription)))
  );

  const handleCloseModal = () => {
    attributeDispatch({
      type: SET_ATTRIBUTE_MODAL_STATUS,
      payload: modalStatus.hidden,
    });

    attributeDispatch({
      type: CLEAR_CURRENT_ATTRIBUTE,
    });

    attributeDispatch({
      type: SET_ATTRIBUTE_REQUEST_STATUS,
      payload: {
        data: {},
        inProgress: false,
      },
    });

    form.resetFields();
  };

  const saveAttribute = (formValues) => {
    attributeDispatch({
      type: SET_ATTRIBUTE_REQUEST_STATUS,
      payload: {
        data: {},
        inProgress: true,
      },
    });

    AttributeRequest.postAttribute(
      (response) => {
        handleCloseModal();

        attributeDispatch({
          type: SET_ATTRIBUTES,
          payload: [...attributeItems, response.data],
        });

        attributeDispatch({
          type: SET_ATTRIBUTE_REQUEST_STATUS,
          payload: {
            data: {},
            inProgress: false,
          },
        });

        messageApi.open({
          type: 'success',
          content: 'Attribute created successfully!',
        });
      },
      (error) => {
        messageApi.open({
          type: 'error',
          content: 'Something went wrong performing this action.',
        });

        attributeDispatch({
          type: SET_ATTRIBUTE_REQUEST_STATUS,
          payload: {
            data: {
              ...error,
            },
            inProgress: false,
          },
        });
      },
      {
        owner_type: ownerType,
        generic_fk_model_name: modelName,
        generic_fk_object_id: siteId || instanceId,
        owner_object_id: platformId,
        category,
        ...formValues,
        type: showType
          ? formValues?.type
          : selectInputAttributeOptions[ownerType][category][0][0],
      },
    );
  };

  const editAttribute = (attribute) => {
    attributeDispatch({
      type: SET_ATTRIBUTE_REQUEST_STATUS,
      payload: {
        data: {},
        inProgress: true,
      },
    });

    AttributeRequest.patchAttribute(
      (response) => {
        handleCloseModal();

        const newState = attributeState.attributeItems.filter(
          (item) => item.id !== attribute.id,
        );

        attributeDispatch({
          type: SET_ATTRIBUTES,
          payload: [...newState, response.data],
        });

        attributeDispatch({
          type: SET_ATTRIBUTE_REQUEST_STATUS,
          payload: {
            data: {},
            inProgress: false,
          },
        });

        messageApi.open({
          type: 'success',
          content: 'Attribute updated successfully!',
        });
      },
      () => {
        messageApi.open({
          type: 'error',
          content: 'Something went wrong performing this action.',
        });

        attributeDispatch({
          type: SET_ATTRIBUTE_REQUEST_STATUS,
          payload: {
            data: {},
            inProgress: false,
          },
        });
      },
      attribute.id,
      attribute,
    );
  };

  const deleteAttribute = (attributeId) => {
    attributeDispatch({
      type: SET_ATTRIBUTE_REQUEST_STATUS,
      payload: {
        data: {},
        inProgress: true,
      },
    });

    AttributeRequest.deleteAttribute(
      () => {
        handleCloseModal();

        const newState = attributeState.attributeItems.filter(
          (item) => item.id !== attributeId,
        );

        attributeDispatch({ type: SET_ATTRIBUTES, payload: newState });

        attributeDispatch({
          type: SET_ATTRIBUTE_REQUEST_STATUS,
          payload: {
            data: {},
            inProgress: false,
          },
        });

        messageApi.open({
          type: 'success',
          content: 'Attribute deleted successfully!',
        });
      },
      () => {
        messageApi.open({
          type: 'error',
          content: 'Something went wrong performing this action.',
        });

        attributeDispatch({
          type: SET_ATTRIBUTE_REQUEST_STATUS,
          payload: {
            data: {},
            inProgress: false,
          },
        });
      },
      attributeId,
    );
  };

  const actions = {
    creating: (formValues) => saveAttribute(formValues),
    updating: (attribute) => editAttribute(attribute),
    deleting: (attributeId) => deleteAttribute(attributeId),
  };

  const setFormValues = (attribute) => {
    const isDateAddedValid = moment(attribute.date_added).isValid();
    const isDateThroughValid = moment(attribute.support_through).isValid();

    const dateAdded = isDateAddedValid ? moment(attribute.date_added) : null;
    const dateSupportThrough = isDateThroughValid
      ? moment(attribute.support_through)
      : null;

    return form.setFieldsValue({
      ...attribute,
      date_added: dateAdded,
      support_through: dateSupportThrough,
    });
  };

  const setCurrentAttribute = (attribute) => {
    attributeDispatch({
      type: SET_CURRENT_ATTRIBUTE,
      payload: { ...attribute },
    });
    setFormValues(attribute);
  };

  const handleModalDisplaying = (attribute, event) => {
    setCurrentAttribute(attribute);
    event?.stopPropagation();
    attributeDispatch({
      type: SET_ATTRIBUTE_MODAL_STATUS,
      payload: modalStatus.displaying,
    });
  };

  const handleModalEditing = (attribute, event) => {
    setCurrentAttribute(attribute);
    event?.stopPropagation();
    attributeDispatch({
      type: SET_ATTRIBUTE_MODAL_STATUS,
      payload: modalStatus.updating,
    });
  };

  const handleModalDelete = (attribute, event) => {
    setCurrentAttribute(attribute);
    event?.stopPropagation();
    attributeDispatch({
      type: SET_ATTRIBUTE_MODAL_STATUS,
      payload: modalStatus.deleting,
    });
  };

  const handleListItemDelete = async (attribute, event) => {
    event?.stopPropagation();
    await setCurrentAttribute(attribute);
    actions.deleting(attributeState.currentAttribute?.id);
  };

  const handleOk = () => actions[attributeState.modalStatus](attributeState.currentAttribute?.id);

  const handleCancel = () => handleCloseModal();

  useEffect(() => {
    attributeDispatch({
      type: SET_ATTRIBUTE_REQUEST_STATUS,
      payload: {
        data: {},
        inProgress: true,
      },
    });

    AttributeRequest.getAttributes(
      (response) => {
        attributeDispatch({ type: SET_ATTRIBUTES, payload: response.data });
        attributeDispatch({
          type: SET_ATTRIBUTE_REQUEST_STATUS,
          payload: {
            data: {},
            inProgress: false,
          },
        });
      },
      () => {
        attributeDispatch({
          type: SET_ATTRIBUTE_REQUEST_STATUS,
          payload: {
            data: {},
            inProgress: false,
          },
        });
      },
      customQuery,
    );
  }, [customQuery]);

  useEffect(() => {
    setIsMobile(
      !Object.entries(screens).some((screen) => screen[0] === 'xl' && screen[1]),
    );

    return () => setIsMobile(false);
  }, [screens]);

  return (
    <AttributeContext.Provider
      value={[
        {
          form,
          actions,
          showType,
          cardStyle,
          handleOk,
          isMobile,
          attributeName,
          modalStatus,
          handleCancel,
          attributeType,
          attributeState,
          attributeItems,
          isSupportMember,
          handleCloseModal,
          showSupportDate,
          selectTypeOptions,
          attributeDispatch,
          handleModalDelete,
          modalStatusContent,
          handleModalEditing,
          setCurrentAttribute,
          handleListItemDelete,
          showRequestButton,
          handleModalDisplaying,
          selectStatusOptions,
          attributeDefaultImages,
          attributeStatusTagColors,
        },
      ]}
    >
      <section className={Styles.attributeWrapper}>
        {contextHolder}
        <AttributeHeader />
        <AttributeItems />
        <AttributeModal />
        <AttributeModalWarning />
      </section>
    </AttributeContext.Provider>
  );
};

AttributeWrapper.propTypes = {
  instanceId: PropTypes.number,
  category: PropTypes.string,
  showSupportDate: PropTypes.bool,
  showType: PropTypes.bool.isRequired,
  ownerType: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  attributeType: PropTypes.string,
  attributeName: PropTypes.string.isRequired,
  customQuery: PropTypes.string.isRequired,
  deniedSubscriptionAccess: PropTypes.arrayOf(
    PropTypes.oneOf(availableSubscriptions),
  ),
  canMakeRequest: PropTypes.bool,
};

AttributeWrapper.defaultProps = {
  category: 'applications',
  instanceId: undefined,
  showSupportDate: true,
  attributeType: 'default',
  canMakeRequest: true,
  deniedSubscriptionAccess: missConfiguredSubscriptions,
};

export default AttributeWrapper;
