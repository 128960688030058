import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy, usePagination } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltDown,
  faLongArrowAltUp,
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleDoubleRight,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import TableStyle from './Table.module.scss';
/**
 * Component for showing table with columns and data for rows.
 *
 * @component
 * @example
 * https://react-table.tanstack.com/docs/api/useTable#column-options
 * const columns = [
    {
      Header: 'First Name',
      accessor: 'firstName',
      columns: [ // optionals attribute for group header
        {
          Header: 'First Name',
          accessor: 'firstName',
        },
        ...
      ]
    },
    ...
  ];
 *
 * const data = [
    {
      firstName: 'Nombre Prueba',
    },
    ...
  ];
 * return (
 *   <Table columns={columns} data={data} />
 * )
 */

function TableComponent({
  columns,
  data,
  loading,
  fetchData,
  pageCount: controlledPageCount,
  dataName,
  initialState,
}) {
  let pageSizeOptions = [10, 20, 30, 40, 50];
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        ...initialState,
        pageIndex: 0,
      },
      ...(fetchData && { manualPagination: true }),
      ...(fetchData && { pageCount: controlledPageCount }),
      ...(fetchData && { autoResetPage: false }),
    },
    useSortBy,
    usePagination,
  );

  if (initialState.pageSize) {
    pageSizeOptions = pageSizeOptions.fill(initialState.pageSize)
      .map((pageSizeOption, index) => pageSizeOption * (index + 1));
  }

  useEffect(() => {
    if (fetchData) {
      fetchData({ pageIndex, pageSize });
    }
  }, [fetchData, pageIndex, pageSize]);

  // Function to change the sorted Icon depending if the column is sorted descending or ascending
  function sorted(column) {
    let sortedIcon;
    if (column.isSorted) {
      if (column.isSortedDesc) {
        sortedIcon = (
          <FontAwesomeIcon
            icon={faLongArrowAltDown}
            className={TableStyle.arrowIcon}
          />
        );
        return sortedIcon;
      }
      sortedIcon = (
        <FontAwesomeIcon
          icon={faLongArrowAltUp}
          className={TableStyle.arrowIcon}
        />
      );
      return sortedIcon;
    }
    return sortedIcon;
  }

  return (
    <>
      <div className={TableStyle.wrap}>
        <div className={TableStyle.tableWrapper}>
          <table className={TableStyle.tableAccounts} {...getTableProps()}>
            <thead className={TableStyle.theadAccounts}>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // Add the sorting props to control sorting.
                    // we  add them into the header props
                    <th
                      className={TableStyle.thAccounts}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>
                        {' '}
                        {sorted(column)}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {!loading && (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td
                          className={TableStyle.tdAccounts}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        {
           !loading && page.length === 0 && (<p className="mb-0 py-3 text-nowrap text-center border-start border-end border-bottom ms-0">There are no records to display</p>)
        }
        {
           loading && (
             <div className="d-flex flex-column justify-content-center align-items-center border-start border-end border-bottom">
               <div className={TableStyle.tableLoader}>
                 <div />
                 <div />
                 <div />
                 <div />
               </div>
               <p className="text-nowrap text-center">Loading data...</p>
             </div>
           )
        }
        <div className="d-flex justify-content-center mt-1">
          <div className={TableStyle.pagination}>
            <div className={TableStyle.previous}>
              <button
                className={TableStyle.gotoPageBtn}
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                type="button"
                aria-label="Next page icon"
              >
                <FontAwesomeIcon
                  icon={faAngleDoubleLeft}
                  className={TableStyle.angleIcon}
                />
              </button>
              {' '}
              <button
                className={TableStyle.previousBtn}
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                type="button"
                aria-label="Previous page icon"
              >
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className={TableStyle.angleIcon}
                />
              </button>
              {' '}
            </div>
            <div className={TableStyle.pageInfo}>
              <span className={TableStyle.pageIndex}>
                Page
                {' '}
                <strong>
                  {pageIndex + 1}
                  {' '}
                  of
                  {' '}
                  {pageOptions.length}
                </strong>
                {' '}
              </span>
              <select
                className={TableStyle.pageSizeOptions}
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {pageSizeOptions.map((pageSizes) => (
                  <option key={pageSizes} value={pageSizes}>
                    Rows
                    {' '}
                    {pageSizes}
                  </option>
                ))}
              </select>
              <span className={TableStyle.quantity}>
                {data.length}
                {' '}
                {dataName}
              </span>
            </div>
            <div className={TableStyle.next}>
              <button
                className={TableStyle.nextBtn}
                onClick={() => nextPage()}
                disabled={!canNextPage}
                type="button"
                aria-label="Next page icon"
              >
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className={TableStyle.angleIcon}
                />
              </button>
              {' '}
              <button
                className={TableStyle.gotoPageBtn}
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                type="button"
                aria-label="Go to page icon"
              >
                <FontAwesomeIcon
                  icon={faAngleDoubleRight}
                  className={TableStyle.angleIcon}
                />
              </button>
              {' '}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
TableComponent.propTypes = {
  /**
   * table's headers
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
    }),
  ),
  /**
   * table's rows
   */
  data: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * table's loading data
   */
  loading: PropTypes.bool,
  /**
   * table's get data api
   */
  fetchData: PropTypes.func,
  /**
   * table's get data api page count
   */
  pageCount: PropTypes.number,
  /**
   * table's data name
   */
  dataName: PropTypes.string,
  /**
   * table's initial state
   */
  initialState: PropTypes.instanceOf(Object),
};

TableComponent.defaultProps = {
  columns: [],
  data: [],
  loading: false,
  fetchData: null,
  pageCount: undefined,
  dataName: 'accounts',
  initialState: {},
};
export default TableComponent;
