import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert, Button, Result, Row,
} from 'antd';

/**
 * Component that renders a static screen with a
 * generic error message when some microservice fails
 *
 * @param {node} children - React JSX node element.
 * @param {string} title - Indicate the title of static screen.
 * @param {string} message - Indicate the message of static screen.
 * @param {string} detail - Indicate the more detail of error of static screen.
 * @param {func} onTryAgain - Function that is triggered when they click the 'Try Again' button.
 */
const ServiceError = ({
  children,
  title,
  message,
  detail,
  onTryAgain,
}) => (
  <Result
    status="error"
    title={title}
  >
    {children}
    <Alert
      type="error"
      message={message}
    />
    {
      detail && (
        <Alert
          type="error"
          message={detail}
        />
      )
    }
    {
      onTryAgain && (
        <Row align="center" justify="center">
          <Button
            key="try-again"
            onClick={onTryAgain}
            style={{ marginTop: 10 }}
          >
            Try Again
          </Button>
        </Row>
      )
    }
  </Result>
);

ServiceError.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  detail: PropTypes.string,
  onTryAgain: PropTypes.func,
};

ServiceError.defaultProps = {
  children: null,
  detail: null,
  onTryAgain: null,
};

export default ServiceError;
