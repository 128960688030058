import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import NotificationsRequest from '../../../helpers/requests/notificationsRequest';

import { AccountsViewContext } from '../../../views/accountsView/AccountsViewContext';

export const Context = createContext();
Context.displayName = 'NotificationContext';

export const NotificationContext = () => useContext(Context);

const NotificationProvider = ({ children }) => {
  const HTML_SANITIZE_CONFIG = {
    allowedTags: false,
    allowedAttributes: false,
  };

  const LOCAL_STORAGE_NOTIFICATION_KEY = 'controlCenterNotification';

  const NOTIFICATION_DATA_INITIAL_STATE = {
    isOpen: false,
    status: 'creating',
    data: {
      id: null,
      title: '',
      message: '',
      validTo: '',
      validFrom: '',
      textFormat: 'html',
      fullScreenMode: false,
    },
  };

  const [apiErrors, setApiErrors] = useState({});
  const [htmlMessage, setHtmlMessage] = useState('');
  const [allNotifications, setAllNotifications] = useState([]);
  const [activeNotifications, setActiveNotifications] = useState([]);

  const [notification, setNotification] = useState(NOTIFICATION_DATA_INITIAL_STATE);

  const [notificationDetail, setNotificationDetails] = useState({
    isOpen: false,
    data: {},
  });

  const [viewContext] = AccountsViewContext();

  /**
   * Permissions:
   * - Create
   * - Update
   * - Delete
   */
  const hasNotificationPermissions = viewContext.user.allowed_actions.read && (
    viewContext.user.roles?.some((role) => (
      role === 'back_office'
    || role === 'support'
    ))
  );

  const SELECT_OPTIONS = [
    {
      label: 'Plain Text',
      value: 'plain',
    },
    {
      label: 'HTML Text',
      value: 'html',
    },
    {
      label: 'Custom HTML',
      value: 'custom',
    },
  ];

  const handleInputChange = (key, value) => new Promise((resolve) => {
    setNotification({
      ...notification,
      data: {
        ...notification.data,
        [key]: value,
      },
    });
    resolve(true);
  });

  /**
   * @param {Boolean} isActive - true for get the active notifications, false for all notifications.
   * @returns Notifications
   */
  const getNotifications = async (isActive) => {
    const { data: { results } } = await NotificationsRequest.getNotifications(isActive);
    return results.reverse();
  };

  const notificationFormatter = ({
    id,
    title,
    message,
    text_format: textFormat,
    created_date: createdDate,
    display_full_screen: fullScreen,
    valid_from: validFrom,
    valid_to: validTo,
  }) => ({
    id,
    title,
    message,
    textFormat,
    createdDate,
    fullScreen,
    validFrom,
    validTo,
    isActive: true,
  });

  const notificationListFormatter = (notificationList) => {
    const notificationsFormatted = notificationList.map(notificationFormatter);

    return notificationsFormatted;
  };

  const notificationPayload = () => {
    let notificationData = {
      title: notification.data.title,
      message: notification.data.message,
      valid_to: notification.data.validTo,
      valid_from: notification.data.validFrom,
      text_format: notification.data.textFormat,
      display_full_screen: notification.data.fullScreenMode,
    };

    if (notification.data.textFormat === 'html') {
      notificationData = {
        ...notificationData,
        message: htmlMessage,
      };

      return notificationData;
    }

    if (notification.data.textFormat === 'custom') {
      notificationData = {
        ...notificationData,
        text_format: 'html',
        message: notification.data.message,
      };

      return notificationData;
    }

    return notificationData;
  };

  const createNotificationRequest = () => NotificationsRequest
    .newNotification(notificationPayload());

  const createNotification = () => {
    createNotificationRequest()
      .then((response) => {
        setHtmlMessage('');

        setNotification(NOTIFICATION_DATA_INITIAL_STATE);

        setApiErrors({});

        setAllNotifications([
          ...allNotifications,
          notificationFormatter(response.data),
        ].reverse());

        setActiveNotifications([
          ...activeNotifications,
          notificationFormatter(response.data),
        ].reverse());
      })
      .catch((error) => setApiErrors(error?.response?.data || {}));
  };

  const updateNotificationRequest = async (notificationId, notificationData) => {
    const { data } = await NotificationsRequest
      .updateNotification(notificationId, notificationData);
    return data;
  };

  const updateNotification = () => {
    const LOCAL_STORAGE_NOTIFICATIONS = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_NOTIFICATION_KEY),
    );

    updateNotificationRequest(notification.data.id, notificationPayload())
      .then((response) => {
        const NOTIFICATIONS = allNotifications
          .filter((oldNotification) => oldNotification.id !== notification.data.id);

        setAllNotifications([
          notificationFormatter(response),
          ...NOTIFICATIONS,
        ]);

        setNotification(NOTIFICATION_DATA_INITIAL_STATE);

        setHtmlMessage('');

        setApiErrors({});

        getNotifications(true)
          .then((activeNotificationsResponse) => {
            const ACTIVE_NOTIFICATIONS = notificationListFormatter(activeNotificationsResponse);
            ACTIVE_NOTIFICATIONS.map((notificationItem) => {
              if (LOCAL_STORAGE_NOTIFICATIONS !== null && typeof LOCAL_STORAGE_NOTIFICATIONS === 'object' && notificationItem.id in LOCAL_STORAGE_NOTIFICATIONS) {
                return ({
                  ...notificationItem,
                  isActive: LOCAL_STORAGE_NOTIFICATIONS[notificationItem.id],
                });
              }

              return notificationItem;
            });

            setActiveNotifications(ACTIVE_NOTIFICATIONS);
          });
      })
      .catch((error) => setApiErrors(error?.response?.data || {}));
  };

  const deleteNotificationRequest = async (notificationId) => {
    await NotificationsRequest.deleteNotification(notificationId);
  };

  const deleteNotification = (notificationId) => {
    deleteNotificationRequest(notificationId)
      .then(() => {
        const NOTIFICATIONS = allNotifications
          .filter((oldNotification) => oldNotification.id !== notificationId);

        setAllNotifications(NOTIFICATIONS);
      });
  };

  const hideNotification = (id) => {
    const NOTIFICATION = activeNotifications
      .find((notificationActive) => notificationActive.id === id);

    const OLD_NOTIFICATIONS = activeNotifications.filter((item) => item.id !== id);

    document.body.style.overflow = 'auto';

    setActiveNotifications([
      {
        ...NOTIFICATION,
        isActive: false,
      },
      ...OLD_NOTIFICATIONS,
    ]);
  };

  const saveNotificationStatus = (notificationId) => {
    hideNotification(notificationId);

    const LOCAL_STORAGE_IDS = JSON
      .parse(localStorage.getItem(LOCAL_STORAGE_NOTIFICATION_KEY)) || {};

    localStorage.setItem(LOCAL_STORAGE_NOTIFICATION_KEY,
      JSON.stringify({ ...LOCAL_STORAGE_IDS, [notificationId]: false }));
  };

  const dismissNotification = (id) => {
    hideNotification(id);
    saveNotificationStatus(id);
  };

  useEffect(() => {
    Promise.all([
      getNotifications(false),
      getNotifications(true),
    ]).then(([notifications, notificationsActive]) => {
      const LOCAL_STORAGE_NOTIFICATIONS = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_NOTIFICATION_KEY),
      );

      const ALL_NOTIFICATIONS_IDS = notifications.map((item) => item.id);

      if (LOCAL_STORAGE_NOTIFICATIONS === null) {
        localStorage.setItem(LOCAL_STORAGE_NOTIFICATION_KEY,
          JSON.stringify(
            ALL_NOTIFICATIONS_IDS.reduce((obj, noticeId) => (
              { ...obj, [noticeId]: true }
            ), {}),
          ));
      }

      const ACTIVE_NOTIFICATIONS = notificationListFormatter(notificationsActive)
        .map((notificationItem) => {
          if (LOCAL_STORAGE_NOTIFICATIONS !== null && typeof LOCAL_STORAGE_NOTIFICATIONS === 'object' && notificationItem.id in LOCAL_STORAGE_NOTIFICATIONS) {
            return ({
              ...notificationItem,
              isActive: LOCAL_STORAGE_NOTIFICATIONS[notificationItem.id],
            });
          }

          return notificationItem;
        });

      setActiveNotifications(ACTIVE_NOTIFICATIONS);

      return setAllNotifications(notificationListFormatter(notifications));
    });
  }, []);

  return (
    <Context.Provider
      value={{
        apiErrors,
        notification,
        SELECT_OPTIONS,
        htmlMessage,
        allNotifications,
        notificationDetail,
        activeNotifications,
        setApiErrors,
        setNotification,
        setHtmlMessage,
        hideNotification,
        handleInputChange,
        createNotification,
        updateNotification,
        deleteNotification,
        dismissNotification,
        setAllNotifications,
        saveNotificationStatus,
        setNotificationDetails,
        setActiveNotifications,
        HTML_SANITIZE_CONFIG,
        hasNotificationPermissions,
        LOCAL_STORAGE_NOTIFICATION_KEY,
        NOTIFICATION_DATA_INITIAL_STATE,
      }}
    >
      {children}
    </Context.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationProvider;
