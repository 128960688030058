import React, { useEffect, useState } from 'react';
import { Modal, Tooltip } from 'antd';
import {
  AppstoreOutlined,
  BarsOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import {
  SET_ATTRIBUTE_MODAL_STATUS,
  SET_ATTRIBUTE_STYLE_VIEW,
} from '../Reducer/attributeReducer';
import { AttributeContext } from '../Context/AttributeContext';

import ZohoForm from '../../ZohoForm/ZohoForm';

import Styles from './AttributeHeader.module.scss';

const AttributeHeader = () => {
  const [
    {
      isMobile,
      cardStyle,
      modalStatus,
      attributeName,
      attributeItems,
      attributeState,
      isSupportMember,
      attributeDispatch,
      showRequestButton,
    },
  ] = AttributeContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showSortButtons = (
    attributeItems.length > 0 && !isMobile && !attributeState.request.inProgress
  );

  const setRowStyle = () => attributeDispatch({
    type: SET_ATTRIBUTE_STYLE_VIEW,
    payload: cardStyle.list,
  });

  const setCardStyle = () => attributeDispatch({
    type: SET_ATTRIBUTE_STYLE_VIEW,
    payload: cardStyle.card,
  });

  const setOpenNewModal = () => attributeDispatch({
    type: SET_ATTRIBUTE_MODAL_STATUS,
    payload: modalStatus.creating,
  });

  const showRequestModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isMobile) setCardStyle();
  }, [isMobile]);

  return (
    <header className={Styles.headerWrapper}>
      <h4>
        {attributeName}
      </h4>
      <Modal
        open={isModalOpen}
        title="Open a new support ticket"
        handleOk={handleOk}
        onCancel={handleCancel}
        zIndex={1025}
        footer={[]}
      >
        <ZohoForm defaultType="Request" />
      </Modal>
      <div className={Styles.buttonsHeaderWrapper}>
        {
        attributeState.request.inProgress && (
          <>
            <div className={Styles.headerButtonSkeleton} />
            <div className={Styles.headerButtonSkeleton} />
          </>
        )

        }
        {showSortButtons && (
          <>
            <Tooltip title="Card">
              <button
                type="button"
                aria-label="Card style"
                className={`${Styles.headerButton} ${
                  attributeState.cardStyle === cardStyle.card
                  && Styles.activeButton
                }`}
                onClick={setCardStyle}
              >
                <AppstoreOutlined />
              </button>
            </Tooltip>
            <Tooltip title="Row">
              <button
                type="button"
                aria-label="Row style"
                className={`${Styles.headerButton} ${
                  attributeState.cardStyle === cardStyle.list
                  && Styles.activeButton
                }`}
                onClick={setRowStyle}
              >
                <BarsOutlined />
              </button>
            </Tooltip>
          </>
        )}
        {isSupportMember && (
          <button
            type="button"
            className={Styles.addNewAttribute}
            onClick={setOpenNewModal}
          >
            <span>
              Add new
              {' '}
              {attributeName.toLowerCase().replace(/your/i, '')}
            </span>
            <PlusOutlined />
          </button>
        )}
        {showRequestButton && !isSupportMember && (
          <button
            type="button"
            className={Styles.addNewAttribute}
            onClick={showRequestModal}
          >
            <span>
              Request new
              {' '}
              {attributeName.toLowerCase().replace(/your/i, '')}
            </span>
            <PlusOutlined />
          </button>
        )}
      </div>
    </header>
  );
};

export default AttributeHeader;
