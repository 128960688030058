import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useRouteMatch } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { AccountContext } from '../AccountContext';
import { AccountsViewContext } from '../../AccountsViewContext';
import { UPDATE_HIDE_CHATWIDGET } from '../../AccountsViewReducer';

import ErrorPage from '../../../../components/errorPage/ErrorPage';
import TabsContainer from '../../../../components/tabsContainer/TabsContainer';
import LoadingPage from '../../../../components/loading/LoadingPage';
import SubscriptionVariableContent from '../../../../components/subscriptionVariableContent/SubscriptionVariableContent';
import Card from '../../../../components/card/Card';
import PopoverWrapper from '../../../../components/PopoverWrapper/PopoverWrapper';
import IframeWrapper from '../../../../components/iframeWrapper/IframeWrapper';

import { useDocTitle, useParamsToState } from '../../../../helpers/customHooks';
import SupportStyle from './Support.module.scss';

/**
 * Component for showing the Support tab container.
 *
 * @component
 * @example
 * const defaultIndex = 1, This set the tab #1.
 * return (
 *   <Support defaultIndex={1}/>
 * )
 */

function Support({ defaultIndex }) {
  useParamsToState();
  const { path } = useRouteMatch();
  const { accountId } = useParams();
  const [viewContext, viewContextDispatch] = AccountsViewContext();
  const [accountContext] = AccountContext();
  const { products } = accountContext;
  const [, setDocTitle] = useDocTitle('Customer Support');
  const account = viewContext.accounts[accountId];

  useEffect(() => {
    viewContextDispatch({
      type: UPDATE_HIDE_CHATWIDGET,
      state: true,
    });

    ReactGA.send({
      hitType: 'pageview',
      page: path,
      title: 'Support',
    });

    return () => {
      viewContextDispatch({
        type: UPDATE_HIDE_CHATWIDGET,
        state: false,
      });
    };
  }, []);

  const itemsSupport = Object.entries(products).map(([key, subscription]) => ({
    title: subscription.name,
    tier: subscription.tier,
    zohoId: subscription.support_account_id,
    external_key: subscription.external_key,
    key,
  }));

  const changeDocTitleOnSelectedTab = (index, tabName) => {
    const base = ` ${account.name}`;
    if (tabName) {
      setDocTitle(`Customer Support | ${tabName} | ${base}`);
    }
  };

  useEffect(() => {
    if (account && products) {
      changeDocTitleOnSelectedTab(defaultIndex);
    }
  }, [account, products, viewContext.loaded]);
  let SupportComponent = null;

  if (viewContext.loaded) {
    if (!products || !account) {
      SupportComponent = <ErrorPage />;
    } else {
      SupportComponent = (
        <div>
          <div className={SupportStyle.containerSp}>
            <h2 className={SupportStyle.title}>
              Customer Support
            </h2>
            <div className={SupportStyle.aidanContainer}>
              <h1 className={SupportStyle.aidanContainerTitle}>Chat with Aidan</h1>
              <h4 className={SupportStyle.aidanContainerSubtitle}>
                your AI assistant to edunext services and support
              </h4>
              <div className={SupportStyle.aidanContainerIframe}>
                <IframeWrapper url={`https://${process.env.CHATBASE_URL}/chatbot-iframe/${process.env.CHATBASE_BOT_ID}`} />
              </div>
            </div>
            <TabsContainer onSelect={changeDocTitleOnSelectedTab}>
              {itemsSupport.map((item) => (
                <div name={item.title} key={item.zohoId}>
                  <Card name="Card" hasMainElement={false} columnsNumber={1}>
                    <div className="d-flex justify-content-end">
                      <PopoverWrapper title="About this section">
                        <p>
                          In this section you can get information in
                          case of issues with your product.
                        </p>
                      </PopoverWrapper>
                    </div>
                    <SubscriptionVariableContent section="support_explore_knowledge" />
                    <SubscriptionVariableContent
                      section="support_explore_knowledge"
                      suscriptionId={item.external_key}
                      reactElementExtraProps={{ productId: item.external_key }}
                      variable
                    />
                    <SubscriptionVariableContent
                      section="support_open_ticket"
                      suscriptionId={item.external_key}
                      reactElementExtraProps={{ productId: item.external_key }}
                      variable
                    />
                    <SubscriptionVariableContent section="support_footer" />
                  </Card>
                </div>
              ))}
            </TabsContainer>
          </div>
        </div>
      );
    }
  } else {
    return <LoadingPage />;
  }

  return <div>{SupportComponent}</div>;
}

Support.propTypes = {
  /**
   * Default tab
   */
  defaultIndex: PropTypes.number,
};

Support.defaultProps = {
  defaultIndex: 0,
};

export default Support;
