import ReactGA from 'react-ga4';
import React, { useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';

import Card from '../../../../components/card/Card';
import { AccountsViewContext } from '../../AccountsViewContext';
import ErrorPage from '../../../../components/errorPage/ErrorPage';
import LoadingPage from '../../../../components/loading/LoadingPage';
import PopoverWrapper from '../../../../components/PopoverWrapper/PopoverWrapper';

import DocumentationStyles from './Documentation.module.scss';
import IframeWrapper from '../../../../components/iframeWrapper/IframeWrapper';
import DOCS_URLS from '../../../../constants/documentation.constants';

import { useDocTitle, useParamsToState } from '../../../../helpers/customHooks';

function Documentation() {
  useDocTitle('Documentation');
  useParamsToState();
  const { path } = useRouteMatch();
  const { accountId } = useParams();
  const [viewContext] = AccountsViewContext();
  const account = viewContext.accounts[accountId];

  let documentationComponent = null;

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: path,
      title: 'Documentation',
    });
  }, []);

  if (viewContext.loaded) {
    if (account === undefined) {
      documentationComponent = <ErrorPage />;
    } else {
      documentationComponent = (
        <div>
          <div className={DocumentationStyles.iframeContainer}>
            <Card name="Card" hasMainElement={false} columnsNumber={1}>
              <div className="d-flex justify-content-end">
                <PopoverWrapper title="About this section">
                  <p>
                    This section has the full documentation set for edunext
                    services and the open edX platform.
                  </p>
                  <p>
                    The documents can also be accessed from outside of the Edunext
                    control Center at:
                  </p>
                  <a
                    href={DOCS_URLS.index}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={DocumentationStyles.link}
                  >
                    {DOCS_URLS.index}
                    .
                  </a>
                </PopoverWrapper>
              </div>
              <IframeWrapper url={DOCS_URLS.index} />
            </Card>
          </div>
        </div>
      );
    }
  } else {
    return <LoadingPage />;
  }

  return <div>{documentationComponent}</div>;
}

export default Documentation;
