/**
 * This format data for tab component.
 *
 * @param {node} children - The content of the tab, React node.
 * @param {String} label- This will be the tab title.
 * @param {number} key - The key of the tab, required for React elements.
 * @param {boolean} disabled - Indicate if tab is disabled.
 */
const tabContentFormatter = (children, label = ' ', key = 0, disabled = false) => ({
  label,
  key,
  children,
  disabled,
});

export default tabContentFormatter;
