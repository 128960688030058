import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import BCard from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import RefreshPage from '../../refresh/RefreshPage';
import LoadingPage from '../../loading/LoadingPage';
import getFullName from '../../../helpers/utils/getFullName/getFullName.utils';
import SupportInfoStyles from '../SupportInfo.module.scss';

/**
 * Component that renders support ticket detail.
 *
 * @param {bool} show - Indicate if modal is open.
 * @param {func} onHide - Method trigger when close modal.
 * @param {bool} loading - Indicate if modal is loading.
 * @param {bool} error - Indicate if ticket detail load has error.
 * @param {object} ticketDetail - Contain ticket data to render modal.
 * @param {func} renderTicketDetail - Rerender ticket detail method.
 *
 */
const SupportTicketDetail = ({
  show,
  onHide,
  loading,
  error,
  ticketDetail,
  renderTicketDetail,
}) => {
  const canRenderTicketDetail = !loading && !error;
  const canRenderTicketRefresh = !loading && error;
  const canRenderTicketLoading = loading && !error;

  const getAgent = ({ assignee }) => {
    const name = assignee ? getFullName(assignee) : 'Assigning Agent';
    const email = assignee
      ? (assignee.email || '')
      : "We're finding the best person to help with your issue. Please hold tight!";

    return (
      <>
        <p>{name}</p>
        <strong>{email}</strong>
      </>
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title className="new" id="contained-modal-title-vcenter">
          {`Ticket Number ${ticketDetail.ticketNumber ? ticketDetail.ticketNumber : '...'}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          {
            canRenderTicketLoading && (
              <LoadingPage height="420px" message="Loading ticket information..." />
            )
          }
          {
            canRenderTicketDetail && (
              <div className="p-3">
                <div className="pb-5">
                  <h2>
                    {
                      ticketDetail.status && (
                        <small>
                          <Badge pill bg="primary">
                            {ticketDetail.status}
                          </Badge>
                        </small>
                      )
                    }
                  </h2>
                  <h2>{ticketDetail.subject}</h2>
                </div>
                <Row xs={1} md={2} className="pb-4">
                  <Col>
                    <h4>Contact</h4>
                    <div>
                      <div>{getFullName(ticketDetail.contact)}</div>
                      <strong>{ticketDetail.contact?.email}</strong>
                      <div><small>{ticketDetail.contact?.account?.accountName}</small></div>
                    </div>
                  </Col>
                  <Col>
                    <h4>Agent</h4>
                    <div className="d-flex">
                      <div>
                        <Avatar
                          size={60}
                          icon={<UserOutlined />}
                          src={`data:image/png;base64,${ticketDetail.assignee?.photo}`}
                        />
                      </div>
                      <div className={SupportInfoStyles.agentContainer}>
                        {getAgent(ticketDetail)}
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="pb-5">
                  <h4>Description</h4>
                  { ticketDetail.description && (
                    <BCard>
                      <BCard.Body>
                        {parse(sanitizeHtml(ticketDetail.description))}
                      </BCard.Body>
                    </BCard>
                  )}
                </div>
                <div className="pb-5">
                  <h4>Last Thread</h4>
                  { ticketDetail.thread?.content && (
                    <BCard>
                      <BCard.Body>
                        {parse(sanitizeHtml(ticketDetail.thread?.content))}
                      </BCard.Body>
                    </BCard>
                  )}
                </div>
                <div className="pb-5">
                  <h4>Attachments</h4>
                  { ticketDetail.thread?.attachments && (
                    Object.entries(ticketDetail.thread.attachments).map(([, value]) => (
                      <div>
                        {
                          value.mime_type?.includes('image') ? (
                            <Image
                              width="100%"
                              src={`data:${value.mime_type};base64,${value.file}`}
                            />
                          ) : (
                            <a
                              rel="noreferrer"
                              target="_blank"
                              download={value.name}
                              href={`data:${value.mime_type};base64,${value.file}`}
                            >
                              {value.name}
                            </a>
                          )
                        }
                      </div>
                    ))
                  )}
                </div>
                <Row xs={1} md={3}>
                  <Col>
                    <h4>Date</h4>
                    <p>{ticketDetail.createdTime !== null && moment(ticketDetail.createdTime).format('DD/MM/YYYY')}</p>
                  </Col>
                  <Col>
                    <h4>Due Date</h4>
                    <p>{ticketDetail.dueDate !== null && moment(ticketDetail.dueDate).format('DD/MM/YYYY')}</p>
                  </Col>
                  <Col>
                    <h4>Close Date</h4>
                    <p>{ticketDetail.closedTime !== null && moment(ticketDetail.closedTime).format('DD/MM/YYYY')}</p>
                  </Col>
                </Row>
              </div>
            )
          }
          {
            canRenderTicketRefresh && renderTicketDetail && (
              <RefreshPage
                height="420px"
                onClick={() => renderTicketDetail(ticketDetail.id)}
              />
            )
          }
        </Container>
      </Modal.Body>
    </Modal>
  );
};

SupportTicketDetail.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  ticketDetail: PropTypes.shape({
    id: PropTypes.string,
    ticketNumber: PropTypes.string,
    status: PropTypes.string,
    subject: PropTypes.string,
    contact: PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      account: PropTypes.shape({
        accountName: PropTypes.string,
      }),
    }),
    assignee: PropTypes.shape({
      photo: PropTypes.string,
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    description: PropTypes.string,
    thread: PropTypes.shape({
      content: PropTypes.string,
      attachments: PropTypes.shape({}),
    }),
    createdTime: PropTypes.string,
    dueDate: PropTypes.string,
    closedTime: PropTypes.string,
  }),
  renderTicketDetail: PropTypes.func,
};

SupportTicketDetail.defaultProps = {
  ticketDetail: {},
  show: false,
  loading: false,
  error: false,
  renderTicketDetail: null,
  onHide: () => {},
};

export default SupportTicketDetail;
