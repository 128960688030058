import React from 'react';

import { Modal } from 'antd';

import { useModalSearchParams } from '../../helpers/customHooks';

import modalImg from '../../assets/needHelp.png';
import vectorHelp from '../../assets/vectorHelp.svg';

import Styles from './WelcomeModal.module.scss';
import './_overrides.scss';

/**
 * Component for show the welcome modal.
 */
const WelcomeModal = () => {
  const [{ show, handleHide }] = useModalSearchParams('show_welcome_modal');

  return (
    <Modal
      open={show}
      onCancel={handleHide}
      size="lg"
      footer={[]}
      classNames={{
        content: Styles.modal,
      }}
    >
      <div className={Styles.container}>
        <div className={Styles.head}>
          <img src={vectorHelp} className={Styles.svg} alt="" />
          <img src={vectorHelp} className={Styles.svg} alt="" />
          <div className={Styles.text}>
            <h2>
              Level up your
              {' '}
              <br />
              {' '}
              skills in
              {' '}
            </h2>
            <p>LMS, Control Center and STUDIO</p>
          </div>
          <div className={Styles.image}>
            <img src={modalImg} alt="" />
          </div>
        </div>
        <div className={Styles.body}>
          <h6>Welcome aboard! 🚀</h6>
          <p>
            Dive into our carefully curated playlist of videos on Control
            Center, LMS, and STUDIO. Let these videos guide you through
            essential concepts and optimize your experience.
          </p>
          <div className={Styles.button}>
            <a
              className={Styles.buttonWelcomeModal}
              href="https://www.youtube.com/playlist?list=PLAFMiTyh4JzQNGWMhfE62NUD38I3lK94x"
              target="_blank"
              rel="noopener noreferrer"
            >
              Start exploring now!
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
