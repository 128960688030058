import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { NotificationContext } from '../Provider/NotificationProvider';

import logo from '../../../assets/logo.png';
import Styles from '../Notifications.module.scss';

const FullScreenNotification = () => {
  const {
    hideNotification,
    activeNotifications,
    dismissNotification,
  } = NotificationContext();

  const { pathname } = useLocation();

  const fullScreenNotifications = activeNotifications
    .filter((item) => item.fullScreen === true);

  if (pathname.includes('notifications') || fullScreenNotifications.length === 0 || fullScreenNotifications[0].isActive === false) return null;

  useEffect(() => {
    if (!(pathname.includes('notifications') || fullScreenNotifications.length === 0 || fullScreenNotifications[0].isActive === false)) {
      document.body.style.overflow = 'hidden';
    }

    return () => { document.body.style.overflow = 'auto'; };
  }, [fullScreenNotifications]);

  return (
    <div className={Styles.notificationContainer}>
      <img
        src={logo}
        className={Styles.notificationControlImage}
        alt="Edunext Control Center"
      />
      <div className={Styles.notificationTextWrapper}>
        <button
          className={Styles.closeButton}
          type="button"
          aria-label="Close"
          onClick={() => hideNotification(fullScreenNotifications[0].id)}
        >
          <FontAwesomeIcon
            icon={faTimesCircle}
            className={Styles.closeIcon}
          />
        </button>
        <h1 className="text-white">{fullScreenNotifications[0].title}</h1>
        {
        fullScreenNotifications[0].textFormat === 'plain' ? (
          <p>{fullScreenNotifications[0].message}</p>
        ) : (
          <div>{parse(sanitizeHtml(fullScreenNotifications[0].message))}</div>
        )
      }
      </div>
      <div className="d-flex">
        <button
          className={Styles.buttonSecondary}
          type="button"
          onClick={() => dismissNotification(fullScreenNotifications[0].id)}
        >
          Dismiss
        </button>
      </div>
    </div>
  );
};

export default FullScreenNotification;
