import { consoleRequest } from './requests';

class ContactRequest {
  constructor() {
    this.base_url = '/api/contact/v1/accounts/';
  }

  get(responseHandler, errorHandler, accountExternalKey) {
    consoleRequest.get(`${this.base_url}${accountExternalKey}/contacts`)
      .then((res) => {
        responseHandler(res);
      }).catch((error) => {
        errorHandler(error);
      });
  }

  patch(responseHandler, errorHandler, accountExternalKey, contactId, data = {}) {
    const url = `${this.base_url}${accountExternalKey}/contacts/${contactId}/`;
    consoleRequest.patch(url, data)
      .then((res) => {
        responseHandler(res);
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  post(responseHandler, errorHandler, accountExternalKey, data = {}) {
    const url = `${this.base_url}${accountExternalKey}/contacts/`;
    consoleRequest.post(url, data)
      .then((res) => {
        responseHandler(res);
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  delete(responseHandler, errorHandler, accountExternalKey, contactId) {
    const url = `${this.base_url}${accountExternalKey}/contacts/${contactId}/`;
    consoleRequest.delete(url)
      .then((res) => {
        responseHandler(res);
      })
      .catch((error) => {
        errorHandler(error);
      });
  }
}

export default new ContactRequest();
