import React from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

/**
 * Renders a box with shadows inside of the onboarding accordion.
 *
 * @param {node} children - React node
 */
const OnboardingBox = ({ children }) => (
  <Col className="mx-2 my-2 bg-white rounded rounded-3 shadow p-4 d-flex align-items-center justify-content-center">{children}</Col>
);

OnboardingBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OnboardingBox;
