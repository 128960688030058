import { xmanRequest } from './requests';

class LearnerRequest {
  constructor() {
    this.edxappManagerApi = 'edxapp-manager';
    this.userdataApi = 'api/v1/userdata';
  }

  /**
   * @param {string} siteId
   * @param {object} email - learner email.
   * @param {object} controller - AbortController instance.
   * {@link https://developer.mozilla.org/en-US/docs/Web/API/AbortController}
   */
  forceActivation(siteId, email, controller) {
    return xmanRequest.post(
      `${this.edxappManagerApi}/${siteId}/${this.userdataApi}/force-activation/`,
      email,
      {
        signal: controller.signal,
      },
    );
  }

  /**
   * @param {string} siteId
   * @param {object} learnereData - Object with Email and password of the learner.
   * @param {object} controller - AbortController instance.
   * {@link https://developer.mozilla.org/en-US/docs/Web/API/AbortController}
   */
  forcePassword(siteId, learnereData, controller) {
    return xmanRequest.post(
      `${this.edxappManagerApi}/${siteId}/${this.userdataApi}/force-password/`,
      learnereData,
      {
        signal: controller.signal,
      },
    );
  }
}

export default new LearnerRequest();
