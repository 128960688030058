import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { AccountContext } from '../../views/accountsView/account/AccountContext';
import { UPDATE_TICKETS } from '../../views/accountsView/account/AccountReducer';
import ProductRequest from '../../helpers/requests/productRequests';

import SupportBanner from './supportBanner/SupportBanner';
import SupportLite from './supportLite/SupportLite';
import SupportDocs from './supportDocs/SupportDocs';
import SupportExplained from './supportExplained/SupportExplained';
import SupportNewTicket from './supportNewTicket/SupportNewTicket';

const controller = new AbortController();

/**
 * Component that renders much of the Support view.
 *
 * @param {string} type - Indicate type of content to render, i.e: LITE, OPENTICKET, etc.
 * @param {string} productId - Product external key from tab position (index).
 */
const SupportInfo = ({ type, productId }) => {
  const { accountId } = useParams();
  const [accountContext, accountDispatch] = AccountContext();
  const [loadingTickets, setLoadingTickets] = useState(false);

  const getTickets = async () => {
    accountDispatch({
      type: UPDATE_TICKETS,
      data: {
        [productId]: {
          data: [],
          loaded: true,
          error: null,
        },
      },
    });
    setLoadingTickets(true);
    try {
      const response = await ProductRequest.getTickets(productId, controller);
      accountDispatch({
        type: UPDATE_TICKETS,
        data: {
          [productId]: {
            data: response.data.data,
            loaded: true,
            error: null,
          },
        },
      });
    } catch (error) {
      accountDispatch({
        type: UPDATE_TICKETS,
        data: {
          [productId]: {
            data: [],
            loaded: true,
            error: {
              status: error?.response?.status || 500,
              message: error?.response?.data?.details || '',
            },
          },
        },
      });
    } finally {
      setLoadingTickets(false);
    }
  };

  useEffect(() => {
    if (type === 'EXPLAINED' && productId && !accountContext.tickets[productId]?.loaded) {
      getTickets();
    }

    return () => controller.abort();
  }, [productId]);

  switch (type) {
    case 'LITE':
      return <SupportLite productId={productId} />;

    case 'EXPLAINED':
      return (
        <SupportExplained
          productId={productId}
          getTickets={getTickets}
          loadingTickets={loadingTickets}
        />
      );

    case 'OPENTICKET':
      return (
        <SupportNewTicket
          productId={productId}
          onFinishSubmit={getTickets}
        />
      );

    case 'EXPLORE_KNOWLEDGE':
      return <SupportDocs accountId={accountId} />;

    case 'FOOTER':
      return <SupportBanner />;

    default:
      return <div />;
  }
};

SupportInfo.propTypes = {
  type: PropTypes.string.isRequired,
  productId: PropTypes.string,
};

SupportInfo.defaultProps = {
  productId: null,
};

export default SupportInfo;
