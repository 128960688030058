import {
  filterMappingFieldLookup,
  filterNameReplaces,
  fieldSortLookupsOptionsMapping,
} from './Filter.mapping';

/**
 * Indicates if the filter value comes by default with the lookup.
 * For example (lookup: is empty comes with the value 'True')
 * @param {object} filter - Filter object. For more information see PropTypes.
 * @return {boolean} True if value comes by default with the lookup, False otherwise.
 */
export const valueInLookup = (filter) => filter?.lookup?.split('=')[1] !== '';

/**
 * Returns the name of the correctly replaced.
 * You can indicate in filterNameReplaces with the format [a,b]
 * the name 'a' that you want to replace with 'b'.
 * @param {object} filterName - Filter name string joined. For more information see PropTypes.
 * @return {string} Filter name string joined and correctly replaced.
 */
export const clearReplaceFilterName = (filterName) => {
  let filterNameReplaced = filterName;

  if (filterName) {
    filterNameReplaces.forEach((filterNameReplace) => {
      filterNameReplaced = filterNameReplaced.replace(filterNameReplace[0], filterNameReplace[1]);
    });
  }

  return filterNameReplaced;
};

/**
 * Returns the name of the correctly formatted filter.
 * @param {object} filter - Filter object. For more information see PropTypes.
 * @return {array} Unbound formatted filter name.
 */
export const getFilterName = (filter) => {
  if (filter && filter.name) {
    return clearReplaceFilterName(filter.name.join(''))?.split();
  }

  return [];
};

/**
 * Returns the lookup of the correctly formatted filter.
 * @param {object} filter - Filter object. For more information see PropTypes.
 * @return {string} Formatted filter lookup.
 */
export const getLookup = (filter) => {
  if (filter?.valueType === 'date') {
    return '_before=';
  }

  return filter?.lookup;
};

/**
 * Returns the value of the correctly formatted filter.
 * @param {object} filter - Filter object. For more information see PropTypes.
 * @return {string} Formatted filter value.
 */
export const getValue = (filter) => {
  if (filter?.valueType === 'date' && filter?.value) {
    if (Array.isArray(filter?.value)) {
      return `${filter?.value[1]}&${getFilterName(filter).join('')}_after=${filter?.value[0]}`;
    }

    return `${filter?.value}&${getFilterName(filter).join('')}_after=${filter?.value}`;
  }

  return !valueInLookup(filter) ? filter?.value : '';
};

/**
 * Returns the joined filter (name + lookup + value) as a string for queryparams.
 * Example:
 *  'account__vertical=foo'
 * @param {object} filter - Filter object. For more information see PropTypes.
 * @return {string} Joined, Full, and Formatted Filter.
 */
export const filterJoin = (filter) => getFilterName(filter).concat([
  getLookup(filter),
  getValue(filter),
]).join('');

/**
 * Returns the object with all joined filter (name + lookup + value).
 * Example:
 * {
 *  account__vertial: 'foo'
 *  type: 'NSS',
 * }
 * @param {array} filters - Array list of Filter object. For more information see PropTypes.
 * @return {object} Joined, Full, and Formatted Filters.
 */
export const getFiltersToParams = (filters) => filters.map((filter) => (
  filterJoin(filter).split('&').reduce(
    (acc, filterJoined) => ({
      ...acc,
      ...{ [filterJoined.split('=')[0]]: filterJoined.split('=')[1] },
    }),
    {},
  )
)).reduce(
  (acc, filter) => ({ ...acc, ...filter }),
  {},
);

/**
 * Returns the filter sort lookup by filter sort name selected.
 * @param {string} filterSortNameSelected - Filter sort name string value selected.
 * @return {array} Filter lookups options array list to Select component [{ value, label }].
 */
export const getSortLookupOptions = (filterSortNameSelected) => fieldSortLookupsOptionsMapping[filterMappingFieldLookup[filterSortNameSelected] || 'text'];

/**
 * Returns the filter sort params to query params request.
 * @param {array} filterSorts - Filter sort array list.
 * @return {string} Filter sort string query params to request.
 */
export const getFilterSortToParams = (filterSorts) => filterSorts.map((filterSort) => {
  const sortName = clearReplaceFilterName(filterSort?.name);
  const sortValue = filterSort?.value || ''; // ASC

  return `${sortValue}${sortName}`;
}).join(', ');
