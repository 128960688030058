import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import CustomButton from '../../customButton/CustomButton';
import { NotificationContext } from '../Provider/NotificationProvider';

import Styles from './Notification.module.scss';
import ModalConfirmation from '../../modalConfirmation/ModalConfirmation';

const Notification = ({
  id,
  title,
  validTo,
  message,
  validFrom,
  textFormat,
  fullScreen,
  createdDate,
}) => {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const {
    setNotification,
    setHtmlMessage,
    deleteNotification,
    notificationDetail,
    saveNotificationStatus,
    setNotificationDetails,
    HTML_SANITIZE_CONFIG,
    hasNotificationPermissions,
    LOCAL_STORAGE_NOTIFICATION_KEY,
  } = NotificationContext();

  const localStorageIds = JSON
    .parse(localStorage.getItem(LOCAL_STORAGE_NOTIFICATION_KEY)) || {};

  const toggleModalVisibility = () => setNotificationDetails({ isOpen: false, data: {} });

  return (
    <>
      <ModalConfirmation
        showModal={showWarningModal}
        toggleModalVisibility={() => setShowWarningModal(false)}
        confirmButtonCustomText="Delete"
        cancelAction={() => setShowWarningModal(false)}
        confirmationAction={() => deleteNotification(id)}
        modalTitle={
          () => (
            <p>
              Are sure to delete this notification?
            </p>
          )
        }
        modalBody={
          () => (
            <p className="mb-0">
              This can not be undone.
            </p>
          )
        }
      />

      <Modal
        show={notificationDetail.isOpen}
        onHide={toggleModalVisibility}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title id="contained-modal-title-vcenter">
            {notificationDetail?.data?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={Styles.notificationMessageWrapper}>
          {parse(sanitizeHtml(notificationDetail?.data?.message, HTML_SANITIZE_CONFIG))}
        </Modal.Body>
        <Modal.Footer>
          <button
            className={Styles.buttonPrimary}
            type="button"
            onClick={toggleModalVisibility}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <div className="position-relative">
        <button
          type="button"
          className={Styles.wrapper}
          onClick={() => {
            saveNotificationStatus(id);
            setNotificationDetails({
              isOpen: true,
              data: {
                title,
                message,
                createdDate,
              },
            });
          }}
        >
          <h5 className={Styles.title} title={title}>{title}</h5>
          {
            textFormat === 'html'
              ? <p className={Styles.detailsLink}>More details</p>
              : <p className={Styles.message} title={title}>{message}</p>
            }
          <p>{moment(createdDate).format('MMMM, DD - h:mm a')}</p>
          {
            (localStorageIds !== null && typeof localStorageIds === 'object' && id in localStorageIds && !localStorageIds[id])
            && <FontAwesomeIcon icon={faCheckCircle} className={Styles.check} />
          }
        </button>
        {
          hasNotificationPermissions
          && (
            <div className={Styles.buttonsActionsWrapper}>
              <CustomButton
                variant="tableEditItem"
                onClick={() => {
                  setNotification({
                    isOpen: true,
                    status: 'editing',
                    data: {
                      id,
                      title,
                      message,
                      validTo,
                      validFrom,
                      textFormat,
                      fullScreenMode: fullScreen,
                      createdDate,
                    },
                  });

                  setHtmlMessage(message);
                }}
              />
              <CustomButton
                variant="tableDeleteItem"
                onClick={() => setShowWarningModal(true)}
              />
            </div>
          )
        }
      </div>
    </>
  );
};

Notification.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  textFormat: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
  validFrom: PropTypes.string.isRequired,
  validTo: PropTypes.string.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};

export default Notification;
