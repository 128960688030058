import React from 'react';
import PropTypes from 'prop-types';
import LabeledTextStyle from './LabeledText.module.scss';

/**
 * Component for showing a text and a label grouped together.
 *
 * @component
 * @example
 * return (
 *   <LabeledText title="Label" content="Text" />
 * )
 */

function LabeledText({
  title,
  content,
  children,
  className,
  classContent,
  classWrapper,
}) {
  let contentElement = null;

  if (content === null) {
    contentElement = children;
  } else {
    contentElement = (
      <div
        className={
          classContent != null ? classContent : LabeledTextStyle.content
        }
      >
        {content.toString()}
      </div>
    );
  }

  return (
    <div className={
      classWrapper != null ? (
        `${classWrapper} ${LabeledTextStyle.wrapper}`
      ) : LabeledTextStyle.wrapper
    }
    >
      <div
        className={className != null ? className : LabeledTextStyle.title}
      >
        {title}
      </div>
      {contentElement}
    </div>
  );
}

LabeledText.propTypes = {
  /**
   * Component title.
   */
  title: PropTypes.string.isRequired,
  /**
   * Component content.
   */
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  /**
   * Alternative content.
   */
  children: PropTypes.node,
  /**
   * Class name additional
   */
  className: PropTypes.string,
  /**
   * Class name additional
   */
  classContent: PropTypes.string,
  /**
   * Class name additional
   */
  classWrapper: PropTypes.string,
};

LabeledText.defaultProps = {
  children: null,
  content: null,
  className: null,
  classContent: null,
  classWrapper: null,
};

export default LabeledText;
