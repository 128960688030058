import React from 'react';
import PropTypes from 'prop-types';
import {
  faPen,
  faPlus,
  faTrash,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CustomButtonStyles from '../../Common.module.scss';

/**
 * Component that returns a button with a specific style and icon.
 *
 *  @param children - Text of the button.
 *  @param variant - (Required) Style of the button.
 *  @param props - Events or whatever necessary.
 *  - tableEditItem
 *  - tableDeleteItem
 *  - addNewItem
 *  - deleteItem
 *  - upgradeSubscription
 *  - normalButton
 *  - normalOutLine
 *  - confirmAction
 *  - cancelAction
 *  - cancelSubscription
 *  - normalButtonAccordion
 *  - buttonFilter
 *  - buttonForm
 *  - buttonBlockBottomNextStep
 *  - buttonBlockBottomPreviusStep
 *  - defaultButton
 *
 * @returns A button with a span and a font awesome icon.
 */
const CustomButton = ({ variant, children, ...props }) => {
  const BUTTONS_PROPERTIES = {
    tableEditItem: {
      style: CustomButtonStyles.buttonEditTableItem,
      haveIcon: true,
      icon: faPen,
    },
    tableDeleteItem: {
      style: CustomButtonStyles.buttonDeleteTableItem,
      haveIcon: true,
      icon: faTrash,
    },
    addNewItem: {
      style: CustomButtonStyles.buttonAddItem,
      haveIcon: true,
      icon: faPlus,
    },
    deleteItem: {
      style: CustomButtonStyles.buttonDeleteItem,
      haveIcon: true,
      icon: faTrash,
    },
    upgradeSubscription: {
      style: CustomButtonStyles.buttonUpgrade,
      haveIcon: true,
      icon: faChevronRight,
    },
    normalButton: {
      style: CustomButtonStyles.buttonPrimary,
      haveIcon: true,
      icon: faChevronRight,
    },
    normalOutLine: {
      style: CustomButtonStyles.buttonOutLine,
      haveIcon: false,
      icon: undefined,
    },
    normalButtonAccordion: {
      style: CustomButtonStyles.normalButtonAccordion,
      haveIcon: true,
      icon: faChevronUp,
    },
    confirmAction: {
      style: CustomButtonStyles.buttonAddItem,
      haveIcon: false,
      icon: undefined,
    },
    cancelAction: {
      style: CustomButtonStyles.buttonCancelAction,
      haveIcon: false,
      icon: undefined,
    },
    cancelSubscription: {
      style: CustomButtonStyles.buttonDeleteItem,
      haveIcon: true,
      icon: faChevronRight,
    },
    buttonFilter: {
      style: CustomButtonStyles.buttonWhite,
      haveIcon: false,
      icon: undefined,
    },
    buttonDelete: {
      style: CustomButtonStyles.buttonWhiteDelete,
      haveIcon: false,
      icon: undefined,
    },
    buttonForm: {
      style: CustomButtonStyles.buttonForm,
      haveIcon: false,
      icon: undefined,
    },
    buttonBlockBottomNextStep: {
      style: CustomButtonStyles.buttonBlockBottomNextStep,
      haveIcon: true,
      icon: faChevronRight,
    },
    buttonBlockBottomPreviusStep: {
      style: CustomButtonStyles.buttonBlockBottomPreviusStep,
      haveIconLeft: true,
      icon: faChevronLeft,
    },
    defaultButton: {
      style: CustomButtonStyles.buttonGreen,
      haveIcon: false,
      icon: undefined,
    },
  };

  return (
    <button
      type="button"
      className={
        BUTTONS_PROPERTIES[variant]?.style || CustomButtonStyles.defaultButton
      }
      {...props}
    >
      {BUTTONS_PROPERTIES[variant].haveIconLeft && (
        <FontAwesomeIcon icon={BUTTONS_PROPERTIES[variant].icon} />
      )}
      {children !== null && (
        <span className={CustomButtonStyles.spanText}>{children}</span>
      )}
      {BUTTONS_PROPERTIES[variant].haveIcon && (
        <FontAwesomeIcon icon={BUTTONS_PROPERTIES[variant].icon} />
      )}
    </button>
  );
};

CustomButton.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf([
    'tableEditItem',
    'tableDeleteItem',
    'addNewItem',
    'deleteItem',
    'upgradeSubscription',
    'normalButton',
    'normalOutLine',
    'cancelSubscription',
    'defaultButton',
    'cancelAction',
    'confirmAction',
    'normalButtonAccordion',
    'buttonFilter',
    'buttonForm',
    'buttonDelete',
    'buttonBlockBottomNextStep',
    'buttonBlockBottomPreviusStep',
  ]),
};

CustomButton.defaultProps = {
  children: null,
  variant: 'defaultButton',
};

export default CustomButton;
