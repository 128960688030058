import React from 'react';
import { Popover } from 'antd';
import PropTypes from 'prop-types';
import { InfoCircleFilled } from '@ant-design/icons';

import Styles from './PopoverWrapper.module.scss';

/**
 * @param {string} title - Popover title.
 * @param {node} children - Custom content for popover body.
 * @param {string} placements - Place where the information will be render default "left".
 * @param {string} iconClassName - Custom css class.
 * @param {any} popoverProps - Extra popover props, visit:
 * {@link https://ant.design/components/tooltip#api} for more info.
 */
const PopoverWrapper = ({
  title,
  children,
  placement,
  iconClassName,
  popoverClassName,
  ...wrapperProps
}) => (
  <Popover
    title={title}
    trigger="hover"
    placement={placement}
    content={
      () => (
        <div className={`${Styles.content} ${popoverClassName}`}>
          {children}
        </div>
      )
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...wrapperProps}
  >
    <button
      type="button"
      className={Styles.popoverButton}
      aria-label="Info icon"
    >
      <InfoCircleFilled className={`${Styles.popoverIcon} ${iconClassName}`} />
    </button>
  </Popover>
);

PopoverWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  placement: PropTypes.oneOf(['right', 'left']),
  children: PropTypes.node.isRequired,
  iconClassName: PropTypes.string,
  popoverClassName: PropTypes.string,
};

PopoverWrapper.defaultProps = {
  placement: 'left',
  iconClassName: '',
  popoverClassName: '',
};

export default PopoverWrapper;
