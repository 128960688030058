import React from 'react';
import PropTypes from 'prop-types';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './RefreshPage.module.scss';

/**
 * This will show a spinner loading with a custom message in case to be required.
 *
 * @param {string} message - Indicate to message loading.
 * @param {string} height - Indicate height style.
 * @param {function} onClick - Indicate function to clicked.
 *
 * @returns A component that displays a refresh icon and a message.
 */
const RefreshPage = ({ onClick, message, height }) => (
  <div className={styles.container} style={{ height }}>
    <div className="text-center">
      <FontAwesomeIcon
        aria-label="refreshPage"
        className={styles.icon}
        icon={faRedo}
        size="lg"
        onClick={onClick}
      />
      <div className="pt-2">{message}</div>
    </div>
  </div>
);

RefreshPage.propTypes = {
  message: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

RefreshPage.defaultProps = {
  message: 'Oops! Please try reload',
  height: '100vh',
};

export default RefreshPage;
