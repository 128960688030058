import React from 'react';
import PropTypes from 'prop-types';

import BannerSiteInfo from '../BannerSiteInfo';

import BannerImgDenied from '../../../assets/bannerImgDenied.png';

import Styles from '../../../Common.module.scss';

/**
 * It renders a banner site information to show messages denied.
 *
 * @param {node} children - React JSX node element.
 * @param {node} src - Assets source.
 */
const BannergeDenied = ({ tier }) => (
  <BannerSiteInfo src={BannerImgDenied}>
    <b className={Styles.bannerUpgradeTxtSecondary}>
      This feature is not available for
      {' '}
      <span
        className={Styles.bannerUpgradeTxtPrimary}
        style={{ fontSize: 'inherit' }}
      >
        {tier}
        {' '}
      </span>
      subscriptions.
    </b>
  </BannerSiteInfo>
);

BannergeDenied.propTypes = {
  tier: PropTypes.string.isRequired,
};

export default BannergeDenied;
