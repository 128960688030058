import React from 'react';
import PropTypes from 'prop-types';

import BannerImgUpgrade from '../../assets/bannerImgUpgrade.png';
import BannerContainer from '../../assets/bannerContainer.png';

import styles from './BannerSiteInfo.module.scss';

/**
 * It renders a banner site information to show messages.
 *
 * @param {node} children - React JSX node element.
 * @param {node} src - Assets source.
 */
const BannerSiteInfo = ({ children, src }) => (
  <div
    className={styles.container}
    style={{ backgroundImage: `url(${BannerContainer})` }}
  >
    <div className={styles.imgContainer}>
      <img src={src} alt="upgrade" />
    </div>
    <div className={styles.txtContainer}>{children}</div>
  </div>
);

BannerSiteInfo.propTypes = {
  src: PropTypes.node,
  children: PropTypes.node.isRequired,
};

BannerSiteInfo.defaultProps = {
  src: BannerImgUpgrade,
};

export default BannerSiteInfo;
