import React from 'react';
import PropTypes from 'prop-types';

import styles from './ErrorPage.module.scss';

/**
 * It returns a div with a class of main and the children prop passed to it, or a default message if
 * the children prop is null.
 *
 * @returns Default message or custom error message.
*/
const ErrorPage = ({ children }) => {
  const DEFAULT_MESSAGE = (
    <>
      <p>
        Your current user does not have access to the
        requested resource or the resource does not exist.
      </p>
      <p>From this point you can:</p>
      <ol type="a">
        <li>Visit your dashboard to find the information you do have access to</li>
        <li>Sign in with a different user</li>
      </ol>
    </>
  );
  return (
    <div className={styles.main}>
      { children === null ? DEFAULT_MESSAGE : children}
    </div>
  );
};

ErrorPage.propTypes = {
  children: PropTypes.node,
};

ErrorPage.defaultProps = {
  children: null,
};

export default ErrorPage;
