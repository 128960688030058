import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';

import SiderWrapper from './siderWrapper/SiderWrapper';
import HeaderWrapper from '../HeaderWrapper/HeaderWrapper';

const { Content } = Layout;

/**
 * Component for showing layout application.
 *
 * @param {node} children - Content to render in main section.
 */
const LayoutWrapper = ({ children }) => {
  const [sidebarCollapse, setSidebarCollapse] = useState(false);
  const location = useLocation();
  const isRootLocation = location.pathname === '/accounts' || location.pathname === '/accounts/';

  return (
    <Layout>
      <Layout style={{ minHeight: '100vh' }}>
        {!isRootLocation && (
          <SiderWrapper
            collapse={sidebarCollapse}
            setCollapse={setSidebarCollapse}
          />
        )}
        <Content>
          <HeaderWrapper
            isSidebarCollapse={sidebarCollapse}
            setSidebarCollapse={setSidebarCollapse}
          />
          <div>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

LayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(LayoutWrapper);
