import React, { useEffect } from 'react';
import {
  Route, Switch, useRouteMatch, useLocation,
} from 'react-router-dom';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import ReactGA from 'react-ga4';

import AccountsView from './AccountsView';
import AccountRouter from './account/AccountRouter';
import { AccountProvider } from './account/AccountContext';
import LayoutWrapper from '../../components/layoutWrapper/LayoutWrapper';
import WelcomeModal from '../../components/welcomeModal/WelcomeModal';
import ToastNotification from '../../components/Notifications/Toast/ToastNotification';
import FullScreenNotification from '../../components/Notifications/FullScreen/FullScreenNotification';
import { useDocTitle } from '../../helpers/customHooks';

const AccountsViewRouter = () => {
  const { path } = useRouteMatch();
  const [, setDocTitle] = useDocTitle('Accounts');
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/accounts/docs/') {
      setDocTitle('Docs');
    }

    return function cleanup() {
      setDocTitle('Accounts');
    };
  }, [location]);

  useEffect(() => {
    const token = Cookies.get(process.env.JWT_HEADER_PAYLOAD_COOKIE_NAME);
    const payload = jwtDecode(token);
    ReactGA.initialize([
      {
        trackingId: process.env.UA_TRACKING_ID,
        gaOptions: {
          userId: payload.key,
        },
        titleCase: false,
      },
    ]);
  }, []);

  return (
    <AccountProvider>
      <LayoutWrapper>
        <Switch>
          <Route exact path={path}>
            <AccountsView />
          </Route>
          <Route path={`${path}/:accountId`}>
            <AccountRouter />
          </Route>
        </Switch>
      </LayoutWrapper>

      <WelcomeModal />
      <ToastNotification />
      <FullScreenNotification />
    </AccountProvider>
  );
};

export default AccountsViewRouter;
