/**
 *  MESSAGES CONSTANTS CONTROL CENTER
 *  In this file the messages constants of the project are configured.
 */
import React from 'react';
import { Link } from 'react-router-dom';

import BannerImgRedirect from '../assets/bannerImgRedirect.png';
import BannerImgSupport from '../assets/bannerImgSupport.png';

import BannerSiteInfo from '../components/bannerSiteInfo/BannerSiteInfo';
import UpgradeSubscriptionButton from '../components/upgradeSubscriptionButton/UpgradeSubscriptionButton';

import Styles from '../Common.module.scss';

const GoToSiteConfigurationSection = (href, text) => (
  <BannerSiteInfo src={BannerImgRedirect}>
    <p className={Styles.bannerRedirectTxtPrimary}>{text}</p>
    <Link
      to={href}
      className={Styles.linkNormalButton}
      onClick={() => window.scrollTo(0, 0)}
    >
      <span>Go to configurations</span>
    </Link>
  </BannerSiteInfo>
);

export const MESSAGE_EMPOWERED = (
  <p>
    Your Open edX platform needs to be empowered with some edunext extensions
    before you can make use of this feature.
  </p>
);

export const MESSAGE_CONTACT_EDUNEXT = (
  <p>Contact edunext support for more information.</p>
);

export const MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT = (
  <BannerSiteInfo src={BannerImgSupport}>
    <p className={Styles.bannerUpgradeTxtPrimary}>{MESSAGE_EMPOWERED}</p>
    <p className={Styles.bannerUpgradeTxtSecondary}>
      {MESSAGE_CONTACT_EDUNEXT}
    </p>
  </BannerSiteInfo>
);

const FEATURE_NOT_AVAILABLE = (
  <BannerSiteInfo>
    <p className={Styles.bannerUpgradeTxtPrimary}>
      Your subscription does not currently have this option available!
    </p>
    <p className={Styles.bannerUpgradeTxtSecondary}>
      Upgrade your subscription and enjoy this functionality
    </p>
    <UpgradeSubscriptionButton>Upgrade</UpgradeSubscriptionButton>
  </BannerSiteInfo>
);

const FEATURE_NOT_AVAILABLE_IN_FREE = FEATURE_NOT_AVAILABLE;

const FEATURE_NOT_AVAILABLE_IN_BASIC = FEATURE_NOT_AVAILABLE;

const FEATURE_NOT_AVAILABLE_IN_ENHANCED = FEATURE_NOT_AVAILABLE;

const FEATURE_NOT_AVAILABLE_IN_ADVANCED = FEATURE_NOT_AVAILABLE;

const FEATURE_NOT_AVIABLE_IN_STRATUS = (
  <BannerSiteInfo src={BannerImgSupport}>
    <p className={Styles.bannerUpgradeTxtPrimary}>
      This functionality is not available in your STRATUS subscription.
    </p>
    <p className={Styles.bannerUpgradeTxtSecondary}>
      {MESSAGE_CONTACT_EDUNEXT}
    </p>
  </BannerSiteInfo>
);

const FEATURE_NOT_AVAILABLE_IN_CIRRUS = (
  <BannerSiteInfo src={BannerImgSupport}>
    <p className={Styles.bannerUpgradeTxtPrimary}>
      This feature is not yet available for Cirrus Installations.
    </p>
    <p className={Styles.bannerUpgradeTxtSecondary}>
      {MESSAGE_CONTACT_EDUNEXT}
    </p>
  </BannerSiteInfo>
);

const FEATURE_NOT_AVAILABLE_IN_ON_PREMISE = (
  <BannerSiteInfo src={BannerImgSupport}>
    <p className={Styles.bannerUpgradeTxtPrimary}>
      This feature is not yet available for On Premise or Dedicated SaaS
      subscriptions.
    </p>
    <p className={Styles.bannerUpgradeTxtSecondary}>
      {MESSAGE_CONTACT_EDUNEXT}
    </p>
  </BannerSiteInfo>
);

export const NOT_AVAILABLE = {
  default: FEATURE_NOT_AVAILABLE,
  goTo: GoToSiteConfigurationSection,
  nimbus_free: FEATURE_NOT_AVAILABLE_IN_FREE,
  nimbus_basic: FEATURE_NOT_AVAILABLE_IN_BASIC,
  nimbus_enhanced: FEATURE_NOT_AVAILABLE_IN_ENHANCED,
  nimbus_advanced: FEATURE_NOT_AVAILABLE_IN_ADVANCED,
  stratus: FEATURE_NOT_AVIABLE_IN_STRATUS,
  cirrus: FEATURE_NOT_AVAILABLE_IN_CIRRUS,
  on_premise: FEATURE_NOT_AVAILABLE_IN_ON_PREMISE,
};
