import React from 'react';
import { Divider } from 'antd';

import CardItem from '../Cards/Card/CardItem';
import ListItem from '../Cards/List/ListItem';
import { AttributeContext } from '../Context/AttributeContext';

import noAttributeImg from '../../../assets/noAttributes.png';

import Styles from './AttributeItems.module.scss';

const AttributeItems = () => {
  let items = [];

  const [{
    attributeItems, attributeState, cardStyle, attributeName,
  }] = AttributeContext();

  const showCardStyle = attributeState.cardStyle === cardStyle.card;
  const showListStyle = attributeState.cardStyle === cardStyle.list;

  if (attributeState.request.inProgress) {
    (items = [1, 2, 3, 4].map((i) => (
      <div key={i} className={Styles.attributeItemsCardLoading} />
    )));

    return (
      <>
        <Divider />
        <div
          className={`${Styles.attributeItemsWrapper} ${Styles.attributeItemsCard}`}
        >
          {items}
        </div>
      </>
    );
  }

  if (attributeItems.length < 1) {
    return (
      <section className={Styles.noItemsWrapper}>
        <img src={noAttributeImg} alt="No attributes" />
        <h5>
          So far you do not have any
          {' '}
          {attributeName.toLowerCase().replace(/your/i, '')}
          .
        </h5>
      </section>
    );
  }

  if (showCardStyle) {
    items = attributeItems.map((attribute) => (
      <CardItem
        key={`${attribute.created_date}-${attribute.name}-card-item`}
        attribute={attribute}
      />
    ));
  }

  if (showListStyle) {
    items = attributeItems.map((attribute) => (
      <ListItem
        key={`${attribute.created_date}-${attribute.name}-list-item`}
        attribute={attribute}
      />
    ));
  }

  return (
    <div>
      <Divider />
      <div
        className={`${Styles.attributeItemsWrapper} ${
          showCardStyle && Styles.attributeItemsCard
        }`}
      >
        {items.reverse()}
      </div>
    </div>
  );
};

export default AttributeItems;
