/**
 * Determines if value is an object.
 * @param {node} value - The value to determinate if is an object.
 * @return {boolean} True if is an object, False otherwise.
 */
const isObject = (value) => (
  Object.prototype.toString.call(value) === '[object Object]'
);

export default isObject;
