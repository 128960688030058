import { useParams } from 'react-router-dom';

import { AccountsViewContext } from '../../../views/accountsView/AccountsViewContext';
import { AccountContext } from '../../../views/accountsView/account/AccountContext';

/**
 * Calculates the base or root URL starting from account to the site in case
 * it detects the appropriate params in the URL. With the same calculation,
 * generate the title for the page expected in the browser.
 *
 * @returns Array with Base URL and Title for Page according view section.
 */
const useBaseUrl = () => {
  const {
    accountId,
    productId,
    platformId,
    siteId,
  } = useParams();
  const [viewContext] = AccountsViewContext();
  const [accountContext] = AccountContext();

  const { name: accountName } = viewContext.accounts[accountId] || {};
  const { name: productName } = accountContext.products[productId] || {};
  const { name: instanceName } = accountContext.instances?.find((instanceIterator) => (
    instanceIterator.id === parseInt(platformId, 10)
  )) || {};
  const { name: siteName } = accountContext.tenants?.find(
    (tenant) => tenant.external_key === siteId,
  ) || {};

  const accountBaseUrl = `accounts/${accountId}`;
  const accountTitle = accountName;
  const productBaseUrl = `${accountBaseUrl}/products/${productId}`;
  const productTitle = `${productName} | ${accountTitle}`;
  const platformBaseUrl = `${productBaseUrl}/platform/${platformId}`;
  const platformTitle = `${instanceName} | ${productTitle}`;
  const siteBaseUrl = `${platformBaseUrl}/sites/${siteId}`;
  const siteTitle = `${siteName} | ${platformTitle}`;

  let baseUrl = accountBaseUrl;
  let titlePage = accountName;

  if (siteId) {
    baseUrl = siteBaseUrl;
    titlePage = siteTitle;
  } else if (platformId) {
    baseUrl = platformBaseUrl;
    titlePage = platformTitle;
  } else if (productId) {
    baseUrl = productBaseUrl;
    titlePage = productTitle;
  }

  return { baseUrl, titlePage };
};

export default useBaseUrl;
