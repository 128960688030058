import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';

import {
  Form,
  Spin,
  Radio,
  Input,
  Alert,
  Space,
  Select,
  Upload,
  Result,
  Button,
  Checkbox,
} from 'antd';

import {
  InboxOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import { useUrlSearchParams } from '../../helpers/customHooks';
import ExternalRequest from '../../helpers/requests/externalRequests';
import { AccountsViewContext } from '../../views/accountsView/AccountsViewContext';

import CustomButton from '../customButton/CustomButton';

import Styles from './ZohoForm.module.scss';

/**
 * Renders from for open a Zoho ticket.
 *
 * @param {object} user - Object with user data as full name and email.
 * @param {string} customProductId - Product External Key to create ticket.
 * @param {func} onFinishSubmit - Function to callback after submitted.
 * @param {array} extraFields - List of object to render extra fields in modal zoho.
 * @param {bool} showDescription - Indicate if field description in the screen.
 * @param {string} subject - Default subject for ticket.
 * @param {bool} disableSubject - Indicate if field subject is disabled.
 * @param {bool} showSubject - Indicate if field subject is in the screen.
 * @param {string} defaultType - Default type in select element.
 * @param {bool} disableType - Indicate if field type is disabled.
 * @param {bool} showOptions - Indicate if field type is in the screen.
 * @param {bool} showEmail - Indicate if field email is in the screen.
 * @param {bool} showUserName - Indicate if field name is in the screen.
 * @param {bool} showAttachments - Indicate if field attachments is in the screen.
 * @param {string} attachmentsDescription - Allows to add extra text into the attatchments section.
 * @param {string} submitButtonText - Text for submit button.
 */
const ZohoForm = ({
  user,
  subject,
  showEmail,
  extraFields,
  disableType,
  showOptions,
  defaultType,
  showSubject,
  showUserName,
  disableSubject,
  onFinishSubmit,
  customProductId,
  showDescription,
  showAttachments,
  submitButtonText,
  attachmentsDescription,
}) => {
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const [selectValues, setSelectValue] = useState({});
  const [fileList, setFileList] = useState([]);
  const [fileSizeValidator, setFileSizeValidator] = useState({
    size: 0,
    error: false,
  });

  const { accountId, productId } = useParams();

  const [urlSearchParams] = useUrlSearchParams();
  const [accountsViewContext] = AccountsViewContext();

  const { name, email } = accountsViewContext.user;

  const userData = {
    name: user?.name || name || 'No name provied',
    email: user?.email || email || 'No email provided',
  };

  let subscriptionId = customProductId || productId;

  if (!subscriptionId && urlSearchParams && urlSearchParams.get('productId')) {
    subscriptionId = urlSearchParams.get('productId');
  }

  const initialRequestInfo = {
    status: 'waiting',
    isLoading: false,
    error: null,
  };

  const [requestInfo, setRequestInfo] = useState(initialRequestInfo);

  const maxFileSize = 1000000; // 1mb

  const ticketTypeOptions = [
    {
      label: 'Incident',
      value: 'Incident',
    },
    {
      label: 'Request',
      value: 'Request',
    },
    {
      label: 'Enquiry',
      value: 'Enquiry',
    },
    {
      label: 'Suggestions',
      value: 'Suggestions',
    },
    {
      label: 'Customer Success',
      value: 'Customer Success',
    },
  ];

  const resetUpload = () => setFileSizeValidator({ ...fileSizeValidator, error: false });

  const resetForm = () => {
    form.resetFields();
    form.setFieldsValue({ ...userData });
    setRequestInfo(initialRequestInfo);
    setFileList([]);
    resetUpload();
  };

  const handleUploadFiles = (inputs) => {
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append('attachments', file, file.name);
    });

    Object.entries(inputs).forEach(([key, value]) => {
      if (key !== 'attachments' && value) {
        formData.append(key, value);
      }
    });

    return formData;
  };

  const handleDropFiles = (file) => {
    setFileSizeValidator((prevState) => {
      if (prevState.size + file.size >= maxFileSize) {
        return {
          error: true,
          size: prevState.size,
        };
      }

      setFileList((prevFiles) => [file, ...prevFiles]);

      return {
        error: false,
        size: prevState.size + file.size,
      };
    });

    /**
     * Required for mount file
     * @see {@link https://ant.design/components/upload#components-upload-demo-upload-manually}
     */
    return false;
  };

  const handleRemoveFile = (file) => {
    setFileSizeValidator((prevState) => (
      {
        error: false,
        size: prevState.size - file.size,
      }
    ));

    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();

    newFileList.splice(index, 1);

    setFileList(newFileList);
  };

  const isFieldMeetDependencies = (field) => {
    if (field.depends) {
      const [selectKey, selectValue] = Object.values(selectValues);

      if (selectKey === undefined || selectValue === undefined) return false;

      const fieldDependsEntries = [field.depends[selectKey]];

      const ruleValidate = () => (selectValue === field.depends[selectKey]);

      if (field.depends_disjunction) {
        return fieldDependsEntries.some(ruleValidate);
      }

      return fieldDependsEntries.every(ruleValidate);
    }

    return true;
  };

  const formatDescription = () => {
    let description = form.getFieldValue('description') || '';

    if (extraFields.length > 0) {
      extraFields.forEach((field) => {
        if (isFieldMeetDependencies(field)) {
          description += `<br /><div><b>${field.label}</b>: ${form.getFieldValue(field.id)}</div>`;
        }
      });
    }

    return description;
  };

  const onSubmitHanlder = async (formData) => {
    try {
      setRequestInfo({
        ...initialRequestInfo,
        status: 'inProgress',
      });

      let description = formatDescription();
      description = description || (subject || formData?.subject);
      description += `<br /><br /><div>From Control Center: <b>${pathname}</b></div>`;

      const data = handleUploadFiles({
        ...formData,
        description,
        product_external_key: subscriptionId,
        subject: subject || formData?.subject,
        cf_category_test: formData?.cf_category_test || defaultType,
      });

      await ExternalRequest.createTicket(data);

      setRequestInfo({
        ...initialRequestInfo,
        status: 'complete',
      });

      if (onFinishSubmit) {
        onFinishSubmit();
      }
    } catch (error) {
      setRequestInfo({
        ...initialRequestInfo,
        status: 'fail',
        error: {
          details: {
            ...error.response,
          },
        },
      });
    }
  };

  const renderFieldByType = (field) => {
    if (field.type === 'select') {
      return (
        <Select
          options={field.options}
          onChange={(value) => setSelectValue({ key: field.name, value })}
        />
      );
    }

    if (field.type === 'checkbox') {
      return (
        <Checkbox.Group
          className={Styles.optionsWrapper}
          options={field.options}
        />
      );
    }

    if (field.type === 'radio') {
      return (
        <Radio.Group className={Styles.optionsWrapper} options={field.options} />
      );
    }

    if (field.type === 'textarea') {
      return (
        <Input.TextArea
          rows={4}
          disabled={field.isDisabled}
        />
      );
    }

    return <Input disabled={field.isDisabled} />;
  };

  const renderField = (field) => {
    const extraValidations = field.validation ? [{ ...field.validation }] : [];

    return (
      <Form.Item
        key={field.id}
        name={field.name}
        label={field.label}
        help={
          (field.helpType === 'code' ? (
            <Alert
              type="info"
              style={{ margin: '10px 0' }}
              message={field.helpTitle}
              description={field.textHelp}
            />
          ) : field.textHelp || '')
        }
        hasFeedback
        className={Styles.margin}
        disabled={field.isDisabled}
        initialValue={field?.value}
        rules={[{ required: field.isRequired, message: 'This field is required' }, ...extraValidations]}
      >
        { renderFieldByType(field) }
      </Form.Item>
    );
  };

  const Loading = () => (
    <Result
      icon={<Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />}
      title="Creating ticket..."
      extra={
        (
          <>
            {
              1 > 0 && (
              <div className={Styles.textCenter}>
                This may take longer than usual due to the load of attachments
              </div>
              )
            }
          </>
        )
    }
    />
  );

  const Success = () => (
    <Result
      status="success"
      title="Your ticket has been sent!"
      subTitle={
        (
          <p>
            You can check its progress
            {' '}
            <Link
              key="go-to-tickets"
              to={`/accounts/${accountId}/support/`}
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              Here
            </Link>
          </p>
        )
      }
      extra={[
        <CustomButton type="button" onClick={resetForm}>
          Open new ticket
        </CustomButton>,
      ]}
    />
  );

  const Error = () => (
    <Result
      status="error"
      title="Submission Failed"
      subTitle="Please check the information before resubmitting."
    >
      <Alert
        type="error"
        message={(
          <>
            {
            requestInfo.error?.status && (
              <>
                <span>
                  Status:
                  {`(${requestInfo.error?.status}) ${requestInfo.error?.statusText}`}
                </span>
                <br />
                <hr />
              </>
            )
            }
            <p>
              An unexpected error has occurred on our servers while trying to
              process this information, please try again in a few minutes.
            </p>
            <br />
            {
              requestInfo.error?.data?.details && (
                <code>
                  {requestInfo.error?.data?.details}
                </code>
              )
            }
          </>
        )}
      />
      <Space align="center" style={{ marginTop: 20 }}>
        <Button
          key="try-again"
          onClick={() => (
            setRequestInfo({
              ...initialRequestInfo,
              status: 'waiting',
            })
          )}
        >
          Try Again
        </Button>
      </Space>
    </Result>
  );

  const FormContent = () => (
    <Form
      form={form}
      name="basic"
      scrollToFirstError
      onFinish={onSubmitHanlder}
      initialValues={{ ...userData }}
      layout="vertical"
      size="large"
    >
      {
        showUserName && (
          <Form.Item
            label="Contact Name"
            name="name"
            className={Styles.margin}
          >
            <Input disabled />
          </Form.Item>
        )
      }
      {
        showEmail && (
          <Form.Item
            className={Styles.margin}
            label="Email Adress"
            name="email"
          >
            <Input disabled />
          </Form.Item>
        )
      }
      {
        showSubject && (
          <Form.Item
            className={Styles.margin}
            label="Subject"
            name="subject"
            initialValue={subject}
            rules={[{ required: true, message: 'Please type a subject.' }]}
          >
            <Input disabled={disableSubject} />
          </Form.Item>
        )
      }
      {
        showOptions && (
          <Form.Item
            className={Styles.margin}
            label="Type of ticket"
            name="cf_category_test"
            rules={[{ required: true, message: 'Please choose a type of ticket.' }]}
            initialValue={defaultType}
          >
            <Select placeholder="Type of ticket" options={ticketTypeOptions} disabled={disableType} />
          </Form.Item>
        )
      }
      {
        extraFields.map(
          (field) => isFieldMeetDependencies(field) && renderField(field),
        )
      }
      {
        showDescription && (
          <Form.Item
            className={Styles.margin}
            label="Detailed Description"
            name="description"
            rules={[{ required: true, message: 'Please type a description.' }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        )
      }
      {
        showAttachments && (
          <>
            {
            fileSizeValidator.error ? (
              <Alert
                message="Warning! The maximun size of the files must be 1mb!"
                type="error"
                closable
                onClose={resetUpload}
                style={{ marginBottom: 20 }}
              />
            ) : (
              <Form.Item
                valuePropName="fileList"
                className={Styles.upload}
                hasFeedback
                validateStatus="error"
              >
                <Upload
                  multiple
                  listType="picture"
                  fileList={fileList}
                  beforeUpload={handleDropFiles}
                  onRemove={handleRemoveFile}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined className={Styles.uploadIcon} />
                  </p>
                  {attachmentsDescription && (
                    <>
                      <p className="ant-upload-text">{attachmentsDescription}</p>
                      <br />
                    </>
                  )}
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint" style={{ marginBottom: 20 }}>
                    Support for a single or bulk upload.
                  </p>
                </Upload>
              </Form.Item>
            )
          }
          </>
        )
      }
      <CustomButton type="submit" variant="buttonForm">
        {submitButtonText}
      </CustomButton>
    </Form>
  );

  useEffect(() => (
    () => {
      if (requestInfo.status === 'complete') {
        resetForm();
      }
    }
  ), []);

  return (
    <>
      {requestInfo.status === 'fail' && <Error />}
      {requestInfo.status === 'waiting' && <FormContent />}
      {requestInfo.status === 'inProgress' && <Loading />}
      {requestInfo.status === 'complete' && <Success />}
    </>
  );
};

ZohoForm.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
  customProductId: PropTypes.string,
  onFinishSubmit: PropTypes.func,
  extraFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      as: PropTypes.string,
      textHelp: PropTypes.string,
      isRequired: PropTypes.bool,
      depends: PropTypes.instanceOf(Object),
      depends_disjunction: PropTypes.bool,
    }),
  ),
  showDescription: PropTypes.bool,
  subject: PropTypes.string,
  disableSubject: PropTypes.bool,
  showSubject: PropTypes.bool,
  defaultType: PropTypes.string,
  disableType: PropTypes.bool,
  showOptions: PropTypes.bool,
  showEmail: PropTypes.bool,
  showUserName: PropTypes.bool,
  showAttachments: PropTypes.bool,
  submitButtonText: PropTypes.string,
  attachmentsDescription: PropTypes.string,
};

ZohoForm.defaultProps = {
  user: {
    name: undefined,
    email: undefined,
  },
  subject: null,
  extraFields: [],
  onFinishSubmit: null,
  customProductId: '',
  defaultType: 'Request',
  showEmail: true,
  disableType: false,
  showSubject: true,
  showOptions: true,
  showUserName: true,
  disableSubject: false,
  showDescription: true,
  showAttachments: true,
  attachmentsDescription: null,
  submitButtonText: 'Request',
};

export default ZohoForm;
