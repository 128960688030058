import React from 'react';
import PropTypes from 'prop-types';

import {
  CheckCircleFilled,
  CloseCircleFilled,
} from '@ant-design/icons';
import { Select, Space } from 'antd';

import { sortFilterLookupsChoices } from '../filter/Filter.options';
import { getSortLookupOptions } from '../filter/Filter.utils';

import FilterDropdownMenuStyle from './FilterSortDropdownMenu.module.scss';

/**
 * Component that renders a dropdown menu to Dropdown AntD with filters sort selected.
 *
 * @param {array} filterSort - Array list of object filters sort.
 * @param {function} onChange - Method when change filter sort name of array list.
 * @param {function} onChangeLookup - Method when change filter sort lookup of array list.
 * @param {function} onSubmit - Method when submit filter sort.
 * @param {function} onReset - Method when reset filters sort.
 */
const FilterSortDropdownMenu = ({
  filterSort,
  onChange,
  onChangeLookup,
  onSubmit,
  onReset,
}) => (
  <div className={FilterDropdownMenuStyle.main}>
    <div className="d-flex">
      <Select
        className="w-100"
        optionFilterProp="label"
        options={sortFilterLookupsChoices}
        onChange={onChange}
        value={filterSort[0]?.name}
        placeholder="Select an option"
      />
      <Select
        className="w-100 ms-2"
        optionFilterProp="label"
        options={getSortLookupOptions(filterSort[0]?.name)}
        onChange={onChangeLookup}
        value={filterSort[0]?.value}
        placeholder="Select an option"
      />
    </div>
    <hr />
    <div className={FilterDropdownMenuStyle.applyFiltersSection}>
      <div className={FilterDropdownMenuStyle.describeSection}>
        Approve or cancel your sort
      </div>
      <Space>
        <button
          type="button"
          onClick={onSubmit}
          aria-label="Approve filter button"
          className={FilterDropdownMenuStyle.searchButton}
        >
          <CheckCircleFilled />
        </button>
        <button
          type="button"
          onClick={onReset}
          aria-label="Reset filter button"
          className={FilterDropdownMenuStyle.resetButton}
        >
          <CloseCircleFilled />
        </button>
      </Space>
    </div>
  </div>
);

FilterSortDropdownMenu.propTypes = {
  filterSort: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeLookup: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default FilterSortDropdownMenu;
