import React from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  Skeleton,
  Tooltip,
} from 'antd';
import moment from 'moment';

import { AccountsViewContext } from '../../../views/accountsView/AccountsViewContext';
import { getLabelByValue } from '../../../helpers/utils';

import SubscriptionCardStyle from './SubscriptionCard.module.scss';

import NoImage from '../../../assets/not-image-card.png';

/**
 * Component that renders a subscription row element like the ones in '/accounts'
 * @param {object} data - Subscription information object.
 * @param {func} onSelect - Function that is triggered when the element is selected.
 * @param {boolean} isActive - Indicate if element is active or selected.
 * @param {boolean} loading - Indicate if element is loading.
 */
const SubscriptionCard = ({
  data,
  onSelect,
  isActive,
  loading,
}) => {
  const [viewContext] = AccountsViewContext();

  return (
    <Card
      className={`${SubscriptionCardStyle.subscriptionCard} ${isActive && SubscriptionCardStyle.active}`}
      onClick={!loading ? onSelect : undefined}
      bodyStyle={{ padding: '15px 0 15px 24px' }}
    >
      <div className={SubscriptionCardStyle.container}>
        <div className={SubscriptionCardStyle.logo}>
          {
            !loading ? (
              <img
                src={data.account?.profile_logo_url ? data.account.profile_logo_url : NoImage}
                className={SubscriptionCardStyle.image}
                alt="Account logo"
              />
            ) : (
              <Skeleton.Input active block style={{ minWidth: '100%' }} />
            )
          }
        </div>
        <div className={SubscriptionCardStyle.content}>
          <div className={SubscriptionCardStyle.contentElement}>
            {
              !loading ? (
                <>
                  <p className={SubscriptionCardStyle.result}>
                    <b>Account: </b>
                  </p>
                  <p className={SubscriptionCardStyle.result}>
                    {data.account?.name}
                  </p>
                </>
              ) : (
                <>
                  <Skeleton.Input
                    active
                    block
                    style={{ height: 12 }}
                  />
                  <Skeleton.Input
                    active
                    block
                    style={{ height: 12 }}
                  />
                </>
              )
            }
          </div>
          <div className={SubscriptionCardStyle.contentElement}>
            {
              !loading ? (
                <>
                  <p className={SubscriptionCardStyle.result}>
                    <b>Subscription: </b>
                    {' '}
                    <span className={SubscriptionCardStyle.subscriptionResult}>
                      <Tooltip title={data.name}>
                        <span className={SubscriptionCardStyle.link}>
                          {data.name}
                        </span>
                      </Tooltip>
                      <Tooltip title={data.is_active ? 'Active' : 'Inactive'}>
                        <span className={`${SubscriptionCardStyle.circleActive} ${data.is_active ? SubscriptionCardStyle.on : SubscriptionCardStyle.off}`} />
                      </Tooltip>
                    </span>
                  </p>
                  <p className={SubscriptionCardStyle.result}>
                    <b>Status: </b>
                    {' '}
                    {getLabelByValue(viewContext.options, 'product_status_choices', data.status)}
                  </p>
                </>
              ) : (
                <>
                  <Skeleton.Input
                    active
                    block
                    style={{ height: 12 }}
                  />
                  <Skeleton.Input
                    active
                    block
                    style={{ height: 12 }}
                  />
                </>
              )
            }
          </div>
          <div className={SubscriptionCardStyle.contentElement}>
            {
              !loading ? (
                <>
                  <p className={SubscriptionCardStyle.result}>
                    <b>Type: </b>
                    {' '}
                    {getLabelByValue(viewContext.options, 'product_types', data.product_type)}
                  </p>
                  <p className={SubscriptionCardStyle.result}>
                    <b>Tier: </b>
                    {' '}
                    {getLabelByValue(viewContext.options, 'product_tier_choices', data.tier)}
                  </p>
                </>
              ) : (
                <>
                  <Skeleton.Input
                    active
                    block
                    style={{ height: 12 }}
                  />
                  <Skeleton.Input
                    active
                    block
                    style={{ height: 12 }}
                  />
                </>
              )
            }
          </div>
          <div className={SubscriptionCardStyle.contentElement}>
            {
              !loading ? (
                <>
                  <p className={SubscriptionCardStyle.result}>
                    <b>Country: </b>
                    {' '}
                    {getLabelByValue(viewContext.options, 'country_choices', data.account?.main_country, true)}
                  </p>
                  <p className={SubscriptionCardStyle.result}>
                    <b>Language: </b>
                    {' '}
                    {getLabelByValue(viewContext.options, 'language_choices', data.main_language)}
                  </p>
                </>
              ) : (
                <>
                  <Skeleton.Input
                    active
                    block
                    style={{ height: 12 }}
                  />
                  <Skeleton.Input
                    active
                    block
                    style={{ height: 12 }}
                  />
                </>
              )
            }
          </div>
          <div className={SubscriptionCardStyle.contentElement}>
            {
              !loading ? (
                <>
                  <p className={SubscriptionCardStyle.result}>
                    <b>Billing Cycle: </b>
                    {' '}
                    {getLabelByValue(viewContext.options, 'product_billing_terms_choices', data.billing_terms)}
                  </p>
                  <p className={SubscriptionCardStyle.result}>
                    <b>Next Invoice: </b>
                    {' '}
                    {moment(data.next_invoice).utc().format('DD/MM/YYYY')}
                  </p>
                </>
              ) : (
                <>
                  <Skeleton.Input
                    active
                    block
                    style={{ height: 12 }}
                  />
                  <Skeleton.Input
                    active
                    block
                    style={{ height: 12 }}
                  />
                </>
              )
            }
          </div>
          <div className={SubscriptionCardStyle.contentElement}>
            {
              !loading ? (
                <>
                  <p className={SubscriptionCardStyle.result}>
                    <b>Company Name: </b>
                    {' '}
                    {data.account?.billing_name}
                  </p>
                  <p className={SubscriptionCardStyle.result}>
                    <b>Billing last change: </b>
                    {' '}
                    {moment(data.account?.billing_info_last_changed).utc().format('DD/MM/YYYY')}
                  </p>
                </>
              ) : (
                <>
                  <Skeleton.Input
                    active
                    block
                    style={{ height: 12 }}
                  />
                  <Skeleton.Input
                    active
                    block
                    style={{ height: 12 }}
                  />
                </>
              )
            }
          </div>
        </div>
      </div>
    </Card>
  );
};

SubscriptionCard.propTypes = {
  data: PropTypes.shape({
    account: PropTypes.shape({
      name: PropTypes.string,
      vertical: PropTypes.string,
      crm_id: PropTypes.string,
      billing_alegra_id: PropTypes.string,
      profile_logo_url: PropTypes.string,
      main_country: PropTypes.string,
      billing_info_last_changed: PropTypes.string,
      billing_name: PropTypes.string,
    }),
    name: PropTypes.string,
    external_key: PropTypes.string,
    mau_quota: PropTypes.string,
    billing_paypal_profile_id: PropTypes.string,
    size: PropTypes.string,
    is_active: PropTypes.bool,
    product_type: PropTypes.string,
    tier: PropTypes.string,
    status: PropTypes.string,
    main_language: PropTypes.string,
    billing_terms: PropTypes.string,
    next_invoice: PropTypes.string,
  }),
  onSelect: PropTypes.func,
  isActive: PropTypes.bool,
  loading: PropTypes.bool,
};

SubscriptionCard.defaultProps = {
  data: {},
  onSelect: undefined,
  isActive: false,
  loading: false,
};

export default SubscriptionCard;
