import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { AccountsViewReducer } from './AccountsViewReducer';

export const initialState = {
  loaded: false,
  accounts: {},
  menus: [],
  extraMenus: [],
  user: {
    name: '',
    email: '',
    roles: [],
    allowed_actions: {
      create: undefined,
      read: undefined,
      update: undefined,
      delete: undefined,
      instance: {
        create: undefined,
        read: undefined,
        update: undefined,
        delete: undefined,
      },
      contact: {
        create: undefined,
        read: undefined,
        update: undefined,
        delete: undefined,
      },
      memo: {
        create: undefined,
        read: undefined,
        update: undefined,
        delete: undefined,
      },
      team: {
        create: undefined,
        read: undefined,
        update: undefined,
        delete: undefined,
      },
    },
  },
  userLoaded: false,
  options: {},
  subscriptions: [],
  hideChatWidget: false,
};

export const Context = createContext();
Context.displayName = 'AccountsViewContext';

export const AccountsViewContext = () => useContext(Context);

export const AccountsViewProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(AccountsViewReducer, initialState);

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

AccountsViewProvider.propTypes = {
  /**
   * Provider elements.
   */
  children: PropTypes.node.isRequired,
};
