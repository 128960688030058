/**
 *  SITE OPERATION CONSTANTS CONTROL CENTER
 *  In this file the site operation constants of the project are configured.
 *  In this file we will find ONLY the keys used in the My Site > Site Operation view.
 *
 *  WARNINGS:
 *            - When you need to include more than one HTML tag, they must be contained in
 *              a parent HTML tag, for example:
 *              Examples of incorrect code:
 *              (
 *                <h1>Hello World!</h1>
 *                <p>Lorem ipsum ... </p>
 *              )
 *
 *              Examples of correct code:
 *              (
 *                <>
 *                    <h1>Hello World!</h1>
 *                    <p>Lorem ipsum ... </p>
 *                </>
 *              )
 *
 *            - Make sure to preserve both the structure and the indentation, our format is:
 *              {
 *                section_name: {
 *                    static: (... Text|HTML),
 *                    variable: {
 *                        variable_1: (... Text|HTML),
 *                        ...
 *                    },
 *                },
 *                ...
 *              }
*/
import React from 'react';
import { Col, Row } from 'antd';

import ZohoForm from '../components/ZohoForm/ZohoForm';
import DynamicElement from '../components/DynamicElement/DynamicElement';
import LearnerManagement from '../components/LearnerManagement/LearnerManagement';
import tabContentFormatter from '../helpers/utils/tabContentFormatter/tabContentFormatter';

import intermediate from '../assets/intermediate.png';
import apisImage from '../assets/clipBanner.png';

import Styles from '../Common.module.scss';

import {
  NOT_AVAILABLE,
} from './messages.constants';

import {
  INACTIVE_SUBSCRIPTION,
  MISCONFIGURED_SUBSCRIPTION,
  INACTIVE_STRATUS_SUBSCRIPTION,
} from './subscription.constants';

// Add new learners
const ADD_NEW_LEARNER_TAB_1 = (
  <Row justify="center" align="middle">
    <Col xs={24} md={12}>
      <ol>
        <li>
          <DynamicElement type="lms" text="Go to your LMS" />
          and login
        </li>
        <br />
        <li>
          <b className={Styles.boldText}>Enter the course </b>
          where you want to add the learner.
        </li>
        <br />
        <li>
          Go to the
          <b className={Styles.boldText}> Instructor tab.</b>
        </li>
        <br />
        <li>
          Go to the
          <b className={Styles.boldText}> Membership tab.</b>
        </li>
        <br />
        <li>
          Add the emails and usernames in the
          <b className={Styles.boldText}> batch registration section.</b>
        </li>
        <br />
        <li>
          Make sure that self enroll and notify users by email are enabled.
        </li>
        <br />
        <li>
          Click on
          <b className={Styles.boldText}> Enroll </b>
          button and
          <b className={Styles.boldText}>
            your students must register by their own.
          </b>
        </li>
      </ol>
    </Col>
    <Col xs={0} md={12}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/qfHjS1k6adY"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </Col>
  </Row>
);

const ADD_NEW_LEARNER_TAB_2 = (
  <Row>
    <DynamicElement type="iframeCustom" url="https://public.docs.edunext.co/en/latest/external/developers/developer_tools/Edunext-cli.html?highlight" />
  </Row>
);

const ADD_NEW_LEARNER_TAB_3 = (
  <Row justify="center" align="middle">
    <Col xl={{ span: 12 }} lg={{ span: 24 }}>
      <div style={{ textAlign: 'center' }}>
        <img
          src={apisImage}
          alt="apisImage"
          style={{ maxWidth: '400px', width: '100%' }}
        />
      </div>
    </Col>
    <Col xl={{ span: 12 }} lg={{ span: 24 }}>
      <p>
        You can take a look at the APIs
        {' '}
        <DynamicElement type="customLms" text="here." url="/eox-core/api-docs/#/enrollment/enrollment_create" />
      </p>
    </Col>
  </Row>
);

const ADD_NEW_LEARNER_TAB_4 = (
  <Row justify="center" align="middle">
    <Col xl={{ span: 12 }} lg={{ span: 24 }}>
      <div style={{ textAlign: 'center' }}>
        <img
          src={intermediate}
          alt="intermediate"
          style={{ maxWidth: '400px', width: '100%' }}
        />
      </div>
    </Col>
    <Col xl={{ span: 12 }} lg={{ span: 24 }}>
      <Row style={{ maxWidth: '600px', margin: '0 auto' }}>
        <p>
          Create a list with the following information: Full name, Username,
          Password and email address.
          <DynamicElement
            type="custom"
            text="Use this template"
            url="https://docs.google.com/spreadsheets/d/1gPi20DJzsm4btLeZqv-NKSUTGrkrLCBdqeQ_6X5IScU/edit#gid=0"
          />
        </p>
        <p>
          NOTE: Usernames can only contain letters (A-Z, a-z), numbers (0-9),
          underscores (_), and hyphens (-).
        </p>
      </Row>
      <div style={{ maxWidth: '600px', margin: '0 auto' }}>
        <ZohoForm
          subject="Bulk learner registration"
          showUserName={false}
          showEmail={false}
          showSubject={false}
          showOptions={false}
          showDescription={false}
          attachmentsDescription="Add the template with the information of your learners"
          extraFields={[
            {
              id: 'activate-account-or-receive-an-email',
              name: 'activate-account-or-receive-an-email',
              label:
              'Do you want the accounts to be activated upon creation or instead to receive an email for activating the account?',
              type: 'checkbox',
              options: [
                'Yes, activate the accounts as part of the bulk creation.',
                'No. The learners should receive and email to activate their accounts manually.',
                'Other.',
              ],
              isRequired: true,
            },
            {
              id: 'other-option',
              name: 'other-option',
              label:
              'If you have chosen "Other" in the previous option, specify:',
              isRequired: false,
            },
          ]}
        />
      </div>
    </Col>
  </Row>
);

// View all learners
const VIEW_ALL_LEARNERS_TAB_1 = (
  <Row justify="center" align="middle">
    <Col xs={24} md={12}>
      <ol>
        <li>
          Go to your site in
          <b className={Styles.boldText}> Control Center.</b>
        </li>
        <br />
        <li>
          In the section
          <b className={Styles.boldText}> Reporting and Anaytics</b>
          go to
          <DynamicElement type="reports" text="report." />
        </li>
        <br />
        <li>
          Create a
          <b className={Styles.boldText}> new user report</b>
          and download in XLSX, XLS or CSV format.
        </li>
        <br />
      </ol>
    </Col>
    <Col xs={0} md={12}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/w6DAvRBYv9k"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </Col>
  </Row>
);

const VIEW_ALL_LEARNERS_TAB_2 = (
  <Row justify="center" align="middle">
    <Col xs={24} md={12}>
      <ol>
        <li>
          <DynamicElement type="lms" text="Go to your LMS" />
          and login.
        </li>
        <br />
        <li>
          Enter the
          <b className={Styles.boldText}>course</b>
          where you want to see the learner.
        </li>
        <br />
        <li>
          Go to the
          <b className={Styles.boldText}>Instructor tab.</b>
        </li>
        <br />
        <li>
          Go to the
          <b className={Styles.boldText}>Data download tab.</b>
        </li>
        <p style={{ marginTop: 5, marginBottom: 0, maxWidth: 400 }}>
          <b className={Styles.boldText}>
            Note:
          </b>
          You must have the &ldquo;data researcher&rdquo; permission to have active this
          option. If case do not have it, you can ask to support team.
        </p>
        <br />
        <li>Download the list of your learners enrolled.</li>
      </ol>
    </Col>
    <Col xs={0} md={12}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/8CGJRVuqcrA"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </Col>
  </Row>
);

const VIEW_ALL_LEARNERS_TAB_3 = (
  <Row align="middle">
    <Row>
      <p>
        To see your students through the main database you must have access and
        permissions. If you do not have them, perform this operation with the
        other options in the top menu.
      </p>
      <br />
      <br />
    </Row>
    <Col xs={24} md={12}>
      <ol>
        <li>
          <DynamicElement type="djangoAdmin" text="Go to Django Admin" />
          and login.
        </li>
        <br />
        <li>
          Go to the
          <b className={Styles.boldText}>users</b>
          located in
          <b className={Styles.boldText}>authentication and authorization</b>
          section.
        </li>
        <br />
        <li>See the list of your all learners of the initiatives.</li>
        <br />
        <li>If you want you can apply a filter of a specific course.</li>
      </ol>
    </Col>
    <Col xs={0} md={12}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/zxQoR0L5U78"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </Col>
  </Row>
);

const VIEW_ALL_LEARNERS_TAB_4 = (
  <Row justify="center" align="middle">
    <Col xl={{ span: 12 }} lg={{ span: 24 }}>
      <div style={{ textAlign: 'center' }}>
        <img
          src={intermediate}
          alt="intermediate"
          style={{ maxWidth: '400px', width: '100%' }}
        />
      </div>
    </Col>
    <Col xl={{ span: 12 }} lg={{ span: 24 }}>
      <Row style={{ maxWidth: '600px', margin: '0 auto' }}>
        <p>
          Ask for help from the technical support team only if you have not had
          problems in the first two options to see your students.
        </p>
      </Row>
      <div style={{ maxWidth: '600px', margin: '0 auto' }}>
        <ZohoForm
          disableSubject
          subject="Request view all learners"
          showUserName={false}
          showEmail={false}
          showOptions={false}
          showDescription={false}
          showAttachments={false}
          extraFields={[
            {
              id: 'id-course',
              name: 'id-course',
              label: 'ID Course',
              isRequired: true,
            },
            {
              id: 'url-for-your-lms-site',
              name: 'url-for-your-lms-site',
              label: 'URL for your LMS site',
              isRequired: true,
              validation: { type: 'url', warningOnly: false },
            },
          ]}
        />
      </div>
    </Col>
  </Row>
);

// Edit learners
const EDIT_LEARNER_INFORMATION_TAB_1 = (
  <Row justify="center">
    <Col>
      <LearnerManagement />
    </Col>
  </Row>
);

const EDIT_LEARNER_INFORMATION_TAB_2 = (
  <Row justify="center">
    <Col>
      <LearnerManagement learnerAction="force-password" />
    </Col>
  </Row>
);

const EDIT_LEARNER_INFORMATION_TAB_3 = (
  <Row align="middle">
    <Row>
      <p>
        To edit your students through the main database you must have access and
        permissions. If you do not have them, perform this operation with the
        other options in the top menu
      </p>
    </Row>
    <Col xs={24} md={12}>
      <ol>
        <li>
          <DynamicElement type="djangoAdmin" text="Go to Django Admin" />
          and login.
        </li>
        <br />
        <li>
          Go to the
          <b className={Styles.boldText}>users</b>
          located in
          <b className={Styles.boldText}>authentication and authorization</b>
          section.
        </li>
        <br />
        <li>See the list of your learners.</li>
        <br />
        <li>
          Use the search engine to locate the user you need to edit some other
          data.
        </li>
        <br />
        <li>Save the changes.</li>
      </ol>
    </Col>
    <Col xs={0} md={12}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/34aX_6VMoUU"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </Col>
  </Row>
);

const EDIT_LEARNER_INFORMATION_TAB_4 = (
  <Row align="middle">
    <Row>
      <p>
        Share this information with your users so they can update
        their profile information. Your learner can edit general
        information from their account such as:
      </p>
      <br />
      <p>
        <b className={Styles.boldText}>
          Full name, Email address, Password, Language, Country or region of residence,
          Time zone, Education, Gender, Year of birth, Language preference
          and Social Media Links.
        </b>
      </p>
      <br />
      <br />
    </Row>
    <Col xs={24} md={12}>
      <ol>
        <li>
          Your learners must be logged into your LMS.
        </li>
        <br />
        <li>
          Click your username dropdown.
        </li>
        <br />
        <li>
          Enter account and edit the information as necessary.
        </li>
      </ol>
    </Col>
    <Col xs={0} md={12}>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/HGkMiX4N7VU" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
    </Col>
  </Row>
);

// Delete learners
const DELETE_LEARNER_TAB_1 = (
  <Row align="middle">
    <Col xs={24} md={12}>
      <ol>
        <li>
          <DynamicElement type="djangoAdmin" text="Go to Django Admin" />
          and login.
        </li>
        <br />
        <li>
          Go to the
          <b className={Styles.boldText}>users</b>
          located in
          <b className={Styles.boldText}>authentication and authorization</b>
          section.
        </li>
        <br />
        <li>
          See the list of your all learners of the initiative.
        </li>
        <br />
        <li>Find the user you want to delete using the search engine.</li>
        <br />
        <li>
          Before clicking on the delete option, make sure that it is
          the user you want to delete.
        </li>
      </ol>
    </Col>
    <Col xs={0} md={12}>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/60vjpsok8f0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
    </Col>
  </Row>
);

const DELETE_LEARNER_TAB_2 = (
  <Row>
    <DynamicElement type="iframe" url="/eox-core/api-docs/#/enrollment/enrollment_delete" />
  </Row>
);

const DELETE_LEARNER_TAB_3 = (
  <Row justify="center" align="middle">
    <Col xl={{ span: 12 }} lg={{ span: 24 }}>
      <div style={{ textAlign: 'center' }}>
        <img
          src={intermediate}
          alt="intermediate"
          style={{ maxWidth: '400px', width: '100%' }}
        />
      </div>
    </Col>
    <Col xl={{ span: 12 }} lg={{ span: 24 }}>
      <Row style={{ maxWidth: '600px', margin: '0 auto' }}>
        <p>Make sure the information you are providing is correct</p>
      </Row>
      <div style={{ maxWidth: '600px', margin: '0 auto' }}>
        <ZohoForm
          subject="Request delete learner"
          showSubject={false}
          showUserName={false}
          showEmail={false}
          showOptions={false}
          showDescription={false}
          showAttachments={false}
          extraFields={[
            {
              id: 'user-name',
              name: 'user-name',
              label: 'Username',
              isRequired: true,
            },
            {
              id: 'email-to-delete',
              name: 'email-to-delete',
              label: 'Email',
              isRequired: true,
              validation: { type: 'email', warningOnly: false },
            },
          ]}
        />
      </div>
    </Col>
  </Row>
);

export default {
  add_new_learners: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: [
        tabContentFormatter(ADD_NEW_LEARNER_TAB_1, 'From the LMS', 0),
        tabContentFormatter(NOT_AVAILABLE.nimbus_free, 'From the CLI', 1),
        tabContentFormatter(NOT_AVAILABLE.nimbus_free, 'From APIS', 2),
        tabContentFormatter(NOT_AVAILABLE.nimbus_free, 'Bulk learner registration', 3),
      ],
      nimbus_basic: [
        tabContentFormatter(ADD_NEW_LEARNER_TAB_1, 'From the LMS', 0),
        tabContentFormatter(NOT_AVAILABLE.nimbus_basic, 'From the CLI', 1),
        tabContentFormatter(NOT_AVAILABLE.nimbus_basic, 'From APIS', 2),
        tabContentFormatter(NOT_AVAILABLE.nimbus_basic, 'Bulk learner registration', 3),
      ],
      nimbus_enhanced: [
        tabContentFormatter(ADD_NEW_LEARNER_TAB_1, 'From the LMS', 0),
        tabContentFormatter(NOT_AVAILABLE.nimbus_enhanced, 'From the CLI', 1),
        tabContentFormatter(NOT_AVAILABLE.nimbus_enhanced, 'From APIS', 2),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_4, 'Bulk learner registration', 3),
      ],
      nimbus_advanced: [
        tabContentFormatter(ADD_NEW_LEARNER_TAB_1, 'From the LMS', 0),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_2, 'From the CLI', 1),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_3, 'From APIS', 2),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_4, 'Bulk learner registration', 3),
      ],
      on_premise: [
        tabContentFormatter(ADD_NEW_LEARNER_TAB_1, 'From the LMS', 0),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_2, 'From the CLI', 1),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_3, 'From APIS', 2),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_4, 'Bulk learner registration', 3),
      ],
      cirrus: [
        tabContentFormatter(ADD_NEW_LEARNER_TAB_1, 'From the LMS', 0),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_2, 'From the CLI', 1),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_3, 'From APIS', 2),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_4, 'Bulk learner registration', 3),
      ],
      cirrus_sn: [
        tabContentFormatter(ADD_NEW_LEARNER_TAB_1, 'From the LMS', 0),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_2, 'From the CLI', 1),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_3, 'From APIS', 2),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_4, 'Bulk learner registration', 3),
      ],
      cirrus_se: [
        tabContentFormatter(ADD_NEW_LEARNER_TAB_1, 'From the LMS', 0),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_2, 'From the CLI', 1),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_3, 'From APIS', 2),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_4, 'Bulk learner registration', 3),
      ],
      stratus: [
        tabContentFormatter(ADD_NEW_LEARNER_TAB_1, 'From the LMS', 0),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_2, 'From the CLI', 1),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_3, 'From APIS', 2),
        tabContentFormatter(ADD_NEW_LEARNER_TAB_4, 'Bulk learner registration', 3),
      ],
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  view_all_learners: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: [
        tabContentFormatter(NOT_AVAILABLE.nimbus_free, 'Through reports', 0),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_1, 'Through the LMS', 1),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_3, 'Through Django Admin', -1, true),
        tabContentFormatter(NOT_AVAILABLE.nimbus_free, 'Request Customer Support', 2),
      ],
      nimbus_basic: [
        tabContentFormatter(NOT_AVAILABLE.nimbus_basic, 'Through reports', 0),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_2, 'Through the LMS', 1),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_3, 'Through Django Admin', -1, true),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_4, 'Request Customer Support', 2),
      ],
      nimbus_enhanced: [
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_1, 'Through reports', 0),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_2, 'Through the LMS', 1),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_3, 'Through Django Admin', -1, true),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_4, 'Request Customer Support', 2),
      ],
      nimbus_advanced: [
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_1, 'Through reports', 0),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_2, 'Through the LMS', 1),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_3, 'Through Django Admin', -1, true),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_4, 'Request Customer Support', 2),
      ],
      on_premise: [
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_1, 'Through reports', 0),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_2, 'Through the LMS', 1),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_3, 'Through Django Admin', 2),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_4, 'Request Customer Support', 3),
      ],
      cirrus: [
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_1, 'Through reports', 0),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_2, 'Through the LMS', 1),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_3, 'Through Django Admin', 2),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_4, 'Request Customer Support', 3),
      ],
      cirrus_sn: [
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_1, 'Through reports', 0),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_2, 'Through the LMS', 1),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_3, 'Through Django Admin', 2),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_4, 'Request Customer Support', 3),
      ],
      cirrus_se: [
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_1, 'Through reports', 0),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_2, 'Through the LMS', 1),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_3, 'Through Django Admin', 2),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_4, 'Request Customer Support', 3),
      ],
      stratus: [
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_1, 'Through reports', 0),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_2, 'Through the LMS', 1),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_3, 'Through Django Admin', 2),
        tabContentFormatter(VIEW_ALL_LEARNERS_TAB_4, 'Request Customer Support', 3),
      ],
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  edit_all_learners: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: [
        tabContentFormatter(NOT_AVAILABLE.nimbus_free, 'Activate user', 0),
        tabContentFormatter(NOT_AVAILABLE.nimbus_free, 'Change password', 1),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_3, 'Edit general data in Django admin', -1, true),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_4, 'Edit general data in learner account', 2),
      ],
      nimbus_basic: [
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_1, 'Activate user', 0),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_2, 'Change password', 1),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_3, 'Edit general data in Django admin', -1, true),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_4, 'Edit general data in learner account', 2),
      ],
      nimbus_enhanced: [
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_1, 'Activate user', 0),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_2, 'Change password', 1),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_3, 'Edit general data in Django admin', -1, true),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_4, 'Edit general data in learner account', 2),
      ],
      nimbus_advanced: [
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_1, 'Activate user', 0),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_2, 'Change password', 1),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_3, 'Edit general data in Django admin', -1, true),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_4, 'Edit general data in learner account', 2),
      ],
      on_premise: [
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_1, 'Activate user', 0),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_2, 'Change password', 1),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_3, 'Edit general data in Django admin', 2),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_4, 'Edit general data in learner account', 3),
      ],
      cirrus: [
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_1, 'Activate user', 0),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_2, 'Change password', 1),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_3, 'Edit general data in Django admin', 2),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_4, 'Edit general data in learner account', 3),
      ],
      cirrus_sn: [
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_1, 'Activate user', 0),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_2, 'Change password', 1),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_3, 'Edit general data in Django admin', 2),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_4, 'Edit general data in learner account', 3),
      ],
      cirrus_se: [
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_1, 'Activate user', 0),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_2, 'Change password', 1),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_3, 'Edit general data in Django admin', 2),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_4, 'Edit general data in learner account', 3),
      ],
      stratus: [
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_1, 'Activate user', 0),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_2, 'Change password', 1),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_3, 'Edit general data in Django admin', 2),
        tabContentFormatter(EDIT_LEARNER_INFORMATION_TAB_4, 'Edit general data in learner account', 3),
      ],
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  delete_all_learners: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: [
        tabContentFormatter(NOT_AVAILABLE.nimbus_free, 'From API', 0),
        tabContentFormatter(NOT_AVAILABLE.nimbus_free, 'Request Customer Support', 1),
        tabContentFormatter(DELETE_LEARNER_TAB_1, 'Delete from Django Admin', -1, true),
      ],
      nimbus_basic: [
        tabContentFormatter(NOT_AVAILABLE.nimbus_basic, 'From API', 0),
        tabContentFormatter(DELETE_LEARNER_TAB_3, 'Request Customer Support', 1),
        tabContentFormatter(DELETE_LEARNER_TAB_1, 'Delete from Django Admin', -1, true),
      ],
      nimbus_enhanced: [
        tabContentFormatter(NOT_AVAILABLE.nimbus_enhanced, 'From API', 0),
        tabContentFormatter(DELETE_LEARNER_TAB_3, 'Request Customer Support', 1),
        tabContentFormatter(DELETE_LEARNER_TAB_1, 'Delete from Django Admin', -1, true),
      ],
      nimbus_advanced: [
        tabContentFormatter(DELETE_LEARNER_TAB_2, 'From API', 0),
        tabContentFormatter(DELETE_LEARNER_TAB_3, 'Request Customer Support', 1),
        tabContentFormatter(DELETE_LEARNER_TAB_1, 'Delete from Django Admin', -1, true),
      ],
      on_premise: [
        tabContentFormatter(DELETE_LEARNER_TAB_1, 'Delete from Django Admin', 0),
        tabContentFormatter(DELETE_LEARNER_TAB_2, 'From API', 2),
        tabContentFormatter(DELETE_LEARNER_TAB_3, 'Request Customer Support', 1),
      ],
      cirrus: [
        tabContentFormatter(DELETE_LEARNER_TAB_1, 'Delete from Django Admin', 0),
        tabContentFormatter(DELETE_LEARNER_TAB_2, 'From API', 2),
        tabContentFormatter(DELETE_LEARNER_TAB_3, 'Request Customer Support', 1),
      ],
      cirrus_sn: [
        tabContentFormatter(DELETE_LEARNER_TAB_1, 'Delete from Django Admin', 0),
        tabContentFormatter(DELETE_LEARNER_TAB_2, 'From API', 2),
        tabContentFormatter(DELETE_LEARNER_TAB_3, 'Request Customer Support', 1),
      ],
      cirrus_se: [
        tabContentFormatter(DELETE_LEARNER_TAB_1, 'Delete from Django Admin', 0),
        tabContentFormatter(DELETE_LEARNER_TAB_2, 'From API', 2),
        tabContentFormatter(DELETE_LEARNER_TAB_3, 'Request Customer Support', 1),
      ],
      stratus: [
        tabContentFormatter(DELETE_LEARNER_TAB_1, 'Delete from Django Admin', 0),
        tabContentFormatter(DELETE_LEARNER_TAB_2, 'From API', 2),
        tabContentFormatter(DELETE_LEARNER_TAB_3, 'Request Customer Support', 1),
      ],
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
};
