import { consoleRequest } from './requests';

class ProductRequest {
  constructor() {
    this.base = 'api/product/v1';
    this.base_url = `${this.base}/products/`;
    this.base_search_url = `${this.base}/products-search`;
  }

  get(responseHandler, params) {
    consoleRequest.get(this.base_url, { params })
      .then((res) => {
        responseHandler(res);
      });
  }

  getTickets(productId, controller) {
    return consoleRequest.get(`${this.base_url}${productId}/tickets/`, {}, {
      signal: controller.signal,
    });
  }

  getOptions() {
    const url = `${this.base_search_url}/context/`;
    return consoleRequest.get(url);
  }

  patch(responseHandler, errorHandler, productId, data = {}) {
    const url = `${this.base_url}${productId}/`;
    consoleRequest.patch(url, data)
      .then((res) => {
        responseHandler(res);
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  search(params) {
    return consoleRequest.get(this.base_search_url, { params });
  }
}

export default new ProductRequest();
