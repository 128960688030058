/**
 *  SITE CONFIGURATIONS CONSTANTS CONTROL CENTER
 *  In this file the site configurations constants of the project are configured.
 *  In this file we will find ONLY the keys used in the My Site > Site Configuration view.
 *
 *  WARNINGS:
 *            - When you need to include more than one HTML tag, they must be contained in
 *              a parent HTML tag, for example:
 *              Examples of incorrect code:
 *              (
 *                <h1>Hello World!</h1>
 *                <p>Lorem ipsum ... </p>
 *              )
 *
 *              Examples of correct code:
 *              (
 *                <>
 *                    <h1>Hello World!</h1>
 *                    <p>Lorem ipsum ... </p>
 *                </>
 *              )
 *
 *            - Make sure to preserve both the structure and the indentation, our format is:
 *              {
 *                section_name: {
 *                    static: (... Text|HTML),
 *                    variable: {
 *                        variable_1: (... Text|HTML),
 *                        ...
 *                    },
 *                },
 *                ...
 *              }
 */
import React from 'react';
import OnboardingBox from '../components/Onboarding/OnboardingBox/OnboardingBox';

import lmsImage from '../assets/lms_domain.png';
import sslImage from '../assets/ssl_encryption.png';
import generalLenguageOnboarding from '../assets/onboardings/general2.png';
import cmsGif from '../assets/onboardings/cms.gif';
import courseCatalogA from '../assets/onboardings/courseCatalogA.png';
import courseCatalogB from '../assets/onboardings/courseCatalogB.png';
import courseDescriptionA from '../assets/onboardings/courseDescriptionA.png';
import courseDescriptionB from '../assets/onboardings/courseDescriptionB.png';
import customStyles from '../assets/onboardings/customStyles.png';
import footerGif from '../assets/onboardings/footer.gif';
import footerAdvanced from '../assets/onboardings/footerAdvanced.png';
import general from '../assets/onboardings/general.png';
import headerGif from '../assets/onboardings/header.gif';
import homePage from '../assets/onboardings/homePage.png';
import homePageAdvanced from '../assets/onboardings/homePageAdvanced.png';
import lmsDomainAndSSLGif from '../assets/onboardings/lmsDomainAndSSL.gif';
import contactUs from '../assets/onboardings/contact-us.png';
import emailDeliveryAdvancedSettings from '../assets/onboardings/email-delivery-advanced-settings.png';
import emailDeliveryBasic from '../assets/onboardings/email-delivery-basic.png';
import faq from '../assets/onboardings/faq.png';
import honor from '../assets/onboardings/honor.png';
import infoPageAboutUs from '../assets/onboardings/info-page-about-us.png';
import infoPagePrivacy from '../assets/onboardings/info-page-privacy.png';
import learnerDashboardAdvanced from '../assets/onboardings/learner-dashboard-advanced.png';
import learnerDashboardBasic from '../assets/onboardings/learner-dashboard-basic.png';
import registrationSettings from '../assets/onboardings/registration-settings.png';
import registrationLogin from '../assets/onboardings/registration-login-settings.png';
import registrationCustomFields from '../assets/onboardings/registration-custom-fields.png';
import registrationThirdPartyAuth from '../assets/onboardings/registration-third-party-auth.png';
import tos from '../assets/onboardings/tos.png';

import Styles from '../Common.module.scss';

import {
  GOTO_MANAGE_SETTINGS_LANGUAGE,
  GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_CONTACT,
  GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_ABOUT,
  GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_FAQ,
  GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_HONOR,
  GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_PRIVACY,
  GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_TOS,
  GOTO_MANAGE_SETTINGS_REGISTERLOGIN,
  GOTO_MANAGE_SETTINGS_REGISTERLOGINREGISTER,
  GOTO_MANAGE_SETTINGS_SUPPORT,
  GOTO_MANAGE_SETTINGS_LOOK_AND_FEEL_BRAND,
  GOTO_MANAGE_SETTINGS_LOOK_AND_HEADER,
  GOTO_MANAGE_SETTINGS_LOOK_AND_FOOTER_BASIC_SETTINGS,
  GOTO_MANAGE_SETTINGS_LOOK_AND_FOOTER_ADVANCED_SETTINGS,
  GOTO_MANAGE_SETTINGS_LOOK_AND_CUSTOM_STYLES,
  GOTO_MANAGE_SETTINGS_HOMEPAGE_BASIC,
  GOTO_MANAGE_SETTINGS_HOMEPAGE_ADVANCED,
  GOTO_COUSE_CATALOG_BASIC_SETTINGS,
  GOTO_COUSE_CATALOG_ADVANCED_SETTINGS,
  GOTO_COUSE_CATALOG_DESCRIPTION,
  GOTO_COUSE_CATALOG_DESCRIPTION_OVERRIDE,
  GOTO_MANAGE_LEARNER_DASHBOARD_BASIC_SETTINGS,
  GOTO_MANAGE_LEARNER_DASHBOARD_ADVANCED_SETTINGS,
  GOTO_MANAGE_SETTINGS_EMAIL_DELIVERY_BASIC_SETTINGS,
  GOTO_MANAGE_SETTINGS_EMAIL_DELIVERY_ADVANCED_SETTINGS,
} from './iframes.constants';

import {
  CUSTOM_REGISTRATION_FIELD_FORM,
  LMS_DOMAIN_ACTIVATION_FORM_REQUEST,
} from './modals.constants';

import {
  INACTIVE_SUBSCRIPTION,
  INACTIVE_STRATUS_SUBSCRIPTION,
  MISCONFIGURED_SUBSCRIPTION,
} from './subscription.constants';

import {
  MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
  NOT_AVAILABLE,
} from './messages.constants';

export default {
  general_language: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_LANGUAGE,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_LANGUAGE,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_LANGUAGE,
      stratus: GOTO_MANAGE_SETTINGS_LANGUAGE,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Localize the LMS platform in the right language
        </b>
        {' '}
        to give your learners a better experience.
      </>
    ),
    onboardingSubtitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Break the language barriers
        </b>
        {' '}
        <br />
        {' '}
        in your initiative
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Enable multiple languages and give your learners full control with a
            language selector.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>Up to 24 languages to choose from.</p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Translations managed and reviewed by the Open edX community via
            Transifex.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: generalLenguageOnboarding,
  },
  general_lms_domain: {
    static: (
      <div className="p-4">
        <p>
          The steps below are meant for initiatives that are using
          edunext&apos;s Cloud subscriptions powered by Open edX and who already
          upgraded the subscription to a paid version. If you are running Open
          edX differently (for example in your installation) you&apos;ll need to
          follow the Open edX official documentation instead.
        </p>
        <h5 className="my-2">For this guide, we will assume that:</h5>
        <ul>
          <li>You have an active Cloud subscription with edunext.</li>
          <li>
            You already own an internet domain (we will call it
            &quot;youracademy.com&quot;) and have access to the domain admin
            console.
          </li>
          <li>You are familiar with the very basics of DNS configurations.</li>
          <li>
            You have already decided about the specific domain you want to use
            for your learning site, for example, www.youracademy.com or
            courses.youracademy.com
          </li>
        </ul>
        <h5 className="my-2">The basic process will entail 3 steps:</h5>
        <p>
          <span className="fw-bold me-2">1.</span>
          Accessing your domain DNS manager. The place to manage the DNS records
          for a domain is normally in the admin console of the registrar you
          purchased the domain registration from. (for example domain.com,
          godaddy.com, namecheap.com, bluehost.com, google domains, etc). Make
          sure you log in to the admin console given by your specific provider
          and look for the section to manage the DNS records. (It&apos;s often
          called DNS manager, DNS admin, DNS zones manager).
        </p>
        <img src={lmsImage} alt="lms" style={{ maxWidth: '90%' }} />
        <p>
          <span className="fw-bold me-2">2.</span>
          Adding one DNS record to direct the traffic towards edunext servers.
          All you need to do is to add one or two DNS records to your
          configuration, depending on the domain you are choosing:
        </p>
        <p>
          <span className="fw-bold me-2">Option 1.</span>
          A subdomain: courses.youracademy.com
          <br />
          In this case, you need to add a CNAME record to the domain you want
          with the following settings:
        </p>
        <ul>
          <li>
            <span className="fw-bold me-2">Subdomain:</span>
            courses (or whatever subdomain you are choosing)
          </li>
          <li>
            <span className="fw-bold me-2">Value:</span>
            secure-la.edunext.co
          </li>
          <li>
            <span className="fw-bold me-2">Type of record:</span>
            CNAME
          </li>
          <li>
            <span className="fw-bold me-2">TTL</span>
            14400
          </li>
        </ul>
        <p>
          <span className="fw-bold me-2">Option 2.</span>
          The naked domain: youracademy.com
          {' '}
          <br />
          In case you want to have the open edX site in the main domain, we
          recommend creating two DNS records:
        </p>
        <p>
          A. One A record for the naked domain &quot;@&quot; pointing to
          52.38.30.184
        </p>
        <ul>
          <li>
            <span className="fw-bold me-2">Subdomain:</span>
            @
          </li>
          <li>
            <span className="fw-bold me-2">Value:</span>
            52.38.30.184
          </li>
          <li>
            <span className="fw-bold me-2">Type of record:</span>
            A
          </li>
          <li>
            <span className="fw-bold me-2">TTL</span>
            14400
          </li>
        </ul>
        <p>
          B. One CNAME record for www pointing to secure-la.edunext.co. This
          will prevent your users to get lost if they accidentally use
          www.youracademy.com
        </p>
        <ul>
          <li>
            <span className="fw-bold me-2">Subdomain:</span>
            www
          </li>
          <li>
            <span className="fw-bold me-2">Value:</span>
            secure-la.edunext.co
          </li>
          <li>
            <span className="fw-bold me-2">Type of record:</span>
            CNAME
          </li>
          <li>
            <span className="fw-bold me-2">TTL</span>
            14400
          </li>
        </ul>
        <p>
          <span className="fw-bold me-2">3.</span>
          Informing the edunext support team about your configuration. Once
          you&apos;ve made your changes, make sure to inform the edunext support
          team of the domain or subdomain that you configured so that all the
          configurations can be tested and applied to production. You can do so
          by sending a support ticket with this information.
        </p>
        <p>
          Keep in mind that the propagation of the DNS information over the
          internet may take a few hours, so your changes may not be reflected
          immediately in the service, but they will be reflected as soon as the
          propagation is done and the edunext support team receives your
          information and applies the configurations.
        </p>
      </div>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: LMS_DOMAIN_ACTIVATION_FORM_REQUEST(),
      nimbus_basic: LMS_DOMAIN_ACTIVATION_FORM_REQUEST(),
      nimbus_enhanced: LMS_DOMAIN_ACTIVATION_FORM_REQUEST(),
      nimbus_advanced: LMS_DOMAIN_ACTIVATION_FORM_REQUEST(),
      on_premise: LMS_DOMAIN_ACTIVATION_FORM_REQUEST(),
      cirrus: LMS_DOMAIN_ACTIVATION_FORM_REQUEST(),
      cirrus_sn: LMS_DOMAIN_ACTIVATION_FORM_REQUEST(),
      cirrus_se: LMS_DOMAIN_ACTIVATION_FORM_REQUEST(),
      stratus: LMS_DOMAIN_ACTIVATION_FORM_REQUEST(),
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Publish the LMS site in
        <b className={Styles.onboardingTitleBlue}>your organization domain.</b>
      </>
    ),
    onboardingSubtitle: (
      <>
        Your own domain and SSL
        <br />
        {' '}
        encryption
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>Increased reputation, brand presence and trustworthiness</p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            End to end encryption either with the SSL provided by edunext or
            with your own SSL certificate.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>Just set the DNS records and let edunext handle the rest.</p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: lmsDomainAndSSLGif,
  },
  general_ssl_encryption: {
    static: (
      <div className="p-4">
        <p>
          If you have already purchased an SSL certificate for all their online
          services and do not wish to use the SSL certificate that edunext
          provides free of charge, you can also coordinate with the edunext
          support team as explained as follows. If you don&apos;t have your SSL
          certificate you may simply ignore this section and your site will
          still be delivered securely with HTTPS encryption.
        </p>
        <img src={sslImage} alt="ssl" style={{ maxWidth: '100%' }} />
        <p>
          In case you want to use your own SSL certificate, the procedure will
          be:
        </p>
        <p>
          Request via support ticket the creation of a CSR file (This is a
          certificate signing Request file – xxx.csr) for the type of SSL
          certificate that you plan to use. Once you receive the xxx.csr file,
          proceed to sign the certificate and send it back to the edunext
          support team. The edunext team will take care of the configurations.
        </p>
        <p>
          Whenever your SSL certificate is close to expiration, you should renew
          it and restart the procedure with edunext support for the renewed
          version to be configured in your LMS site.
        </p>
        <p>Do you want to request to add your own SSL certificate?</p>
      </div>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: LMS_DOMAIN_ACTIVATION_FORM_REQUEST(
        'Request custom SSL certificate activation',
      ),
      nimbus_basic: LMS_DOMAIN_ACTIVATION_FORM_REQUEST(
        'Request custom SSL certificate activation',
      ),
      nimbus_enhanced: LMS_DOMAIN_ACTIVATION_FORM_REQUEST(
        'Request custom SSL certificate activation',
      ),
      nimbus_advanced: LMS_DOMAIN_ACTIVATION_FORM_REQUEST(
        'Request custom SSL certificate activation',
      ),
      on_premise: LMS_DOMAIN_ACTIVATION_FORM_REQUEST(
        'Request custom SSL certificate activation',
      ),
      cirrus: LMS_DOMAIN_ACTIVATION_FORM_REQUEST(
        'Request custom SSL certificate activation',
      ),
      cirrus_sn: LMS_DOMAIN_ACTIVATION_FORM_REQUEST(
        'Request custom SSL certificate activation',
      ),
      cirrus_se: LMS_DOMAIN_ACTIVATION_FORM_REQUEST(
        'Request custom SSL certificate activation',
      ),
      stratus: LMS_DOMAIN_ACTIVATION_FORM_REQUEST(
        'Request custom SSL certificate activation',
      ),
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  general_support_info: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: GOTO_MANAGE_SETTINGS_SUPPORT,
      nimbus_basic: GOTO_MANAGE_SETTINGS_SUPPORT,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_SUPPORT,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_SUPPORT,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_SUPPORT,
      stratus: GOTO_MANAGE_SETTINGS_SUPPORT,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <b className={Styles.onboardingTitleBlue}>
        The tools to give your learners all the support they need
      </b>
    ),
    onboardingSubtitle: <b>Are you ready to start setting up?</b>,
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>Provide a channel for your users to reach you if needed.</p>
        </OnboardingBox>
        <OnboardingBox>
          <p>Give them support tools and resources.</p>
        </OnboardingBox>
        <OnboardingBox>
          <p>Integrate your existing support or service desk tools.</p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: general,
  },
  general_look_and_feel_brand: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: GOTO_MANAGE_SETTINGS_LOOK_AND_FEEL_BRAND,
      nimbus_basic: GOTO_MANAGE_SETTINGS_LOOK_AND_FEEL_BRAND,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_LOOK_AND_FEEL_BRAND,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_LOOK_AND_FEEL_BRAND,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_LOOK_AND_FEEL_BRAND,
      stratus: GOTO_MANAGE_SETTINGS_LOOK_AND_FEEL_BRAND,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Make
        <b className={Styles.onboardingTitleBlue}>your brand stand out</b>
        {' '}
        as
        part of your initiative.
      </>
    ),
    onboardingSubtitle: (
      <>
        Manage the details of your
        <br />
        {' '}
        brand presence in the site.
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>Give your intiative a name and make it memorable.</p>
        </OnboardingBox>
        <OnboardingBox>
          <b>Add your logo and icons to give that personalized touch.</b>
        </OnboardingBox>
        <OnboardingBox>
          <b>
            Set the color palette to achieve brand consistency across the site.
          </b>
        </OnboardingBox>
      </>
    ),
  },
  general_look_and_feel_header: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: GOTO_MANAGE_SETTINGS_LOOK_AND_HEADER,
      nimbus_basic: GOTO_MANAGE_SETTINGS_LOOK_AND_HEADER,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_LOOK_AND_HEADER,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_LOOK_AND_HEADER,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_LOOK_AND_HEADER,
      stratus: GOTO_MANAGE_SETTINGS_LOOK_AND_HEADER,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        The
        <b className={Styles.onboardingTitleBlue}>
          header will be the first view of the user
        </b>
        {' '}
        on your website and is presented on all your pages
      </>
    ),
    onboardingSubtitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>Make your user navigate</b>
        {' '}
        <br />
        easily in your header
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            <b>Add the links</b>
            {' '}
            that you consider necessary for your menu and
            facilitate the navigation of your static pages.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>Customize the colors of your header and aligned to your brand.</p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Do you need to communicate something super important? Do it with
            <b>custom header top</b>
            {' '}
            section with HTML.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: headerGif,
  },
  general_look_and_feel_footer_basic_settings: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: GOTO_MANAGE_SETTINGS_LOOK_AND_FOOTER_BASIC_SETTINGS,
      nimbus_basic: GOTO_MANAGE_SETTINGS_LOOK_AND_FOOTER_BASIC_SETTINGS,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_LOOK_AND_FOOTER_BASIC_SETTINGS,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_LOOK_AND_FOOTER_BASIC_SETTINGS,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_LOOK_AND_FOOTER_BASIC_SETTINGS,
      stratus: GOTO_MANAGE_SETTINGS_LOOK_AND_FOOTER_BASIC_SETTINGS,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        The footer is the last thing your user will see,
        <b className={Styles.onboardingTitleBlue}>
          but it is not the least important!
        </b>
      </>
    ),
    onboardingSubtitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Highlights the elements that have not had the leading role
        </b>
        {' '}
        <br />
        {' '}
        throughout the web
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Customize your footer by adding your brand colors, a logo and links
            to sections that are important to your site
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Add a description and the links of your social networks,
            <b>make your users follow your steps in different digital media.</b>
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            <b>Customize the color of the logos</b>
            {' '}
            of edunext and OpenEdx so
            that your graphic line is consistent.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: footerGif,
  },
  general_look_and_feel_footer_advanced_settings: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_LOOK_AND_FOOTER_ADVANCED_SETTINGS,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_LOOK_AND_FOOTER_ADVANCED_SETTINGS,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_LOOK_AND_FOOTER_ADVANCED_SETTINGS,
      stratus: GOTO_MANAGE_SETTINGS_LOOK_AND_FOOTER_ADVANCED_SETTINGS,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        The footer is more than a logo a couple of links.
        <b className={Styles.onboardingTitleBlue}>
          Set to 100% for a better user experience
        </b>
      </>
    ),
    onboardingSubtitle: (
      <>
        Create a complete, intuitive and
        <br />
        <b className={Styles.onboardingTitleBlue}>
          valuable footer for your users
        </b>
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Add
            <b>Alternative Text</b>
            {' '}
            Allows search engines to
            <b>better crawl and rank your website.</b>
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            <b>Customize the links</b>
            {' '}
            that redirect your users when clicking on
            the logos
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            <b>Create a footer</b>
            {' '}
            from scratch with HTML in the style of your
            initiative.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: footerAdvanced,
  },
  general_look_and_feel_custom_styles: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: NOT_AVAILABLE.nimbus_enhanced,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_LOOK_AND_CUSTOM_STYLES,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_LOOK_AND_CUSTOM_STYLES,
      stratus: GOTO_MANAGE_SETTINGS_LOOK_AND_CUSTOM_STYLES,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Customize the style and interactions of your site with
        <b className={Styles.onboardingTitleBlue}>css and JavaScript</b>
      </>
    ),
    onboardingSubtitle: (
      <>
        Develop out of the box and
        <br />
        {' '}
        <b className={Styles.onboardingTitleBlue}>
          create a unique browsing experience
        </b>
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            If you have a frontend development team it will be much easier for
            you to initiate these customizations
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Change the typography, sizes and elements of your entire site with
            CSS.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Javascript will allow you to create a few things like dynamically
            updating content and animating images.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: customStyles,
  },
  general_look_and_feel_cms_integration: {
    static: NOT_AVAILABLE.goTo(
      '../../integrations/website',
      <>
        <b className={Styles.bannerRedirectBoldColor}>
          Start integrating and configuring
        </b>
        <span> a CMS to your site</span>
      </>,
    ),
    onboardingTitle: (
      <>
        A CMS is a system that allows you to launch and
        <b className={Styles.onboardingTitleBlue}>
          manage a website easily and quickly
        </b>
      </>
    ),
    onboardingSubtitle: (
      <>
        Create, organize, publish and delete content
        <br />
        {' '}
        <b className={Styles.onboardingTitleBlue}>
          from your LMS site with a CMS
        </b>
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            With a CMS you
            <b>can install plugins and extensions</b>
            {' '}
            to increase the functions
            of the site quickly.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            You have the freedom to
            <b>use the site templates</b>
            {' '}
            offered by the CMS of your choice.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            CMS usually cover needs for blogs, pages with structured content and
            user management.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: cmsGif,
  },
  home_page_basic: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: GOTO_MANAGE_SETTINGS_HOMEPAGE_BASIC,
      nimbus_basic: GOTO_MANAGE_SETTINGS_HOMEPAGE_BASIC,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_HOMEPAGE_BASIC,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_HOMEPAGE_BASIC,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_HOMEPAGE_BASIC,
      stratus: GOTO_MANAGE_SETTINGS_HOMEPAGE_BASIC,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>Captivate your visitors</b>
        {' '}
        with your best possible homepage!
      </>
    ),
    onboardingSubtitle: (
      <>
        The homepage is the main entry point to your site
        <br />
        {' '}
        and often has the highest number of views
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Personalize your homepage with a high impact image banner and title.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Deliver your message and increase conversions with the different
            sections of the homepage.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>Customize the colors for the different homepage sections.</p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: homePage,
  },
  home_page_advanced_settings: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_HOMEPAGE_ADVANCED,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_HOMEPAGE_ADVANCED,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_HOMEPAGE_ADVANCED,
      stratus: GOTO_MANAGE_SETTINGS_HOMEPAGE_ADVANCED,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>Enhance your homepage</b>
        {' '}
        further with custom content
      </>
    ),
    onboardingSubtitle: (
      <b className={Styles.onboardingTitleBlue}>Make your homepage stand out</b>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Override the default homepage banner with your own HTML piece and
            highlight your site looks.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>Showcase your partner network to create trust and relevance.</p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Customize any of the homepage sections with your own HTML content.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: homePageAdvanced,
  },
  course_catalog_basic_settings: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: GOTO_COUSE_CATALOG_BASIC_SETTINGS,
      nimbus_basic: GOTO_COUSE_CATALOG_BASIC_SETTINGS,
      nimbus_enhanced: GOTO_COUSE_CATALOG_BASIC_SETTINGS,
      nimbus_advanced: GOTO_COUSE_CATALOG_BASIC_SETTINGS,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_COUSE_CATALOG_BASIC_SETTINGS,
      stratus: GOTO_COUSE_CATALOG_BASIC_SETTINGS,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        The course catalog is the page where all
        <b className={Styles.onboardingTitleBlue}>
          your available courses are listed
        </b>
      </>
    ),
    onboardingSubtitle: (
      <>
        Showcase the best of your
        <br />
        {' '}
        course offering
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Personalize your Course catalogue page with a banner image that
            attracts attention.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Change the colors and create titles and paragraphs that connect your
            users with the course catalog.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>Configure the properties of the course catalog.</p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: courseCatalogB,
  },
  course_catalog_advanced_settings: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: GOTO_COUSE_CATALOG_ADVANCED_SETTINGS,
      nimbus_advanced: GOTO_COUSE_CATALOG_ADVANCED_SETTINGS,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_COUSE_CATALOG_ADVANCED_SETTINGS,
      stratus: GOTO_COUSE_CATALOG_ADVANCED_SETTINGS,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Power up your course catalogue
        </b>
        {' '}
        page with advanced settings
      </>
    ),
    onboardingSubtitle: (
      <>
        Enhance your catalog further
        <br />
        <b className={Styles.onboardingTitleBlue}>with custom content</b>
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Override the default catalog banner with your own HTML piece and
            highlight your value proposition.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>Showcase your partner network to create trust and relevance.</p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Customize any of the catalog sections with your own HTML content.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: courseCatalogA,
  },
  course_catalog_description: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: GOTO_COUSE_CATALOG_DESCRIPTION,
      nimbus_basic: GOTO_COUSE_CATALOG_DESCRIPTION,
      nimbus_enhanced: GOTO_COUSE_CATALOG_DESCRIPTION,
      nimbus_advanced: GOTO_COUSE_CATALOG_DESCRIPTION,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_COUSE_CATALOG_DESCRIPTION,
      stratus: GOTO_COUSE_CATALOG_DESCRIPTION,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>Configure the basics</b>
        {' '}
        of
        your course description page template
      </>
    ),
    onboardingSubtitle: (
      <>
        Each course will have a course
        <br />
        {' '}
        description page that uses this template
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>Add a banner image applicable for all courses.</p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Enter additional content in different sections of the course
            description template.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>Configure social sharing options for this pages.</p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: courseDescriptionB,
  },
  course_catalog_description_override: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: GOTO_COUSE_CATALOG_DESCRIPTION_OVERRIDE,
      nimbus_advanced: GOTO_COUSE_CATALOG_DESCRIPTION_OVERRIDE,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_COUSE_CATALOG_DESCRIPTION_OVERRIDE,
      stratus: GOTO_COUSE_CATALOG_DESCRIPTION_OVERRIDE,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Take your course descriptions to the next level by
        <b className={Styles.onboardingTitleBlue}>adding HTML</b>
      </>
    ),
    onboardingSubtitle: <b>Create a unique course description page template</b>,
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Override the default banner with your own HTML piece and highlight
            your value proposition.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>Customize any of the sections with your own HTML content.</p>
        </OnboardingBox>
        <OnboardingBox>
          <p>Give your course page template a unique touch.</p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: courseDescriptionA,
  },
  learner_dashboard_basic_settings: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: GOTO_MANAGE_LEARNER_DASHBOARD_BASIC_SETTINGS,
      nimbus_basic: GOTO_MANAGE_LEARNER_DASHBOARD_BASIC_SETTINGS,
      nimbus_enhanced: GOTO_MANAGE_LEARNER_DASHBOARD_BASIC_SETTINGS,
      nimbus_advanced: GOTO_MANAGE_LEARNER_DASHBOARD_BASIC_SETTINGS,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_LEARNER_DASHBOARD_BASIC_SETTINGS,
      stratus: GOTO_MANAGE_LEARNER_DASHBOARD_BASIC_SETTINGS,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        This is the section where your learners will view of
        <b className={Styles.onboardingTitleBlue}>
          the courses they are enrolled in
        </b>
      </>
    ),
    onboardingSubtitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Improve your students&apos; experience
        </b>
        <br />
        on the learner dashboard
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Configure your students&apos; banner, texts and colors for a
            <b>personalized experience.</b>
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            <b>Add additional content</b>
            {' '}
            so your students don&apos;t miss a
            thing by presenting them with the full information.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Make it easy for your students to search for courses by activating
            or deactivating
            <b>the search option on their dashboard.</b>
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: learnerDashboardBasic,
  },
  learner_dashboard_advanced_settings: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: GOTO_MANAGE_LEARNER_DASHBOARD_ADVANCED_SETTINGS,
      nimbus_advanced: GOTO_MANAGE_LEARNER_DASHBOARD_ADVANCED_SETTINGS,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_LEARNER_DASHBOARD_ADVANCED_SETTINGS,
      stratus: GOTO_MANAGE_LEARNER_DASHBOARD_ADVANCED_SETTINGS,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Power the learner dashboard page
        </b>
        {' '}
        with advanced settings
      </>
    ),
    onboardingSubtitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Take the dashboard learner to another level
        </b>
        {' '}
        <br />
        to increase usability.
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            <b>Configure social sharing options</b>
            {' '}
            for your students and let
            more people know about your courses.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Override the basic content sections and create new custom content
            with HTML.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>Showcase your partner network to create trust and relevance.</p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: learnerDashboardAdvanced,
  },
  info_pages_about: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_ABOUT,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_ABOUT,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_ABOUT,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_ABOUT,
      stratus: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_ABOUT,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Build trust by explaining
        <b className={Styles.onboardingTitleBlue}>
          your story, mission and all about your initiative
        </b>
      </>
    ),
    onboardingSubtitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Your turn to tell your visitors everything
        </b>
        {' '}
        <br />
        they need to know about the initiative.
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>Build your about us page creatively with HTML.</p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Alternatively you can redirect incoming trafic for this page to an
            existing url.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            This page can be then linked in any of your site&apos;s navigation
            menus.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: infoPageAboutUs,
  },
  info_pages_contact: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_CONTACT,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_CONTACT,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_CONTACT,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_CONTACT,
      stratus: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_CONTACT,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Tell your users why, when and how they should or should not
        <b className={Styles.onboardingTitleBlue}>contact you</b>
      </>
    ),
    onboardingSubtitle: (
      <>
        Make your contact page a way to
        <br />
        <b className={Styles.onboardingTitleBlue}>
          create conexions with current and potential users.
        </b>
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>Build your contact page creatively with HTML.</p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Alternatively you can redirect incoming trafic for this page to an
            existing url.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            This page can be then linked in any of your site&apos;s navigation
            menus.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: contactUs,
  },
  info_pages_faq: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_FAQ,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_FAQ,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_FAQ,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_FAQ,
      stratus: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_FAQ,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>Help and guide your users </b>
        with a good FAQ page
      </>
    ),
    onboardingSubtitle: (
      <>
        A good resource to clarify information
        <br />
        <b className={Styles.onboardingTitleBlue}>
          and minimize customer inquiries.
        </b>
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Build your faq page creatively with HTML.
            <b>constant connection.</b>
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Alternatively you can redirect incoming trafic for this page to an
            existing url.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            This page can be then linked in any of your site&apos;s navigation
            menus.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: faq,
  },
  info_pages_honor: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_HONOR,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_HONOR,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_HONOR,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_HONOR,
      stratus: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_HONOR,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Accentuate the
        <b className={Styles.onboardingTitleBlue}>
          values and codes of conduct that
        </b>
        {' '}
        your users should have on your LMS site
      </>
    ),
    onboardingSubtitle: (
      <>
        Disseminate the guidelines that will maintain
        <b className={Styles.onboardingTitleBlue}>
          <br />
          {' '}
          good conduct on your site
        </b>
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Build your honor page with HTML. Be explicit about the behavior you
            expect your users to maintain when taking your courses.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Alternatively you can redirect incoming trafic for this page to an
            existing url.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            This page can be then linked in any of your site&apos;s navigation
            menus.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: honor,
  },
  info_pages_privacy: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_PRIVACY,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_PRIVACY,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_PRIVACY,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_PRIVACY,
      stratus: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_PRIVACY,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Inform your users about the collection and
        <b className={Styles.onboardingTitleBlue}>
          protection of their data and personal information
        </b>
      </>
    ),
    onboardingSubtitle: (
      <>
        Provide your visitor
        <b className={Styles.onboardingTitleBlue}>
          security and confidence
        </b>
        {' '}
        when
        <br />
        entering their personal information
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Build your Privacy page with HTML. Make sure to comply with
            applicable data protection regulations.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Alternatively you can redirect incoming trafic for this page to an
            existing url.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            This page can be then linked in any of your site&apos;s navigation
            menus.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: infoPagePrivacy,
  },
  info_pages_tos: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_TOS,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_TOS,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_TOS,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_TOS,
      stratus: GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_TOS,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Describes the legal or technical details
        </b>
        of the relationship between you and your users
      </>
    ),
    onboardingSubtitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Provide your visitor clarity and align expectations
        </b>
        {' '}
        <br />
        {' '}
        about the service they are signing up for
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Build your Terms of service with HTML. Make sure to comply with any
            applicable regulations.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Alternatively you can redirect incoming trafic for this page to an
            existing url.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            This page can be then linked in any of your site&apos;s navigation
            menus.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: tos,
  },
  email_delivery_basic_settings: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: GOTO_MANAGE_SETTINGS_EMAIL_DELIVERY_BASIC_SETTINGS,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_EMAIL_DELIVERY_BASIC_SETTINGS,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_EMAIL_DELIVERY_BASIC_SETTINGS,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_EMAIL_DELIVERY_BASIC_SETTINGS,
      stratus: GOTO_MANAGE_SETTINGS_EMAIL_DELIVERY_BASIC_SETTINGS,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Set your emails to be branded
        </b>
        {' '}
        and to communicate effectively
      </>
    ),
    onboardingSubtitle: <></>,
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            The platform sends automated email upon registration, when learners
            need to recover their passwords or change their email address.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Course authors can send bulk emails to the learners enrolled in a
            course.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>Simple and effective email delivery.</p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: emailDeliveryBasic,
  },
  email_delivery_advanced_settings: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_free,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_EMAIL_DELIVERY_ADVANCED_SETTINGS,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_EMAIL_DELIVERY_ADVANCED_SETTINGS,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_EMAIL_DELIVERY_ADVANCED_SETTINGS,
      stratus: GOTO_MANAGE_SETTINGS_EMAIL_DELIVERY_ADVANCED_SETTINGS,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>Advanced options</b>
        {' '}
        for your
        emails branding and deliverability
      </>
    ),
    onboardingSubtitle: <></>,
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Provide extra clarity and compliance with customized email footers
            in all the communications sent from the platform.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Advanced email delivery configurations can be made with the
            activation of SPK and DKIM authentication.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            This functionality is limited to cloud based Nimbus Advanced
            subscriptions.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: emailDeliveryAdvancedSettings,
  },
  site_configuration_registration_and_login_login: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_REGISTERLOGIN,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_REGISTERLOGIN,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_REGISTERLOGIN,
      stratus: GOTO_MANAGE_SETTINGS_REGISTERLOGIN,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Set up an easy login experience
        </b>
        {' '}
        for your learners
      </>
    ),
    onboardingSubtitle: <>Make it easy for your users to log in!</>,
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>Configure the setting for the login experience.</p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Customize the content that will help your users navigate the login
            page.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>Add additional instructions to your login page if necessary.</p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: registrationSettings,
  },
  site_configuration_registration_and_login_register: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_REGISTERLOGINREGISTER,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_REGISTERLOGINREGISTER,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_REGISTERLOGINREGISTER,
      stratus: GOTO_MANAGE_SETTINGS_REGISTERLOGINREGISTER,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Facilitate the registration
        </b>
        {' '}
        process of your users with a friendly registration page
      </>
    ),
    onboardingSubtitle: (
      <>
        This is the first step to become a user in your site.
        <br />
        {' '}
        <b className={Styles.onboardingTitleBlue}>
          Make it easy and efficient!
        </b>
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Configure restrictions in the registration by email address domains.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Configure the standard fields in the registration form according to
            your needs or remove the ones you won&apos;t need.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Customize the additional content that will help your users navigate
            the registration page.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: registrationLogin,
  },
  site_configuration_registration_and_login_custom_fields: {
    static: (
      <div className="p-4">
        <p>
          For very special cases, there is the posibility to have additional
          fields added to the registration form to collect key information in
          the learner profile.
        </p>
        <p>
          Before requesting the addition of new fields to the registration form,
          it&apos;s important to note this is a limited capability and there
          will be certain restrictions:
        </p>
        <ul>
          <li>
            The new fields will collect information that will be stored in the
            database, but will not appear in the Platform&apos;s native reports
            or analytics dashboards.
          </li>
          <li>
            Learners will be able to view and modify the new fields from the
            &apos;My account&apos; page with a simple interface.
          </li>
        </ul>
        <h5>
          Note: The fields can only be presented in the three following ways
        </h5>
        <ol>
          <li>Text-input field</li>
          <li>Checkbox field</li>
          <li>Dropdown-select field</li>
        </ol>
      </div>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: NOT_AVAILABLE.nimbus_enhanced,
      nimbus_advanced: CUSTOM_REGISTRATION_FIELD_FORM,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: CUSTOM_REGISTRATION_FIELD_FORM,
      stratus: CUSTOM_REGISTRATION_FIELD_FORM,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Add custom fields to your registration form to
        <b className={Styles.onboardingTitleBlue}>
          collect relevant information for your learner profiles
        </b>
      </>
    ),
    onboardingSubtitle: (
      <>
        Try to only collect data you will use
        <br />
        <b className={Styles.onboardingTitleBlue}>
          to improve the learner experience on the site
        </b>
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Specify your requirements in detail and let the edunext team handle
            the nuts and bolts of the configurations.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            For each additional field, make sure you provide all the required
            instructions and restrictions to be implemented in the form.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Display the required disclaimers to comply with any applicable data
            privacy regulation.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: registrationCustomFields,
  },
  site_configuration_registration_and_login_third_party_auth: {
    static: NOT_AVAILABLE.goTo(
      '../../integrations/third-party-auth',
      <>
        <b className={Styles.bannerRedirectBoldColor}>Choose and start</b>
        <span>
          {' '}
          configuring the provider options that we offer you for your SSO
        </span>
      </>,
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: NOT_AVAILABLE.nimbus_basic,
      nimbus_advanced: NOT_AVAILABLE.nimbus_basic,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: NOT_AVAILABLE.nimbus_basic,
      stratus: NOT_AVAILABLE.nimbus_basic,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Leverage Third party auth integration to
        <b className={Styles.onboardingTitleBlue}>
          make registration and login as easy as one click
        </b>
      </>
    ),
    onboardingSubtitle: (
      <>
        Facilitate access and enable
        <br />
        {' '}
        more control
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Provide learners with the option to register and login using an
            external service.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Widely used services like google, microsoft, Linkedin and facebook
            are supported.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Corporate identity providers can also be configured via SAML or
            Oauth 2.0 + OIDC.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: registrationThirdPartyAuth,
  },
};
