import React from 'react';
import { Modal, Tag } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import CardItem from '../../Cards/Card/CardItem';
import { AttributeContext } from '../../Context/AttributeContext';

import Styles from './AttributeModalWarning.module.scss';

const AttributeModalWarning = () => {
  const [{
    handleOk, modalStatus, attributeState, handleCancel,
  }] = AttributeContext();

  const isModalOpen = attributeState.modalStatus === modalStatus.deleting;

  return (
    <Modal
      title="Are you sure to delete this attribute?"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Yes, delete."
    >
      <div className={Styles.modalContentWrapper}>
        <div className={Styles.cardItemShield} />
        <CardItem attribute={attributeState.currentAttribute} />
        <p style={{ marginTop: 10 }}>
          <WarningOutlined style={{ color: '#FFDB00' }} />
          {' '}
          This action
          {' '}
          <Tag color="red">can not</Tag>
          be undone.
        </p>
      </div>
    </Modal>
  );
};

export default AttributeModalWarning;
