import React from 'react';
import ReactQuill from 'react-quill';
import { Modal } from 'react-bootstrap';

import Editable from '../../editable/Editable';
import LabeledText from '../../labeledText/LabeledText';
import CustomButton from '../../customButton/CustomButton';
import { getErrorByFieldName } from '../../../helpers/utils';
import { NotificationContext } from '../Provider/NotificationProvider';

const NotificationModal = () => {
  const {
    apiErrors,
    setApiErrors,
    htmlMessage,
    notification,
    SELECT_OPTIONS,
    setHtmlMessage,
    setNotification,
    handleInputChange,
    createNotification,
    updateNotification,
    NOTIFICATION_DATA_INITIAL_STATE,
  } = NotificationContext();

  return (
    <Modal
      show={notification.isOpen}
      onHide={() => {
        setNotification(NOTIFICATION_DATA_INITIAL_STATE);
        setApiErrors({});
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter">
          {
            notification.status === 'creating'
              ? 'New notification'
              : 'Edit notification'
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LabeledText title="Title">
          <Editable
            type="text"
            nameField="title"
            classPointer="w-100"
            content={notification.data.title}
            onChangeValue={handleInputChange}
            isNew={notification.status === 'creating'}
            errorMessage={notification.status === 'creating' || notification.status === 'editing' ? getErrorByFieldName(apiErrors, 'title') : null}
            showEditMessage={false}
            showLoadingBar={false}
            block={false}
          />
        </LabeledText>
        <div className="d-flex align-items-center mt-2">
          <LabeledText title="Valid from">
            <Editable
              type="datetime"
              nameField="validFrom"
              content={notification.data.validFrom}
              onChangeValue={handleInputChange}
              isNew={notification.status === 'creating'}
              errorMessage={notification.status === 'creating' || notification.status === 'editing' ? getErrorByFieldName(apiErrors, 'valid_from') : null}
              showEditMessage={false}
              showLoadingBar={false}
              block={false}
            />
          </LabeledText>
          <LabeledText title="Valid To" classWrapper="ms-3">
            <Editable
              type="datetime"
              nameField="validTo"
              content={notification.data.validTo}
              onChangeValue={handleInputChange}
              isNew={notification.status === 'creating'}
              errorMessage={notification.status === 'creating' || notification.status === 'editing' ? getErrorByFieldName(apiErrors, 'valid_to') : null}
              showEditMessage={false}
              showLoadingBar={false}
              block={false}
            />
          </LabeledText>
        </div>
        <div className="d-flex align-items-center mt-2">
          <LabeledText title="Text format">
            <Editable
              type="select"
              nameField="textFormat"
              options={SELECT_OPTIONS}
              content={notification.data.textFormat}
              onChangeValue={handleInputChange}
              isNew={notification.status === 'creating'}
              errorMessage={null}
              showEditMessage={false}
              showLoadingBar={false}
              block={false}
            />
          </LabeledText>
          <LabeledText title="Full Screen mode" classWrapper="ms-4">
            <Editable
              type="checkbox"
              nameField="fullScreenMode"
              content={notification.data.fullScreenMode}
              onChangeValue={handleInputChange}
              isNew={notification.status === 'creating'}
              errorMessage={null}
              showEditMessage={false}
              showLoadingBar={false}
              block={false}
            />
          </LabeledText>
        </div>
        {
          notification.data.textFormat === 'plain' && (
            <LabeledText title="Message" classWrapper="mt-3">
              <Editable
                type="text"
                nameField="message"
                classPointer="w-100"
                content={notification.data.message}
                onChangeValue={handleInputChange}
                isNew={notification.status === 'creating'}
                errorMessage={null}
                showEditMessage={false}
                showLoadingBar={false}
                block={false}
              />
            </LabeledText>
          )
        }
        {
          notification.data.textFormat === 'html' && (
            <>
              <i className="mt-5">
                Note:
                In case to need to paste custom HTML you must use
                &ldquo;Custom HTML&rdquo; option,
                <b className="ms-1">otherwise the content will not rendered properly.</b>
              </i>
              <ReactQuill
                theme="snow"
                className="mt-3"
                value={htmlMessage}
                onChange={setHtmlMessage}
              />
            </>
          )
        }
        {
          notification.data.textFormat === 'custom' && (
            <LabeledText title="Message" classWrapper="mt-3">
              <Editable
                type="textarea"
                nameField="message"
                classPointer="w-100"
                content={notification.data.message}
                onChangeValue={handleInputChange}
                isNew={notification.status === 'creating'}
                errorMessage={null}
                showEditMessage={false}
                showLoadingBar={false}
                block={false}
              />
            </LabeledText>
          )
        }
        {
          ('message' in apiErrors) && (
            <p className="text-danger">{getErrorByFieldName(apiErrors, 'message')}</p>
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          onClick={() => {
            if (notification.status === 'creating') return createNotification();
            return updateNotification();
          }}
          disabled={notification.data.title === ''}
        >
          {
            notification.status === 'creating'
              ? 'Publish'
              : 'Edit'
          }
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

export default NotificationModal;
