import { useEffect, useState } from 'react';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { hotjar } from 'react-hotjar';

import {
  UPDATE_CURRENT_PRODUCTID,
  UPDATE_CURRENT_PLATFORMID,
  UPDATE_CURRENT_ACCOUNTID,
  UPDATE_CURRENT_SITEID,
} from '../views/accountsView/account/AccountReducer';
import { AccountContext } from '../views/accountsView/account/AccountContext';

export const LOCAL_STORAGE_ACCORDION_ID = 'eccAccordionChoices';

export const useMySiteSectionUrl = () => {
  let { url } = useRouteMatch();
  url = url.slice(-1) === '/' ? url.slice(0, -1) : url;

  return [url];
};

export const useDocTitle = (title) => {
  const [doctitle, setDocTitle] = useState(title);

  useEffect(() => {
    if (doctitle) {
      document.title = `${doctitle} | Edunext Control Center`;
    } else {
      document.title = 'Edunext Control Center';
    }
  }, [doctitle]);

  return [doctitle, setDocTitle];
};

export const useUrlSearchParams = (initQueryParams = '') => {
  const location = useLocation();
  const history = useHistory();
  const [urlSearchParams, setUrlSearchParams] = useState(initQueryParams);

  useEffect(() => {
    setUrlSearchParams(new URLSearchParams(location.search));
  }, [location.search]);

  const setUrlSearchParamsHistory = (state) => {
    setUrlSearchParams(state);
    history.replace({
      search: state.toString(),
    });
  };

  return [urlSearchParams, setUrlSearchParams, setUrlSearchParamsHistory];
};

export const useModalSearchParams = (key, initialShow = false) => {
  const [show, setShow] = useState(initialShow);

  const [urlSearchParams, , setUrlSearchParamsHistory] = useUrlSearchParams();

  useEffect(() => {
    if (urlSearchParams && urlSearchParams.get(key) === 'true') {
      setShow(true);
    }
  }, [urlSearchParams]);

  const handleHide = () => {
    setShow(false);
    urlSearchParams.delete(key);
    setUrlSearchParamsHistory(urlSearchParams);
  };

  return [{
    show,
    setShow,
    handleHide,
  }];
};

export const useHotJar = () => {
  useEffect(() => {
    hotjar.initialize(3037277, 6);
  }, []);

  return hotjar;
};

export const useParamsToState = () => {
  const {
    accountId, productId, platformId, siteId,
  } = useParams();
  const [, accountDispatch] = AccountContext();

  useEffect(() => {
    accountDispatch({
      type: UPDATE_CURRENT_ACCOUNTID,
      data: accountId,
    });
  }, [accountId]);

  useEffect(() => {
    accountDispatch({ type: UPDATE_CURRENT_PRODUCTID, data: productId });
  }, [productId]);

  useEffect(() => {
    accountDispatch({ type: UPDATE_CURRENT_PLATFORMID, data: platformId });
  }, [platformId]);

  useEffect(() => {
    accountDispatch({ type: UPDATE_CURRENT_SITEID, data: siteId });
  }, [siteId]);
};

export const useAccordionLocalStorage = (accordionId = null) => {
  const [accordionChoices, setAccordionChoices] = useState(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_ACCORDION_ID)) || {},
  );

  useEffect(() => {
    if (
      accordionId !== null
      && !localStorage.getItem(LOCAL_STORAGE_ACCORDION_ID)
    ) {
      const STATE = { ...accordionChoices, [accordionId]: '1' };

      setAccordionChoices(STATE);
      localStorage.setItem(LOCAL_STORAGE_ACCORDION_ID, JSON.stringify(STATE));
    }
  }, []);

  const accordionChoiceHandler = () => {
    if (accordionId) {
      setAccordionChoices((prevState) => {
        if (accordionId in accordionChoices === false) {
          const STATE = {
            ...prevState,
            [accordionId]: '-',
          };

          localStorage.setItem(
            LOCAL_STORAGE_ACCORDION_ID,
            JSON.stringify(STATE),
          );
          return STATE;
        }

        /**
         * 1 means: Open the first element in the accordion elements.
         * @see https://react-bootstrap.github.io/components/accordion/#api
         */
        const STATE = {
          ...prevState,
          [accordionId]: accordionChoices[accordionId] === '1' ? '-' : '1',
        };

        localStorage.setItem(LOCAL_STORAGE_ACCORDION_ID, JSON.stringify(STATE));
        return STATE;
      });
    }
  };

  return [accordionChoices, accordionChoiceHandler];
};

export const useUrlSearchParamsReactive = (
  key,
  initialState = null,
  jsonParse = false,
) => {
  const location = useLocation();
  const [stateReactive, setStateReactive] = useState(() => {
    const rootURLSearchParams = new URLSearchParams(location.search);
    if (rootURLSearchParams.get(key)) {
      if (jsonParse) {
        return JSON.parse(decodeURIComponent(rootURLSearchParams.get(key)));
      }

      return rootURLSearchParams.get(key);
    }

    return initialState;
  });
  const [urlSearchParams, , setUrlSearchParamsHistory] = useUrlSearchParams();

  useEffect(() => {
    if (urlSearchParams) {
      if (urlSearchParams.get(key)) {
        urlSearchParams.delete(key);
      }
      if (
        stateReactive
        && stateReactive.length > 0
        && Object.keys(stateReactive[0]).length > 0
      ) {
        if (jsonParse) {
          urlSearchParams.append(
            key,
            encodeURIComponent(JSON.stringify(stateReactive)),
          );
        } else {
          urlSearchParams.append(key, stateReactive);
        }
      }
      setUrlSearchParamsHistory(urlSearchParams);
    }
  }, [stateReactive]);

  return [stateReactive, setStateReactive];
};
