export const SET_ATTRIBUTES = 'setAttributes';
export const SET_CURRENT_ATTRIBUTE = 'setCurrentAttribute';
export const CLEAR_CURRENT_ATTRIBUTE = 'clearCurrentAttribute';
export const SET_ATTRIBUTE_STYLE_VIEW = 'setAttributeStyleView';
export const SET_ATTRIBUTE_MODAL_STATUS = 'setAttributeModalStatus';
export const SET_ATTRIBUTE_REQUEST_STATUS = 'setAttributeRequestStatus';

export const attributeReducer = (state, action) => {
  switch (action.type) {
    case SET_ATTRIBUTE_STYLE_VIEW:
      return {
        ...state,
        cardStyle: action.payload,
      };

    case SET_ATTRIBUTE_MODAL_STATUS:
      return {
        ...state,
        modalStatus: action.payload,
      };

    case SET_ATTRIBUTES:
      return {
        ...state,
        attributeItems: [...action.payload],
      };

    case SET_CURRENT_ATTRIBUTE:
      return {
        ...state,
        currentAttribute: {
          ...action.payload,
        },
      };

    case SET_ATTRIBUTE_REQUEST_STATUS:
      return {
        ...state,
        request: {
          data: { ...action.payload.data },
          inProgress: action.payload.inProgress,
        },
      };

    case CLEAR_CURRENT_ATTRIBUTE:
      return {
        ...state,
        currentAttribute: {},
      };

    default:
      throw new Error();
  }
};

export default {
  attributeReducer,
};
