import React, { useEffect, useState } from 'react';
import { Spin, Tooltip } from 'antd';

import {
  DownOutlined,
  LoadingOutlined,
  WarningOutlined,
  MessageOutlined,
} from '@ant-design/icons';

import { AccountsViewContext } from '../../views/accountsView/AccountsViewContext';

import Styles from './ChatWidget.module.scss';

const widgetSelector = '#chatbase-bubble-button';
const widgetWindowSelector = '#chatbase-bubble-window';

/**
 * This component wait until the DOM content is loaded, then search for the chat widget,
 * which come from special CDN located in accounts.html,
 * we do this in this way to have the complete power to customize
 * the widget with our icons and colors.
 */
const ChatWidget = () => {
  const [viewContext] = AccountsViewContext();
  const [widgetElement, setWidgetElement] = useState(undefined);
  const [widgetLoaded, setWidgetLoaded] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [widgetError, setWidgetError] = useState(false);
  const [widgetWindowElementVisible, setWidgetWindowElementVisible] = useState(false);

  const widgetSearcher = () => {
    const interval = setInterval(() => {
      const domWidgetSelected = document.querySelector(widgetSelector);
      if (domWidgetSelected) {
        setWidgetElement(domWidgetSelected);
        setWidgetLoaded(true);
        clearInterval(interval);
      }
    }, 300);
  };

  useEffect(() => {
    document.addEventListener('DOMContentLoaded', widgetSearcher);

    return () => {
      document.removeEventListener('DOMContentLoaded', widgetSearcher);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!document.querySelector(widgetSelector)) {
        setWidgetError(true);
      }
    }, 90000); // 1.5 minutes

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (viewContext.hideChatWidget && isClicked) {
      widgetElement.click();
      setIsClicked(false);
    }
  }, [viewContext.hideChatWidget]);

  useEffect(() => {
    const domWidgetWindowSelected = document.querySelector(widgetWindowSelector);

    if (!domWidgetWindowSelected) return null;

    const observer = new MutationObserver(() => {
      if (domWidgetWindowSelected.getAttribute('style')?.includes('display: none')) {
        setWidgetWindowElementVisible(false);
      } else {
        setWidgetWindowElementVisible(true);
      }
    });

    const config = { attributes: true, attributeFilter: ['style'] };
    observer.observe(domWidgetWindowSelected, config);

    return () => observer.disconnect();
  }, [document.querySelector(widgetWindowSelector)]);

  useEffect(() => {
    if (!widgetWindowElementVisible && isClicked) {
      setIsClicked(false);
    }
  }, [widgetWindowElementVisible]);

  const handleButtonClick = () => {
    if (widgetElement) {
      widgetElement.click();
      setIsClicked(!isClicked);
    }
  };

  if (viewContext.hideChatWidget) {
    return <></>;
  }

  return (
    <Tooltip title="AIdan Chat Bot" placement="left">
      <button
        onClick={handleButtonClick}
        type="button"
        className={`chatwidget__selector ${Styles.button} ${widgetError && Styles.error}`}
        data-open={isClicked}
      >
        {widgetLoaded ? (
          <>
            {isClicked ? (
              <DownOutlined className={Styles.widgetIcons} />
            ) : (
              <MessageOutlined className={Styles.widgetIcons} />
            )}
          </>
        ) : (
          <>
            {widgetError ? (
              <WarningOutlined className={Styles.widgetIcons} />
            ) : (
              <Spin
                indicator={
                  <LoadingOutlined className={Styles.loaderIcon} spin />
                }
              />
            )}
          </>
        )}
      </button>
    </Tooltip>
  );
};

export default ChatWidget;
