import { consoleRequest } from './requests';

class AttributeRequest {
  constructor() {
    this.base_url = 'api/attribute/v1/attributes/';
    this.context_url = '/api/attribute/v1/context/';
  }

  /**
   * @param {function} responseHandler
   * @param {function} errorHandler
   * @param {string} queryParams - Extra url parameter for bring data.
   * E.g: ?owner_type__model=tenant
   */
  getAttributes(responseHandler, errorHandler, queryParams = '') {
    consoleRequest.get(`${this.base_url}${queryParams}`)
      .then(responseHandler)
      .catch((error) => {
        errorHandler(error.response.data);
      });
  }

  /**
   * @param {function} responseHandler
   * @param {function} errorHandler
   * @param {object} data
   */
  postAttribute(responseHandler, errorHandler, data) {
    consoleRequest.post(this.base_url, data)
      .then(responseHandler)
      .catch((error) => {
        errorHandler(error.response.data);
      });
  }

  /**
   * @param {function} responseHandler
   * @param {function} errorHandler
   * @param {number} attributeId
   * @param {object} data
   */
  patchAttribute(responseHandler, errorHandler, attributeId, data) {
    consoleRequest.patch(`${this.base_url + attributeId}/`, data)
      .then(responseHandler)
      .catch((error) => {
        errorHandler(error.response.data);
      });
  }

  /**
   * @param {function} responseHandler
   * @param {function} errorHandler
   * @param {number} attributeId
   */
  deleteAttribute(responseHandler, errorHandler, attributeId) {
    consoleRequest.delete(`${this.base_url + attributeId}`)
      .then(responseHandler)
      .catch((error) => {
        errorHandler(error.response.data);
      });
  }
}

export default new AttributeRequest();
