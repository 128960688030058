import { useParams } from 'react-router-dom';

import { AccountContext } from '../../../views/accountsView/account/AccountContext';
import { AccountsViewContext } from '../../../views/accountsView/AccountsViewContext';

/**
 * This hook return site, account, product, instance, and url params.
 */
const useAccountInfo = () => {
  const {
    siteId,
    accountId,
    productId,
    platformId,
  } = useParams();

  const [{
    tenants,
    products,
    instances,
  }] = AccountContext();

  const [{
    accounts,
  }] = AccountsViewContext();

  const product = products[productId];
  const account = accounts[accountId];

  const site = tenants.find((tenant) => tenant.external_key === siteId);
  const instance = instances.find((instanceIterator) => (
    instanceIterator.id === site?.instance
  ));

  return [{
    site,
    siteId,
    account,
    product,
    instance,
    accountId,
    productId,
    platformId,
  }];
};

export default useAccountInfo;
