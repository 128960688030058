import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { isReactObject } from '../../helpers/utils';
import { getTypeTierMapBySubscription } from '../../helpers/utils/get-variable-content-by-subscription.utils';
import { AccountContext } from '../../views/accountsView/account/AccountContext';

import constants from '../../constants';

/**
 * Component for return subscription variable content data.
 * @param {string} section - Section to render generic data.
 * @param {bool} variable - Indicate if render generic data is variable,
 *                          if true renders content according to the tier.
 * @param {string} suscriptionId
 * @param {object} reactElementExtraProps - Indicate extra props to React.createElement.
 * @param {string} extraKey - Indicate extra key in constans to render.
 *
 * @returns Dinamic text from the constants file.
 */
const SubscriptionVariableContent = ({
  section,
  variable,
  suscriptionId,
  reactElementExtraProps,
  extraKey,
}) => {
  const { productId } = useParams();
  const [accountContext] = AccountContext();
  const getSuscriptionId = suscriptionId !== undefined ? suscriptionId : productId;
  const suscription = accountContext.products[getSuscriptionId];
  const status = (suscription?.is_active ? 'active' : 'inactive');

  if (section in constants) {
    const content = constants[section];

    if ((variable || extraKey === 'variable') && ('variable' in content)) {
      let variableId = getTypeTierMapBySubscription(suscription);

      let renderVariable = content.variable[variableId] === undefined
        ? content.variable.else : content.variable[variableId];

      if (status === 'inactive' && suscription?.product_type === 'STR') {
        variableId = 'inactive_stratus_subscription';

        renderVariable = content.variable[variableId] === undefined
          ? content.variable.else : content.variable[variableId];

        if (isReactObject(renderVariable)) {
          return React.createElement(renderVariable.component, {
            ...renderVariable.props,
            ...reactElementExtraProps,
          });
        }
      }

      if (isReactObject(renderVariable)) {
        return React.createElement(renderVariable.component, {
          ...renderVariable.props,
          ...reactElementExtraProps,
        });
      }

      return renderVariable || 'Undefined variable name in constant block';
    }

    if (extraKey && extraKey !== 'variable') {
      let extraValue = null;

      try {
        extraValue = eval(`content.${extraKey}`); // eslint-disable-line no-eval
      } catch (e) {} // eslint-disable-line no-empty

      if (isReactObject(extraValue)) {
        return React.createElement(extraValue.component, {
          ...extraValue.props,
          ...reactElementExtraProps,
        });
      }

      return extraValue || 'Undefined extraKey name in constant block';
    }

    return content.static instanceof Function
      ? content.static(reactElementExtraProps) : content.static;
  }

  throw new Error('Undefined section name in constant block');
};

SubscriptionVariableContent.propTypes = {
  section: PropTypes.string.isRequired,
  variable: PropTypes.bool,
  suscriptionId: PropTypes.string,
  reactElementExtraProps: PropTypes.instanceOf(Object),
  extraKey: PropTypes.string,
};

SubscriptionVariableContent.defaultProps = {
  variable: false,
  suscriptionId: undefined,
  reactElementExtraProps: {},
  extraKey: undefined,
};

export default SubscriptionVariableContent;
