import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import IframeWrapper from '../iframeWrapper/IframeWrapper';

/**
 * Component for render a Manage iframe.
 *
 * @param {string} path - Custom url for Manage.
 * @param {string} height - Height of the iframe
 * @returns Manage iframe
 */
const IframeManage = ({ path, height }) => {
  const { siteId } = useParams();
  const URL = `${process.env.MANAGE_ROOT_URL}${path}?show_frame=false&tenant_key=${siteId}`;

  return (
    <IframeWrapper
      url={URL}
      height={height}
    />
  );
};

IframeManage.propTypes = {
  path: PropTypes.string,
  height: PropTypes.string,
};

IframeManage.defaultProps = {
  path: '#',
  height: '500px',
};

export default IframeManage;
