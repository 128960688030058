import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { AccountReducer } from './AccountReducer';
import NotificationProvider from '../../../components/Notifications/Provider/NotificationProvider';
import getVariableContentBySubscription, { getTypeTierMapBySubscription } from '../../../helpers/utils/get-variable-content-by-subscription.utils';
import ChatWidget from '../../../components/ChatWidget/ChatWidget';

const initialState = {
  products: {},
  contacts: [],
  invoices: [],
  associations: {
    accountAssociations: [],
    productAssociations: [],
    tenantAssociations: [],
  },
  memos: [],
  countMemosActive: 0,
  tenants: [],
  instances: [],
  tickets: {},
  ticketDetails: {},
  tenantThumbnails: {},
  accoutOptions: {
    geographyChoices: [],
    memoCategories: [],
    memoPriorities: [],
    memoStatuses: [],
    referralChoices: [],
    verticalChoices: [],
    attributeStatus: [],
    contactCategories: [],
    countryChoices: [],
    instanceAttributeTypes: [],
    languageChoices: [],
    permissionChoices: [],
    productLevels: [],
    productBillingTermsChoices: [],
    productPaymentTypeChoices: [],
    productRatingChoices: [],
    productSizeChoices: [],
    productStateusChoices: [],
    productTierChoices: [],
    productTypes: [],
    attributeTypes: [],
    tenantTypes: [],
  },
  currentAccountId: undefined,
  currentProductId: undefined,
  currentPlatformId: undefined,
  tenantFlags: {},
};

export const Context = createContext();
Context.displayName = 'AccountContext';

export const AccountContext = () => useContext(Context);

/**
 * @param children - React child element.
 */
export const AccountProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(AccountReducer, initialState);

  const getVariableContent = (subscriptionId, section, constants) => {
    const subscription = state.products[subscriptionId];
    return getVariableContentBySubscription(subscription, section, constants);
  };

  const getTier = (subscriptionId) => {
    const subscription = state.products[subscriptionId];
    return getTypeTierMapBySubscription(subscription);
  };

  return (
    <Context.Provider value={[{ ...state, getVariableContent, getTier }, dispatch]}>
      <NotificationProvider>
        {children}
        <ChatWidget />
      </NotificationProvider>
    </Context.Provider>
  );
};

AccountProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
