import React from 'react';
import {
  Card, Dropdown, Tag,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';

import { textSelectFormatter } from '../../../../helpers/utils';
import { AttributeContext } from '../../Context/AttributeContext';

import Styles from './CardItem.module.scss';

const { Meta } = Card;

const CardItem = ({ attribute }) => {
  const {
    id,
    name,
    status = 'removed',
    image_url: imageUrl,
    date_added: dateAdded,
    support_through: supportThrough,
  } = attribute;

  const [
    {
      modalStatus,
      attributeType,
      attributeState,
      isSupportMember,
      handleModalDelete,
      handleModalEditing,
      handleModalDisplaying,
      attributeDefaultImages,
      attributeStatusTagColors,
    },
  ] = AttributeContext();

  const { label: tagText } = textSelectFormatter(status);
  const isDeleting = attributeState.modalStatus === modalStatus.deleting
    && attributeState.currentAttribute?.id === id;

  const items = [
    {
      key: '1',
      label: (
        <button
          type="button"
          className={Styles.buttonEdit}
          onClick={(event) => handleModalEditing(attribute, event)}
        >
          <EditOutlined />
          {' '}
          <span>
            Edit attribute
          </span>
        </button>

      ),
    },
    {
      key: '2',
      label: (
        <button
          type="button"
          className={Styles.buttonDelete}
          onClick={(event) => handleModalDelete(attribute, event)}
        >
          <DeleteOutlined />
          {' '}
          <span>
            Delete attribute
          </span>
        </button>
      ),
      danger: true,

    },
  ];

  return (
    <Card
      title={(
        <div className={Styles.cardTitleWrapper}>
          <h4 className={Styles.cardTitle} title={name}>{name}</h4>
          {
            isSupportMember && !isDeleting && (
              <Dropdown menu={{ items }} overlayStyle={{ zIndex: 300 }}>
                <EllipsisOutlined className={Styles.cardTitleIcon} />
              </Dropdown>
            )
          }
        </div>
      )}
      bodyStyle={{ minHeight: 90, padding: '24px 14px' }}
      className={Styles.cardWrapper}
      hoverable={!isDeleting}
      style={{
        width: 240,
        height: 320,
      }}
      cover={(
        <img
          alt=""
          role="presentation"
          className={Styles.cardImage}
          src={imageUrl || attributeDefaultImages[attributeType]}
          onClick={(event) => handleModalDisplaying(attribute, event)}
        />
      )}
    >
      <div onClick={(event) => handleModalDisplaying(attribute, event)} role="presentation">

        <Tag color={attributeStatusTagColors[status]} className={Styles.cardTag}>
          {tagText}
        </Tag>

        {dateAdded && (
          <Meta
            description={(
              <p style={{ marginTop: isDeleting && '10px' }}>
                Added on:
                {' '}
                {moment(dateAdded).utc().format('MM/DD/YY')}
              </p>
            )}
          />
        )}

        {supportThrough && (
          <Meta
            description={(
              <p>
                Last modification:
                {' '}
                {moment(supportThrough).utc().format('MM/DD/YY')}
              </p>
            )}
          />
        )}

        {supportThrough === null && dateAdded === null && (
          <Meta description={<p style={{ marginBottom: 0 }}>No data provided</p>} />
        )}
      </div>
    </Card>
  );
};

CardItem.propTypes = {
  attribute: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    status: PropTypes.string.isRequired,
    image_url: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    date_added: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    support_through: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
};

export default CardItem;
