/**
 *  SUPPORT CONSTANTS CONTROL CENTER
 *  In this file the support constants of the project are configured.
 *  In this file we will find ONLY the keys used in the Support view.
 *
 *  WARNINGS:
 *            - When you need to include more than one HTML tag, they must be contained in
 *              a parent HTML tag, for example:
 *              Examples of incorrect code:
 *              (
 *                <h1>Hello World!</h1>
 *                <p>Lorem ipsum ... </p>
 *              )
 *
 *              Examples of correct code:
 *              (
 *                <>
 *                    <h1>Hello World!</h1>
 *                    <p>Lorem ipsum ... </p>
 *                </>
 *              )
 *
 *            - Make sure to preserve both the structure and the indentation, our format is:
 *              {
 *                section_name: {
 *                    static: (... Text|HTML),
 *                    variable: {
 *                        variable_1: (... Text|HTML),
 *                        ...
 *                    },
 *                },
 *                ...
 *              }
 */
import React from 'react';
import SupportInfo from '../components/supportInfo/SupportInfo';

import {
  INACTIVE_SUBSCRIPTION,
  INACTIVE_STRATUS_SUBSCRIPTION,
  MISCONFIGURED_SUBSCRIPTION,
  UPGRADE_SUBSCRIPTION,
} from './subscription.constants';

// It is used like this so that `SubscriptionVariableContent` can use `reactElementExtraProps`
const SUPPORT_EXPLAINED = {
  component: SupportInfo,
  props: {
    type: 'EXPLAINED',
  },
};

// It is used like this so that `SubscriptionVariableContent` can use `reactElementExtraProps`
const SUPPORT_OPENTICKET = {
  component: SupportInfo,
  props: {
    type: 'OPENTICKET',
  },
};

export default {
  support_explore_knowledge: {
    static: <SupportInfo type="EXPLORE_KNOWLEDGE" />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: UPGRADE_SUBSCRIPTION,
      nimbus_basic: SUPPORT_EXPLAINED,
      nimbus_enhanced: SUPPORT_EXPLAINED,
      nimbus_advanced: SUPPORT_EXPLAINED,
      on_premise: SUPPORT_EXPLAINED,
      cirrus: SUPPORT_EXPLAINED,
      cirrus_sn: SUPPORT_EXPLAINED,
      cirrus_se: SUPPORT_EXPLAINED,
      stratus: SUPPORT_EXPLAINED,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  support_open_ticket: {
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: (<div />),
      nimbus_free: (<div />),
      nimbus_basic: SUPPORT_OPENTICKET,
      nimbus_enhanced: SUPPORT_OPENTICKET,
      nimbus_advanced: SUPPORT_OPENTICKET,
      on_premise: SUPPORT_OPENTICKET,
      cirrus: SUPPORT_OPENTICKET,
      cirrus_sn: SUPPORT_OPENTICKET,
      cirrus_se: SUPPORT_OPENTICKET,
      stratus: SUPPORT_OPENTICKET,
      else: (<div />),
    },
  },
  support_footer: {
    static: <SupportInfo type="FOOTER" />,
  },
};
