import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';

import CustomButton from '../customButton/CustomButton';
import CommonStyles from '../../Common.module.scss';

/**
 * Renders upgrade subscription button.
 *
 * @param children - JSX element embedded inside UpgradeSubscriptionButton component.
 * @param as - Indicate as redenring button or link.
 * @param productExternalKey - Specific product external key.
 *
 * @returns React Router Dom Link for redirect to /upgrade.
 */
const UpgradeSubscriptionButton = ({ children, as, productExternalKey }) => {
  const { accountId, productId } = useParams();

  const productIdSelect = !productId ? productExternalKey : productId;
  const pathname = `/accounts/${accountId}/products/${productIdSelect}/upgrade`;
  const childrenBase = 'Upgrade my subscription';

  // use CommonStyles.linkUpgradeSubscription
  return (
    <Link to={{ pathname }} className={`${CommonStyles.link} d-flex align-items-center`}>
      {
        as === 'link' ? (
          <span className={CommonStyles.link}>{children || childrenBase}</span>
        ) : (
          <CustomButton variant="confirmAction">
            {children || childrenBase}
          </CustomButton>
        )
      }
    </Link>
  );
};

UpgradeSubscriptionButton.propTypes = {
  children: PropTypes.node,
  as: PropTypes.oneOf(
    ['button', 'link'],
  ),
  productExternalKey: PropTypes.string,
};

UpgradeSubscriptionButton.defaultProps = {
  children: null,
  as: 'button',
  productExternalKey: undefined,
};

export default UpgradeSubscriptionButton;
