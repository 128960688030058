import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Pagination } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';

import { AccountsViewContext } from '../../views/accountsView/AccountsViewContext';
import { useUrlSearchParamsReactive } from '../../helpers/customHooks';

import SubscriptionCard from './subscriptionCard/SubscriptionCard';
import SubscriptionDetailCard from './subscriptionDetailCard/SubscriptionDetailCard';

import Styles from './SubscriptionListCard.module.scss';

import { DEFAULT_MAX_SIZE_PAGINATION } from '../../constants';

/**
 * Component that renders a list of subscription card element like the ones in '/accounts'
 * @param {boolean} loading - Indicate if results is loading.
 * @param {number} currentPage - Current page selected.
 * @param {number} totalPage - Total page found.
 * @param {func} onChangePage - Function that is triggered when change page.
 */
const SubscriptionListCard = ({
  loading,
  currentPage,
  totalPage,
  onChangePage,
}) => {
  const [viewContext] = AccountsViewContext();
  const [subscriptionSelected, setSubscriptionSelected] = useUrlSearchParamsReactive('subscription');
  const [subscriptionSelectedData, setSubscriptionSelectedData] = useState(null);

  const onCloseDetail = () => {
    setSubscriptionSelected('');
    setSubscriptionSelectedData(null);
  };

  const selectSubscription = (e, subscription) => {
    e.preventDefault();
    setSubscriptionSelected(subscription.external_key);
    if (subscriptionSelected === subscription.external_key) {
      return onCloseDetail();
    }

    return setSubscriptionSelectedData(subscription);
  };

  useEffect(() => {
    if (viewContext.subscriptions.length > 0) {
      const subscriptionFind = viewContext.subscriptions.find(
        (subscription) => subscription.external_key === subscriptionSelected,
      );
      setSubscriptionSelectedData(subscriptionFind);
    }
  }, [subscriptionSelected]);

  if (loading) {
    return Array(DEFAULT_MAX_SIZE_PAGINATION)
      .fill(' ')
      .map((_, index) => <SubscriptionCard loading key={`subscription-card-${index * 1}`} />);
  }

  let CONTENT = (
    <section className={Styles.resultMessage}>
      <QuestionCircleFilled className={Styles.resultMessageIcon} />
      <p>No results were found, try with other options... 🤔</p>
    </section>
  );

  if (viewContext.subscriptions.length > 0) {
    CONTENT = (
      <>
        {
          viewContext.subscriptions.map((subscription) => (
            <SubscriptionCard
              key={subscription.name}
              data={subscription}
              onSelect={(e) => selectSubscription(e, subscription)}
              isActive={subscriptionSelected === subscription.external_key}
            />
          ))
        }
        <Pagination
          className={Styles.paginate}
          current={currentPage}
          onChange={onChangePage}
          total={totalPage}
          defaultPageSize={DEFAULT_MAX_SIZE_PAGINATION}
          showSizeChanger={false}
        />
      </>
    );
  }

  return (
    <div className={Styles.main}>
      <div className={Styles.content}>{CONTENT}</div>
      {
        subscriptionSelectedData
        && viewContext.subscriptions.some((subscription) => (
          subscription.external_key === subscriptionSelected
        )) && (
          <div className={Styles.detail}>
            <SubscriptionDetailCard
              data={subscriptionSelectedData}
              onClose={onCloseDetail}
            />
          </div>
        )
      }
    </div>
  );
};

SubscriptionListCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
};

export default SubscriptionListCard;
