/*
 * This reducer does nothing, it is just the place where the actions must be implemented.
 * If the state will be modified the action must have the respective case in this reducer.
 */
export const INIT = 'INIT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_MENUS = 'updateMenus';
export const UPDATE_USER_METADATA = 'UPDATE_USER_METADATA';
export const UPDATE_ALLOWED_ACTION = 'UPDATE_ALLOWED_ACTION';
export const UPDATE_EXTRA_MENUS = 'UPDATE_EXTRA_MENUS';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const UPDATE_OPTIONS = 'UPDATE_OPTIONS';
export const UPDATE_SUBSCRIPTIONS = 'UPDATE_SUBSCRIPTIONS';
export const UPDATE_HIDE_CHATWIDGET = 'UPDATE_HIDE_CHATWIDGET';

export function AccountsViewReducer(state, action) {
  switch (action.type) {
    case INIT:
      return { ...state, ...action.state, loaded: true };
    case UPDATE_ACCOUNT:
      return {
        ...state,
        accounts: { ...state.accounts, ...action.state },
        loaded: true,
      };
    case UPDATE_MENUS:
      return { ...state, menus: action.menus };
    case UPDATE_EXTRA_MENUS:
      return { ...state, extraMenus: action.menus };
    case UPDATE_OPTIONS:
      return { ...state, options: action.state };
    case UPDATE_USER_METADATA:
      return {
        ...state,
        user: {
          ...state.user,
          roles: action.user.roles,
          allowed_actions: {
            ...state.user.allowed_actions,
            ...action.user.allowed_actions,
            instance: {
              ...state.user.allowed_actions.instance,
              ...action.user.allowed_actions.instance,
            },
            contact: {
              ...state.user.allowed_actions.contact,
              ...action.user.allowed_actions.contact,
            },
            memo: {
              ...state.user.allowed_actions.memo,
              ...action.user.allowed_actions.memo,
            },
            team: {
              ...state.user.allowed_actions.team,
              ...action.user.allowed_actions.team,
            },
          },
        },
        userLoaded: true,
      };
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        user: {
          ...state.user,
          name: action.user.name,
          email: action.user.email,
          username: action.user.username,
        },
      };
    case UPDATE_ALLOWED_ACTION:
      return {
        ...state,
        user: {
          ...state.user,
          allowed_actions: {
            ...state.user.allowed_actions,
            [action.view]: {
              ...state.user.allowed_actions[action.view],
              [action.action]: action.value,
            },
          },
        },
      };
    case UPDATE_SUBSCRIPTIONS:
      return { ...state, subscriptions: action.state };
    case UPDATE_HIDE_CHATWIDGET:
      return { ...state, hideChatWidget: action.state };
    default:
      throw new Error();
  }
}
