/**
 *  IFRAMES CONSTANTS CONTROL CENTER
 *  In this file the iframe constants of the project are configured.
 */
import React from 'react';
import IframeManage from '../components/iframeManage/IframeManage';
import IframeWrapper from '../components/iframeWrapper/IframeWrapper';
import DOCS_URLS from './documentation.constants';

export const GOTO_MANAGE_DASHBOARD = <IframeManage path="usage" height="700px" />;

export const GOTO_MANAGE_REPORTS_MY_REPORTS = <IframeManage path="reports/my-reports" height="600px" />;

export const GOTO_MANAGE_EMAIL = <IframeManage path="email" height="1000px" />;

export const GOTO_MANAGE_SETTINGS_LANGUAGE = <IframeManage path="settings/GeneralLanguage" height="700px" />;

export const GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_CONTACT = <IframeManage path="settings/InfoPagesContact" height="700px" />;

export const GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_ABOUT = <IframeManage path="settings/InfoPagesAboutUs" height="700px" />;

export const GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_FAQ = <IframeManage path="settings/InfoPagesFAQ" height="700px" />;

export const GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_HONOR = <IframeManage path="settings/InfoPagesHonor" height="700px" />;

export const GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_PRIVACY = <IframeManage path="settings/InfoPagesPrivacy" height="700px" />;

export const GOTO_MANAGE_SETTINGS_BRANDING_CONTENT_TOS = <IframeManage path="settings/InfoPagesTOS" height="700px" />;

export const GOTO_MANAGE_SETTINGS_INTEGRATIONS_WEBSITE_CMS_SIMPLE = <IframeManage path="settings/WebsiteIntegrationCMSSimple" height="700px" />;

export const GOTO_MANAGE_SETTINGS_INTEGRATIONS_WEBSITE_WORDPRESS = <IframeManage path="settings/WebsiteIntegrationWordpress" height="700px" />;

export const GOTO_MANAGE_SETTINGS_REGISTERLOGIN = <IframeManage path="settings/RegistrationAndLoginLogin" height="1200px" />;

export const GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_SETTINGS = <IframeManage path="settings/ThirdPartyAuthSettings" height="700px" />;

export const GOTO_MANAGE_SETTINGS_REGISTERLOGINREGISTER = <IframeManage path="settings/RegistrationAndLoginRegister" height="700px" />;

export const GOTO_MANAGE_SETTINGS_SUPPORT = <IframeManage path="settings/GeneralSupportInfo" height="700px" />;

export const GOTO_MANAGE_SETTINGS_LOOK_AND_FEEL_BRAND = <IframeManage path="settings/LookAndFeelBrand" height="700px" />;

export const GOTO_MANAGE_SETTINGS_LOOK_AND_HEADER = <IframeManage path="settings/LookAndFeelHeader" height="700px" />;

export const GOTO_MANAGE_SETTINGS_LOOK_AND_FOOTER_BASIC_SETTINGS = <IframeManage path="settings/LookAndFeelFooterBasic" height="700px" />;

export const GOTO_MANAGE_SETTINGS_LOOK_AND_FOOTER_ADVANCED_SETTINGS = <IframeManage path="settings/LookAndFeelFooterAdvanced" height="700px" />;

export const GOTO_MANAGE_SETTINGS_LOOK_AND_CUSTOM_STYLES = <IframeManage path="settings/LookAndFeelCustomStyles" height="700px" />;

export const GOTO_MANAGE_SETTINGS_HOMEPAGE_BASIC = <IframeManage path="settings/HomepageBasicSettings" height="700px" />;

export const GOTO_MANAGE_SETTINGS_HOMEPAGE_ADVANCED = <IframeManage path="settings/HomepageAdvancedSettings" height="700px" />;

export const GOTO_COUSE_CATALOG_BASIC_SETTINGS = <IframeManage path="settings/CourseCatalogBasicSettings" height="700px" />;

export const GOTO_COUSE_CATALOG_ADVANCED_SETTINGS = <IframeManage path="settings/CourseCatalogAdvancedSettings" height="700px" />;

export const GOTO_COUSE_CATALOG_DESCRIPTION = <IframeManage path="settings/CourseCatalogCourseDescription" height="700px" />;

export const GOTO_COUSE_CATALOG_DESCRIPTION_OVERRIDE = <IframeManage path="settings/CourseCatalogDescriptOverride" height="700px" />;

export const GOTO_MANAGE_LEARNER_DASHBOARD_BASIC_SETTINGS = <IframeManage path="settings/LearnerDashboardBasic" height="700px" />;

export const GOTO_MANAGE_LEARNER_DASHBOARD_ADVANCED_SETTINGS = <IframeManage path="settings/LearnerDashboardAdvanced" height="700px" />;

export const GOTO_MANAGE_SETTINGS_COURSEEXPERIENCE = <IframeManage path="settings/CourseAuthoringStudioSettings" height="850px" />;

export const GOTO_MANAGE_SETTINGS_EMAIL_DELIVERY_BASIC_SETTINGS = <IframeManage path="settings/EmailDeliveryBasicSettings" height="750px" />;

export const GOTO_MANAGE_SETTINGS_EMAIL_DELIVERY_ADVANCED_SETTINGS = <IframeManage path="settings/EmailDeliveryAdvancedSettings" height="750px" />;

export const GOTO_MANAGE_SETTINGS_TRACKING_TOOLS_GA = <IframeManage path="settings/TrackingToolsGoogleAnalytics" height="750px" />;

export const BASIC_XBLOCKS = <IframeWrapper url={`${DOCS_URLS.courseAuthoring}X-blocks-edunext.html?highlight=native%20xblocks#native-x-blocks-developed-by-open-edx`} />;

export const ADVANCED_XBLOCKS = <IframeWrapper url={`${DOCS_URLS.courseAuthoring}X-blocks-edunext.html?highlight=native%20xblocks#x-blocks-developed-by-third-party-providers`} />;

export const GOTO_MANAGE_SETTINGS_PROGRAMS_CONFIGURATION = <IframeWrapper url={`${DOCS_URLS.courseCreators}prepare_test_launch_courses/multi-course-programs.html?highlight=Configure%20Multi%20course%20programs`} />;
