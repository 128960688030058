import React from 'react';
import PropTypes from 'prop-types';

import IframeWrapper from '../iframeWrapper/IframeWrapper';
import useAccountInfo from '../../helpers/hooks/useAccountInfo/useAccountInfo';

import Styles from '../../Common.module.scss';

/**
 * This renders different types of links based on the provided type,
 *
 * The component returns different JSX elements based on the
 * conditions in the code, such as [iframe, link, b]
 *
 * @param {string} type - Check the proptypes for full definition.
 * @param {string} text - Text for the link elements.
 * @param {string} url - Path for [link, iframe]
 */
const DynamicElement = ({ type, text, url }) => {
  const [{
    site, siteId, productId, accountId, platformId,
  }] = useAccountInfo();

  const linkTypes = {
    djangoAdmin: `${site?.lms_base}/admin`,
    lms: `${site?.lms_base || 'null'}`,
    studio: `${site?.studio_base || 'null'}`,
    custom: url,
    iframe: 'iframe',
    customLms: 'customLms',
    iframeCustom: 'iframeCustom',
    reports: `https://control.edunext.co/accounts/${accountId}/products/${productId}/platform/${platformId}/sites/${siteId}/analytics/reports`,
  };

  const isInvalidUrl = linkTypes[type] === 'null' || (linkTypes[type] === 'customLms' && linkTypes.lms === 'null');
  const isValidIframeUrl = (linkTypes[type] === 'iframe' || linkTypes[type] === 'iframeCustom') && linkTypes.lms === 'null';

  if (!linkTypes[type] || isInvalidUrl) {
    return <b className={Styles.boldText}>{text}</b>;
  }

  if (isValidIframeUrl) {
    return (
      <div className={Styles.bannerContactSupport}>
        <p>
          LMS url not provided, please contact to support to enjoy this feature.
        </p>
      </div>
    );
  }

  if (linkTypes[type] === 'iframe') {
    return <IframeWrapper url={`${linkTypes.lms}${url}`} />;
  }

  if (linkTypes[type] === 'iframeCustom') {
    return <IframeWrapper url={url} />;
  }

  if (linkTypes[type] === 'customLms') {
    return (
      <a
        href={`${linkTypes.lms}${url}`}
        className={Styles.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {' '}
        {text}
        {' '}
      </a>
    );
  }

  return (
    <a
      href={linkTypes[type]}
      className={Styles.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {' '}
      {text}
      {' '}
    </a>
  );
};

DynamicElement.propTypes = {
  type: PropTypes.oneOf([
    'djangoAdmin',
    'lms',
    'custom',
    'iframe',
    'iframeCustom',
    'reports',
    'studio',
    'customLms',
  ]).isRequired,
  url: PropTypes.string,
  text: PropTypes.string,
};

DynamicElement.defaultProps = {
  text: null,
  url: 'null',
};

export default DynamicElement;
