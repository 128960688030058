import { getFormattedOptionsByContext } from '../../../helpers/utils';

const DATE = 'date';
const NUMBER = 'number';
const SELECT = 'select';
const TEXT = 'text';

export const filterMappingFieldLookup = {
  account__name: TEXT,
  account__vertical: SELECT,
  account__region: SELECT,
  account__main_country: SELECT,
  account__referral: SELECT,
  account__referral_date: DATE,
  account__referral_expiration: DATE,
  account__date_created: DATE,
  account__contact__name: TEXT,
  account__contact__category: SELECT,
  product__type: SELECT,
  product__status: SELECT,
  product__date_upgraded: DATE,
  product__date_downgraded: DATE,
  product__tier: SELECT,
  product__is_active: SELECT,
  product__mau_quota: NUMBER,
  product__size: SELECT,
  product__main_language: SELECT,
  product__name: TEXT,
  product__billing_and_payment__billing_cycle: SELECT,
  product__billing_and_payment__next_invoice_date: DATE,
  product__billing_and_payment__recurring_payment: SELECT,
  product__billing_and_payment__payment_type: SELECT,
  product__billing_and_payment__account__billing_alegra_last_sync: TEXT,
  product__billing_and_payment__account__billing_info_last_changed: TEXT,
  product__rating__maturity_rating: SELECT,
  product__rating__brand_rating: SELECT,
  product__rating__aesthetic_rating: SELECT,
  product__rating__features_rating: SELECT,
  product__rating__courses_rating: SELECT,
  product__team_member__name: TEXT,
  product__team_member__role__account: SELECT,
  product__team_member__role__product: SELECT,
  product__team_member__role__site: SELECT,
  platform__applications__name: TEXT,
  platform__applications__type: SELECT,
  platform__applications__version: TEXT,
  platform__applications__status: SELECT,
  platform__applications__date_added: DATE,
  platform__infrastructures__name: TEXT,
  platform__infrastructures__type: SELECT,
  platform__infrastructures__version: TEXT,
  platform__infrastructures__status: SELECT,
  platform__infrastructures__date_added: DATE,
  platform__customizations__name: TEXT,
  platform__customizations__version: TEXT,
  platform__customizations__status: SELECT,
  platform__customizations__date_added: DATE,
  site__type: SELECT,
  site__language: TEXT,
  site__advanced_configs__name: TEXT,
  site__advanced_configs__type: SELECT,
  site__advanced_configs__version: TEXT,
  site__advanced_configs__status: SELECT,
  site__advanced_configs__date_added: DATE,
  site__advanced_configs__support_through: DATE,
  site__mobile_apps__name: TEXT,
  site__mobile_apps__version: TEXT,
  site__mobile_apps__status: SELECT,
  site__mobile_apps__date_added: DATE,
  site__mobile_apps__support_through: DATE,
  site__apis__name: TEXT,
  site__apis__version: TEXT,
  site__apis__status: SELECT,
  site__apis__date_added: DATE,
  site__apis__support_through: DATE,
  site__triggers__name: TEXT,
  site__triggers__version: TEXT,
  site__triggers__status: SELECT,
  site__triggers__date_added: DATE,
  site__triggers__support_through: DATE,
  site__customizations__name: TEXT,
  site__customizations__version: TEXT,
  site__customizations__status: SELECT,
  site__customizations__date_added: DATE,
  site__customizations__support_through: DATE,
};

/**
 * FIELD LOOKUPS
 */

export const fieldLookupsOptionsForChoice = [
  {
    value: '=',
    label: 'Is',
  },
  {
    value: '__isnot=',
    label: 'Is not',
  },
  {
    value: '__isnull=True',
    label: 'Is empty',
  },
  {
    value: '__isnull=False',
    label: 'Is not empty',
  },
];

export const fieldLookupsOptionsForText = [
  ...fieldLookupsOptionsForChoice,
  {
    value: '__icontains=',
    label: 'Contains',
  },
  {
    value: '__icontains_not=',
    label: 'Does not contains',
  },
];

export const fieldLookupsOptionsForNumber = [
  ...fieldLookupsOptionsForChoice,
  {
    value: '__lt=',
    label: 'Less than',
  },
  {
    value: '__gt=',
    label: 'Greater than',
  },
];

export const fieldLookupsOptionsForDate = [
  {
    value: '=',
    label: 'Is',
  },
  {
    value: '__range_date=',
    label: 'Is between',
  },
  {
    value: '__isnull=True',
    label: 'Is empty',
  },
  {
    value: '__isnull=False',
    label: 'Is not empty',
  },
];

export const fieldSortLookupsOptionsForText = [
  {
    value: '',
    label: 'Sort by A > Z',
  },
  {
    value: '-',
    label: 'Sort by Z > A',
  },
];

export const fieldSortLookupsOptionsForNumber = [
  {
    value: '',
    label: 'Ascending',
  },
  {
    value: '-',
    label: 'Descending',
  },
];

export const fieldSortLookupsOptionsForChoice = [
  ...fieldSortLookupsOptionsForNumber,
];

export const fieldSortLookupsOptionsForDate = [
  {
    value: '-',
    label: 'Recent',
  },
  {
    value: '',
    label: 'Oldest',
  },
];

export const fieldLookupsOptionsMapping = {
  text: fieldLookupsOptionsForText,
  select: fieldLookupsOptionsForChoice,
  number: fieldLookupsOptionsForNumber,
  date: fieldLookupsOptionsForDate,
};

export const fieldSortLookupsOptionsMapping = {
  text: fieldSortLookupsOptionsForText,
  select: fieldSortLookupsOptionsForChoice,
  number: fieldSortLookupsOptionsForNumber,
  date: fieldSortLookupsOptionsForDate,
};

/**
 * FIELD OPTIONS VALUE
 */

export const getOptionsByFilerBoolean = [
  {
    value: 'True',
    label: 'Yes',
  },
  {
    value: 'False',
    label: 'No',
  },
];

export const getMappingFormattedOptionsByContext = (context) => (
  {
    account__vertical: getFormattedOptionsByContext(context, 'account_vertical_choices'),
    account__region: getFormattedOptionsByContext(context, 'account_geography_choices'),
    account__main_country: getFormattedOptionsByContext(context, 'country_choices', true),
    account__referral: getFormattedOptionsByContext(context, 'account_referral_choices'),
    account__contact__category: getFormattedOptionsByContext(context, 'contact_categories'),
    product__type: getFormattedOptionsByContext(context, 'product_types'),
    product__status: getFormattedOptionsByContext(context, 'product_status_choices'),
    product__tier: getFormattedOptionsByContext(context, 'product_tier_choices'),
    product__size: getFormattedOptionsByContext(context, 'product_size_choices'),
    product__main_language: getFormattedOptionsByContext(context, 'language_choices'),
    product__billing_and_payment__billing_cycle: getFormattedOptionsByContext(context, 'product_billing_terms_choices'),
    product__is_active: getOptionsByFilerBoolean,
    product__billing_and_payment__recurring_payment: getOptionsByFilerBoolean,
    product__billing_and_payment__payment_type: getFormattedOptionsByContext(context, 'product_payment_type_choices'),
    product__rating__maturity_rating: getFormattedOptionsByContext(context, 'product_rating_choices'),
    product__rating__brand_rating: getFormattedOptionsByContext(context, 'product_rating_choices'),
    product__rating__aesthetic_rating: getFormattedOptionsByContext(context, 'product_rating_choices'),
    product__rating__features_rating: getFormattedOptionsByContext(context, 'product_rating_choices'),
    product__rating__courses_rating: getFormattedOptionsByContext(context, 'product_rating_choices'),
    product__team_member__role__account: getFormattedOptionsByContext(context, 'permission_choices'),
    product__team_member__role__product: getFormattedOptionsByContext(context, 'permission_choices'),
    product__team_member__role__site: getFormattedOptionsByContext(context, 'permission_choices'),
    platform__applications__type: getFormattedOptionsByContext(context, 'instance_attribute_types', false, 'applications'),
    platform__applications__status: getFormattedOptionsByContext(context, 'attribute_status'),
    platform__infrastructures__type: getFormattedOptionsByContext(context, 'instance_attribute_types', false, 'infrastructures'),
    platform__infrastructures__status: getFormattedOptionsByContext(context, 'attribute_status'),
    platform__customizations__status: getFormattedOptionsByContext(context, 'attribute_status'),
    site__type: getFormattedOptionsByContext(context, 'tenant_types'),
    site__advanced_configs__type: getFormattedOptionsByContext(context, 'tenant_attribute_types', false, 'configurations_advanced'),
    site__advanced_configs__status: getFormattedOptionsByContext(context, 'attribute_status'),
    site__mobile_apps__status: getFormattedOptionsByContext(context, 'attribute_status'),
    site__apis__status: getFormattedOptionsByContext(context, 'attribute_status'),
    site__triggers__status: getFormattedOptionsByContext(context, 'attribute_status'),
    site__customizations__status: getFormattedOptionsByContext(context, 'attribute_status'),
  }
);

export const filterNameReplaces = [
  ['product__billing_and_payment__', ''],
  ['product__rating__', ''],
  ['product__', ''],
];
