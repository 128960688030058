import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Cascader,
  Input,
  Select,
} from 'antd';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import generatePicker from 'antd/es/date-picker/generatePicker';

import { AccountsViewContext } from '../../../views/accountsView/AccountsViewContext';

import {
  fieldLookupsOptionsMapping,
  filterMappingFieldLookup,
  getMappingFormattedOptionsByContext,
} from './Filter.mapping';
import filterOptions, { dateFormat, logicOperatorOptions } from './Filter.options';
import { valueInLookup } from './Filter.utils';
import FilterStyle from './Filter.module.scss';

const DatePicker = generatePicker(momentGenerateConfig);

/**
 * Component that renders a filter bar with an cascader, logic connector and value.
 *
 * @param {number} index - Indicate index in list of filters.
 * @param {object} filter - Object with filter data.
 * @param {array} disableds - List with keys filter to disabled in Cascader.
 * @param {string} logicOperator - Indicate logic operator (and, or).
 * @param {func} onChange - Callback when any change in filter.
 * @param {bool} disableCascader - Indicate if Cascader is disabled.
 * @param {bool} disableLogicOperator - Indicate if logic operator selector is disabled.
 */
const Filter = ({
  index,
  filter,
  disableds,
  logicOperator,
  onChange,
  disableCascader,
  disableLogicOperator,
}) => {
  const [viewContext] = AccountsViewContext();
  const [fieldLookupsOptions, setFieldLookupsOptions] = useState([]);
  const optionsByFilter = getMappingFormattedOptionsByContext(viewContext.options);
  const filterValueOptions = optionsByFilter[filter?.name?.join('')] || [];

  const renderInputValue = () => {
    if (filter?.valueType === 'select') {
      return (
        <Select
          className={FilterStyle.defaultInputValue}
          showSearch={filterValueOptions.length > 10}
          optionFilterProp="label"
          options={filterValueOptions}
          onChange={(value) => onChange({ ...filter, value }, logicOperator, index)}
          value={filter?.value || ''}
          placeholder="Select an option"
        />
      );
    } if (filter?.valueType === 'date') {
      if (filter?.lookup === '__range_date=') {
        return (
          <DatePicker.RangePicker
            className={FilterStyle.rangeDateValue}
            onChange={(date) => onChange({
              ...filter,
              value: [moment(date[0]).format(dateFormat), moment(date[1]).format(dateFormat)],
            }, logicOperator, index)}
            value={Array.isArray(filter?.value) && filter?.value.length === 2 ? filter.value.map((v) => moment(v, dateFormat)) : ''}
            format={dateFormat}
            placeholder="Select a range date"
          />
        );
      }

      return (
        <DatePicker
          className={FilterStyle.defaultInputValue}
          onChange={(date) => onChange({
            ...filter,
            value: moment(date).format(dateFormat),
          }, logicOperator, index)}
          value={moment(filter?.value).isValid() ? moment(filter?.value, dateFormat) : ''}
          format={dateFormat}
          placeholder="Select a date"
        />
      );
    }

    return (
      <Input
        className={FilterStyle.defaultInputValue}
        onChange={(e) => onChange({ ...filter, value: e.target.value }, logicOperator, index)}
        value={filter?.value || ''}
        placeholder={filter?.valueType === 'number' ? 'Write only numbers' : 'Type a value...'}
      />
    );
  };

  const handlerChangeFilter = (filterName) => {
    onChange({
      ...filter,
      name: filterName,
      lookup: filterName && '=',
      value: filterName && '',
      valueType: filterName && filterMappingFieldLookup[filterName.join('')],
    }, logicOperator, index);
  };

  useEffect(() => {
    if (filter?.valueType) {
      setFieldLookupsOptions(fieldLookupsOptionsMapping[filter.valueType]);
    } else {
      setFieldLookupsOptions([]);
    }
  }, [filter?.valueType]);

  const displayRender = (labels) => labels[labels.length - 1];

  return (
    <div className={FilterStyle.main}>
      <div className={FilterStyle.logicOperator}>
        {
          index === 0 && (
            <span>Where</span>
          )
        }
        {
          index === 1 && (
            <Select
              style={{ width: '100%' }}
              options={logicOperatorOptions}
              onChange={(value) => onChange(filter, value, index)}
              value={logicOperator}
              disabled={disableLogicOperator}
            />
          )
        }
        {
          index > 1 && (
            <span>
              {logicOperatorOptions.find((logicOperatorIter) => (
                logicOperatorIter.value === logicOperator
              )).label}
            </span>
          )
        }
      </div>
      <div className={FilterStyle.filterSelector}>
        <Cascader
          options={filterOptions(disableds)}
          expandTrigger="hover"
          displayRender={displayRender}
          onChange={handlerChangeFilter}
          value={filter?.name}
          placeholder="Choose a filter"
          disabled={disableCascader && filter?.name}
        />
      </div>
      {
        filter?.name && (
          <>
            <div className={FilterStyle.fieldLookupsOptions}>
              <Select
                style={{ width: '100%' }}
                options={fieldLookupsOptions}
                onChange={(value) => onChange({ ...filter, lookup: value }, logicOperator, index)}
                value={filter?.lookup}
              />
            </div>
            {
              !valueInLookup(filter) && (
                <div>
                  {renderInputValue()}
                </div>
              )
            }
          </>
        )
      }
    </div>
  );
};

Filter.propTypes = {
  index: PropTypes.number.isRequired,
  filter: PropTypes.shape({
    name: PropTypes.arrayOf(PropTypes.string),
    lookup: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.instanceOf(moment),
    ]),
    valueType: PropTypes.string,
  }).isRequired,
  disableds: PropTypes.arrayOf(PropTypes.string),
  logicOperator: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disableCascader: PropTypes.bool,
  disableLogicOperator: PropTypes.bool,
};

Filter.defaultProps = {
  disableds: [],
  logicOperator: logicOperatorOptions[0].value,
  disableCascader: false,
  disableLogicOperator: false,
};

export default Filter;
