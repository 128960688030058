import { consoleRequest } from './requests';

class AccountRequest {
  constructor() {
    this.base_url = 'api/account/v1/accounts/';
    this.base_url_search = 'api/account/v1/accounts-search/';
    this.base_url_context = '/api/core/v1/';
  }

  get(responseHandler, errorHandler, accountId = '') {
    const url = `${this.base_url}${accountId}`;
    consoleRequest.get(url)
      .then((res) => {
        responseHandler(res);
      }).catch((error) => {
        errorHandler(error);
      });
  }

  getInvoices(responseHandler, accountId) {
    const url = `${this.base_url}${accountId}/invoices/?status=open,closed`;
    consoleRequest.get(url)
      .then((res) => {
        responseHandler(res.data);
      });
  }

  getContextOptions(responseHandler) {
    const url = `${this.base_url_context}context/`;

    consoleRequest.get(url)
      .then((res) => {
        responseHandler(res.data);
      });
  }

  paginate(responseHandler, pageIndex, pageSize) {
    const params = {
      page: pageIndex + 1,
      page_size: pageSize,
    };
    const url = `${this.base_url_search}`;
    consoleRequest.get(url, { params })
      .then((res) => {
        responseHandler(res);
      });
  }

  /**
 * Sends a PATCH request to update an account with the specified account ID.
 * @param {function} responseHandler - The function to handle the response from the server.
 * @param {function} errorHandler - The function to handle any errors that occur during the request.
 * @param {string} accountId - The ID of the account to update.
 * @param {Object} data - The data to send in the request body.
 * @param {AbortController} controller - The AbortController object used to abort
 * the request if needed.
 */
  patch(responseHandler, errorHandler, accountId, data = {}, controller = {}) {
    const url = `${this.base_url}${accountId}/`;
    consoleRequest.patch(url, data, { signal: controller?.signal })
      .then((res) => {
        responseHandler(res);
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  async getSearch(params) {
    return consoleRequest.get(this.base_url_search, { params });
  }
}

export default new AccountRequest();
